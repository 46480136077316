import React, { useEffect } from "react";
import * as d3 from "d3";
import $ from "jquery";
import { WorldMapServiceData } from './WorldMapServiceData';
import * as topojson from 'topojson';
import { useErrorBoundary } from 'react-error-boundary';
import "./WorldMap.scss";

const WorldMap = (props) => {
    const { showBoundary } = useErrorBoundary();
    let bucketData = props.WorldMapData;

    d3.selectAll(".tooltipVisibility").remove();
    d3.selectAll(".worldMap").remove();

    const tooltip = d3.select('body')
    .append('div')
    .style('position', 'absolute')
    .style('z-index', '10')
    .style('display', 'none')
    .style('color', '#222')
    .style('padding', '8px')
    .style('background-color', 'rgba(255, 255, 255, 1)')
    .style('border-radius', '4px')
    .style('border', '1px solid transparent')
    .style('box-shadow', '0px 4px 4px 0px #2122263D,0px 0px 4px 0px #2122261F')

    .style('font', '14px lato')
    .attr('class', 'worldMap');
    
    useEffect(() => {
        try {
            $('#worldmap').empty();


            let countryTag = document.getElementById('country');
            if (countryTag) {
                countryTag.innerHTML = '';
            }


            const colorScale = d3.scaleQuantile()
                .domain(props.boudaryValues)
                .range(props.colors);

            let mapdata = {};
            let width = 700, height = 700;
            let projection = d3.geoMercator()
                .scale((width + 1) / 2 / Math.PI)
                .translate([width / 2, height / 2])
                .precision(.1);
            let path = d3.geoPath().projection(projection);

            let svg = d3.select('#worldmap').append('svg')
                .attr('width', width)
                .attr('height', height)
                .style("background-color", "white")
                .call(d3.zoom().scaleExtent([0, 2.5]).on("zoom", function (event) {
                    svg.attr("transform", "translate(" + event.transform.x + "," + event.transform.y + ") scale(" + event.transform.k + ")");
                }))
                .append('g');

            let mockdata = {
                "aggregations": {
                    "world_map": {
                        "buckets": bucketData
                    }
                }
            };

            mapdata = mockdata;
            draw(mockdata);

            function draw(data) {
                for (let geometry of WorldMapServiceData.GEOMETRIES) {
                    geometry.properties.count = 0;
                }

                let world = {
                    "type": "Topology",
                    "objects": {
                        "subunits": {
                            "type": "GeometryCollection",
                            "geometries": WorldMapServiceData.GEOMETRIES
                        }
                    },
                    "arcs": WorldMapServiceData.ARCS,
                    "transform": {
                        "scale": [0.036003600360036005, 0.016927109510951093],
                        "translate": [-180, -85.609038]
                    }
                };

                processWorldD(world, data);
            }

            function processWorldD(world, data) {
                for (var idx = 0; idx < data.aggregations.world_map.buckets.length; idx++) {
                    let cCode = data.aggregations.world_map.buckets[idx].countryCode.toUpperCase();
                    let count = data.aggregations.world_map.buckets[idx].count;
            
                    for (let wdx = 0; wdx < world.objects.subunits.geometries.length; wdx++) {
                        let cName = world.objects.subunits.geometries[wdx].id.toUpperCase();
                        if (cCode === cName) {
                            // Ensure name and count are set correctly
                            world.objects.subunits.geometries[wdx].properties.count = count;
                            world.objects.subunits.geometries[wdx].properties.name = world.objects.subunits.geometries[wdx].properties.name || "Unknown Country"; // Set name if not already set
                        }
                    }
                }
            
                let subunits = topojson.feature(world, world.objects.subunits);
                subunits.features = subunits.features.filter(d => d.id !== "ATA");
            
            
                let countries = svg.selectAll('path.subunit')
                    .data(subunits.features)
                    .enter().append('path')
                    .attr('class', d => 'subunit ca' + d.id)
                    .style('fill', d => (d.properties.count === 0 || d.properties.count === undefined) ? '#EEEEEE' : colorScale(d.properties.count))
                    .attr('d', path)
                    .on('mouseover', mouseoverLegend)
                    .on('mousemove', function(event, d) {
                        const pageX = event.pageX;
                        const pageY = event.pageY;
                        tooltip.style('top', (pageY + 30) + 'px').style('left', (pageX - 50) + 'px');
                    })
                    .on('mouseout', mouseoutLegend);
            }
            function mouseoverLegend(event, datum) {
                // Log the datum to inspect its properties
                const targetElement = event.target;

                // Access the data bound to the target element
                const data = targetElement.__data__; // Access the __data__ property
                const currentColor = d3.select(targetElement).style('fill');
      
        
                tooltip.html(`
                    <div style="display: block!important; align-items: center;">
                        <span style="display: inline-block; height: 12px; width: 12px; background-color: ${currentColor}; margin-right: 5px; border-radius: 2px;"></span>
                        ${data.properties['name']}: (${data.properties['count']})
                    </div>
                `);

                tooltip.style('display', 'block');
            
                // Adjust the opacity of the hovered country
           
            }
            

            function mouseoutLegend(datum) {
                d3.selectAll('.subunit.ca' + datum.id)
                    .style('fill', d => (d.properties.count === 0 || d.properties.count === undefined) ? '#EEEEEE' : colorScale(d.properties.count))
                    .style('opacity', '1');
                tooltip.style('display', 'none');
            }

        } catch (error) {
            showBoundary(error);
        }
    }, [bucketData, props.boudaryValues, props.colors, showBoundary]);

    return (
        <>
            <div className="paddingTop45 minHeight450">
                <div id="worldmap" className="margin-0-auto width700"></div>
            </div>
        </>
    );
}

export default React.memo(WorldMap);
