/**
 * @author mtiwari
 * Updated on 22 May, 2024
 * Corrected section tooltip structure while adding chartto custom dashboard
 */

import React, { useState, useEffect, useRef, useCallback } from "react";
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation } from 'react-router-dom';


import { Button, ButtonGroup, Dropdown, OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";


// Redux Imports
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { SearchState } from "store/Reducers/GuideInsights/searches";
import searchReducer from "store/Reducers/GuideInsights/searches";
import * as searchActions from "store/Actions/index";
import { CommonComponentState, LanguageState } from "store/Reducers";
import * as ActionTypes from "../../../store/Actions/ActionTypes";


import qs from 'querystring';
import { CommonUtils } from "utils/CommonUtils";
import { ApiRelativePaths } from "utils/GlobalConstants";
import { getCallExportExcel } from "utils/ApiCallActions";


// SCSS Imports
import "./Searches.scss";


import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";
import SectionHeader from "components/Layouts/SectionHeader";
import Datepicker from "components/Calender/Datepicker";
import SectionButtons from "components/Layouts/SectionButtons";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import EventSource from 'components/EventSource/EventSource';
import { withReducer } from "store/withReducer";
import Loader from "components/Loader/Loader";
import SearchDropdown from "components/SearchDropdown/SearchDropdown";
import WordCloud from "components/Chart/WordCloud";
import CustomDashboardModal from "components/CommonModal/CustomDashboardModal/CustomDashboardModal";
import CommonTable from "components/Table/CommonTable";
import Maintenance from "components/Maintenance/Maintenance";
import { useErrorBoundary } from 'react-error-boundary';



import { getSelectedLabels } from "utils/stringConstants/trans_utils";
import SectionFilters from "components/Layouts/SectionFilters";
import EnvironmentFilter from "components/EnvironmentFilter/EnvironmentFilter";
import { InsightLabels } from "labels";
import { v4 as uuidv4 } from 'uuid';
import UserInfoSection from "components/Layouts/UserInfoSection";

// type PathParamsType = {
//     // param1: string,
// }

// // Your component own properties
// type props = RouteComponentProps<PathParamsType> & {
//     // someString: string,
// }

interface CurrentState {
    searches: SearchState,
    commonComponentData: CommonComponentState,
    languageData: LanguageState
}

const Searches: React.FC = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)


    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    const dispatch = useDispatch();

    let tooltip: any;

    const location = useLocation();
    const navigate = useNavigate();

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    let fetched_details = useSelector((state: CurrentState) => state);

    const userListMount = useRef(false);
    const ChartMount = useRef(false);
    const StatsMount = useRef(false);
    const SummaryMount = useRef(false);

    const [PItracking, setPItracking] = useState({
        piTrackingEnabled: localStorage.getItem("USER_ACTIVITY") === 'true' ? true : false,
    });

    const [filterValues, setFilterValues] = useState({
        eventSource: "",
        guideStatus: ""
    });

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        loader2: false,
        loader3: false,
        disabled: true,
    });

    const [errorCount, setErrorCount] = useState({
        errorCount: [1, 0, 0, 0],
        showMaintenance_flag: false
    });

    const [listData, setListData] = useState({
        userData: [],
        userDataFetched: false,
    });

    const [selectedUser, setSelectedUser] = useState({
        searchedByUserEmail: "all",
        searchedByUserDisplayName: insightsLbls.allUsers,
    })

    const [searchChart, setSearchChart] = useState({
        chartData: [],
        chartDataFetched: false,
        stats: [],
        api: [],
        statsDataFetched: false
    })

    const [searchSummary, setSearchSummary] = useState({
        searchFetched: false,
        searchQuery: '',
        sortKey: 'search_performed',
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 1,
            'sortValue': 'desc'
        },
        // renderTaggedTable: false,
        sortValue: 'desc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true
    });


    const [request_uuid, setUuid] = useState({
        value: uuidv4()
    })

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
            window.scrollTo(0, 0);
            CommonUtils.LeftPanelHighlight(1, 7, 0, true, false);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        document.title = insightsLbls.docTitleSearches ? insightsLbls.docTitleSearches : "";
    }, [insightsLbls])

    useEffect(() => {

        setFilterValues((prevState: any) => {
            return {
                ...prevState,
                eventSource: fetched_details?.commonComponentData?.eventSource.eventSourceSearches,
            };
        });

    }, []);



    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const searchUsersListData = (reqUUID, apiErrorFlag?: string) => {
        let params: any;
        try {
            if (PItracking.piTrackingEnabled) {
                params = {
                    "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                    "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
                    "time_zone": timeZone,
                    "app_id": fetched_details?.commonComponentData.appsData.appId,
                    "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceSearches,
                    // 'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
                    'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
                    "env_code": fetched_details?.commonComponentData?.envFilter.envCode ?? '',
                    'request_uuid': reqUUID
                    // 'searched_by_user_email' : ""
                };


                searchActions.getSearchData(
                    params,
                    "SEARCH_USER_LIST",
                    ActionTypes.SET_SEARCH_USER_LIST,
                    apiErrorFlag ?? '',
                ).then((res: any) => dispatch(res));
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const searchActivitiesTilesData = (reqUUID, apiErrorFlag?: string) => {
        try {
            let params: any;

            if (PItracking.piTrackingEnabled && selectedUser.searchedByUserEmail !== "all") {
                params = {
                    "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                    "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
                    "time_zone": timeZone,
                    "app_id": fetched_details?.commonComponentData.appsData.appId,
                    "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceSearches,
                    'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
                    'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
                    'searched_by_user_email': encodeURIComponent(selectedUser.searchedByUserEmail),
                    "env_code": fetched_details?.commonComponentData?.envFilter.envCode ?? '',
                    'request_uuid': reqUUID
                };
            }
            else if ((!PItracking.piTrackingEnabled) || selectedUser.searchedByUserEmail === "all") {
                params = {
                    "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                    "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
                    "time_zone": timeZone,
                    "app_id": fetched_details?.commonComponentData.appsData.appId,
                    "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceSearches,
                    'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
                    'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
                    "env_code": fetched_details?.commonComponentData?.envFilter.envCode ?? '',
                    'request_uuid': reqUUID
                };
            }

            searchActions.getSearchData(
                params,
                "SEARCH_STATS",
                ActionTypes.SET_SEARCH_STATS_DATA,
                apiErrorFlag ?? '',
            ).then((res: any) => dispatch(res));
        } catch (error) {
            showBoundary(error)
        }

    }

    const getSearchChartData = (reqUUID, apiErrorFlag?: string) => {
        try {
            let params: any;

            if (PItracking.piTrackingEnabled && selectedUser.searchedByUserEmail !== "all") {
                params = {
                    "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                    "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
                    "time_zone": timeZone,
                    "app_id": fetched_details?.commonComponentData.appsData.appId,
                    "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceSearches,
                    'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
                    'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
                    'searched_by_user_email': encodeURIComponent(selectedUser.searchedByUserEmail),
                    "env_code": fetched_details?.commonComponentData?.envFilter.envCode ?? '',
                    'request_uuid': reqUUID

                };
            }
            else if ((!PItracking.piTrackingEnabled) || selectedUser.searchedByUserEmail === "all") {
                params = {
                    "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                    "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
                    "time_zone": timeZone,
                    "app_id": fetched_details?.commonComponentData.appsData.appId,
                    "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceSearches,
                    'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
                    'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
                    "env_code": fetched_details?.commonComponentData?.envFilter.envCode ?? '',
                    'request_uuid': reqUUID
                };
            }

            let path = ApiRelativePaths.SEARCH_CHART;
            let paramObj = {};
            const api = CommonUtils.URLGenerator(params, path, paramObj);

            setSearchChart((prevState: any) => {
                return {
                    ...prevState,
                    api: ['SEARCH_CHART', 'SEARCH_STATS'],
                    eventSource: fetched_details?.commonComponentData?.eventSource.eventSourceSearches,
                }
            })
            searchActions.getSearchData(
                params,
                "SEARCH_CHART",
                ActionTypes.SET_SEARCH_CHART_DATA,
                apiErrorFlag ?? '',
            ).then((res: any) => dispatch(res));
        } catch (error) {
            showBoundary(error)
        }

    }

    const getSearchSummary = (reqUUID, apiErrorFlag?: string) => {
        try {
            let params: any;

            if (PItracking.piTrackingEnabled && selectedUser.searchedByUserEmail !== "all") {
                params = {
                    "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                    "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
                    "time_zone": timeZone,
                    "app_id": fetched_details?.commonComponentData.appsData.appId,
                    "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceSearches,
                    'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
                    'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
                    'sort_by': searchSummary.sortKey,
                    'order': searchSummary.sortValue,
                    'query': encodeURIComponent(searchSummary.searchQuery),
                    'page_index': searchSummary.page,
                    'page_size': searchSummary.limit,
                    'searched_by_user_email': encodeURIComponent(selectedUser.searchedByUserEmail),
                    "env_code": fetched_details?.commonComponentData?.envFilter.envCode ?? '',
                    'request_uuid': reqUUID

                };
            }
            else if ((!PItracking.piTrackingEnabled) || selectedUser.searchedByUserEmail === "all") {
                params = {
                    "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                    "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
                    "time_zone": timeZone,
                    "app_id": fetched_details?.commonComponentData.appsData.appId,
                    "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceSearches,
                    'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
                    'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
                    'sort_by': searchSummary.sortKey,
                    'order': searchSummary.sortValue,
                    'query': encodeURIComponent(searchSummary.searchQuery),
                    'page_index': searchSummary.page,
                    'page_size': searchSummary.limit,
                    "env_code": fetched_details?.commonComponentData?.envFilter.envCode ?? '',
                    'request_uuid': reqUUID
                };
            }

            searchActions.getSearchData(
                params,
                "SEARCH_SUMMARY",
                ActionTypes.SET_SEARCH_SUMMARY,
                apiErrorFlag ?? '',
                searchSummary.firstLoad
            ).then((res: any) => dispatch(res));
        } catch (error) {
            showBoundary(error)
        }

    }

    const exportExcel = (reqUUID, data: any) => {
        try {
            let params: any;

            if (PItracking.piTrackingEnabled && selectedUser.searchedByUserEmail !== "all") {
                params = {
                    "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                    "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
                    "search_text": encodeURIComponent(searchSummary.searchQuery),
                    "time_zone": timeZone,
                    "app_id": fetched_details?.commonComponentData.appsData.appId,
                    "sort_by": searchSummary.sortKey,
                    "order": searchSummary.sortValue,
                    "page_index": searchSummary.page,
                    "page_size": searchSummary.limit,
                    "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceSearches,
                    "limit": searchSummary.limit,
                    'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
                    'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
                    'searched_by_user_email': encodeURIComponent(selectedUser.searchedByUserEmail),
                    "env_code": fetched_details?.commonComponentData?.envFilter.envCode ?? '',
                    'request_uuid': reqUUID

                };
            }
            else if ((!PItracking.piTrackingEnabled) || selectedUser.searchedByUserEmail === "all") {
                params = {
                    "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                    "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
                    "search_text": encodeURIComponent(searchSummary.searchQuery),
                    "time_zone": timeZone,
                    "app_id": fetched_details?.commonComponentData.appsData.appId,
                    "sort_by": searchSummary.sortKey,
                    "order": searchSummary.sortValue,
                    "page_index": searchSummary.page,
                    "page_size": searchSummary.limit,
                    "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceSearches,
                    "limit": searchSummary.limit,
                    'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
                    'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
                    "env_code": fetched_details?.commonComponentData?.envFilter.envCode ?? '',
                    'request_uuid': reqUUID
                };
            }
            getCallExportExcel(params, 'SEARCH_SUMMARY', data);
        } catch (error) {
            showBoundary(error)
        }

    }

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : 0
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }
    };

    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(0, false);
            enableExportPdf(1, false);
            enableExportPdf(2, false);
            enableExportPdf(3, false);

            switch (optn) {
                case 0: {
                    setListData((prevState: any) => ({
                        ...prevState,
                        userDataFetched: false,
                        chartData: [],
                    }));
            
                    setSearchChart((prevState: any) => ({
                        ...prevState,
                        chartDataFetched: false,
                        statsDataFetched: false,
                    }));
            
                    searchUsersListData(request_uuid.value);
            
                    if (selectedUser.searchedByUserEmail === "all") {
                        getSearchChartData(request_uuid.value);
                        searchActivitiesTilesData(request_uuid.value);
                    }
                    break;
                }
            
                case 1: {
                    setSearchSummary((prevState: any) => ({
                        ...prevState,
                        searchFetched: false,
                        searchQuery: '',
                        sortKey: 'search_performed',
                        filterLabels: [],
                        page: 1,
                        showExport: true,
                        defaultSort: {
                            sortColumn_index: 1,
                            sortValue: 'desc',
                        },
                        sortValue: 'desc',
                        limit: 5,
                        tablePagination: {
                            offset: 0,
                            data: [],
                            perPage: 5,
                            currentPage: 0,
                        },
                        firstLoad: true,
                    }));
            
                    break; // Added missing break statement
                }
            
                default:
                    break;
            }
            
        } catch (error) {
            showBoundary(error)
        }

    }

    const handleTableEvents = useCallback((option: string, data: any) => {
        try {
            switch (option) {

                case "sortColumn":
                    setSearchSummary((prevState) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        }
                    });

                    break;

                case "changePage":

                    setSearchSummary((prevState) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "searchTxt":

                    setSearchSummary((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false
                        }
                    });

                    break;

                case "changeEntries":

                    setSearchSummary((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "exportExcel":
                    exportExcel(request_uuid.value, data)
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [searchSummary]);

    const getUserStatus = (user: any) => {
        try {
            const displayName = user['label']
            const email = user['value'];
            setSelectedUser((prevState: any) => {
                return {
                    ...prevState,
                    searchedByUserEmail: email,
                    searchedByUserDisplayName: displayName,
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // Search Users List Data
    useEffect(() => {
        try {
            if (
                fetched_details.searches.searchUserList.data !== undefined &&
                fetched_details.searches.searchUserList.result === "success" &&
                fetched_details?.searches.searchUserList.requestUUID === request_uuid.value
            ) {
                userListMount.current = true;
                setListData((prevState: any) => {
                    return {
                        ...prevState,
                        userDataFetched: true,
                        userData: fetched_details.searches.searchUserList.data.userList
                    }
                })
                apiCatchError(0, 0);
                enableExportPdf(0, true);
            } else if (fetched_details.searches.searchUserList.result === 'retry') {
                searchUsersListData(request_uuid.value, 'retry');
            } else if (fetched_details.searches.searchUserList.result === "error") {
                setListData((prevState: any) => {
                    return {
                        ...prevState,
                        userDataFetched: true,
                        chartData: []
                    }
                })
                apiCatchError(0, 1);
                enableExportPdf(0, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.searches.searchUserList.data]);




    // Api calls on user change
    useEffect(() => {
        try {
            if (userListMount.current === true) {

                enableExportPdf(1, false);
                enableExportPdf(2, false);
                enableExportPdf(3, false);

                setSearchChart((prevState: any) => {
                    return {
                        ...prevState,
                        chartDataFetched: false,
                        statsDataFetched: false
                    }
                })

                setSearchSummary((prevState: any) => {
                    return {
                        ...prevState,
                        searchFetched: false,

                        searchQuery: '',
                        sortKey: 'search_performed',
                        filterLabels: [],
                        page: 1,
                        showExport: true,
                        defaultSort: {
                            'sortColumn_index': 1,
                            'sortValue': 'desc'
                        },
                        // renderTaggedTable: false,
                        sortValue: 'desc',
                        limit: 5,
                        tablePagination: {
                            offset: 0,
                            data: [],
                            perPage: 5,
                            currentPage: 0
                        },
                        firstLoad: true
                    };
                });

                getSearchChartData(request_uuid.value);
                searchActivitiesTilesData(request_uuid.value);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [selectedUser.searchedByUserEmail]);

    // Search Chart Data
    useEffect(() => {
        try {
            if (
                fetched_details.searches.searchChartData.data !== undefined &&
                fetched_details.searches.searchChartData.result === "success" &&
                fetched_details?.searches.searchChartData?.requestUUID === request_uuid.value
            ) {
                ChartMount.current = true;
                setSearchChart((prevState: any) => {
                    return {
                        ...prevState,
                        chartDataFetched: true,
                        chartData: fetched_details.searches.searchChartData.data.wordData
                    }
                })

                apiCatchError(1, 0);
                enableExportPdf(1, true);
            } else if (fetched_details.searches.searchChartData.result === 'retry') {
                getSearchChartData(request_uuid.value, 'retry');
            } else if (fetched_details.searches.searchChartData.result === "error") {

                setSearchChart((prevState: any) => {
                    return {
                        ...prevState,
                        chartDataFetched: true,
                        chartData: []
                    }
                })
                apiCatchError(1, 1);
                enableExportPdf(1, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.searches.searchChartData.data]);

    // Search Stats Data
    useEffect(() => {
        try {
            if (
                fetched_details.searches.searchStatsData.data !== undefined &&
                fetched_details.searches.searchStatsData.result === "success" &&
                fetched_details?.searches.searchStatsData.requestUUID === request_uuid.value
            ) {
                StatsMount.current = true;
                for (let i = 0; i < fetched_details.searches.searchStatsData.data.searchStats.length; i++) {
                    if (i === 0)
                        fetched_details.searches.searchStatsData.data.searchStats[i].tooltip = insightsLbls['searchCount'];
                    else if (i === 1)
                        fetched_details.searches.searchStatsData.data.searchStats[i].tooltip = insightsLbls['actionCount']

                }
                setSearchChart((prevState: any) => {
                    return {
                        ...prevState,
                        statsDataFetched: true,
                        stats: fetched_details.searches.searchStatsData.data.searchStats
                    }
                })

                apiCatchError(2, 0);
                enableExportPdf(2, true);
            } else if (fetched_details.searches.searchChartData.result === 'retry') {
                searchActivitiesTilesData(request_uuid.value, 'retry');
            } else if (fetched_details.searches.searchStatsData.result === "error") {
                setSearchChart((prevState: any) => {
                    return {
                        ...prevState,
                        statsDataFetched: true,
                        stats: []
                    }
                })
                apiCatchError(2, 1);
                enableExportPdf(2, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.searches.searchStatsData.data]);

    // Search Summary Data
    useEffect(() => {
        try {
            if (
                fetched_details.searches.searchSummary.data !== undefined &&
                fetched_details.searches.searchSummary.result === "success" &&
                fetched_details?.searches.searchSummary.requestUUID === request_uuid.value
            ) {
                SummaryMount.current = true;
                setSearchSummary((prevState: any) => {
                    return {
                        ...prevState,
                        searchFetched: true,
                    }
                })

                apiCatchError(3, 0);
                enableExportPdf(3, true);
            } else if (fetched_details.searches.searchChartData.result === 'retry') {
                getSearchSummary(request_uuid.value, 'retry');
            } else if (fetched_details.searches.searchSummary.result === "error") {
                setSearchSummary((prevState: any) => {
                    return {
                        ...prevState,
                        searchFetched: true,
                    }
                })
                apiCatchError(3, 1);
                enableExportPdf(3, false);

            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.searches.searchSummary.data]);

    // UseEffect for date, app, filters change
    useEffect(() => {
        try {
            let queryParams = {
                app_id: fetched_details.commonComponentData.appsData.appId,
                app_code: fetched_details.commonComponentData.appsData.appCode,
                fdate: fetched_details?.commonComponentData.datesData.fdate,
                tdate: fetched_details?.commonComponentData.datesData.tdate,
                event_source: fetched_details?.commonComponentData?.eventSource.eventSourceSearches,
                env: fetched_details?.commonComponentData?.envFilter?.envCode,
            }

            navigate({
                pathname: `/guide-insights/search-activities/`,
                search: qs.stringify(queryParams)
            })

            if (fetched_details?.commonComponentData.datesData.source === 'component' &&
                fetched_details?.commonComponentData.appsData?.appCode === localStorage.getItem('switch_app_code') &&
                fetched_details?.commonComponentData?.envFilter.listFetched) {
                // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
                if (fetched_details?.commonComponentData.datesData?.url.includes('search-activities')) {

                    const reqUUID = uuidv4();
                    setUuid((prevState: any) => {
                        return {
                            ...prevState,
                            value: reqUUID
                        }
                    })

                    enableExportPdf(0, false);
                    enableExportPdf(1, false);
                    enableExportPdf(2, false);
                    enableExportPdf(3, false);

                    setListData((prevState: any) => {
                        return {
                            ...prevState,
                            userDataFetched: false,
                            chartData: []
                        }
                    })

                    setFilterValues((prevState: any) => {
                        return {
                            ...prevState,
                            eventSource: fetched_details?.commonComponentData?.eventSource.eventSourceSearches,
                        };
                    });
                    setSearchChart((prevState: any) => {
                        return {
                            ...prevState,
                            chartDataFetched: false,
                            statsDataFetched: false
                        }
                    })

                    setSearchSummary((prevState: any) => {
                        return {
                            ...prevState,
                            searchFetched: false,
                            firstLoad: true,
                            searchQuery: '',
                            sortKey: 'search_performed',
                            filterLabels: [],
                            page: 1,
                            showExport: true,
                            defaultSort: {
                                'sortColumn_index': 1,
                                'sortValue': 'desc'
                            },
                            // renderTaggedTable: false,
                            sortValue: 'desc',
                            limit: 5,
                            tablePagination: {
                                offset: 0,
                                data: [],
                                perPage: 5,
                                currentPage: 0
                            },
                        }
                    })

                    searchUsersListData(reqUUID);
                    getSearchChartData(reqUUID);
                    searchActivitiesTilesData(reqUUID);
                }
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [
        fetched_details?.commonComponentData?.eventSource.eventSourceSearches,
        fetched_details.commonComponentData.datesData,
        fetched_details?.commonComponentData?.envFilter,
        fetched_details.languageData.languageData])

    // Call summary table api on table related actions changes
    useEffect(() => {
        try {
            // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
            if (fetched_details?.commonComponentData?.datesData?.url?.includes('search-activities') || fetched_details?.commonComponentData?.datesData?.dateSelection === 15) {
                enableExportPdf(3, false);
                getSearchSummary(request_uuid.value);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        searchSummary.sortKey,
        searchSummary.sortValue,
        searchSummary.defaultSort,
        searchSummary.page,
        searchSummary.searchQuery,
        searchSummary.limit,
    ]);

    //Export PDF Button
    useEffect(() => {
        try {

            if (loaderBtn.loader1 && loaderBtn.loader2 && loaderBtn.loader3) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            } else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader1, loaderBtn.loader2, loaderBtn.loader3])


    useEffect(() => {
        try {
            const result = errorCount.errorCount.every((val) => val === 1);

            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    showMaintenance_flag: result
                }
            })
        } catch (error) {
            showBoundary(error)
        }


    }, [errorCount.errorCount])


    const getDataValue = (value: string) => {
        try {
            switch (value) {
                case 'Performed Searches':
                    return insightsLbls.performedSearches;

                case 'Searches Resulted In Action':
                    return insightsLbls.searchesResultedIntoAction;

            }
        } catch (error) {
            showBoundary(error)
        }
    }


    return (
        <section className="demo searchSection width100" ref={componentRef}>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li className="active">{insightsLbls.searches}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <EnvironmentFilter component={"searches"} disabled={false} />
                    <EventSource component={"searches"}></EventSource>
                    <Datepicker source={'component'}></Datepicker>
                </SectionFilters>
            </div>

            {/* <SectionButtons sectionTitle={insightsLbls.searches} svgImage={"searches"} className={'marginTop-50'}>
                <ExportToPdf
                    componentRef={componentRef}
                    source={"Searches"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
            </SectionButtons> */}


            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={insightsLbls.searches}
                                widthDividedBy={12}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                dateObject={fetched_details?.commonComponentData?.datesData}
                messageText={insightsLbls.search}>
            </UserInfoSection>

            <div id="SearchSection">
                {

                    // <SectionRefresh sectionTitle={insightsLbls.searchOverview} refreshClass={""}>

                    //     <i className="fa fa-refresh pull-right cursorPointer row displayFlex"
                    //         aria-hidden="true"
                    //         title={insightsLbls.refresh}
                    //         onClick={() => refreshSections(0)}></i>
                    //     <CustomDashboardModal
                    //         api={searchChart.api}
                    //         eventSource={filterValues.eventSource}
                    //         guideStatus={filterValues.guideStatus}
                    //         chartType={"WordCloud"}
                    //         sectionHeader={insightsLbls.searchOverview}
                    //         sectionTooltip={
                    //             {
                    //                 "subTitle": insightsLbls.searchKeywords,
                    //                 "tooltipText": insightsLbls.wordClouseTooltipText,
                    //                 "childern": []
                    //             }
                    //         }
                    //         sectionType={'type1'}
                    //         guide_filter={false}
                    //         event_filter={true}
                    //         segment_filter={false}
                    //         tooltip_filter={false}
                    //         component={'searches'}
                    //     ></CustomDashboardModal>
                    // </SectionRefresh>
                }
                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height600 blockImportant">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        {
                            PItracking.piTrackingEnabled === true ?
                                (
                                    <>
                                        <div className="">
                                            <div className="filterDiv">
                                                <div className="col-sm-12 col-md-12 col-lg-12">
                                                    {
                                                        listData.userDataFetched ?
                                                            (
                                                                errorCount.errorCount[0] === 0 ?
                                                                    (
                                                                        <SearchDropdown list={listData.userData} dispatchStatus={getUserStatus} source={"searches"}></SearchDropdown>
                                                                    )
                                                                    :
                                                                    (
                                                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                            {insightsLbls.serviceNotAvailable}
                                                                        </h5>
                                                                    )
                                                            )
                                                            :
                                                            (
                                                                <div className="loaderHeight width100 displayFlex height100-percent positionRelative minHeight90">
                                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                                        <Loader height={75} width={100}></Loader>
                                                                    </div>
                                                                </div>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <hr></hr>
                                    </>
                                )
                                :
                                (
                                    null
                                )
                        }
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            {
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.searchKeywords,
                                        "tooltipText": insightsLbls.wordClouseTooltipText,
                                        "childern": []
                                    }
                                } placement="right"></SectionTooltip>
                            }


                            <span>
                                <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(0)}> {insightsLbls.refresh}</button>
                                <CustomDashboardModal
                                    api={searchChart.api}
                                    eventSource={filterValues.eventSource}
                                    guideStatus={filterValues.guideStatus}
                                    chartType={"WordCloud"}
                                    sectionHeader={insightsLbls.searchOverview}
                                    sectionTooltip={
                                        {
                                            "subTitle": insightsLbls.searchKeywords,
                                            "tooltipText": insightsLbls.wordClouseTooltipText,
                                            "childern": []
                                        }
                                    }
                                    sectionType={'type1'}
                                    guide_filter={false}
                                    event_filter={true}
                                    segment_filter={false}
                                    tooltip_filter={false}
                                    component={'searches'}
                                ></CustomDashboardModal>
                            </span>
                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            {searchChart.chartDataFetched ? (
                                errorCount.errorCount[1] === 0 ? (
                                    searchChart.chartData.length === 0 ? (
                                        <div className="errorCss alignCenter justifyCenter minHeight490 card-heading font-weight-400 text-center">
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (
                                        <div className="displayFlex minHeight490">
                                            <div className="col-sm-9 col-md-9 col-lg-9">
                                                <WordCloud chartData={searchChart.chartData}></WordCloud>
                                            </div>

                                            <div className="col-sm-3 col-md-3 col-lg-3">
                                                {
                                                    searchChart.statsDataFetched ? (
                                                        <div className="positionAbsolute right15">
                                                            {
                                                                searchChart.stats.map((data: any, index: number) => {
                                                                    return (
                                                                        <div className="" key={index}>
                                                                            <div className={"borderBottom_" + `${index}` + " card tiles-border tileBoxes"} >
                                                                                <div className="heigth100" >
                                                                                    <div className="stat-icon">
                                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 search-tooltip">
                                                                                            <div className="searchTile-tooltip headingText height0">

                                                                                                {
                                                                                                    tooltip = (
                                                                                                        <Tooltip id="tooltip">
                                                                                                            <br></br>
                                                                                                            <div><span className="">{data.tooltip}</span></div>
                                                                                                            <br></br>
                                                                                                        </Tooltip>
                                                                                                    )
                                                                                                }

                                                                                                <OverlayTrigger placement={"bottom"} overlay={tooltip}>
                                                                                                    <i className="fa fa-info-circle info-icon-color" style={{ marginLeft: '10px' }} aria-hidden="true" >
                                                                                                    </i>
                                                                                                </OverlayTrigger>

                                                                                            </div>


                                                                                            <div className={"statColor_" + `${index}` + " stat-digit font_28"}>{data.value}</div>
                                                                                            <div className="stat-digit">{getDataValue(data.key)}</div>



                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                        :
                                                        (
                                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                    <Loader></Loader>
                                                                </div>
                                                            </div>
                                                        )
                                                }
                                            </div>
                                        </div>

                                    )
                                ) : (
                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                        {insightsLbls.serviceNotAvailable}
                                    </h5>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}
                        </div>
                    </CardContainer>

                    {/* <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            {
                                (JSON.stringify(componentText['searchSummary']) !== '{}' && componentText['searchSummary'] !== undefined) ?
                                    (<SectionTooltip tooltipText={componentText['searchOverview'].section[1]} placement="right"></SectionTooltip>) : (null)
                            }
                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            {
                                searchSummary.searchFetched ? (
                                    errorCount.errorCount[1] === 0 ? (
                                        <div className=" commonSummaryTable">
                                            <CommonTable tableId="1"
                                                data={fetched_details.searches.searchSummary.data}
                                                currentPage={searchSummary.page}
                                                barColors=""
                                                linkPresent={false}
                                                showExport={true}
                                                showSearch={true}
                                                showEntries={true}
                                                defaultSort={searchSummary.defaultSort}
                                                tablePagination={searchSummary.tablePagination}
                                                tableEvent={handleTableEvents}
                                                firstTimeloadFlag={fetched_details.searches.searchSummary.firstTimeload}
                                                modalTable={false}

                                            />
                                        </div>
                                    )
                                        : errorCount.errorCount[1] === 1 ? (
                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                {insightsLbls.serviceNotAvailable}
                                            </h5>
                                        )
                                            : null)
                                    : (
                                        <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader></Loader>
                                            </div>
                                        </div>
                                    )
                            }
                        </div>

                    </CardContainer> */}
                </div>

                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height600 blockImportant marginTop-20">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            <SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.summary,
                                    "tooltipText": insightsLbls.tableOfSearchSummary,
                                    "childern": [
                                        {
                                            "key": insightsLbls.keyword + ":",
                                            "value": insightsLbls.searchKeystrokes
                                        },
                                        {
                                            "key": insightsLbls.numberOfSearches + ":",
                                            "value": insightsLbls.keystrokesHitByUser
                                        },
                                        {
                                            "key": insightsLbls.avgResultRetured + ":",
                                            "value": insightsLbls.avgKeystrokes
                                        },
                                        {
                                            "key": insightsLbls.clicks + ":",
                                            "value": insightsLbls.totalClickSearchResult
                                        }
                                    ]
                                }
                            } placement="right"></SectionTooltip>
                            <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(1)}> {insightsLbls.refresh}</button>

                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            {
                                searchSummary.searchFetched ? (
                                    errorCount.errorCount[3] === 0 ? (
                                        <div className=" commonSummaryTable">
                                            <CommonTable tableId="1"
                                                data={fetched_details.searches.searchSummary.data}
                                                currentPage={searchSummary.page}
                                                barColors=""
                                                linkPresent={false}
                                                showExport={true}
                                                showSearch={true}
                                                showEntries={true}
                                                defaultSort={searchSummary.defaultSort}
                                                tablePagination={searchSummary.tablePagination}
                                                tableEvent={handleTableEvents}
                                                firstTimeloadFlag={fetched_details.searches.searchSummary.firstTimeload}
                                                modalTable={false}

                                            />
                                        </div>
                                    )
                                        : errorCount.errorCount[3] === 1 ? (
                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                {insightsLbls.serviceNotAvailable}
                                            </h5>
                                        )
                                            : null)
                                    : (
                                        <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader></Loader>
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                    </CardContainer>
                </div>
            </div>



            {/* <div id="SearchSummarySection" className="">
                {
                    (JSON.stringify(componentText['searchSummary']) !== '{}' && componentText['searchSummary'] !== undefined) ?
                        (
                            <SectionRefresh sectionTitle={componentText['searchSummary'].title} refreshClass={"marginTop-30"}>
                                <i className="fa fa-refresh pull-right cursorPointer row displayFlex"
                                    aria-hidden="true"
                                    title={insightsLbls['refresh']}
                                    onClick={() => refreshSections(1)}></i>
                            </SectionRefresh>
                        ) : (null)
                }
                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height600 blockImportant">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            {
                                (JSON.stringify(componentText['searchSummary']) !== '{}' && componentText['searchSummary'] !== undefined) ?
                                    (<SectionTooltip tooltipText={componentText['searchSummary'].section[0]} placement="right"></SectionTooltip>) : (null)
                            }
                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            {
                                searchSummary.searchFetched ? (
                                    errorCount.errorCount[1] === 0 ? (
                                        <div className=" commonSummaryTable">
                                            <CommonTable tableId="1"
                                                data={fetched_details.searches.searchSummary.data}
                                                currentPage={searchSummary.page}
                                                barColors=""
                                                linkPresent={false}
                                                showExport={true}
                                                showSearch={true}
                                                showEntries={true}
                                                defaultSort={searchSummary.defaultSort}
                                                tablePagination={searchSummary.tablePagination}
                                                tableEvent={handleTableEvents}
                                                firstTimeloadFlag={fetched_details.searches.searchSummary.firstTimeload}
                                                modalTable={false}

                                            />
                                        </div>
                                    )
                                        : errorCount.errorCount[1] === 1 ? (
                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                {insightsLbls.serviceNotAvailable}
                                            </h5>
                                        )
                                            : null)
                                    : (
                                        <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader></Loader>
                                            </div>
                                        </div>
                                    )
                            }
                        </div>

                    </CardContainer>
                </div>
            </div> */}

            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}
        </section>

    )
}

export default withRouter(withReducer("searches", searchReducer)(Searches));

