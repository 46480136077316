import React, { useEffect, useState } from "react";
import CommonModal from "../CommonModal/CommonModal";
import { InsightLabels } from "./../../labels";
import { useSelector } from "react-redux";
import { LanguageState } from "./../../store/Reducers";
import { getCall } from "utils/ApiCallActions";
import { RetryApi } from "utils/GlobalConstants";
import { useErrorBoundary } from "react-error-boundary";
import Loader from "components/Loader/Loader";

const SelectedTooltipModal = ({ isOpen, onClose, data, appCode, fromComponent }) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);
    const [tooltipData, setTooltipData] = useState({
        isLoading: false,
        data: [],
        error: false
    });
    const { showBoundary } = useErrorBoundary();
    const [isTooltipTop, setToolTipPosition] = useState(false);
    const [searchText, setSearchText] = useState("");

    let fetched_details_language = useSelector((state: LanguageState) => state.languageData);

    useEffect(() => {
        if (fetched_details_language.languageData) {
            setInsightsLbls(fetched_details_language.languageData);
        }

    }, [fetched_details_language.languageData])

    useEffect(() => {
        if (isOpen) {
            getTooltipList("");
        }
    }, [isOpen])

    const getTooltipList = (apiErrorFlag?: string) => {
        try {
            const params = {
                app_code: appCode,
                search_text: "",
                page_index: 1,
                page_size: 99999,
                exclude_weekends: true,
            };
            setTooltipData({ isLoading: true, data: [], error: false });
            getCall(params, "TOOLTIP_LIST", apiErrorFlag ?? "").then((res: any) => {
                if (res.result === "success") {
                    setTooltipData({ isLoading: false, data: res?.data?.data, error: false });
                } else if (res.result === "retry") {
                    setTimeout(() => {
                        getTooltipList('retry')
                    }, RetryApi.TIMEOUT);
                } else if (res.result === "error") {
                    setTooltipData({ isLoading: false, data: [], error: true });
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const onSearchText = (event: React.ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value);

    const getLoaderTemplate = () => {
        return <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight300">
            <div className="displayFlex alignCenter margin-0-auto">
                <Loader></Loader>
            </div>
        </div>
    }

    const getNoDataFoundTemplate = () => {
        return <div className="noDataText-font textCenter-absolute">
            {insightsLbls.noRecordFound}
        </div>
    }

    const getServiceNotAvailableTemplate = () => {
        return <h5 className="errorCss card-heading font-weight-400 text-center">
            {insightsLbls.serviceNotAvailable}
        </h5>
    }

    const getFilteredData = () => {
        if (!searchText) return tooltipData.data;
        return tooltipData.data.filter(tooltip => tooltip.guide_title.toLowerCase().includes(searchText.toLowerCase()));
    }

    const getTooltipDataTemplate = () => {
        return <div>
            {getFilteredData().map(tooltip => {
                if (data.includes(tooltip.guide_id)) {
                    return <label
                        className={`check-container guideListCard stpesLabel displayBlock alignCenter ${tooltip.is_deleted === 1 ? "deletedToolTipLabel" : ""
                            }`}
                        key={tooltip.guide_id}
                        id={"label_" + `${tooltip.guide_id}`}
                    >
                        <span
                            className={`toolTipTitleOverflow " ${tooltip.is_deleted === 1 ? "_85Width" : "_100Width"
                                }`}
                            onMouseEnter={(event) =>
                                setToolTipPosition(
                                    event.clientY > window.innerHeight * 0.5
                                )
                            }
                        >
                            {tooltip.guide_title}
                            <span
                                className={
                                    isTooltipTop
                                        ? "tooltiptextTop"
                                        : "tooltiptextBottom"
                                }
                            >
                                {tooltip.guide_title}
                            </span>
                        </span>
                        {tooltip.is_deleted === 1 ? (
                            <span className="_16Width stepDeleted">
                                {"(" + insightsLbls.deleted + ")"}
                            </span>
                        ) : null}
                    </label>
                }
            })
            }
        </div>
    }

    return (
        <CommonModal
            modalState={isOpen}
            footerDisabled="true"
            dispatchModalState={onClose}
            modalTitle={insightsLbls.selectedTooltipList}
        >
            {isOpen && <div onClick={(e) => e.stopPropagation()}>
                <div className="col-lg-12 paddingLR-30 searchBox inline-block">
                    <p className="pull-right search-field-wrapper floatRT marginBottom0">
                        <input
                            type="text"
                            className="positionRelative right15 searchIcon form-control round-input margin10"
                            aria-label="Search"
                            placeholder={insightsLbls.search}
                            autoComplete="off"
                            onChange={(event) => onSearchText(event)}
                        />
                    </p>
                </div>
                <div className="tooltipList">
                    {tooltipData.isLoading ? getLoaderTemplate() :
                        (tooltipData.error ? getServiceNotAvailableTemplate() : (
                            tooltipData.data.length == 0 ? getNoDataFoundTemplate() : getTooltipDataTemplate()
                        ))}
                </div>
            </div>}
        </CommonModal>
    );
};

export default SelectedTooltipModal;