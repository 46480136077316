/**
 * @author mtiwari
 * Updated on 21 May, 2024
 * Setted guide status and segment status as false, while adding custom dashboard chart, 
 * as segment and guide is not valid filter for PUSH_NOTIFICATION and BEACON chart
 */

import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import withRouter from "hoc/withRouter";
import { withReducer } from "store/withReducer";
import { useErrorBoundary } from 'react-error-boundary';

// Redux Imports
import { useDispatch, useSelector } from "react-redux";
import * as ActionTypes from "../../../store/Actions/ActionTypes";

import qs from 'querystring';
import Datepicker from "components/Calender/Datepicker";
import EnvironmentFilter from "components/EnvironmentFilter/EnvironmentFilter";
import SectionFilters from "components/Layouts/SectionFilters";
import SectionHeader from "components/Layouts/SectionHeader";
import StateFilter from "components/StateFilter/StateFilter";
import Segments from 'components/SegmentationFilter/Segmentation';
import EventSource from 'components/EventSource/EventSource';
import { EventSourceState, UrlOptionState, EnvFilterState, CommonComponentState, LanguageState } from "store/Reducers";
import { CommonUtils } from "utils/CommonUtils";
import pushNotificationsBeaconsReducer from "store/Reducers/GuideInsights/pushNotificationsBeacons";
import * as pushNotificationsBeacons from "store/Actions/index";
import { PushNotificationsBeaconsState } from "store/Reducers/GuideInsights/pushNotificationsBeacons";

import { InsightLabels } from "labels";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import SectionButtons from "components/Layouts/SectionButtons";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";
import Loader from "components/Loader/Loader";
import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import LineChart from "components/Chart/nvd3Charts/LineChart";
import { ChartColors, RetryApi } from "utils/GlobalConstants";
import CustomDashboardModal from "components/CommonModal/CustomDashboardModal/CustomDashboardModal";
import { v4 as uuidv4 } from 'uuid';
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";
import UserInfoSection from "components/Layouts/UserInfoSection";


interface CurrentState {
    languageData: LanguageState,
    commonComponentData: CommonComponentState,
    urlOption: UrlOptionState,
    pushNotificationsBeacons: PushNotificationsBeaconsState,
}

const PushNotificationsBecons: React.FC = (props: any) => {
    const navigate = useNavigate();
    const { showBoundary } = useErrorBoundary();
    const dispatch = useDispatch();
    const componentRef = useRef() as React.RefObject<HTMLDivElement>;

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);
    let fetched_details = useSelector((state: CurrentState) => state);

    let linechart_height = 400;

    let lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "75",
    }

    const [filterValues, setFilterValues] = useState({
        eventSource: "",
        guideStatus: "",
        segmentsGroup: {
            segmentsFilter: 0,
            segmentTitle: ''
        }
    });

    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0],
        showMaintenance_flag: false
    });

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        disabled: true,
    });

    const [guideAnalyticsPushNotificationsChart, setGuideAnalyticsPushNotificationsChart] = useState({
        loadPushNotificationsChartData: [],
        guideAnalyticsPushNotificationsChartDataFetched: false,
        pushNotificationsChart: {},
        pushNotificationChartOptions: [],
        pushNotificationsChartFetched: false,
        api: '',
    });

    const [guideAnalyticsBeaconsChart, setGuideAnalyticsBeaconsChart] = useState({
        loadBeaconsChartData: [],
        guideAnalyticsBeaconsChartDataFetched: false,
        beaconsChartOptions: [],
        beaconsChart: {},
        beaconsChartFetched: false,
        api: '',
    });

    const [request_uuid, setUuid] = useState({
        value: uuidv4()
    })

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            window.scrollTo(0, 0);
            CommonUtils.LeftPanelHighlight(1, 3, 0, true, false);
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData]);

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        document.title = insightsLbls.pushNotificationsAndBeacons ? insightsLbls.pushNotificationsAndBeacons : "";
    }, [insightsLbls])

    useEffect(() => {
        setFilterValues((prevState: any) => {
            return {
                ...prevState,
                eventSource: fetched_details?.commonComponentData?.eventSource.eventSourcePushNotificationsBeacons,
                guideStatus: fetched_details?.commonComponentData?.stateFilterData.stateFilter,
                segmentsGroup: { segmentsFilter: fetched_details?.commonComponentData?.segmentsFilter, segmentTitle: fetched_details?.commonComponentData?.segmentsFilter?.segmentTitle },
            };
        });
    }, []);


    // Sidenav hightlight on scroll
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            setTimeout(() => {
                if (process.env.REACT_APP_WESTPAC === 'true') {
                    if (currentScrollY >= 530) {
                        CommonUtils.LeftPanelHighlight(1, 3, 1, true, false);
                    } else {
                        CommonUtils.LeftPanelHighlight(1, 3, 0, true, false);
                    }
                }
                else {
                    if (currentScrollY >= 500) {
                        CommonUtils.LeftPanelHighlight(1, 3, 1, true, false);
                    } else {
                        CommonUtils.LeftPanelHighlight(1, 3, 0, true, false);
                    }
                }
            }, 0);
        };

        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => window.removeEventListener("scroll", handleScroll);
    }, [window.scrollY]);


    // Scroll on sidenav click
    useEffect(() => {
        let queryParams = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            fdate: fetched_details?.commonComponentData?.datesData.fdate,
            tdate: fetched_details?.commonComponentData?.datesData.tdate,
            event_source: fetched_details?.commonComponentData?.eventSource.eventSourcePushNotificationsBeacons,
            state: fetched_details?.commonComponentData?.stateFilterData.stateFilter,
            segment: fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            option: fetched_details?.urlOption.urlOption,
        }

        navigate({
            pathname: `/guide-insights/push-notifications-beacons/`,
            search: qs.stringify(queryParams)
        })

        if (fetched_details?.urlOption.urlOption === 'PushNotifications' || fetched_details?.urlOption.urlOption === '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        else {
            window.scrollTo({ top: 630, behavior: 'smooth' });
        }

    }, [fetched_details?.urlOption.urlOption])


    useEffect(() => {
        try {
            if (Object.keys(fetched_details.commonComponentData.datesData).length !== 0) {
                let queryParams = {
                    app_id: fetched_details?.commonComponentData.appsData.appId,
                    app_code: fetched_details?.commonComponentData.appsData.appCode,
                    fdate: fetched_details?.commonComponentData?.datesData.fdate,
                    tdate: fetched_details?.commonComponentData?.datesData.tdate,
                    event_source: fetched_details?.commonComponentData?.eventSource.eventSourcePushNotificationsBeacons,
                    option: fetched_details?.urlOption.urlOption,
                }
                navigate({
                    pathname: `/guide-insights/push-notifications-beacons/`,
                    search: qs.stringify(queryParams)
                })

                if (fetched_details?.commonComponentData?.datesData.source === 'component' &&
                    fetched_details?.commonComponentData.appsData?.appCode === localStorage.getItem('switch_app_code')) {

                    if (fetched_details.commonComponentData.datesData.url.includes('push-notifications-beacons')) {

                        const reqUUID = uuidv4();
                        setUuid((prevState: any) => {
                            return {
                                ...prevState,
                                value: reqUUID
                            }
                        })

                        setFilterValues((prevState: any) => {
                            return {
                                ...prevState,
                                eventSource: fetched_details?.commonComponentData?.eventSource.eventSourcePushNotificationsBeacons
                            };
                        });

                        setGuideAnalyticsPushNotificationsChart((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsPushNotificationsChartDataFetched: false,
                                pushNotificationsChartFetched: false,
                                pushNotificationsChart: {},
                                pushNotificationChartOptions: []
                            };
                        });
                        enableExportPdf(0, false);
                        getGuideAnalyticsPushNotificationsChartData(reqUUID);

                        setGuideAnalyticsBeaconsChart((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsBeaconsChartDataFetched: false,
                                beaconChartFetched: false,
                                beaconsChart: {},
                                beaconsChartOptions: []
                            };
                        });
                        enableExportPdf(1, false);
                        getGuideAnalyticsBeaconsChartData(reqUUID);
                    }
                }
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details.commonComponentData?.datesData,
    fetched_details.commonComponentData?.stateFilterData,
    fetched_details?.commonComponentData?.eventSource?.eventSourcePushNotificationsBeacons,
    fetched_details?.commonComponentData?.segmentsFilter?.listFetched,
    fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
    fetched_details.languageData.languageData])


    // Export PDF Btn
    useEffect(() => {
        try {
            if (loaderBtn.loader0 && loaderBtn.loader1) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            }
            else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [loaderBtn.loader0, loaderBtn.loader1])

    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }
    };

    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(optn, false);
            if (optn === 0) {
                setGuideAnalyticsPushNotificationsChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsPushNotificationsChartDataFetched: false,
                        pushNotificationsChartFetched: false,
                        pushNotificationsChart: {},
                        pushNotificationChartOptions: []
                    };
                });
                getGuideAnalyticsPushNotificationsChartData(request_uuid.value);
            } else if (optn === 1) {
                setGuideAnalyticsBeaconsChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsBeaconsChartDataFetched: false,
                        beaconsChartFetched: false,
                        beaconsChart: {},
                        beaconsChartOptions: []
                    };
                });
                getGuideAnalyticsBeaconsChartData(request_uuid.value);
            }
        } catch (error) {
            showBoundary(error)
        }
    }


    // const LineChartPushNotifications = useCallback(() => {
    //     try {
    //         setGuideAnalyticsPushNotificationsChart((prevState: any) => {
    //             return {
    //                 ...prevState,
    //                 pushNotificationsChart: <LineChart
    //                     chartEntities={['pushNotificationInteracted', 'pushNotificationShown']}
    //                     optionEntities={
    //                         [
    //                             {
    //                                 key: insightsLbls.pushNotificationInteractions,
    //                                 color: ChartColors.orangesandybrown, area: true
    //                             },
    //                             {
    //                                 key: insightsLbls.pushNotificationDisplayed,
    //                                 color: ChartColors.darkturquoise, area: true
    //                             }
    //                         ]}
    //                     chartData={fetched_details?.pushNotificationsBeacons?.guideAnalyticsPushNotificationsChartData?.data.graphData}
    //                     value_type={"count"}
    //                     height={linechart_height}
    //                     margin={lineChartMargin}
    //                     yAxisLabel={insightsLbls.yAxisLabelCount}
    //                     refId="guideAnalytics_pushNotifications">
    //                 </LineChart>,
    //                 pushNotificationsChartFetched: true,
    //             };
    //         });
    //         return (
    //             null
    //         );
    //     } catch (error) {
    //         showBoundary(error)
    //     }

    // }, [lineChartMargin, linechart_height]);

    // const LineChartBeacons = useCallback(() => {
    //     try {
    //         setGuideAnalyticsBeaconsChart((prevState: any) => {
    //             return {
    //                 ...prevState,
    //                 beaconsChart: <LineChart
    //                     chartEntities={['beaconInteracted', 'beaconShown']}
    //                     optionEntities={
    //                         [
    //                             {
    //                                 key: insightsLbls.beaconsInteractions,
    //                                 color: ChartColors.salmon,
    //                                 area: true
    //                             },
    //                             {
    //                                 key: insightsLbls.beaconsDisplayed,
    //                                 color: ChartColors.lightseagreen,
    //                                 area: true
    //                             }
    //                         ]}
    //                     chartData={fetched_details?.pushNotificationsBeacons?.guideAnalyticsBeaconsChartData?.data.graphData}
    //                     value_type={"count"}
    //                     height={linechart_height}
    //                     margin={lineChartMargin}
    //                     yAxisLabel={insightsLbls.yAxisLabelCount}
    //                     refId="guideAnalytics_beacons">
    //                 </LineChart>,
    //                 beaconsChartFetched: true,
    //             };
    //         });
    //         return (
    //             null
    //         );
    //     } catch (error) {
    //         showBoundary(error)
    //     }

    // }, [lineChartMargin, linechart_height]);

    // Push Notifications
    useEffect(() => {
        try {
            if (
                fetched_details?.pushNotificationsBeacons?.guideAnalyticsPushNotificationsChartData?.data !== undefined &&
                fetched_details?.pushNotificationsBeacons?.guideAnalyticsPushNotificationsChartData?.result === "success" &&
                fetched_details?.pushNotificationsBeacons?.guideAnalyticsPushNotificationsChartData?.requestUUID === request_uuid.value
            ) {

                let PushNotificationsChartData: any = [];
                PushNotificationsChartData = fetched_details?.pushNotificationsBeacons?.guideAnalyticsPushNotificationsChartData?.data.graphData;
                let optionEntities: any = [];
                let pushNotificationChartOptions: any = [];
                optionEntities = fetched_details?.pushNotificationsBeacons?.guideAnalyticsPushNotificationsChartData?.data?.legends;

                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    pushNotificationChartOptions.push({
                        key: optionEntities[itr0].legendTitle?.trim(),
                        color: optionEntities[itr0].color,
                        area: optionEntities[itr0].area,
                        associatedDataPoint: optionEntities[itr0].associatedDataPoint
                    })
                }
                setGuideAnalyticsPushNotificationsChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadPushNotificationsChartData: [...PushNotificationsChartData],
                        guideAnalyticsPushNotificationsChartDataFetched: true,
                        pushNotificationChartOptions: pushNotificationChartOptions
                    };
                });
                apiCatchError(0, 0);
                enableExportPdf(0, true)

            } else if (fetched_details?.pushNotificationsBeacons?.guideAnalyticsPushNotificationsChartData?.result === 'retry') {
                getGuideAnalyticsPushNotificationsChartData(request_uuid.value, 'retry');
            } else if (
                fetched_details?.pushNotificationsBeacons?.guideAnalyticsPushNotificationsChartData?.result === "error"
            ) {
                setGuideAnalyticsPushNotificationsChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsPushNotificationsChartDataFetched: true,
                    };
                });
                apiCatchError(0, 1);
                enableExportPdf(0, false)
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details?.pushNotificationsBeacons?.guideAnalyticsPushNotificationsChartData]);


    // Beacons
    useEffect(() => {
        try {
            if (
                fetched_details?.pushNotificationsBeacons?.guideAnalyticsBeaconsChartData?.data !== undefined &&
                fetched_details?.pushNotificationsBeacons?.guideAnalyticsBeaconsChartData?.result === "success" &&
                fetched_details?.pushNotificationsBeacons?.guideAnalyticsBeaconsChartData?.requestUUID === request_uuid.value
            ) {

                let BeaconsChartData: any = [];
                BeaconsChartData = fetched_details?.pushNotificationsBeacons?.guideAnalyticsBeaconsChartData?.data.graphData;
                let optionEntities: any = [];
                let beaconsChartOptions: any = [];
                optionEntities = fetched_details?.pushNotificationsBeacons?.guideAnalyticsBeaconsChartData?.data?.legends;

                for (let itr0 = 0; itr0 < optionEntities.length; itr0++) {
                    beaconsChartOptions.push({
                        key: optionEntities[itr0].legendTitle?.trim(),
                        color: optionEntities[itr0].color,
                        area: optionEntities[itr0].area,
                        associatedDataPoint: optionEntities[itr0].associatedDataPoint
                    })
                }

                setGuideAnalyticsBeaconsChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadBeaconsChartData: [...BeaconsChartData],
                        guideAnalyticsBeaconsChartDataFetched: true,
                        beaconsChartOptions: beaconsChartOptions
                    };
                });
                apiCatchError(1, 0);
                enableExportPdf(1, true)

            } else if (fetched_details?.pushNotificationsBeacons?.guideAnalyticsPushNotificationsChartData?.result === 'retry') {
                getGuideAnalyticsBeaconsChartData(request_uuid.value, 'retry');
            } else if (
                fetched_details?.pushNotificationsBeacons?.guideAnalyticsBeaconsChartData?.result === "error"
            ) {
                setGuideAnalyticsBeaconsChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsBeaconsChartDataFetched: true,
                    };
                });
                apiCatchError(1, 1);
                enableExportPdf(1, false)
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details?.pushNotificationsBeacons?.guideAnalyticsBeaconsChartData]);

    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    const getGuideAnalyticsPushNotificationsChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            "event_source": fetched_details?.commonComponentData?.eventSource.eventSourcePushNotificationsBeacons ?? 'player',
            'request_uuid': reqUUID

        };

        setGuideAnalyticsPushNotificationsChart((prevState: any) => {
            return {
                ...prevState,
                api: ['PUSH_NOTIFICATIONS_CHART']
            }
        })

        pushNotificationsBeacons.getPushNotificationsBeaconsData(
            params,
            "PUSH_NOTIFICATIONS_CHART",
            ActionTypes.SET_PUSH_NOTIFICATIONS_CHART,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsBeaconsChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            "event_source": fetched_details?.commonComponentData?.eventSource.eventSourcePushNotificationsBeacons ?? 'player',
            'request_uuid': reqUUID
        };

        setGuideAnalyticsBeaconsChart((prevState: any) => {
            return {
                ...prevState,
                api: ['BEACONS_CHART']
            }
        })

        pushNotificationsBeacons.getPushNotificationsBeaconsData(
            params,
            "BEACONS_CHART",
            ActionTypes.SET_BEACONS_CHART,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };




    return (
        <section className="demo tutorialGuideAnalytics width100" ref={componentRef}>
            {/* Breadcrumb */}
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li className="active">
                            {insightsLbls.pushNotificationsAndBeacons}
                        </li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <EventSource component={"pushNotificationsBeacons"}></EventSource>
                    <Datepicker source="component"></Datepicker>
                </SectionFilters>
            </div>

            {/* <SectionButtons sectionTitle={insightsLbls.pushNotificationsAndBeacons} svgImage={"guides"} className={'marginTop-50'}>
                <ExportToPdf
                    componentRef={componentRef}
                    source={"PushNotifications"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
            </SectionButtons> */}

            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={"Guides"}
                                widthDividedBy={12}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                dateObject={fetched_details?.commonComponentData?.datesData}
                messageText={insightsLbls.pushNotification}>
            </UserInfoSection>
            

            <div id="guideAnalyticsSection">

                {
                    // <SectionRefresh sectionTitle={insightsLbls.pushNotification} refreshClass={"marginTop-30"}>
                    //     <i
                    //         className="fa fa-refresh pull-right pointer row displayFlex"
                    //         aria-hidden="true" title={insightsLbls.refresh}
                    //         onClick={() => refreshSections(0)}>
                    //     </i>
                    //     <CustomDashboardModal
                    //         api={guideAnalyticsPushNotificationsChart.api}
                    //         eventSource={filterValues.eventSource}
                    //         guideStatus={false}
                    //         segmentsGroup={false}
                    //         chartType={"LineChart"}
                    //         sectionHeader={insightsLbls.pushNotification}
                    //         sectionTooltip={
                    //             {
                    //                 "subTitle": insightsLbls.activity,
                    //                 "tooltipText": '',
                    //                 "childern": [
                    //                     {
                    //                         "key": insightsLbls.pushNotificationInteractedKey + ":",
                    //                         "value": insightsLbls.pushNotificationInteractedValue
                    //                     },
                    //                     {
                    //                         "key": insightsLbls.pushNotificationDisplayedKey + ":",
                    //                         "value": insightsLbls.pushNotificationDisplayedValue
                    //                     }
                    //                 ]
                    //             }
                    //         }
                    //         sectionType={'type1'}
                    //         guide_filter={true}
                    //         event_filter={true}
                    //         segment_filter={true}
                    //         tooltip_filter={false}
                    //         component={'guides'}
                    //     ></CustomDashboardModal>

                    // </SectionRefresh>
                }

                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant marginTop-20">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            <SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.pushNotification,
                                    "tooltipText": '',
                                    "childern": [
                                        {
                                            "key": insightsLbls.pushNotificationInteractedKey + ":",
                                            "value": insightsLbls.pushNotificationInteractedValue
                                        },
                                        {
                                            "key": insightsLbls.pushNotificationDisplayedKey + ":",
                                            "value": insightsLbls.pushNotificationDisplayedValue
                                        }
                                    ]
                                }
                            } placement="right"></SectionTooltip>

                            <span>
                                <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(0)}> {insightsLbls.refresh}</button>
                                <CustomDashboardModal
                                    api={guideAnalyticsPushNotificationsChart.api}
                                    eventSource={filterValues.eventSource}
                                    guideStatus={false}
                                    segmentsGroup={false}
                                    chartType={"LineChart"}
                                    sectionHeader={insightsLbls.pushNotification}
                                    sectionTooltip={
                                        {
                                            "subTitle": insightsLbls.activity,
                                            "tooltipText": '',
                                            "childern": [
                                                {
                                                    "key": insightsLbls.pushNotificationInteractedKey + ":",
                                                    "value": insightsLbls.pushNotificationInteractedValue
                                                },
                                                {
                                                    "key": insightsLbls.pushNotificationDisplayedKey + ":",
                                                    "value": insightsLbls.pushNotificationDisplayedValue
                                                }
                                            ]
                                        }
                                    }
                                    sectionType={'type1'}
                                    guide_filter={true}
                                    event_filter={true}
                                    segment_filter={true}
                                    tooltip_filter={false}
                                    component={'guides'}
                                ></CustomDashboardModal>
                            </span>
                        </ContainerTooltip>

                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            {guideAnalyticsPushNotificationsChart.guideAnalyticsPushNotificationsChartDataFetched ? (
                                errorCount.errorCount[0] === 0 ? (
                                    guideAnalyticsPushNotificationsChart.loadPushNotificationsChartData.length === 0 ? (
                                        <div className="noDataText-font textCenter-absolute">
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (
                                        <>
                                            <LineChart
                                                chartEntities={['pushNotificationInteracted', 'pushNotificationShown']}
                                                optionEntities={guideAnalyticsPushNotificationsChart.pushNotificationChartOptions}
                                                chartData={fetched_details?.pushNotificationsBeacons?.guideAnalyticsPushNotificationsChartData?.data.graphData}
                                                value_type={"count"}
                                                height={linechart_height}
                                                margin={lineChartMargin}
                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                refId="guideAnalytics_pushNotifications">
                                            </LineChart>
                                        </>
                                    )
                                ) : (
                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                        {insightsLbls.serviceNotAvailable}
                                    </h5>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}
                        </div>
                    </CardContainer>
                </div>




                {
                    // <SectionRefresh sectionTitle={insightsLbls.beacons} refreshClass={"marginTop-30"}>
                    //     <i
                    //         className="fa fa-refresh pull-right pointer row displayFlex"
                    //         aria-hidden="true" title={insightsLbls.refresh}
                    //         onClick={() => refreshSections(1)}>
                    //     </i>
                    //     <CustomDashboardModal
                    //         api={guideAnalyticsBeaconsChart.api}
                    //         eventSource={filterValues.eventSource}
                    //         guideStatus={false}
                    //         segmentsGroup={false}
                    //         chartType={"LineChart"}
                    //         sectionHeader={insightsLbls.beacons}
                    //         sectionTooltip={
                    //             {
                    //                 "subTitle": insightsLbls.activity,
                    //                 "tooltipText": '',
                    //                 "childern": [
                    //                     {
                    //                         "key": insightsLbls.beaconInteractedKey + ":",
                    //                         "value": insightsLbls.beaconInteractedValue
                    //                     },
                    //                     {
                    //                         "key": insightsLbls.beaconDisplayedKey + ":",
                    //                         "value": insightsLbls.beaconDisplayedValue
                    //                     }
                    //                 ]
                    //             }
                    //         }
                    //         sectionType={'type1'}
                    //         guide_filter={true}
                    //         event_filter={true}
                    //         segment_filter={true}
                    //         tooltip_filter={false}
                    //         component={'guides'}
                    //     ></CustomDashboardModal>
                    // </SectionRefresh>
                }

                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant marginTop-20">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            <SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.beacons,
                                    "tooltipText": '',
                                    "childern": [
                                        {
                                            "key": insightsLbls.beaconInteractedKey + ":",
                                            "value": insightsLbls.beaconInteractedValue
                                        },
                                        {
                                            "key": insightsLbls.beaconDisplayedKey1 + ":",
                                            "value": insightsLbls.beaconDisplayedValue
                                        }
                                    ]
                                }
                            } placement="right"></SectionTooltip>

                            <span>
                                <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(1)}> {insightsLbls.refresh}</button>
                                <CustomDashboardModal
                                    api={guideAnalyticsBeaconsChart.api}
                                    eventSource={filterValues.eventSource}
                                    guideStatus={false}
                                    segmentsGroup={false}
                                    chartType={"LineChart"}
                                    sectionHeader={insightsLbls.beacons}
                                    sectionTooltip={
                                        {
                                            "subTitle": insightsLbls.activity,
                                            "tooltipText": '',
                                            "childern": [
                                                {
                                                    "key": insightsLbls.beaconInteractedKey + ":",
                                                    "value": insightsLbls.beaconInteractedValue
                                                },
                                                {
                                                    "key": insightsLbls.beaconDisplayedKey + ":",
                                                    "value": insightsLbls.beaconDisplayedValue
                                                }
                                            ]
                                        }
                                    }
                                    sectionType={'type1'}
                                    guide_filter={true}
                                    event_filter={true}
                                    segment_filter={true}
                                    tooltip_filter={false}
                                    component={'guides'}
                                ></CustomDashboardModal>
                            </span>

                        </ContainerTooltip>

                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            {guideAnalyticsBeaconsChart.guideAnalyticsBeaconsChartDataFetched ? (
                                errorCount.errorCount[0] === 0 ? (
                                    guideAnalyticsBeaconsChart.loadBeaconsChartData.length === 0 ? (
                                        <div className="noDataText-font textCenter-absolute">
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (
                                        <>
                                            <LineChart
                                                chartEntities={['beaconShown', 'beaconInteracted']}
                                                optionEntities={guideAnalyticsBeaconsChart.beaconsChartOptions}
                                                chartData={fetched_details?.pushNotificationsBeacons?.guideAnalyticsBeaconsChartData?.data.graphData}
                                                value_type={"count"}
                                                height={linechart_height}
                                                margin={lineChartMargin}
                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                refId="guideAnalytics_beacons">
                                            </LineChart>
                                        </>
                                    )
                                ) : (
                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                        {insightsLbls.serviceNotAvailable}
                                    </h5>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}
                        </div>

                    </CardContainer>
                </div>
            </div>
        </section>

    )
}
export default withRouter(withReducer("pushNotificationsBeacons", pushNotificationsBeaconsReducer)(PushNotificationsBecons));
