
import { CommonUtils } from "./CommonUtils";
import { getCall } from "./ApiCallActions";
import { ChartColors } from "./GlobalConstants";

export const CommonCharts = {
    lineChart: async function (api: any, params: any, sectionHeader: string, guideIds?: any, apiErrorFlag?: string, activeTab?: number) {
        let guideStats = {
            totalGuideMeCount: 0, totalGuideMeCompleteCount: 0,
            guideVideoPlay: 0, guideGifPlay: 0, guideSlideshowPlay: 0, guideShowMePlay: 0,
            guideVideoDownload: 0, guideGifDownload: 0, guideSlideshowDownload: 0,
            guidePdfDownload: 0, guideDocxDownload: 0, guideHtmlDownload: 0
        };

        let { totalGuideMeCount, totalGuideMeCompleteCount, guideVideoPlay, guideGifPlay, guideSlideshowPlay, guideShowMePlay, guideVideoDownload,
            guideGifDownload, guideSlideshowDownload, guidePdfDownload, guideDocxDownload, guideHtmlDownload } = guideStats;

        let dataObj: any = {};
        const data = await getCall(params, api[0], apiErrorFlag ?? '', guideIds);
        if (data.result !== "success" || !data.data) {
            return { serviceAvailable: false, data_fetched: true, retry: data.result === "retry" };
        }

        if (data.result === "success" && data.data !== undefined) {
            let dummyData: any = [];
            let lineChartData = CommonUtils.dateSeparator(data.data.graphData || []);
            lineChartData.forEach(item => {
                totalGuideMeCount += item.counts.guide_play || 0;
                totalGuideMeCompleteCount += item.counts.guide_complete || 0;
                guideShowMePlay += item.counts.guided_showme_play || 0;
                guideSlideshowPlay += item.counts.guide_slideshow_play || 0;
                guideVideoPlay += item.counts.guide_video_play || 0;
                guideGifPlay += item.counts.guide_gif_play || 0;
                guideVideoDownload += item.counts.guide_video_download || 0;
                guideGifDownload += item.counts.guide_gif_download || 0;
                guideSlideshowDownload += item.counts.guide_slideshow_download || 0;
                guidePdfDownload += item.counts.guide_pdf_download || 0;
                guideDocxDownload += item.counts.guide_docx_download || 0;
                guideHtmlDownload += item.counts.guide_html_download || 0;
            });

            let totalGuideMePlayedCompleteRatio = totalGuideMeCount ? (totalGuideMeCompleteCount / totalGuideMeCount) * 100 : 0;

            let chartOptions = [], dummyDonutData = [], dummyPieData = [];
            let itr = 0;

            
            const addToChartOptions = (index: number, legend: any, customKey?: string) => {
                chartOptions[index] = {
                    key: customKey || legend.legendTitle,
                    values: dummyData[legend.associatedDataPoint],
                    area: legend.area,
                    color: legend.color,
                    associatedDataPoint: legend.associatedDataPoint
                };
            };

            data.data.legends.map((d: any, j: number) => {
                if (sectionHeader === 'Duct Tape' || sectionHeader === 'Power Form') {
                    if (d.associatedDataPoint === 'digitalDuctTape') addToChartOptions(0, d);
                    if (d.associatedDataPoint === 'powerForm') addToChartOptions(1, d);
                } else if (sectionHeader === 'Tooltip & Validation' ) {
                    if (d.associatedDataPoint === 'guidance') addToChartOptions(0, d);
                    if (d.associatedDataPoint === 'validation') addToChartOptions(1, d);
                    if (d.associatedDataPoint === 'guidanceValidation') addToChartOptions(2, d);
                    // addToChartOptions(itr++, d);
                } else if (sectionHeader === 'Power Form & Digital Duct Tape') {
                    if (d.associatedDataPoint === 'digitalDuctTape') addToChartOptions(0, d);
                    if (d.associatedDataPoint === 'powerForm') addToChartOptions(1, d);
                } else if ((sectionHeader === 'ShowMe' && api[0] === 'GUIDE_ANALYTICS_SHOW_ME_CHART')) {
                    const getGuideLabel = (associatedDataPoint, playLabel) => associatedDataPoint == playLabel ? 'Play' : 'Completed';
                    switch (activeTab) {
                        case 0:
                            if (d.associatedDataPoint === 'guided_showme_play' || d.associatedDataPoint === 'guided_showme_complete') {
                                addToChartOptions(itr++, d, getGuideLabel[d.associatedDataPoint, "guided_showme_play"]);
                            }
                            break;
                        case 1:
                            if (d.associatedDataPoint === 'guide_slideshow_play' || d.associatedDataPoint === 'guide_slideshow_complete') {
                                addToChartOptions(itr++, d, getGuideLabel[d.associatedDataPoint, "guide_slideshow_play"]);
                            }
                            break;
                        case 2:
                            if (d.associatedDataPoint === 'guide_video_play' || d.associatedDataPoint === 'guide_video_complete') {
                                addToChartOptions(itr++, d, getGuideLabel[d.associatedDataPoint, "guide_video_play"]);
                            }
                            break;
                        case 3:
                            if (d.associatedDataPoint === 'guide_gif_play') {
                                addToChartOptions(itr++, d, getGuideLabel[d.associatedDataPoint, "guide_gif_play"]);
                            }
                            break;
                    }

                    const playMappings: Record<string, { y: number, color: string }> = {
                        guided_showme_play: { y: guideShowMePlay, color: ChartColors.color1 },
                        guide_slideshow_play: { y: guideSlideshowPlay, color: ChartColors.color2 },
                        guide_video_play: { y: guideVideoPlay, color: ChartColors.color3 },
                        guide_gif_play: { y: guideGifPlay, color: ChartColors.color4 }
                    };

                    const downloadMappings: Record<string, number> = {
                        guide_video_download: guideVideoDownload,
                        guide_gif_download: guideGifDownload,
                        guide_slideshow_download: guideSlideshowDownload,
                        guide_pdf_download: guidePdfDownload,
                        guide_docx_download: guideDocxDownload,
                        guide_html_download: guideHtmlDownload
                    };

                    if (playMappings[d.associatedDataPoint]) {
                        dummyDonutData.push({ key: d.legendTitle, ...playMappings[d.associatedDataPoint] });
                    } else if (downloadMappings[d.associatedDataPoint]) {
                        dummyPieData.push({ key: d.legendTitle, y: downloadMappings[d.associatedDataPoint], color: d.color });
                    }
                }
                else {
                    addToChartOptions(j, d);
                }
                return {};
            });
            // Calculate the total sum of 'y' values in dummyDonutData and dummyPieData
            let cnt_donut = dummyDonutData.reduce((sum, data) => sum + data.y, 0);
            let cnt_pie = dummyPieData.reduce((sum, data) => sum + data.y, 0);

            dataObj = {
                data: lineChartData,
                serviceAvailable: true,
                data_fetched: true,
                options: chartOptions,
                ratioVal: totalGuideMePlayedCompleteRatio ?? null,
                totalGuideMeCount: totalGuideMeCount ?? null,
                totalGuideMeCompleteCount: totalGuideMeCompleteCount ?? null,
                dummyDonutData: cnt_donut === 0 ? [] : dummyDonutData,
                dummyPieData: cnt_pie === 0 ? [] : dummyPieData
            }
        }
        return dataObj;
    },

    barChart: async function (api: any, params: any, sectionHeader: string, apiErrorFlag?: string) {
        let dataObj: any = {};

        await getCall(params, api[0], apiErrorFlag ?? '').then((data: any) => {

            if (data.result === "success" && data.data !== undefined) {

                let barChartData: any = [];

                barChartData = data.data.graphData;
                barChartData = CommonUtils.dateSeparator(barChartData);

                let dummyData: any = [];
                data.data.legends.map((d: any) => {
                    dummyData[d.associatedDataPoint] = [];
                    data.data.graphData.map((optn: any, i: number) => {
                        dummyData[d.associatedDataPoint][i] = {};
                        dummyData[d.associatedDataPoint][i].x = optn.startTimestamp;
                        dummyData[d.associatedDataPoint][i].y = parseInt(optn.counts[d.associatedDataPoint], 10);
                        dummyData[d.associatedDataPoint][i].date = optn.date;
                        dummyData[d.associatedDataPoint][i].startTimeStamp = optn.startTimestamp;
                        dummyData[d.associatedDataPoint][i].associatedDataPoint = d.associatedDataPoint;

                        return {};
                    })
                    return {};
                })


                let chartOptions: any = [];
                data.data.legends.map((d: any, j: number) => {
                    chartOptions[j] = {};
                    chartOptions[j].key = d.legendTitle;
                    chartOptions[j].values = dummyData[d.associatedDataPoint];
                    chartOptions[j].area = d.area;
                    chartOptions[j].color = d.color;
                    chartOptions[j].associatedDataPoint = d.associatedDataPoint;

                    return {};
                })

                dataObj = {
                    data: barChartData,
                    serviceAvailable: true,
                    data_fetched: true,
                    options: chartOptions,
                }
            } else if (data.result === "retry") {
                dataObj = {
                    serviceAvailable: false,
                    data_fetched: true,
                    retry: true
                }
            }

            else if (data.result === "error") {
                dataObj = {
                    serviceAvailable: false,
                    data_fetched: true,
                }
            }
        });

        return dataObj;
    },

    wordCloudChart: async function (api: any, params: any, apiErrorFlag?: string) {
        let dataObj: any = [];
        await getCall(params, api[0], apiErrorFlag ?? '').then((data: any) => {
            if (data.result === "success" && data.data !== undefined) {
                dataObj = {
                    serviceAvailable: true,
                    data: data.data.wordData,
                    data_fetched: true
                }
            } else if (data.result === "retry") {
                dataObj = {
                    serviceAvailable: false,
                    data_fetched: true,
                    retry: true
                }
            }
            else if (data.result === "error") {
                dataObj = {
                    serviceAvailable: false,
                    data_fetched: true,
                }
            }
        });
        return dataObj;
    },

    searchStats: async function (api: any, params: any, apiErrorFlag?: string) {
        let dataObj: any = [];
        await getCall(params, api[1], apiErrorFlag ?? '').then((data: any) => {
            if (data.result === "success" && data.data !== undefined) {
                dataObj = {
                    data: data.data.searchStats,
                    serviceAvailable: true,
                    data_fetched: true,
                }
            } else if (data.result === "retry") {
                dataObj = {
                    serviceAvailable: false,
                    data_fetched: true,
                    retry: true
                }
            }
            else if (data.result === "error") {
                dataObj = {
                    serviceAvailable: false,
                    data_fetched: true,
                }
            }
        });
        return dataObj;
    }
};
