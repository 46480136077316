
// Modified by: Mrunal Mane
// On: 2024-05-10 05:48 PM
// Reason: Removed language dependancy from useEffects of (props)

import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useErrorBoundary } from 'react-error-boundary';
import { InsightLabels } from "labels";
import { LanguageState } from "store/Reducers";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
interface CurrentState {
    languageData: LanguageState
}

const Barchart = (props: any) => {
    let fetched_details = useSelector((state: CurrentState) => state);
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();
    let barChartRef = [];
    barChartRef[props.refId] = useRef(null);

    const [renderData, setRenderData] = useState({
        chart: [],
        options: [],
        flag: false,
        max: 0
    })

    useEffect(() => {
        setRenderData((prevState) => {
            return {
                ...prevState,
                chart: [],
                options: [],
                flag: false
            }
        })
        let tempChartData = [];
        let colorChartData = [];
        if (props.optionEntities) {
            colorChartData = props.optionEntities.map(entry => ({
                color: entry.color,
                associatedDataPoint: entry.associatedDataPoint,
                key: entry.key
            }));
            if (props.chartData) {
                tempChartData = props.chartData.map(entry => ({
                    startTimestamp: entry.startTimestamp,
                    ...Object.keys(entry.counts).filter(key => props.chartEntities.includes(key)).reduce((obj, key) => {
                        obj[key] = entry.counts[key];
                        return obj;
                    }, {})
                }))
            }

            setRenderData((prevState) => {
                return {
                    ...prevState,
                    chart: tempChartData,
                    options: colorChartData,
                    flag: true
                }
            })
        }
    }, [props]);


    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    const bars = () => {
        if (props.chartEntities && renderData.options.length > 0) {
            let barData = props.chartEntities.map((entity, index) => {
                let obj = renderData.options.find(obj => obj.associatedDataPoint === entity);
                if (obj) {
                    return <Bar key={index} stackId="a" dataKey={entity} fill={obj.color} />
                } else {
                    return null;
                }
            })
            return barData;
        }
        return null;
    }

    return (
        <div id={props.refId} className={`width-100  height${props.height}`}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart data={renderData.chart} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="startTimestamp" />
                    <YAxis label={{
                        value: props.yAxisLabel,
                        position: 'insideLeft',
                        angle: -90,
                        offset: 20,
                        style: {
                            fontSize: '14px',
                            fontWeight: 'bold'
                        }
                    }} domain={[0, 'auto']} />
                    <Tooltip formatter={(value: number | string, name: string) => {
                        const label = renderData.options.find(obj => obj.associatedDataPoint === name)?.key;
                        return [value, label];
                    }} />
                    <Legend formatter={(value) => renderData.options.find(obj => obj.associatedDataPoint === value)?.key} />
                    {bars()}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};
export default React.memo(Barchart);