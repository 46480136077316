/*
    @author Mohit Tiwari
    Last Updated on 11 June 2024
    Description: a) Set initial load app selection limit as 3, post that user can select up to 10.
                 b) Withdraw state of app selection on close of modal without applying, by adding one temporary app selection state
*/

import axios from "axios";
import CommonModal from "components/CommonModal/CommonModal";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { LanguageState, OrgState, VisibilityState } from "store/Reducers"

import { getCall } from "utils/ApiCallActions";
import { RetryApi } from "utils/GlobalConstants";
import "./AppEnvListDropdown.scss";
import { Dropdown, Modal } from "react-bootstrap";
import Loader from "components/Loader/Loader";
import { InsightLabels } from "labels";
import { useErrorBoundary } from 'react-error-boundary';


interface CurrentState {
    languageData: LanguageState,
    sectionVisibility: VisibilityState,
    org: OrgState
}

const AppEnvListDropdown = (props: any) => {

    const { showBoundary } = useErrorBoundary();

    /* ---------- Constants ---------- */
    // const total_appCount = 10;
    const [total_appCount, setTotalAppCount] = useState(3);
    /* ---------- State Initialization ---------- */
    const AppEnvListMount = useRef(false);
    //To set labels
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);
    //To check whether data is fetched, used to show loader
    const [apiFetched, setApiFetch] = useState(false);
    //Map app id with app name, used to show app list
    const [appMapper, setAppMapper] = useState(new Map());
    //Map app id with app code
    const [appCodeMapper, setAppCodeMapper] = useState(new Map());
    //To store deleted apps
    const [deletedApps, setDeletedApps] = useState([]);
    //Map app id with environments of that app
    const [appEnvList, setAppEnvList] = useState(new Map());
    //To handle show & hide of app and environment selection modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    //To handle dropdown open and close of environment filter 
    const [openedEnvSelectDropdownAppId, setopenedEnvSelectDropdownAppId] = useState("");
    //To store the selected environment for the respective app
    const [envSelection, setEnvSelection] = useState(new Map());
    //To store the selected app checked status
    const [appSelection, setAppSelection] = useState(new Map());
    //To create a temporary state of newly selected apps
    const [tempAppSelector, setTempAppSelector] = useState(new Map());
    //Hide Deleted checkbox state
    const [isHideDeleted, setIsHideDeleted] = useState(false);
    const [btnState, setBtnState] = useState({
        okBtnState: false
    });
    const isMounted = useRef(false);
    let cdnSig = localStorage.getItem('USER_SIGN');

    let fetched_details = useSelector((state: CurrentState) => state);
    let orgDetails = useSelector((state: CurrentState) => state.org);
    let searchTxt: any = '';

    /* ---------- UseEffects ---------- */
    //Language
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])


    // First Load
    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            getAppList();
        }
    }, []);


    useEffect(() => {
        try {
            if (orgDetails.orgSwitch) {
                setTotalAppCount(3);
                setApiFetch(false);
                AppEnvListMount.current = false
                getAppList("org_switch", 3);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [orgDetails])

    // To send data to dashboard first time
    useEffect(() => {
        try {
            if (!AppEnvListMount.current && appCodeMapper.size > 0 && appMapper.size > 0 && appSelection.size > 0 && envSelection.size > 0) {
                applyEnvFilter();
                AppEnvListMount.current = true;
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [appCodeMapper, appMapper, appSelection, envSelection])

    const getAppList = (apiErrorFlag?: string, totalAppCountParam?: number) => {
        try {
            let cancelToken: any;
            if (typeof cancelToken !== typeof undefined) {
                cancelToken.cancel(insightsLbls.tokenCancelledMsg);
            }
            cancelToken = axios.CancelToken.source();

            let params = {
                "type": 'org',
                "sort_by": "app_name",
                "order": "asc",
                "search_text": encodeURIComponent(searchTxt),
                "page_index": 1,
                "page_size": 9999,
                "hide_deleted": 0
            };
            /* Fetching existing selected apps, saved in local storage */
            let dashboardAppList: any = localStorage.getItem('DASHBOARD_APP_LIST');
            dashboardAppList = JSON.parse(dashboardAppList);
            /* These mapper are to provide one to one mapping of app code with other attribute, to access data easily */
            let tempAppCheck = new Map();
            let tempAppEnvMap = new Map();
            let tempAppMapper = new Map();
            let tempEnvSelection = new Map();
            let tempAppCodeMapper = new Map();
            let deletedAppList: any = [];
            /* Api fetch is made false, as API is called */
            setApiFetch(false);
            /* Fetching app list */
            let tempTotalAppCount = total_appCount;
            if (totalAppCountParam) {
                tempTotalAppCount = totalAppCountParam;
            }
            getCall(params, 'APP_LIST', apiErrorFlag ?? '').then((data: any) => {
                if (data?.result === 'success') {
                    setApiFetch(true);
                    let totalSelectionCount = data.data.total_count
                    for (let i = 0; i < totalSelectionCount; i++) {
                        let environment_list = data.data.apps[i].environments;
                        let update_env_list = [];
                        /* All is default environment */
                        update_env_list.push({ "environment_code": "", "environment_title": "All" });
                        if (environment_list.length > 0) {
                            update_env_list.push({ "environment_code": "env_legacy", "environment_title": "Legacy" })
                        }
                        update_env_list.push(...environment_list);

                        if (data.data.apps[i].is_archived) {
                            deletedAppList.push(data.data.apps[i].app_id);
                        }
                        tempEnvSelection.set(data.data.apps[i].app_id, { "environment_code": "", "environment_title": "All" });
                        tempAppMapper.set(data.data.apps[i].app_id, data.data.apps[i]);
                        tempAppCodeMapper.set(data.data.apps[i].app_id, data.data.apps[i].app_code);
                        tempAppEnvMap.set(data.data.apps[i].app_id, update_env_list);

                        /**
                         * Currently it's implemented only for dashboard, so no further segregation has been made
                         **/
                        if (dashboardAppList == undefined || dashboardAppList.length < 1) {
                            tempAppCheck.set(data.data.apps[i].app_id, i < tempTotalAppCount);
                        } else {
                            tempAppCheck.set(data.data.apps[i].app_id, false);
                            for (let itr0 = 0; itr0 < dashboardAppList.length; itr0++) {
                                if (data.data.apps[i].app_id == dashboardAppList[itr0].app_id) {
                                    tempAppCheck.set(data.data.apps[i].app_id, true);
                                    if (dashboardAppList[itr0].env_code) {
                                        tempEnvSelection.set(data.data.apps[i].app_id,
                                            { "environment_code": dashboardAppList[itr0].env_code, "environment_title": dashboardAppList[itr0].env_title })
                                    }
                                    break;
                                }
                            }
                        }
                    }
                    setAppMapper(tempAppMapper);
                    setAppEnvList(tempAppEnvMap);
                    setAppSelection(tempAppCheck);
                    setAppCodeMapper(tempAppCodeMapper);
                    setEnvSelection(tempEnvSelection);
                    setDeletedApps(deletedAppList);
                } else if (data.result === 'retry') {
                    setTimeout(() => {
                        getAppList('retry')
                    }, RetryApi.TIMEOUT)
                }
                else if (
                    data?.result === "error"
                ) {
                    setApiFetch(true);
                    //set error message
                }
            });
        } catch (error) {
            showBoundary(error)
        }
    }

    const openAppEnvModal = () => {
        setTotalAppCount(10);
        setIsModalOpen(true);
        setTempAppSelector(new Map());
    }

    const closeAppEnvModal = () => {
        setIsModalOpen(false);
    }


    const toggleEnvDropDown = (key: any, event: boolean, i: number) => {
        try {
            if (event) {
                setopenedEnvSelectDropdownAppId(key);
            } else {
                setopenedEnvSelectDropdownAppId("");
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    const selectEnvironment = (key: any, value: any, i: number) => {
        try {
            let tempEnvSelection = deepCopyMap(envSelection);
            tempEnvSelection.set(key, value);
            setEnvSelection(tempEnvSelection);
            toggleEnvDropDown(key, false, i);
        } catch (error) {
            showBoundary(error)
        }
    }

    const selectApp = (key: any, event: any) => {
        try {
            let tempAppSelection = tempAppSelector.size > 0 ? shallowCopyMap(tempAppSelector) : shallowCopyMap(appSelection);
            if (event.target.checked) {
                let firstSelectionId = "";
                let numOfAppSelected = 0;
                tempAppSelection.forEach((value: any, key: any) => {
                    if (value) {
                        if (firstSelectionId == "") {
                            firstSelectionId = key;
                        }
                        numOfAppSelected++;
                    }
                })
                if (numOfAppSelected >= total_appCount) {
                    tempAppSelection.set(firstSelectionId, false);
                }

            }
            tempAppSelection.set(key, event.target.checked);
            // setAppSelection(tempAppSelection);
            setTempAppSelector(tempAppSelection);

            let cnt = 0
            tempAppSelection.forEach((value, key) => {
                if (value === false) {
                    cnt++
                }
            });


            setBtnState((prevState) => {
                return {
                    ...prevState,
                    okBtnState: cnt === tempAppSelector.size
                }
            })



        } catch (error) {
            showBoundary(error)
        }
    }



    const applyEnvFilter = () => {
        try {
            closeAppEnvModal();
            let finalAppList: any[] = [];
            if (tempAppSelector.size > 0) {
                setAppSelection(tempAppSelector);
                tempAppSelector.forEach((value, key) => {
                    if (value) {
                        let appObj: any = { app_id: key, app_name: appMapper.get(key)?.app_name };
                        if (envSelection.get(key) != undefined && envSelection.get(key)["environment_code"] != "") {
                            appObj["app_code"] = appCodeMapper.get(key);
                            appObj["env_code"] = envSelection.get(key)["environment_code"];
                            appObj["env_title"] = envSelection.get(key)["environment_title"];
                        }
                        finalAppList.push(appObj);
                    }
                });
            } else {
                appSelection.forEach((value, key) => {
                    if (value) {
                        let appObj: any = { app_id: key, app_name: appMapper.get(key)?.app_name };
                        if (envSelection.get(key) != undefined && envSelection.get(key)["environment_code"] != "") {
                            appObj["app_code"] = appCodeMapper.get(key);
                            appObj["env_code"] = envSelection.get(key)["environment_code"];
                            appObj["env_title"] = envSelection.get(key)["environment_title"];
                        }
                        finalAppList.push(appObj);
                    }
                });
            }

            props.finalApps(finalAppList);
            if (AppEnvListMount.current) {
                localStorage.setItem("DASHBOARD_APP_LIST", JSON.stringify(finalAppList));
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    const shallowCopyMap = (originalMap: any) => {
        try {
            return new Map(originalMap);
        } catch (error) {
            showBoundary(error)
        }

    }

    const deepCopyMap: any = (originalMap: any) => {
        try {
            return new Map([...originalMap].map(([key, value]) =>
                [key, value instanceof Map ? deepCopyMap(value) : value instanceof Object ? deepCopyObject(value) : value]
            ));
        } catch (error) {
            showBoundary(error)
        }

    }

    const deepCopyObject: any = (originalObject: any) => {
        try {
            return Object.fromEntries(
                Object.entries(originalObject).map(([key, value]) =>
                    [key, value instanceof Map ? deepCopyMap(value) :
                        value instanceof Object ? deepCopyObject(value) : value]
                )
            );
        } catch (error) {
            showBoundary(error)
        }
    }

    const urlError = (e: any) => {
        e.target.src = require("../../assets/images/organization_42x42.png");
    }

    let debounceTimer: NodeJS.Timeout;

    const searchInAppList = (e: any) => {
        try {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => {
                searchTxt = e.target.value;
                getAppList();
            }, 500);
        } catch (error) {
            showBoundary(error);
        }
    };

    const getLoaderTemplate = () => <div className="col-lg-12 appList-container paddingLR-0" id="appList_container_val">
        <Loader></Loader>
    </div>

    const getNoDataTemplate = () => <div className="appList-div 1">
        <div className="noData-div ">{insightsLbls.noRecordFound}</div>
    </div>

    const getAppListTemplate = () => {
        return <div className="appList-div">
            {Array.from(appMapper.entries()).map(([key, app], i) => {
                if ((app?.app_icon_image_url?.includes('http:') || app?.app_icon_image_url?.includes('https:'))) {
                    var imageTag = <img className="app-bgimg" src={app?.app_icon_image_url + cdnSig} onError={() => urlError.bind(app)} />
                } else {
                    var imageTag = <img src={require("../../assets/images/organization_42x42.png")} className="app-img" />
                }
                if (isHideDeleted && app.is_archived) {
                    return;
                }
                return (
                    <div className="appSwitcher col-md-6 displayInlineBlock appList" key={app.app_id}>
                        <div className="appCard width100 positionRelative">
                            <div className={"appContainer row nomargin " + (tempAppSelector.size > 0 && tempAppSelector.get(key) ? "highlight" : "")}
                                data-tip data-for={"txtTooltip_" + app.app_code} data-class="reactTooltipStyle" >
                                <div className="app-data pointer">
                                    <div className="tri-container">
                                        <div className="cover">
                                            <div className={"arrow-left "
                                                + (app.is_app_verified === true && app.is_archived === false ? "bg-green " : "")
                                                + (app.is_tracking_enabled === false && app.is_archived === false ? "bg-yellow " : "")
                                                + (app.is_archived === true ? "bg-red" : "")}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="app-check-container">
                                        <input type="checkbox" className={"custom-control-input chk checkbox app-selector"}
                                            id={"customControlAutosizing_" + key} name={"app_name_" + key}
                                            value={key} onChange={(event) => selectApp(key, event)} checked={tempAppSelector.size > 0 ? tempAppSelector.get(key) : appSelection.get(key)}></input>
                                    </div>
                                    <div className="app-img-div">
                                        {imageTag}
                                    </div>
                                    <div className="app-content-div">
                                        <p className="nomargin font14 fontW600 textWrap"></p>
                                        <div className="height30">
                                            <p className="width100 font13 appDesc nomargin textWrap height30 font600">
                                                <span className="ellipsis">{app.app_name}</span>
                                            </p>
                                            <p className="width100 font13 appDesc nomargin textWrap height30">
                                                <span className="ellipsis">{app.app_description}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="app-actions-div">
                                    <div className="app-card"></div>
                                </div>
                                {(app.app_description.length === 0 && app.app_name.length === 0)
                                    ? "No data available."
                                    : null
                                }

                                {/* {(app.app_description.length > 0 || app.app_name.length > 0)
                                    ? (<>
                                        {app.app_name.length > 0 && <p className="font600">{app.app_name}</p>}
                                        {app.app_description.length > 0 && <p>{app.app_description}</p>}
                                    </>)
                                    : null
                                } */}
                            </div>
                        </div>
                    </div>
                )
            })
            }
        </div>
    }

    const getAppDataTemplate = () => {
        return (
            <div className="col-lg-12 appList-container paddingLR-0" id="appList_container_val">
                {appMapper.size === 0 ? getNoDataTemplate() : getAppListTemplate()}
            </div>
        )
    }

    return (
        <section className="">
            <div className="appEnv-filter-title">{insightsLbls['appEnvironmentFilter']}</div>
            <div
                onClick={openAppEnvModal} 
                className={`app-select-btn ${fetched_details.sectionVisibility.guideInsights_flag === false ? 'right-290' : 'right-198'}`}>
                <p>{insightsLbls.appEnvSelect}</p>
            </div>
            <Modal onHide={closeAppEnvModal} show={isModalOpen} size="lg" centered >
                <Modal.Header>
                    <Modal.Title>{insightsLbls.appList}</Modal.Title>
                    <button type="button" className="close" onClick={closeAppEnvModal} >
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">{insightsLbls.close}</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-lg-12 paddingLR-35 searchBox">
                        <span className="font13 appStatus-point bg-green"></span>
                        <span className="font13 marginRight-10">{insightsLbls.active}</span>
                        <div className="displayInlineBlock" title={insightsLbls.deletedApp}>
                            <span className="font13 appStatus-point bg-red"></span>
                            <span className="font13 marginRight-10">{insightsLbls.deleted}</span>
                        </div>
                        <p className="pull-right search-field-wrapper">
                            <input type="text" className="searchIcon form-control round-input" placeholder={insightsLbls.searchApp} autoComplete="off" onInput={(event) => searchInAppList(event)} />
                        </p>
                        <div className="appSwitcher custom-control custom-checkbox mr-sm-2 check-container padding-chk-0 pull-right marginTop-5 font14">
                            <label className="check-container displayFlex alignCenter exportCheck marginBottom-0 marginLeft--5">
                                <span className="marginLeft-10">{insightsLbls.hideDeleted}</span>
                                <input type="checkbox" className="custom-control-input chk checkbox" checked={isHideDeleted}
                                    id="app_deleted_check" name="chk-box" value="delete" onChange={() => setIsHideDeleted(!isHideDeleted)} />
                                <span className="checkmark checkmarkBox-color displayInlineBlock top0"></span>
                            </label>
                        </div>
                    </div>
                    {!apiFetched ? getLoaderTemplate() : getAppDataTemplate()}
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-lg-12 displayFlex justify-content-between">
                        <div className="displayFlex">
                            <span className="font13">{insightsLbls.totalApps}: &nbsp;
                                <b>{appMapper.size}</b>
                            </span>
                        </div>
                        <div className="displayFlex">
                            <button className={props.size === "md" ? ("btn btn-primary floatRight marginLeft-10") : ("btn modal-save-btn-sm floatRight marginLeft-10 btn-primary")} onClick={applyEnvFilter} disabled={btnState.okBtnState}>{insightsLbls['apply']}</button>
                            <button className={props.size === "md" ? ("submit-btn btn greyBtn disableClrBtn modal-cancel-btn floatRight marginLeft-10 btn-secondary width-105") : ("submit-btn btn greyBtn disableClrBtn modal-cancel-btn-sm floatRight marginLeft-10 btn-secondary")} onClick={closeAppEnvModal}>{insightsLbls['cancel']}</button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </section>
    );
}

export default React.memo(AppEnvListDropdown);