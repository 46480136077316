// Modified by: Mrunal Mane
// On: 2024-05-13
// Reason: Removed language dependancy from useEffect (fetched_details.apps.appId)

import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppsState, EnvFilterState, LanguageState } from "../../store/Reducers";
import { setEnvFilter } from "../../store/Actions";
import { RetryApi } from "utils/GlobalConstants";
import { useErrorBoundary } from 'react-error-boundary';
import "./EnvironmentFilter.scss";
import { getCall } from "utils/ApiCallActions";
import Loader from "components/Loader/Loader";
import { useLocation } from "react-router";
import { InsightLabels } from "labels";

interface CurrentState {
    apps: AppsState;
    envFilter: EnvFilterState;
    languageData: LanguageState;
}

const EnvironmentFilter = (props: any) => {
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);
    const { showBoundary } = useErrorBoundary();
    const location = useLocation();
    const dispatch = useDispatch();

    const fetched_details = useSelector((state: CurrentState) => state);
    const [envFilter, setEvnFilter] = useState({
        state: [],
        filterTitle: 'All',
        dataFetched: false,
        apiError: true,
        disabledFlag: false
    });

    const [stateFilter, setStatusFilter] = useState({
        isOpen: false,
        selectedEnvValue: fetched_details.envFilter.value,
        selectedEnvCode: fetched_details.envFilter.envCode
    });

    const toggleDropDown = (event: boolean) => {
        setStatusFilter((prevState: any) => ({
            ...prevState,
            isOpen: event,
        }));
    };

    const changeStatus = (value: string, key: string) => {
        try {
            setStatusFilter((prevState: any) => ({
                ...prevState,
                isOpen: false,
                selectedEnvValue: value,
                selectedEnvCode: key
            }));

            localStorage.setItem("ENV_FILTER_STATE", JSON.stringify({ envCode: key, value: value }));
            dispatch(setEnvFilter({
                envCode: value === 'All' ? '' : key,
                value: value,
                listFetched: true
            }));
        } catch (error) {
            showBoundary(error);
        }
    };

    const useHasChanged = (val: any) => {
        const prevVal = usePrevious(val);
        if (prevVal !== undefined) return prevVal !== val;
        return false;
    };

    const usePrevious = (value: any) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };

    const hasVal1Changed = useHasChanged(fetched_details.apps.appId);

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    }, [fetched_details.languageData.languageData]);

    useEffect(() => {
        try {
            if (fetched_details.apps.appId) {
                setEvnFilter(prevState => ({
                    ...prevState,
                    state: [],
                    dataFetched: false,
                    apiError: true,
                }));

                if (hasVal1Changed) {
                    setStatusFilter(prevState => ({
                        ...prevState,
                        isOpen: false,
                        selectedEnvValue: insightsLbls.all,
                        selectedEnvCode: ''
                    }));

                    localStorage.setItem("ENV_FILTER_STATE", JSON.stringify({ envCode: '', value: 'All' }));
                    dispatch(setEnvFilter({
                        envCode: '',
                        value: insightsLbls.all
                    }));

                    setStatusFilter(prevState => ({
                        ...prevState,
                        selectedEnvValue: insightsLbls.all,
                        selectedEnvCode: ''
                    }));
                }
                getEnvironmentList();
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details.apps.appId]); 

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setEvnFilter(prevState => ({
                ...prevState,
                filterTitle: insightsLbls.all
            }));
        }
    }, [fetched_details.languageData.languageData]);

    const getEnvironmentList = (apiErrorFlag?: string) => {
        try {
            let urlData: any = {};
            const query = new URLSearchParams(location.search);
            query.forEach((v, k) => {
                urlData[k] = v;
            });
            let code = urlData["env"];
            const paramsData = {
                app_code: fetched_details.apps.appCode
            };

            let filterState: any[] = [];

            getCall(paramsData, "ENVIRONMENT_FILTER", apiErrorFlag ?? '').then((res: any) => {
                if (res.result === 'success') {
                    for (let itr0 = 0; itr0 < res.data.length; itr0++) {
                        filterState.push({ key: res.data[itr0]['envCode'], value: res.data[itr0]['name'] });
                    }

                    setEvnFilter(prevState => ({
                        ...prevState,
                        state: filterState,
                        dataFetched: true,
                        apiError: false,
                    }));

                    let setFlag = 0;
                    for (let i = 0; i < res.data.length; i++) {
                        let val = res.data[i];
                        if (val.envCode === code) {
                            localStorage.setItem("ENV_FILTER_STATE", JSON.stringify({ envCode: code, value: val.name }));
                            setFlag = 1;
                            dispatch(setEnvFilter({
                                envCode: code,
                                value: val.name,
                                listFetched: true
                            }));

                            setStatusFilter(prevState => ({
                                ...prevState,
                                selectedEnvValue: val.name,
                                selectedEnvCode: code
                            }));

                            break;
                        }
                    }

                    if (code && setFlag === 0) {
                        localStorage.setItem("ENV_FILTER_STATE", JSON.stringify({ envCode: '', value: "All" }));

                        dispatch(setEnvFilter({
                            envCode: '',
                            value: 'All',
                            listFetched: true
                        }));

                        setStatusFilter(prevState => ({
                            ...prevState,
                            selectedEnvValue: 'All',
                            selectedEnvCode: '',
                        }));
                    } else {
                        let envFilterLocalStorageObject: any = localStorage.getItem('ENV_FILTER_STATE');
                        envFilterLocalStorageObject = JSON.parse(envFilterLocalStorageObject);

                        if (envFilterLocalStorageObject) {
                            dispatch(setEnvFilter({
                                envCode: envFilterLocalStorageObject.envCode ?? 'All',
                                value: envFilterLocalStorageObject.value ?? '',
                                listFetched: true
                            }));

                            setStatusFilter(prevState => ({
                                ...prevState,
                                selectedEnvValue: envFilterLocalStorageObject.value ?? 'All',
                                selectedEnvCode: envFilterLocalStorageObject.envCode ?? '',
                            }));
                        } else {
                            localStorage.setItem("ENV_FILTER_STATE", JSON.stringify({ envCode: '', value: "All" }));

                            dispatch(setEnvFilter({
                                envCode: '',
                                value: 'All',
                                listFetched: true
                            }));

                            setStatusFilter(prevState => ({
                                ...prevState,
                                selectedEnvValue: 'All',
                                selectedEnvCode: '',
                            }));
                        }
                    }
                } else if (res.result === 'retry') {
                    setTimeout(() => {
                        getEnvironmentList('retry');
                    }, RetryApi.TIMEOUT);
                } else if (res.result === "error") {
                    setEvnFilter(prevState => ({
                        ...prevState,
                        dataFetched: true,
                        apiError: true,
                    }));

                    dispatch(setEnvFilter({
                        envCode: stateFilter.selectedEnvCode,
                        value: stateFilter.selectedEnvValue,
                        listFetched: true
                    }));
                }
            });
        } catch (error) {
            showBoundary(error);
        }
    };

    useEffect(() => {
        try {
            if (envFilter.dataFetched) {
                if (!props.disabled) {
                    setEvnFilter(prevState => ({
                        ...prevState,
                        disabledFlag: envFilter.state.length === 1
                    }));
                } else {
                    setEvnFilter(prevState => ({
                        ...prevState,
                        disabledFlag: true
                    }));
                }
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [envFilter.dataFetched]);

    const getEnvFilterValue = (value: string) => {
        try {
            let envFilterLocalStorageObject: any = localStorage.getItem('ENV_FILTER_STATE');
            envFilterLocalStorageObject = JSON.parse(envFilterLocalStorageObject);

            if (value === 'All' || value === 'all') {
                return insightsLbls['all'];
            } else if (envFilterLocalStorageObject) {
                if (envFilterLocalStorageObject['value'] === value) {
                    return envFilterLocalStorageObject['value'];
                }
            } else {
                return value;
            }
        } catch (error) {
            showBoundary(error);
        }
    };

    return (
        <div className="marginLeft-5 marginRight-5">
            <Dropdown id="environment-filter-dropdown" show={stateFilter.isOpen} onToggle={toggleDropDown}>
                <div className={`${props.component}-envfilterTitle`}>{insightsLbls.environmentFilterTitle}</div>
                <Dropdown.Toggle disabled={envFilter.disabledFlag} className={"btn btn-lg buttonPositionStateFilter envBtn " + props.component}>
                    <span>{getEnvFilterValue(stateFilter.selectedEnvValue)}</span>
                    {envFilter.disabledFlag === false && (
                        <i aria-hidden="true" className={" marginTop-3 " + (stateFilter.isOpen ? "fa fa-angle-up" : "fa fa-angle-down")}></i>

                        // <i aria-hidden="true" className={"switchApp-btn-icon " + (stateFilter.isOpen ? "fa fa-angle-up" : "fa fa-angle-down")}></i>
                    )}
                </Dropdown.Toggle>
                <Dropdown.Menu className={`dropdown-menu ${props.component}-envfilterDropdown`}>
                    {envFilter.dataFetched ? (
                        envFilter.apiError ? (
                            <div className="messageDiv">
                                <div className="noDataText-font">
                                    {insightsLbls.serviceNotAvailable}
                                </div>
                            </div>
                        ) : (
                            envFilter.state.length ? (
                                envFilter.state.map((state: any, index: any) => (
                                    <div className="dropdown-status-menu" key={index}>
                                        <button onClick={() => changeStatus(state.value, state.key)} id={"status_" + state.key}
                                            className={"pointer dropdown-menu-item statusBtn " + (stateFilter.selectedEnvValue === state.value ? "active-button" : "")}>
                                            {state.value}
                                        </button>
                                    </div>
                                ))
                            ) : (
                                <div className="messageDiv">
                                    <div className="noDataText-font">
                                        {insightsLbls.noRecordFound}
                                    </div>
                                </div>
                            )
                        )
                    ) : (
                        <div className="messageDiv">
                            <Loader height={110} width={110}></Loader>
                        </div>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default EnvironmentFilter;