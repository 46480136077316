
// Modified by: Mrunal Mane
// On: 2024-05-10 05:48 PM
// Reason: Removed language dependancy from useEffects of (props.data)

import React, { useEffect, useCallback, useState } from 'react';
import { Modal } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";
import { LanguageState } from "../../store/Reducers";


import $ from 'jquery';
import { CommonUtils } from 'utils/CommonUtils';


import Loader from "components/Loader/Loader";
import Filter from 'components/Filter/Filter';
import SingleSelect from 'components/Chart/QuestionListCharts/SingleSelect';
import MultiSelect from 'components/Chart/QuestionListCharts/MultiSelect';
import Comment from 'components/Chart/QuestionListCharts/Comment';
import Range from 'components/Chart/QuestionListCharts/Range';
import YesNo from 'components/Chart/QuestionListCharts/YesNo';
import Rating from 'components/Chart/QuestionListCharts/Rating';

import "./Card.scss";



import { getSelectedLabels } from "utils/stringConstants/trans_utils";
import { InsightLabels } from 'labels';
import Icon from 'components/Icon/Icon';
import Pagination from 'components/Pagination/Pagination';

interface CurrentState {
    languageData: LanguageState
}

const Card = (props: any) => {
    let fetched_details = useSelector((state: CurrentState) => state);

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)


    const filterBy = [
        { key: "5", value: "5" },
        { key: "10", value: "10" },
        { key: "20", value: "20" },
        { key: "30", value: "30" },
    ];

    let eventBy;
    if(process.env.REACT_APP_WESTPAC === 'false'){
        eventBy = [
            { key: "all", value: insightsLbls.all },
            { key: "mi_guide_play", value: "GuideMe" },
            { key: "mi_showme_play", value: "ShowMe" },
            { key: "mi_do_it_for_me", value: "DoItForMe"},
            { key: "mi_test", value: "TestMe" },
            { key: "mi_guide_download", value: insightsLbls.guideDownloads },
        ];

    } else {
        eventBy = [
            { key: "all", value: insightsLbls.all },
            { key: "mi_guide_play", value: "GuideMe" },
            { key: "mi_guide_download", value: insightsLbls.guideDownloads },
        ];

    }

    const [hideLoader, setHideLoader] = useState({
        loader: false
    });

    const [cardLoader, setCardLoader] = useState({
        cardLoader: false
    });

    const [stepData, setStepData] = useState({
        data: [],
        stepDataFetched: false,
        stepData_timestamp: ''
    });

    let forcePage = props.currentPage - 1;

    const paginationVar = props.cardPagination;

    const [modalData, setModalData] = useState({
        modalData: [],
        modalTitle: "",
        isOpen: false,
    })

    const [exportExcelModal, setExportExcelVal] = useState({
        openExportExcelModal: false,
    });

    let activeQuestionList: boolean[] = [];

    useEffect(() => {
        if(fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
     
    },[fetched_details.languageData.languageData])


    const tooltipText = (
        <Tooltip id="tooltipText">
            <br></br>
            <div><span className="fontGreyCard">{insightsLbls.textCommentsFormat}</span></div>
            <br></br>
        </Tooltip>)

    const tooltipSingle = (
        <Tooltip id="tooltipSingle">
            <br></br>
            <ul>
                <li><span className="fontGreyCard">{insightsLbls.pieChartRepresentating}</span></li>
                <br></br>
                <li><span className="fontGreyCard">{insightsLbls.tabularRepresentationOfResponse}</span></li>
            </ul>
            <br></br>
        </Tooltip>)

    const tooltipMulti = (
        <Tooltip id="tooltipMulti">
            <br></br>
            <ul>
                <li><span className="fontGreyCard">{insightsLbls.vennDiagramCombinationOption}</span></li>
                <br></br>
                <li><span className="fontGreyCard">{insightsLbls.tableOfCombinationOption}</span></li>
            </ul>
            <br></br>
        </Tooltip>)

    const tooltipRange = (
        <Tooltip id="tooltipRange">
            <br></br>
            <ul>
                <li><span className="fontGreyCard">{insightsLbls.barforRating}</span></li>
                <br></br>
                <li><span className="fontGreyCard">{insightsLbls.tableForCounts}</span></li>
            </ul>
            <br></br>
        </Tooltip>)

    const tooltipRating = (
        <Tooltip id="tooltipRating">
            <br></br>
            <ul>
                <li><span className="fontGreyCard">{insightsLbls.barForRatings}</span></li>
                <br></br>
                <li><span className="fontGreyCard">{insightsLbls.tableForRatings}</span></li>
            </ul>
            <br></br>
        </Tooltip>)

    const tooltipYesNo = (
        <Tooltip id="tooltipYesNo">
            <br></br>
            <ul>
                <li><span className="fontGreyCard">{insightsLbls.barChartForYesNo}</span></li>
                <br></br>
                <li><span className="fontGreyCard">{insightsLbls.tableofCountsPercentage}</span></li>
            </ul>
            <br></br>
        </Tooltip>)


    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // load on props data change
    useEffect(() => {
        setCardLoader({
            ...cardLoader,
            cardLoader: true
        });
        setHideLoader((prevData: any) => {
            return {
                ...prevData,
                loader: false,
            }
        });
    }, [props.data])


    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    for (let i = 0; i < props.data.length; i++) {
        activeQuestionList[i] = false
    }

    const [responses, setResponses] = useState({
        activeQuestion: activeQuestionList,
    });

    const openModal = (data: any, type: string) => {
        let modalTitle: string = ""
        modalTitle = type === "Guide Me" ? "GuideMe " + insightsLbls.timeLine : "DoItForMe " + insightsLbls.timeLine;

        setModalData((prevState: any) => {
            return {
                ...prevState,
                modalTitle: modalTitle,
                isOpen: true,

            }
        })

        if (data.step_details !== undefined && Object.keys(data.step_details[0]).length !== 6) {
            setStepData((prevState: any) => {
                return {
                    ...prevState,
                    data: data.step_details,
                    stepDataFetched: true,
                    stepData_timestamp: data?.start_timestamp
                }
            })
        } else {
            setStepData((prevState: any) => {
                return {
                    ...prevState,
                    data: insightsLbls.detailsNotAvailable,
                    stepDataFetched: false,
                }
            })
        }
    };

    const closeModal = () => {
        setModalData((prevState: any) => {
            return {
                ...prevState,
                isOpen: false,
            }
        })
    };

    const getType = (type: string) => {
        switch (type) {
            case 'mi_showme_play':
                return 'Show Me';

            case 'mi_guide_play':
                return 'Guide Me';

            case 'mi_guide_download':
                return insightsLbls.guideDownloads;

            case 'mi_test':
                return 'Test Me';

            case 'mi_do_it_for_me':
                return 'Do It For Me';

            default:
                return 'GuideMe';
        }
    }

    const getSubType = (type: string) => {
        if (type === 'live') {
            return insightsLbls.live;
        } else {
            return type;
        }
    }

    const changePage = (e: any) => {
        props.clickEvent("changePage", e);
        forcePage = e;

        // if (e.selected === Math.floor(props.data.totalCount / paginationVar.perPage)) {
        //     $('.nexBtn' + props.cardId).parent().parent().addClass('disabled');
        // } else {
        //     $('.nexBtn' + props.cardId).parent().parent().removeClass('disabled');
        // }
        setCardLoader({
            ...cardLoader,
            cardLoader: false
        });
    };

    const handleClick = useCallback((option: string, data: any) => {
        switch (option) {
            case "showEntries":
                forcePage = 0;
                props.clickEvent("changeEntries", data);
                setCardLoader({
                    ...cardLoader,
                    cardLoader: false
                });
                break;
            case "eventType":
                forcePage = 0;
                props.clickEvent("changeEvent", data);
                setCardLoader({
                    ...cardLoader,
                    cardLoader: false
                });
                break;
        }
    }, []);

    const handleResponseClick = useCallback((index: any) => {
        responseNumberClicked(index)
    }, []);

    const responseNumberClicked = (index: number) => {
        setResponses((prevData: any) => {
            return {
                ...prevData,
                activeQuestion: responses.activeQuestion.map((item: any, i: any) =>
                    i === index ? (prevData.activeQuestion[i] === true ? false : true) : (false)
                ),
            };
        })
    };

    const onHideDeleted = (e: any) => {
        props.cardEvent("hideDeleted", e.target.checked ? true : false);
        setHideLoader((prevData: any) => {
            return {
                ...prevData,
                loader: true,
            }
        });
    };

    const exportExcel = () => {
        setExportExcelVal((prevData: any) => { return { ...prevData, openExportExcelModal: true } });
    };

    const getCsv = (viewOl: boolean) => {
        props.cardEvent("exportExcel", viewOl);
        setExportExcelVal((prevData: any) => { return { ...prevData, openExportExcelModal: false } });
    };

    return (
        <div>
            {
                props.cardId === "surveyStatsDetail" ? (
                    <React.Fragment>
                        <div className="col-sm-12 col-lg-12 col-md-12 surveyExportDiv">
                            <div className="hideDeleted col-lg-4 padding0  font13 alignCenter displayFlex">
                                {props.hideDeleted && (
                                    <div className="marginLeft-30 marginTop25 custom-control custom-checkbox mr-sm-2 check-container displayFlex alignCenter">
                                        <label className="check-container  exportCheck marginBottom-0 marginLeft--5 positionRelative height20 width10px">

                                            {/* [checked]="hide_deleted==1" */}
                                            {/* [disabled]="!((firstTimeLoad === true && powerForm.length!=0) || firstTimeLoad === false)" */}

                                            <input type="checkbox"
                                                className="custom-control-input chk checkbox"
                                                id="customControlAutosizing1"
                                                onClick={(event) => onHideDeleted(event)}
                                                name="chk-box"
                                                value="delete"
                                                disabled={props.data.totalCount === 0 && props.firstTimeloadFlag} />
                                            <span className="checkmark checkmark-tooltip displayInlineBlock top0"></span>

                                        </label>
                                        <span className="marginLeft-10 marginTop-10">{props.checkboxText}</span>
                                    </div>
                                )}

                            </div>
                            <div className=" col-lg-8 padding0 exportDiv">

                                {props.showExport ? (
                                    <div className="export-button marginTB-8 pull-right paddingRight-20">
                                        <button
                                            className="form-control btn btn-primary"
                                            data-toggle="modal"
                                            onClick={() => exportExcel()}
                                            disabled={props.data.totalCount === 0}
                                        >
                                            {/* <img
                                                src={
                                                    require("../../assets/images/export-excel.png")
                                                }
                                                width="22"
                                                height="auto"
                                                alt=""
                                                className="excel-icon" /> */}
                                            {insightsLbls.exportToExcel}
                                        </button>
                                    </div>
                                ) : (
                                    null
                                )}

                            </div>
                        </div>
                        {
                            hideLoader.loader === false ?
                                (
                                    props.data.length !== 0 ?
                                        (
                                            <div className="col-sm-12 col-md-12 col-lg-12 padding0 minHeight300">
                                                {
                                                    props.data.map((data: any, index: number) => {
                                                        return (
                                                            <div key={index} className="col-sm-12 col-md-12 col-lg-12 padding0 surveyQuestionsList">
                                                                {/* + responses.activeQuestion[index]? ("cardLayout"):("cardLayout") */}
                                                                <div className={"col-sm-12 col-md-12 col-lg-12 padding0 cardLayout "}>
                                                                    <div className="questionDiv col-sm-12 col-md-12 col-lg-12 marginBottom-0" onClick={() => handleResponseClick(index)}>
                                                                        <span className="col-lg-8 col-md-8 col-sm-11 quesStatement order1 padding0 subType displayFlex">
                                                                            {
                                                                                <React.Fragment>
                                                                                    <span className="quesNumber-text displayFlex">
                                                                                        Q{(index + 1)}.
                                                                                    </span>
                                                                                    <span className="question-title text-nowrap order1" id="textHidden">
                                                                                        &nbsp;{data.question}
                                                                                    </span>
                                                                                </React.Fragment>

                                                                            }
                                                                            {data.isDeleted === true && (
                                                                                <span className="stepDeletedCard displayFlex">
                                                                                    ({insightsLbls.deleted})
                                                                                </span>
                                                                            )
                                                                            }
                                                                        </span>
                                                                        <span className="col-lg-2 col-md-2 col-sm-6 displayFlex alignCenter padding0">
                                                                            <span className="quesType comment-type headingTextSurvey sentimentList">
                                                                                {data.type === "comment" ? (<React.Fragment><span>{insightsLbls.textType}</span>
                                                                                    <OverlayTrigger placement={"bottom"} overlay={tooltipText}>
                                                                                        <i className="fa fa-info-circle marginLeft-10" aria-hidden="true"> </i>
                                                                                    </OverlayTrigger></React.Fragment>) :
                                                                                    (data.type === "select" ?
                                                                                        (<React.Fragment><span>{insightsLbls.selectSingleType}</span>
                                                                                            <OverlayTrigger placement={"bottom"} overlay={tooltipSingle}>
                                                                                                <i className="fa fa-info-circle marginLeft-10" aria-hidden="true"> </i>
                                                                                            </OverlayTrigger></React.Fragment>
                                                                                        ) :
                                                                                        (data.type === "multi-select" ? (
                                                                                            <React.Fragment><span>{insightsLbls.multiSelectType}</span>
                                                                                                <OverlayTrigger placement={"bottom"} overlay={tooltipMulti}>
                                                                                                    <i className="fa fa-info-circle marginLeft-10" aria-hidden="true"> </i>
                                                                                                </OverlayTrigger></React.Fragment>) :
                                                                                            (data.type === "range" ? (<React.Fragment><span>{insightsLbls.rangeType}</span>
                                                                                                <OverlayTrigger placement={"bottom"} overlay={tooltipRange}>
                                                                                                    <i className="fa fa-info-circle marginLeft-10" aria-hidden="true"> </i>
                                                                                                </OverlayTrigger></React.Fragment>) :
                                                                                                (data.type === "rating" ? (<React.Fragment><span>{insightsLbls.starRatingType}</span>
                                                                                                    <OverlayTrigger placement={"bottom"} overlay={tooltipRating}>
                                                                                                        <i className="fa fa-info-circle marginLeft-10" aria-hidden="true"> </i>
                                                                                                    </OverlayTrigger></React.Fragment>) :
                                                                                                    data.type === "yes-no" ? (<React.Fragment><span>{insightsLbls.yesnoType}</span>
                                                                                                        <OverlayTrigger placement={"bottom"} overlay={tooltipYesNo}>
                                                                                                            <i className="fa fa-info-circle marginLeft-10" aria-hidden="true"> </i>
                                                                                                        </OverlayTrigger></React.Fragment>) : (null)))))}

                                                                            </span>
                                                                        </span>
                                                                        <span className="col-lg-2 col-md-2 col-sm-2 displayFlex alignCenter paddingLeft-80 marginLeft-35">
                                                                            <span className="text-muted font14">{insightsLbls.responses} </span>
                                                                            <i className={"pull-right text-muted arrow-icon-dropdown fa " + `${responses.activeQuestion[index] === true ? 'fa-angle-up' : 'fa-angle-down'}`}></i>
                                                                        </span>
                                                                    </div>
                                                                    {responses.activeQuestion[index] === true ? (<div className="responseDiv">
                                                                        {
                                                                            data.type === "comment" ? (<Comment tableData={data.comments} totalCount={data.totalComments} divIndex={index}></Comment>) :
                                                                                (data.type === "select" ? (<SingleSelect tableData={data.options} chartData={data.options} divIndex={index}></SingleSelect>) :
                                                                                    (data.type === "multi-select" ? (<MultiSelect tableData={data.options} chartData={data.options} divIndex={index}></MultiSelect>) :
                                                                                        (data.type === "range" ? (<Range tableData={data.options} chartData={data.options} divIndex={index}></Range>) :
                                                                                            (data.type === "rating" ? (<Rating tableData={data.options} chartData={data.options} divIndex={index}></Rating>) :
                                                                                                data.type === "yes-no" ? (<YesNo answeredYes={data.answeredYes} answeredNo={data.answeredNo} divIndex={index}></YesNo>) : (null)))))}</div>) : (null)}
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight300">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    {insightsLbls.noRecordFound} 
                                                </div>
                                            </div>
                                        )
                                )
                                :
                                (
                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight300">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )
                        }

                        <Modal
                            onHide={() =>
                                setExportExcelVal({
                                    ...exportExcelModal,
                                    openExportExcelModal: false,
                                })
                            }
                            show={exportExcelModal.openExportExcelModal}
                            bsSize="medium"
                        >
                            <Modal.Header>
                                <Modal.Title>{insightsLbls.exportExcel}</Modal.Title>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={() =>
                                        setExportExcelVal((prevData: any) => { return { ...prevData, openExportExcelModal: false } })
                                    }
                                >
                                    <span aria-hidden="true">×</span>
                                    <span className="sr-only">{insightsLbls.close}</span>
                                </button>
                            </Modal.Header>
                            <Modal.Body className="displayFlex height100-percent">
                                <div className="col-lg-12 paddingTB-20 displayFlex btns-div">
                                    <div className="downloadCsv col-lg-6 padding0">
                                        {/* (click)="getCsv(false)" */}
                                        <span
                                            className="btn btn-primary font13 width100"
                                            onClick={() => getCsv(false)}
                                        >
                                            {" "}
                                            <i className="fa fa-download" aria-hidden="true"></i> {insightsLbls.download}
                                        </span>
                                    </div>
                                    <div className="marginTop-25">
                                        <div className="vl"></div>
                                        <div className="or-div">{insightsLbls.or}</div>
                                    </div>
                                    <p className="text font14 marginLeft-10 font500 excelText">
                                        {insightsLbls.excelViewerQn}
                                    </p>

                                    <div className="downloadCsv col-lg-6 padding0">
                                        {/* (click)="getCsv(true)" */}
                                        <span
                                            className="btn btn-primary font13"
                                            onClick={() => getCsv(true)}>
                                            <i className="fa fa-cloud" aria-hidden="true"></i> {insightsLbls.viewOnline}
                                        </span>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </React.Fragment>
                ) :
                    (
                        <div>
                            <div className="displayFlex marginTop-50">
                                {props.showEntries ?
                                    (<Filter
                                        data={filterBy}
                                        filterName={insightsLbls.showEntries}
                                        filterType={'showEntries'}
                                        filterID={1}
                                        filterDisabled={props.data.totalCount === 0 && props.firstTimeloadFlag ? (true) : (false)}
                                        clickEvent={handleClick} ></Filter>)
                                    : (<div></div>)}
                                {props.showEventType ?
                                    (<Filter data={eventBy}
                                        filterName={insightsLbls.eventType}
                                        filterType={'eventType'}

                                        filterID={2}
                                        filterDisabled={props.data.totalCount === 0 && props.firstTimeloadFlag ? (true) : (false)}
                                        clickEvent={handleClick}></Filter>)
                                    : (<div></div>)}
                            </div>



                            {cardLoader.cardLoader ? (
                                <div className="marginTop-20 minHeight300 positionRelative">
                                    {props.data.totalCount ? props.data.guideDetails.map((data: any, index: number) => {
                                        return (
                                            <div key={index} className="col-sm-12 col-md-12 col-lg-12 padding0">
                                                <div className="cardLayout col-sm-12 col-md-12 col-lg-12 padding0">
                                                    <p className="displayFlex col-sm-12 col-md-12 col-lg-12 marginBottom-0 paddingLeft-10 paddingRight-10">
                                                        <span className="col-sm-6 col-md-6 col-lg-6 padding0 subType">
                                                            {
                                                                (
                                                                    getType(data?.event_type) === 'Guide Me' ?
                                                                        (
                                                                            <Icon img={'img/GuideMe.svg'}  className={data?.event_type+"_icon title-icon cardTitle-icon"} />
                                                                        )
                                                                        :
                                                                        (
                                                                            getType(data?.event_type) === 'Show Me' ?
                                                                                (
                                                                                    <Icon img={'img/ShowMe.svg'}  className={data?.event_type+"_icon title-icon cardTitle-icon"} />
                                                                                )
                                                                                :
                                                                                (
                                                                                    getType(data?.event_type) === 'Test Me' ?
                                                                                        (
                                                                                            <Icon img={'img/TestMe.svg'}  className={data?.event_type+"_icon title-icon cardTitle-icon"} />
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            getType(data?.event_type) === 'Do It For Me' ?
                                                                                                (
                                                                                                    <Icon img={'img/DoItForMe.svg'}  className={data?.event_type+"_icon title-icon cardTitle-icon"} />
                                                                                                )
                                                                                                :
                                                                                                (
                                                                                                    <div className={`${data?.event_type + "_icon"}` + " title-icon cardTitle-icon"}><i className=" fa fa-download" aria-hidden="true"></i></div>
                                                                                                )
                                                                                        )
                                                                                )
                                                                        )
                                                                )
                                                            }
                                                            <span className={`${data?.event_type}` + " eventType"}>
                                                                {getType(data?.event_type)}
                                                            </span>

                                                            {
                                                                <>
                                                                    {
                                                                        data?.sub_type?.length > 0 ?
                                                                            (
                                                                                <span className={`${data?.sub_type}` + " marginLeft-10 subType-span"}>
                                                                                    {getSubType(data?.sub_type)}
                                                                                </span>
                                                                            )
                                                                            :
                                                                            (
                                                                                null
                                                                            )
                                                                    }

                                                                    {
                                                                        getType(data?.event_type) === 'Guide Me' || getType(data?.event_type) === 'Do It For Me' ?
                                                                            (
                                                                                <span className="marginLeft-35">
                                                                                    <button className="viewBtn" onClick={() => openModal(data, getType(data?.event_type))}>{insightsLbls.viewPlayDetails}</button>
                                                                                </span>
                                                                            )
                                                                            :
                                                                            (
                                                                                null
                                                                            )
                                                                    }
                                                                </>

                                                            }


                                                        </span>

                                                        <span className="col-sm-6 col-md-6 col-lg-6 padding0">
                                                            <span className="floatRight font13 color_grey marginLeft-10">
                                                                <i className="fa fa-calendar-o marginRight-5" aria-hidden="true"></i>
                                                                {CommonUtils.dateFormat(data?.start_timestamp, 'date')}
                                                            </span>
                                                            <span className="floatRight font13 color_grey marginLeft-10">
                                                                <i className="fa fa-clock-o marginRight-5" aria-hidden="true"></i>
                                                                {CommonUtils.dateFormat(data?.start_timestamp, 'time')}
                                                            </span>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })
                                        :
                                        (
                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <div className="noDataText-font textCenter-absolute">
                                                        {insightsLbls.noRecordFound} 
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        props.data.totalCount > 0 ?
                                            (
                                                <div className="positionRelative">
                                                    <div className="countContainer">
                                                        <span className="paginations-showingOf-text marginLeft-5">{insightsLbls.showing} </span>
                                                        <span className="paginations-count-text">{((forcePage + 1) * paginationVar.perPage) - (paginationVar.perPage - 1)}</span>
                                                        <span className="paginations-showingOf-text"> - </span>
                                                        {
                                                            ((forcePage + 1) * paginationVar.perPage < props.data.totalCount) ?
                                                                (<span className="paginations-count-text">{(forcePage + 1) * paginationVar.perPage}</span>)
                                                                : (<span className="paginations-count-text">{props.data.totalCount}</span>)
                                                        }
                                                        <span className="paginations-showingOf-text marginLeft-5"> {insightsLbls.of} </span>
                                                        <span className="paginations-count-text">{props.data.totalCount}</span>
                                                    </div>
                                                    <div className="paginationContainer">
                                                        {/* <ReactPaginate
                                                            previousLabel={<span aria-hidden="true">«</span>}
                                                            nextLabel={<span className={"nexBtn" + props.cardId} aria-hidden="true">»</span>}
                                                            breakLabel={"..."}
                                                            breakClassName={"break-me"}
                                                            pageCount={props.data.totalCount / paginationVar.perPage}
                                                            marginPagesDisplayed={2}
                                                            pageRangeDisplayed={paginationVar.perPage}
                                                            onPageChange={(event) => changePage(event)}
                                                            containerClassName={"pagination"}
                                                            activeClassName={"active"}
                                                            forcePage={forcePage}
                                                        /> */}
                                                        <Pagination
                                                            pageNumber={forcePage}
                                                            showEntries={false}
                                                            totalCount={props.data.totalCount}
                                                            pageLimit={paginationVar.perPage}
                                                            handleChange={(event) => changePage(event)}
                                                            changeLimit={(event) => {}}
                                                            rowsLabel={insightsLbls.rowsPerPage}
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            // <div className="noDataText-font textCenter-absolute">
                                            //     {insightsLbls.noRecordFound} 
                                            // </div>
                                            )
                                    }
                                    <Modal
                                        show={modalData.isOpen}
                                        onHide={closeModal}
                                        backdrop="static"
                                        keyboard={false}
                                        size={'lg'}
                                        aria-labelledby="contained-modal-title-vcenter"
                                    // centered
                                    >
                                        <Modal.Header>
                                            <Modal.Title id="contained-modal-title-vcenter">
                                                {modalData.modalTitle}
                                            </Modal.Title>
                                            <button
                                                type="button"
                                                className="close"
                                                onClick={closeModal}
                                            >
                                                <span aria-hidden="true">×</span>
                                                <span className="sr-only">{insightsLbls.close}</span>
                                            </button>
                                        </Modal.Header>
                                        <Modal.Body className="padding-20 cardsModal">
                                            {stepData.stepDataFetched
                                                ? (
                                                    <>
                                                        <div className="startTime-div">
                                                            <span className="startTime-span">
                                                                <Icon img={'img/GuideMe.svg'} className="guideTitle-icon"/>
                                                                {props.guideTitle}
                                                            </span>
                                                        </div>
                                                        <div className="borderLeft">
                                                            {stepData.data.map((data: any, ind: number) => {
                                                                return (
                                                                    <div key={ind} className="stepsDiv">
                                                                        <div className={`displayFlex alignCenter marginBottom-20 ${ind === 0 ? "paddingTop-30" : ""}`}>
                                                                            <span className="horizontalLine-span"></span>
                                                                            <div className="stepCard">
                                                                                <p className="stepTitle-span displayFlex">
                                                                                    <Icon img="img/GuideMe.svg" className="stepTitle-icon" />
                                                                                    {data.step_title}
                                                                                </p>

                                                                                <p className="margin0 stepTime-span">


                                                                                    <i className="fa fa-calendar-o marginRight-5" aria-hidden="true"></i>
                                                                                    {CommonUtils.dateFormat(data.event_start_time, 'date')}

                                                                                    <i className="fa fa-clock-o marginRight-5 marginLeft-10" aria-hidden="true"></i>
                                                                                    {CommonUtils.dateFormat(data.event_start_time, 'time')}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </>

                                                )
                                                : (
                                                    <div className="noDatadiv">
                                                        <p className="stepTitle-span alignSelf">
                                                            {stepData.data}
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        </Modal.Body>
                                    </Modal>
                                </div>) :
                                (<div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight300">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>)}
                        </div>
                    )}
        </div>


    )
}

export default React.memo(Card);