/**
 * @author mtiwari
 * Updated on 22 May, 2024
 * Corrected section tooltip structure for guide & validation while adding chartto custom dashboard
 */

import React, { useState, useEffect, useRef, useCallback } from "react";
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation } from 'react-router-dom';


import qs from 'querystring';
import { CommonUtils } from "utils/CommonUtils";
import { ChartColors } from "utils/GlobalConstants";


// Redux Imports
import { withReducer } from "store/withReducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { TooltipState } from "store/Reducers/GuideInsights/tooltips";
import tooltipReducer from "store/Reducers/GuideInsights/tooltips";
import * as tooltipActions from "store/Actions/index";
import { EventSourceState, UrlOptionState, TooltipFilterState, CommonComponentState, LanguageState } from "store/Reducers";
import * as ActionTypes from "../../../store/Actions/ActionTypes";


// SCSS Imports
import "./Tooltips.scss";


import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";
import SectionHeader from "components/Layouts/SectionHeader";
import Datepicker from "components/Calender/Datepicker";
import SectionButtons from "components/Layouts/SectionButtons";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import EventSource from 'components/EventSource/EventSource';
import Segments from 'components/SegmentationFilter/Segmentation';
import Loader from "components/Loader/Loader";
import TooltipModal from "components/CommonModal/Tooltip/TooltipModal";
import CustomDashboardModal from "components/CommonModal/CustomDashboardModal/CustomDashboardModal";
import CommonTable from "components/Table/CommonTable";
import Maintenance from "components/Maintenance/Maintenance";
import Linechart from "components/Chart/nvd3Charts/LineChart"
import { useErrorBoundary } from 'react-error-boundary';
import SectionFilters from "components/Layouts/SectionFilters";
import EnvironmentFilter from "components/EnvironmentFilter/EnvironmentFilter";
import { InsightLabels } from "labels";
import { v4 as uuidv4 } from 'uuid';
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";
import UserInfoSection from "components/Layouts/UserInfoSection";
import Icon from 'components/Icon/Icon';

// type PathParamsType = {
//     // param1: string,
// }

// // Your component own properties
// type props = RouteComponentProps<PathParamsType> & {
//     // someString: string,
// }

interface CurrentState {
    eventSource: EventSourceState,
    tooltips: TooltipState,
    urlOption: UrlOptionState,
    tooltipList: TooltipFilterState,
    commonComponentData: CommonComponentState,
    languageData: LanguageState
}


const Tooltips: React.FC = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();
    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    const dispatch = useDispatch();

    const location = useLocation();
    const navigate = useNavigate();

    const tooltipActivityChartMount = useRef(false);
    const tooltipPowerformChartMount = useRef(false);
    const smartTipSummaryTableMount = useRef(false);

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    let fetched_details = useSelector((state: CurrentState) => state);

    let activity_linechart_height = 400;
    let lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "65",
    }

    const [filterValues, setFilterValues] = useState({
        eventSource: "",
        guideStatus: "",
        segmentsGroup: {
            segmentsFilter: 0,
            segmentTitle: ''
        }
    });


    // const [goingUp, setGoingUp] = useState(false);

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        disabled: false,
    });

    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0, 0, 0],
        showMaintenance_flag: false
    });

    const [guideIds, setGuideIds] = useState({
        list: ''
    });

    const [tooltipChartData, setTooltipChartData] = useState({
        chartData: [],
        api: '',
        guidance_count: 0,
        validation_count: 0,
        guidanceValidation_count: 0,
        powerForm_count: 0,
        ductTape_count: 0
    })

    const [activityChart, setActivityChart] = useState({
        activityLineChartFetched: false,
        warningFlag: false
    })

    const [ductTapePowerFormChart, setDuctTapePowerFormChart] = useState({
        ductTapePowerFormLineChartFetched: false,
        warningFlag: false
    })

    const [smartTipSummary, setSmartTipSummary] = useState({
        summaryFetched: false,
        searchQuery: '',
        sortKey: 'guidance_count',
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 1,
            'sortValue': 'desc'
        },
        sortValue: 'desc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true,
        hideDeleted: 0
    });

    const [parameter, setParameter] = useState({
        paramter: "",
        source: "",
    });

    const [request_uuid, setUuid] = useState({
        value: uuidv4()
    })

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
            window.scrollTo(0, 0);
            CommonUtils.LeftPanelHighlight(1, 4, 0, true, false);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        document.title = insightsLbls.docTitleTooltips ? insightsLbls.docTitleTooltips : "";
    }, [insightsLbls])


    useEffect(() => {
        setFilterValues((prevState: any) => {
            return {
                ...prevState,
                eventSource: fetched_details?.commonComponentData?.eventSource.eventSourceTooltips,
                segmentsGroup: { segmentsFilter: fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter, segmentTitle: fetched_details?.commonComponentData?.segmentsFilter?.segmentTitle },
            };
        });
    }, []);

    // Api call on App, Date, event source filter change
    useEffect(() => {
        try {
            let queryParams = {
                app_id: fetched_details?.commonComponentData.appsData.appId,
                app_code: fetched_details?.commonComponentData.appsData.appCode,
                fdate: fetched_details?.commonComponentData.datesData.fdate,
                tdate: fetched_details?.commonComponentData.datesData.tdate,
                event_source: fetched_details?.commonComponentData?.eventSource.eventSourceTooltips,
                segment: fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
                option: 'Tooltips_Validation',
                env: fetched_details?.commonComponentData?.envFilter?.envCode,
            }
            navigate({
                pathname: `/guide-insights/tooltips/`,
                search: qs.stringify(queryParams)
            })
            if (fetched_details?.commonComponentData.datesData.source === 'component' &&
                fetched_details?.commonComponentData.appsData?.appCode === localStorage.getItem('switch_app_code') &&
                fetched_details?.commonComponentData?.envFilter.listFetched) {
                // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
                if (fetched_details?.commonComponentData.datesData?.url?.includes('tooltips/') &&
                    fetched_details.commonComponentData.segmentsFilter.listFetched) {
                    const reqUUID = uuidv4();
                    setUuid((prevState: any) => {
                        return {
                            ...prevState,
                            value: reqUUID
                        }
                    })

                    setFilterValues((prevState: any) => {
                        return {
                            ...prevState,
                            eventSource: fetched_details?.eventSource?.eventSourceTooltips,
                            segmentsGroup: { segmentsFilter: fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter, segmentTitle: fetched_details?.commonComponentData?.segmentsFilter?.segmentTitle },
                        };
                    });

                    setLoaderBtn((prevState: any) => {
                        return {
                            ...prevState,
                            loader0: false,
                            loader1: false,
                            disabled: false,
                        }
                    })

                    setActivityChart((prevState: any) => {
                        return {
                            ...prevState,
                            activityLineChartFetched: false
                        }
                    })
                    setDuctTapePowerFormChart((prevState: any) => {
                        return {
                            ...prevState,
                            ductTapePowerFormLineChartFetched: false
                        }
                    })
                    getTooltipChartData(reqUUID, guideIds.list)
                    setSmartTipSummary((prevState: any) => {
                        return {
                            ...prevState,
                            summaryFetched: false,
                            firstLoad: true,
                            searchQuery: '',
                            sortKey: 'guidance_count',
                            page: 1,
                            showExport: true,
                            defaultSort: {
                                'sortColumn_index': 1,
                                'sortValue': 'desc'
                            },
                            sortValue: 'desc',
                            limit: 5,
                            tablePagination: {
                                offset: 0,
                                data: [],
                                perPage: 5,
                                currentPage: 0
                            },
                            hideDeleted: 0
                        };
                    });
                }
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details?.commonComponentData.datesData,
    fetched_details?.commonComponentData?.eventSource.eventSourceTooltips,
    fetched_details?.commonComponentData.segmentsFilter.listFetched,
    fetched_details?.commonComponentData.segmentsFilter.segmentsFilter,
    fetched_details?.commonComponentData.datesData.url,
    fetched_details?.tooltipList.tooltipList,
    fetched_details?.commonComponentData?.envFilter,
    fetched_details.languageData.languageData])


    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getTooltipChartData = (reqUUID, guideIds: any, apiErrorFlag?: string) => {
        let params = {
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "time_zone": timeZone,
            'event_source': fetched_details?.commonComponentData?.eventSource.eventSourceTooltips,
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            "segment_group": fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            "env_code": fetched_details?.commonComponentData?.envFilter.envCode ?? '',
            'request_uuid': reqUUID
        };

        setTooltipChartData((prevState: any) => {
            return {
                ...prevState,
                api: ['TOOLTIP_VALIDATION'],
            }
        })

        tooltipActions.getTooltipData(
            params,
            "TOOLTIP_VALIDATION",
            ActionTypes.SET_TOOLTIP_ACTIVITY_CHART,
            apiErrorFlag ?? '',
            false,
            guideIds
        ).then((res: any) => dispatch(res));
    }

    const getSmartTipSummaryData = (reqUUID, guideIds: any, apiErrorFlag?: string) => {
        let params = {
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "time_zone": timeZone,
            'hide_deleted': smartTipSummary.hideDeleted,
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            'query': encodeURIComponent(smartTipSummary.searchQuery),
            'sort_by': smartTipSummary.sortKey,
            'order': smartTipSummary.sortValue,
            'page_index': smartTipSummary.page,
            'page_size': smartTipSummary.limit,
            "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceTooltips,
            "segment_group": fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            'all_guides': true,
            "env_code": fetched_details?.commonComponentData?.envFilter.envCode ?? '',
            'request_uuid': reqUUID
        };

        tooltipActions.getTooltipData(
            params,
            "SMART_TIP_SUMMARY",
            ActionTypes.SET_SMART_TIP_SUMMARY,
            apiErrorFlag ?? '',
            smartTipSummary.firstLoad,
            guideIds
        ).then((res: any) => dispatch(res));
    }

    //Setting Chart Data Use Effect
    useEffect(() => {
        try {
            if (
                fetched_details.tooltips.tooltipActivityChart.data !== undefined &&
                fetched_details.tooltips.tooltipActivityChart.result === "success" &&
                fetched_details?.tooltips.tooltipActivityChart?.requestUUID === request_uuid.value
            ) {
                tooltipActivityChartMount.current = true;
                let tempTooltipChartData: any = [];

                tempTooltipChartData = fetched_details.tooltips.tooltipActivityChart.data.graphData;

                let guidance_count = 0;
                let validation_count = 0;
                let guidanceValidation_count = 0;
                let powerForm_count = 0;
                let ductTape_count = 0;


                tempTooltipChartData.map((data: any, index: number) => {
                    guidance_count = (data.counts['guidance']) + guidance_count;
                    validation_count = (data.counts['validation']) + validation_count;
                    guidanceValidation_count = (data.counts['guidanceValidation']) + guidanceValidation_count;
                    powerForm_count = (data.counts['powerForm']) + powerForm_count;
                    ductTape_count = (data.counts['digitalDuctTape']) + ductTape_count;
                })

                setTooltipChartData((prevState: any) => {
                    return {
                        ...prevState,
                        chartData: [...tempTooltipChartData],
                        guidance_count:guidance_count,
                        validation_count:validation_count,
                        guidanceValidation_count:guidanceValidation_count,
                        powerForm_count: powerForm_count,
                        ductTape_count: ductTape_count
                    };
                });
                setActivityChart((prevState) => {
                    return {
                        ...prevState,
                        activityLineChartFetched: true
                    }
                })
                setDuctTapePowerFormChart((prevState) => {
                    return {
                        ...prevState,
                        ductTapePowerFormLineChartFetched: true
                    }
                })

                apiCatchError(0, 0);
                enableExportPdf(0, true);
            } else if (fetched_details.tooltips.tooltipActivityChart.result === 'retry') {
                getTooltipChartData(request_uuid.value, guideIds.list, 'retry');
            } else if (fetched_details.tooltips.tooltipActivityChart.result === "error") {
                if (fetched_details.tooltips.tooltipActivityChart.errorCode === 503) {
                    apiCatchError(0, 503);
                } else {
                    apiCatchError(0, 1);
                }
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details.tooltips.tooltipActivityChart])

    // SmartTip summary data
    useEffect(() => {
        try {
            if (
                fetched_details.tooltips.smartTipSummary.data !== undefined &&
                fetched_details.tooltips.smartTipSummary.result === "success" &&
                fetched_details?.tooltips.smartTipSummary?.requestUUID === request_uuid.value
            ) {
                smartTipSummaryTableMount.current = true;
                setSmartTipSummary((prevState: any) => {
                    return {
                        ...prevState,
                        summaryFetched: true,
                    }
                })

                const d = { ...fetched_details.tooltips.smartTipSummary.data, }
                apiCatchError(2, 0);
                enableExportPdf(2, true);
            } else if (fetched_details.tooltips.smartTipSummary.result === 'retry') {
                getSmartTipSummaryData(request_uuid.value, guideIds.list, 'retry');
            } else if (fetched_details.tooltips.smartTipSummary.result === "error") {
                if (fetched_details.tooltips.smartTipSummary.errorCode === 503) {
                    apiCatchError(2, 503);
                } else {
                    apiCatchError(2, 1);
                }

                setSmartTipSummary((prevState: any) => {
                    return {
                        ...prevState,
                        summaryFetched: true,
                    }
                })
                enableExportPdf(2, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.tooltips.smartTipSummary]);

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(optn, false);

            switch (optn) {
                case 0:
                    setActivityChart((prevState: any) => {
                        return {
                            ...prevState,
                            activityLineChartFetched: false
                        }
                    })
                    getTooltipChartData(request_uuid.value, guideIds.list);
                    break;

                case 1:
                    setDuctTapePowerFormChart((prevState: any) => {
                        return {
                            ...prevState,
                            ductTapePowerFormLineChartFetched: false
                        };
                    });
                    getTooltipChartData(request_uuid.value, guideIds.list);
                    break;

                case 2:
                    setSmartTipSummary((prevState: any) => {
                        return {
                            ...prevState,
                            summaryFetched: false,
                            searchQuery: '',
                            sortKey: 'guidance_count',
                            page: 1,
                            showExport: true,
                            defaultSort: {
                                'sortColumn_index': 1,
                                'sortValue': 'desc'
                            },
                            // renderTaggedTable: false,
                            sortValue: 'desc',
                            limit: 5,
                            tablePagination: {
                                offset: 0,
                                data: [],
                                perPage: 5,
                                currentPage: 0
                            },
                            firstLoad: true,
                            hideDeleted: 0,
                        };
                    });
                    break;

                default:
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const apiCatchError = (index: number, option: number) => {
        try {

            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }
    };

    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const getGuideIds = (data: any) => {
        try {
            setGuideIds((prevState: any) => {
                return {
                    ...prevState,
                    list: data
                }
            })

            setTooltipChartData((prevState) => {
                return {
                    ...prevState,
                    chartData: [],
                    guidance_count: 0,
                    validation_count: 0,
                    guidanceValidation_count: 0,
                    powerForm_count: 0,
                    ductTape_count: 0
                }
            })

            setActivityChart((prevState: any) => {
                return {
                    ...prevState,
                    activityLineChartFetched: false,
                }
            });

            setDuctTapePowerFormChart((prevState) => {
                return {
                    ...prevState,
                    ductTapePowerFormLineChartFetched: false
                }
            })

            setSmartTipSummary((prevState: any) => {
                return {
                    ...prevState,
                    summaryFetched: false,
                    searchQuery: '',
                    sortKey: 'guidance_count',
                    page: 1,
                    showExport: true,
                    defaultSort: {
                        'sortColumn_index': 1,
                        'sortValue': 'desc'
                    },
                    // renderTaggedTable: false,
                    sortValue: 'desc',
                    limit: 5,
                    tablePagination: {
                        offset: 0,
                        data: [],
                        perPage: 5,
                        currentPage: 0
                    },
                    firstLoad: false,
                    hideDeleted: 0
                };
            });
        } catch (error) {
            showBoundary(error)
        }
    }

    const handleTableEvents2 = useCallback((option: string, data: any) => {
        try {
            switch (option) {
                case "routeToDetail":
                    setParameter((prevState: any) => {
                        return {
                            ...prevState,
                            paramter: data.guideID,
                            source: data.guideTitle,
                        };
                    });
                    break;

                case "sortColumn":
                    setSmartTipSummary((prevState) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "changePage":

                    setSmartTipSummary((prevState) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "searchTxt":
                    setSmartTipSummary((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false
                        }
                    });
                    break;

                case "changeEntries":
                    setSmartTipSummary((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false,
                        }
                    });
                    break;

                case "hideDeleted":
                    setSmartTipSummary((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            firstLoad: false,
                            hideDeleted: data
                        }
                    });
                    break;

                case "exportExcel":
                    // exportExcel(data)
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [smartTipSummary]);


    // Use Effects @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    // Highlight on scroll
    useEffect(() => {
        try {
            const handleScroll = () => {
                const currentScrollY = window.scrollY;
                setTimeout(() => {
                    if (currentScrollY >= 450 && currentScrollY < 950) {
                        CommonUtils.LeftPanelHighlight(1, 4, 1, true, false);
                    } else if (currentScrollY >= 950) {
                        CommonUtils.LeftPanelHighlight(1, 4, 2, true, false);
                    } else {
                        CommonUtils.LeftPanelHighlight(1, 4, 0, true, false);
                    }
                }, 0);
            };

            window.addEventListener("scroll", handleScroll, { passive: true });

            return () => window.removeEventListener("scroll", handleScroll);
        } catch (error) {
            showBoundary(error)
        }

    }, [window.scrollY]);

    // On sidenav click scroll to section
    useEffect(() => {
        let queryParams = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            fdate: fetched_details?.commonComponentData.datesData.fdate,
            tdate: fetched_details?.commonComponentData.datesData.tdate,
            event_source: fetched_details?.commonComponentData?.eventSource.eventSourceTooltips,
            segment: fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            option: fetched_details.urlOption.urlOption,
            env: fetched_details?.commonComponentData?.envFilter?.envCode,
        }

        navigate({
            pathname: `/guide-insights/tooltips/`,
            search: qs.stringify(queryParams)
        })

        if (fetched_details.urlOption.urlOption === 'Tooltips_Validation' || fetched_details.urlOption.urlOption === '' || fetched_details.urlOption.urlOption === 'tooltips') {
            window.scrollTo({ top: 0, behavior: 'smooth' });

        } else if (fetched_details.urlOption.urlOption === 'PowerForm') {
            window.scrollTo({ top: 700, behavior: 'smooth' });

        } else if (fetched_details.urlOption.urlOption === 'DuctTape') {
            window.scrollTo({ top: 1900, behavior: 'smooth' });

        } else {
            window.scrollTo({ top: 2500, behavior: 'smooth' });

        }
    }, [fetched_details.urlOption.urlOption])

    // Navigate to detail page
    useEffect(() => {
        try {
            if (parameter.paramter !== '') {
                let queryParams = {
                    app_id: fetched_details?.commonComponentData.appsData.appId,
                    app_code: fetched_details?.commonComponentData.appsData.appCode,
                    fdate: fetched_details?.commonComponentData.datesData.fdate,
                    tdate: fetched_details?.commonComponentData.datesData.tdate,
                }
                navigate({
                    pathname: "/guide-insights/tooltips-detail/" + parameter.paramter + '/',
                    search: qs.stringify(queryParams),
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [parameter])

    // Export pdf btn state
    useEffect(() => {
        try {
            if (loaderBtn.loader0 && loaderBtn.loader1) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            } else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader0, loaderBtn.loader1])

    // Api call on table actions (SmartTip summary)
    useEffect(() => {
        try {
            // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
            if (fetched_details?.commonComponentData.datesData?.url?.includes('tooltips/') || fetched_details?.commonComponentData.datesData.dateSelection === 15) {
                enableExportPdf(3, false);
                getSmartTipSummaryData(request_uuid.value, guideIds.list);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        smartTipSummary.sortKey,
        smartTipSummary.sortValue,
        smartTipSummary.defaultSort,
        smartTipSummary.page,
        smartTipSummary.searchQuery,
        smartTipSummary.limit,
        smartTipSummary.hideDeleted,
        request_uuid.value
    ]);

    useEffect(() => {
        try {
            const result = errorCount.errorCount.every((val) => val === 1);
            const error_503 = errorCount.errorCount.every((val) => val === 503);

            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    showMaintenance_flag: result
                }
            })

            if (error_503) {
                CommonUtils.handle503Redirect(window.location.href)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [errorCount.errorCount])


    // Chart Renders and Callbacks @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const ActivityLineChart = () => {
        try {
            return <>
            
            <div className="width100 row marginLR-0 justifyEnd">
                <div className="col-md-4 col-sm-4 col-lg-4 userStats-card marginBottom-50" >
                    <div className="heigth105 tiles-border borderBottomColor-1">
                        <div className="color-1 font28">{tooltipChartData.guidance_count}</div>
                         <div className="font16">{insightsLbls.tooltip}</div>
                     </div>
                </div>                  
                <div className="col-md-4 col-sm-4 col-lg-4 userStats-card marginBottom-50" >
                    <div className="heigth105 tiles-border borderBottomColor-2">
                        <div className="color-2 font28">{tooltipChartData.validation_count}</div>
                         <div className="font16">{insightsLbls.validation}</div>
                     </div>
                </div>                  
                <div className="col-md-4 col-sm-4 col-lg-4 userStats-card marginBottom-50" >
                    <div className="heigth105 tiles-border borderBottomColor-3">
                        <div className="color-3 font28">{tooltipChartData.guidanceValidation_count}</div>
                         <div className="font16">{insightsLbls.tooltipAndValidation}</div>
                     </div>
                </div>                  
            </div>

            <Linechart
                chartData={tooltipChartData.chartData}
                chartEntities={['guidance', 'validation', 'guidanceValidation']}
                optionEntities={[
                    {
                        key: insightsLbls.tooltip,
                        color: ChartColors.color1,
                        associatedDataPoint: "guidance",
                        area: false
                    },
                    {
                        key: insightsLbls.validation,
                        color: ChartColors.color2,
                        associatedDataPoint: "validation",
                        area: false,
                    },
                    {
                        key: insightsLbls.tooltipAndValidation,
                        color: ChartColors.color3,
                        associatedDataPoint: "guidanceValidation",
                        area: false
                    }
                ]}
                value_type={"count"}
                height={activity_linechart_height}
                margin={lineChartMargin}
                yAxisLabel={insightsLbls.yAxisLabelCount}
            ></Linechart>
            
            </>
            
            
        } catch (error) {
            showBoundary(error)
        }
    };

    const PowerFormDuctTapeLineChart = () => {
        try {
            return <>
             <div className="width100 row marginLR-0 justifyEnd">
                <div className="col-md-4 col-sm-4 col-lg-4 userStats-card marginBottom-50" >
                    <div className="heigth105 tiles-border borderBottomColor-2">
                        <div className="color-2 font28">{tooltipChartData.powerForm_count}</div>
                         <div className="font16">{insightsLbls.powerForm}</div>
                     </div>
                </div>                  
                <div className="col-md-4 col-sm-4 col-lg-4 userStats-card marginBottom-50" >
                    <div className="heigth105 tiles-border borderBottomColor-4">
                        <div className="color-4 font28">{tooltipChartData.ductTape_count}</div>
                         <div className="font16">{insightsLbls.ductTape}</div>
                     </div>
                </div>                                   
            </div>
            <Linechart
                chartData={tooltipChartData.chartData}
                chartEntities={['powerForm', 'digitalDuctTape']}
                optionEntities={[
                    {
                        key: insightsLbls.powerForm,
                        color: ChartColors.color2,
                        associatedDataPoint: "powerForm",
                        area: false
                    },
                    {
                        key: insightsLbls.ductTape,
                        color: ChartColors.color4,
                        associatedDataPoint: "digitalDuctTape",
                        area: false
                    },
                ]}
                value_type={"count"}
                height={activity_linechart_height}
                margin={lineChartMargin}
                yAxisLabel={insightsLbls.yAxisLabelCount}
            ></Linechart>
            </>
            
           
        } catch (error) {
            showBoundary(error)
        }
    };

    const getValuesFromUrl = () => {
        // return 'guide_id=11383'
    }

    const loaderTemplate = () => {
        return <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
            <div className="displayFlex alignCenter margin-0-auto">
                <Loader></Loader>
            </div>
        </div>
    }

    const serviceNotAvailableTemplate = () => {
        return <h5 className="errorCss card-heading font-weight-400 text-center">
            {insightsLbls.serviceNotAvailable}
        </h5>
    }

    const noDataFoundTemplate = () => {
        return <div className="noDataText-font textCenter-absolute">
            {insightsLbls.noRecordFound}
        </div>
    }

    const activityLineChartTemplate = () => {
        return <>
            

            <ActivityLineChart></ActivityLineChart>
            {activityChart.warningFlag && (
                <div className="warningDiv">
                    <p>{insightsLbls.chartLoadWarningMsg}
                        <button
                            type="button"
                            className="closeWarning"
                            onClick={() =>
                                setActivityChart((prevState: any) => ({
                                    ...prevState,
                                    warningFlag: false,
                                }))
                            }
                        >
                            <span aria-hidden="true">
                                <Icon img="img/close-icon-large.svg" />
                            </span>
                            <span className="sr-only">Close</span>
                        </button>
                    </p>
                </div>
            )}
        </>
    }

    const powerFormDuctTapeLineChartTemplate = () => {
        return <>
           
            <PowerFormDuctTapeLineChart></PowerFormDuctTapeLineChart>
            {ductTapePowerFormChart.warningFlag && (
                <div className="warningDiv">

                    <p>{insightsLbls.chartLoadWarningMsg}
                        <button
                            type="button"
                            className="closeWarning"
                            onClick={() =>
                                setDuctTapePowerFormChart((prevState: any) => ({
                                    ...prevState,
                                    warningFlag: false,
                                }))
                            }
                        >
                            <span aria-hidden="true">
                                <Icon img="img/close-icon-large.svg" />
                            </span>
                            <span className="sr-only">Close</span>
                        </button>
                    </p>
                </div>
            )}
        </>
    }

    return (
        <section className="tooltips demo  width100" ref={componentRef}>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li className="active">{insightsLbls.tooltips}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <EnvironmentFilter component={"guideAnalytics"} disabled={false} />
                    <Segments component={"tooltips"} defaultValue={''}></Segments>
                    <EventSource component={"tooltips"}></EventSource>
                    <TooltipModal appCode="" dispatchGuidesList={getGuideIds} fromComponent={'tooltip'} defaultSelected={getValuesFromUrl()}></TooltipModal>
                    <Datepicker source={'component'}></Datepicker>
                </SectionFilters>
            </div>

            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={"Guides"}
                                widthDividedBy={12}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                dateObject={fetched_details?.commonComponentData?.datesData}
                messageText={insightsLbls.tooltipLabel}>
            </UserInfoSection>

            <div id="ActivityChartSection">
                <div className="dashboardTiles  col-lg-12 page_section padding0 height600 blockImportant marginTop-20">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5  height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            {
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.tooltipAndValidation,
                                        "tooltipText": insightsLbls.tooltipGuidanceAndValidationGraph,
                                        "childern": [
                                            {
                                                "key": insightsLbls.tooltip + ":",
                                                "value": insightsLbls.countOfTooltipHoverClicked
                                            },
                                            {
                                                "key": insightsLbls.validation + ":",
                                                "value": insightsLbls.countOfValidationPerformed
                                            },
                                            {
                                                "key": insightsLbls.tooltipAndValidation + ":",
                                                "value": insightsLbls.countOfValidationAndInteractionPerformed
                                            }
                                        ]
                                    }
                                } placement="right"></SectionTooltip>
                            }


                            <span>
                                <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(0)}> {insightsLbls.refresh}</button>
                                <CustomDashboardModal
                                    api={tooltipChartData.api}
                                    eventSource={filterValues.eventSource}
                                    guideStatus={filterValues.guideStatus}
                                    segmentsGroup={filterValues.segmentsGroup}
                                    chartType={"LineChart"}
                                    sectionHeader={insightsLbls.tooltipAndValidation}
                                    sectionTooltip={
                                        {
                                            "subTitle": insightsLbls.activity,
                                            "tooltipText": insightsLbls.tooltipGuidanceAndValidationGraph,
                                            "childern": [
                                                {
                                                    "key": insightsLbls.tooltip + ":",
                                                    "value": insightsLbls.countOfTooltipHoverClicked
                                                },
                                                {
                                                    "key": insightsLbls.validation + ":",
                                                    "value": insightsLbls.countOfValidationPerformed
                                                },
                                                {
                                                    "key": insightsLbls.tooltipAndValidation + ":",
                                                    "value": insightsLbls.countOfValidationAndInteractionPerformed
                                                }
                                            ]
                                        }
                                    }
                                    sectionType={'type1'}
                                    guide_filter={false}
                                    event_filter={true}
                                    segment_filter={true}
                                    tooltip_filter={true}
                                    component={'tooltip'}
                                    tooltip_guideIds={guideIds.list}
                                ></CustomDashboardModal>
                            </span>
                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section positionRelative">
                            {activityChart.activityLineChartFetched ? (
                                errorCount.errorCount[0] === 0 ? (
                                    tooltipChartData.chartData.length === 0 ? noDataFoundTemplate() : activityLineChartTemplate())
                                    : serviceNotAvailableTemplate())
                                : loaderTemplate()}
                        </div>

                    </CardContainer>
                </div>
            </div>

            <div id="ducttapeChartSection">
                <div className="dashboardTiles  col-lg-12 page_section padding0 height600 blockImportant marginTop-20">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5  height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            <SectionTooltip tooltipText={{
                                "subTitle": insightsLbls.powerFormNDigitalDuctTape,
                                "tooltipText": insightsLbls.statsOfDigitalDuctTape,
                                "childern": [
                                    {
                                        "key": insightsLbls.powerForm,
                                        "value": insightsLbls.countPowerFormDisplayed,
                                    },
                                    {
                                        "key": insightsLbls.digitalDuctTapeLabel,
                                        "value": insightsLbls.countDigitalDuctTapeDisplayed
                                    }
                                ]
                            }} placement="right"></SectionTooltip>


                            <span>
                                <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(1)}> {insightsLbls.refresh}</button>
                                <CustomDashboardModal
                                    api={tooltipChartData.api}
                                    eventSource={filterValues.eventSource}
                                    guideStatus={filterValues.guideStatus}
                                    segmentsGroup={filterValues.segmentsGroup}
                                    chartType={"LineChart"}
                                    sectionHeader={ "Power Form & Digital Duct Tape"}
                                    sectionTooltip={{
                                        "subTitle": insightsLbls.powerFormNDigitalDuctTape,
                                        "tooltipText": insightsLbls.statsOfDigitalDuctTape,
                                        "childern": [
                                            {
                                                "key": insightsLbls.powerForm,
                                                "value": insightsLbls.countPowerFormDisplayed,
                                            },
                                            {
                                                "key": insightsLbls.digitalDuctTapeLabel,
                                                "value": insightsLbls.countDigitalDuctTapeDisplayed
                                            }
                                        ]
                                    }}
                                    sectionType={'type1'}
                                    guide_filter={false}
                                    event_filter={true}
                                    tooltip_filter={true}
                                    segment_filter={true}
                                    component={'tooltip'}
                                    tooltip_guideIds={guideIds.list}
                                ></CustomDashboardModal>
                            </span>
                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section positionRelative">

                            {ductTapePowerFormChart.ductTapePowerFormLineChartFetched ? (
                                errorCount.errorCount[0] === 0 ? (
                                    tooltipChartData.chartData.length === 0 ? noDataFoundTemplate() : powerFormDuctTapeLineChartTemplate())
                                    : serviceNotAvailableTemplate())
                                : loaderTemplate()}
                        </div>

                    </CardContainer>
                </div>
            </div>

            <div id="smartTipTableSection">
                <div className="dashboardTiles  col-lg-12 page_section padding0 height600 blockImportant marginTop-20">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5  height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            {
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.summary,
                                        "tooltipText": insightsLbls.tableOfStatsOfTooltip,
                                        "childern": [
                                            {
                                                "key": insightsLbls.guideTitle + ":",
                                                "value": insightsLbls.nameOfTooltipContainsMoreTooltips
                                            },
                                            {
                                                "key": insightsLbls.guidanceLabel + ":",
                                                "value": insightsLbls.interactionHappenedWithGuidanceType
                                            },
                                            {
                                                "key": insightsLbls.validation + ":",
                                                "value": insightsLbls.interactionHappenedWithValidationType
                                            },
                                            {
                                                "key": insightsLbls.guidanceAndValidation + ":",
                                                "value": insightsLbls.interactionHappenedWithGuidanceValidationType
                                            },
                                            {
                                                "key": insightsLbls.digitalDuctTape + ":",
                                                "value": insightsLbls.digitalDuctTapeRenderCount
                                            },
                                            {
                                                "key": insightsLbls.powerForm + ":",
                                                "value": insightsLbls.interactionHappenedWithPowerForm
                                            }
                                        ]
                                    }
                                } placement="right"></SectionTooltip>
                            }
                            <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(2)}> {insightsLbls.refresh}</button>

                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            {
                                smartTipSummary.summaryFetched ? (
                                    errorCount.errorCount[2] === 0 ? (
                                        <div className="commonSummaryTable">
                                            <CommonTable tableId="2"
                                                data={fetched_details.tooltips.smartTipSummary.data}
                                                currentPage={smartTipSummary.page}
                                                barColors=""
                                                linkPresent={true}
                                                showExport={false}
                                                showSearch={true}
                                                showEntries={true}
                                                defaultSort={smartTipSummary.defaultSort}
                                                tablePagination={smartTipSummary.tablePagination}
                                                tableEvent={handleTableEvents2}
                                                firstTimeloadFlag={fetched_details.tooltips.smartTipSummary.firstTimeload}
                                                hideDeleted={true}
                                                checkboxText={insightsLbls.hideDeleted}
                                                modalTable={false}
                                            />
                                        </div>
                                    )
                                        : errorCount.errorCount[2] === 1 ? (
                                           serviceNotAvailableTemplate()
                                        )
                                            : null)
                                    : (
                                        loaderTemplate()
                                    )
                            }
                        </div>
                    </CardContainer>
                </div>
            </div>

            <div className="overlayCursor" id="overlayCursor"></div>
            {
                errorCount.showMaintenance_flag && (
                    <Maintenance></Maintenance>
                )
            }

        </section >
    )
}

export default withRouter(withReducer("tooltips", tooltipReducer)(Tooltips));

