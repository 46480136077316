import React, { useRef, useCallback, useEffect, useState } from "react";
import { useErrorBoundary } from 'react-error-boundary';
import { LineChart as RechartLineChart, CartesianGrid, Legend, Line, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";


const Linechart = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    let lineChartRef = [];
    lineChartRef[props.refId] = useRef(null);

    let max = 0;
    let dates = [];
    let tempChartData = [];
    let colorChartData = [];
    let chartEntities = [];

    const [renderData, setRenderData] = useState({
        chart: [],
        options: [],
        chartEntities: [],
        flag: false,
        max: 0
    })

    useEffect(() => {
        if (props.chartData !== undefined) {

            try {
                setRenderData((prevState) => {
                    return {
                        ...prevState,
                        chart: [],
                        options: [],
                        chartEntities: [],
                        flag: false
                    }
                })
    
                for (let i = 0; i < props.chartData.length; i++) {
                    for (let data in props.chartData[i].counts){
                            if(max < +props.chartData[i].counts[data]){
                                max = props.chartData[i].counts[data]
                            }
                    }
                    dates.push(props.chartData[i].startTimestamp);
    
                    tempChartData.push({
                        startTimestamp: props.chartData[i].startTimestamp, ...props.chartData[i].counts
                    })
                }
    
                chartEntities =  props.chartOptions.map(entry => (entry.associatedDataPoint));
    
                colorChartData = props.chartOptions.map(entry => ({
                    color: entry.color,
                    associatedDataPoint: entry.associatedDataPoint,
                    key: entry.key
                }));
    
                setRenderData((prevState) => {
                    return {
                        ...prevState,
                        chart: tempChartData,
                        options: colorChartData,
                        chartEntities: chartEntities,
                        flag: true
                    }
                })
            } catch (error) {
                showBoundary(error)
            }
        }
    }, [props]);


    const lines = () => {
        if (renderData.chartEntities && renderData.options.length > 0) {
            let lineData = renderData.chartEntities.map((legendKey, index) => {
                let obj = renderData.options.find(obj => obj.associatedDataPoint === legendKey);
                if (obj) {
                    return <Line key={index} type="monotone" dataKey={legendKey} stroke={obj.color} activeDot={{ r: 8 }} />
                } else {
                    return null;
                }
            })
            return lineData;
        }
        return null;
    }

    return (
        <div id={props.refId}className={`width-100  height${props.height}`}>
            {renderData.flag && (
                <ResponsiveContainer width="100%" height="100%">
                    <RechartLineChart width={500} data={renderData.chart} margin={{ top: 20, right: 30, left: 0, bottom: 5}}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="startTimestamp" />
                        <YAxis label={{
                            value: props.yAxisLabel,
                            position: 'insideLeft',
                            angle: -90,
                            offset: 20,
                            style: {
                                fontSize: '14px',
                                fontWeight: 'bold'
                            }
                        }} domain={[0, 'auto']}/>
                        <Tooltip formatter={(value: number | string, name: string) => {
                            const label = renderData.options.find(obj => obj.associatedDataPoint === name)?.key;
                            return [value, label];
                        }} />
                        <Legend
                            formatter={(value) => renderData.options.find(obj => obj.associatedDataPoint === value)?.key} />
                        {lines()}
                    </RechartLineChart>
                </ResponsiveContainer>
            )}
        </div>
    );
};
export default React.memo(Linechart);
