if (GmXt === undefined) var GmXt = {};
GmXt.playerLbls = {};
GmXt.playerLbls.ja_JP = {
    "yAxisLabelCount": "カウント",
    "playedCount": "再生回数",
    "completedCount": "完了済みカウント",
    "expandViews": "ビューの展開",
    "collapseViews": "ビューの折りたたみ",
    "expand": "展開",
    "collapse": "折りたたみ",
    "totalSteps": "合計ステップ数",
    "slideshowStats": "スライドショーの統計",
    "videoStats": "動画の統計",
    "view": "表示",
    "deleted": "削除済み",
    "published": "公開済み",
    "unpublished": "未公開",
    "unknown": "不明",
    "allUsers": "すべてのユーザー",
    "download": "ダウンロード",
    "viewOnline": "オンラインで表示",
    "or": "OR",
    "cioDashboardSubTitle": "すべてのアプリでアクティビティ",
    "cioDashboardModalTitle": "データのエクスポート",
    "play": "再生",
    "totalPlayed": "合計再生数",
    "uniquePlayed": "ユニーク再生",
    "completed": "完了",
    "pass": "合格",
    "fail": "不合格",
    "panel": "パネル",
    "withAudio": "オーディオ付き",
    "withouAudio": "オーディオなし",
    "linkedTable": "リンクテーブル",
    "showGuideWithActivities": "ガイドとアクティビティを表示",
    "submitted": "送信済み",
    "cancelled": "キャンセル済み",
    "hideDeleted": "削除済みの非表示",
    "searchCount": "検索が実行された回数。",
    "actionCount": "検索結果が表示されたときにアクションが取得された回数。",
    "scheduledPlay": "スケジュール済みの再生数",
    "manualPlay": "手動再生数",
    "playedBy": "再生済み",
    "runType": "実行タイプ",
    "result": "結果",
    "status": "ステータス",
    "srNo": "シリアル番号",
    "guideTitle": "ガイドタイトル",
    "description": "説明",
    "moreInfo": "追加情報",
    "jobPrioritisedSuccessMsg": "ジョブの優先順位が変更されました。",
    "apiCacheDeletedMsg": "APIキャッシュが正常に削除されました",
    "guideCreated": "ガイド作成日",
    "guideModified": "ガイド変更済み",
    "exportJobs": "エクスポートジョブ",
    "searchOrganization": "組織の検索",
    "showing": "表示中",
    "exportJobsList": "ジョブリストのエクスポート",
    "organization": "組織",
    "requestTime": "リクエスト時刻",
    "exportJobStatus": "ジョブステータスのエクスポート",
    "pending": "保留中",
    "priortizeJob": "ジョブを優先する",
    "priortized": "優先",
    "inProcess": "処理中",
    "complete": "完了",
    "pendingEventsCount": "保留中のイベント数",
    "deleteCache": "キャッシュの削除",
    "priortizeEventsForOrg": "この組織のPriortizeイベント。",
    "tokenCancelledMsg": "新しいリクエストのために操作がキャンセルされました。",
    "selectAll": "すべて選択",
    "application": "アプリケーション",
    "appList": "アプリリスト",
    "close": "閉じる",
    "searchApp": "アプリの検索",
    "totalApps": "アプリの合計数",
    "active": "アクティブ",
    "deletedApp": "削除済みアプリ",
    "operatingSystem": "オペレーティングシステム",
    "screenResolution": "画面の解像度",
    "webBrowser": "ウェブブラウザ",
    "name": "名前",
    "version": "バージョン",
    "count": "カウント",
    "visitors": "訪問者",
    "includeWeekend": "週末を含める",
    "last7Days": "過去7日間",
    "last30Days": "過去30日間",
    "last60Days": "過去60日間",
    "last90Days": "過去90日間",
    "last180Days": "過去180日間",
    "last365Days": "過去365日間",
    "lastCalendarYear": "今年",
    "customDate": "日付を選択",
    "weekendsIncluded": "週末を含める",
    "weekendsExcluded": "週末を含めない",
    "withRealTimeData": "リアルタイムデータあり",
    "realTimeDataNotAvailable": "リアルタイムデータがありません",
    "includeRealTimeData": "リアルタイムデータを含む",
    "excludeWeekends": "週末を含めない",
    "from": "開始",
    "to": "送信先",
    "invalidStartDate": "無効な開始日です",
    "invalidEndDate": "無効な終了日。",
    "selectDateRangeWithin12mmonths": "12か月以内に日付範囲を選択してください",
    "successfullyAdded": "正常に追加されました",
    "dashboardName": "ダッシュボードの名前",
    "duplicateDashboardName": "重複するダッシュボード名",
    "canAddUpto3Dashboards": "ダッシュボードは3個まで追加できます",
    "dashboardName20Chars": "ダッシュボードの名前は10文字以内でなければなりません",
    "pleaseEnterValidName": "有効な名前を入力してください",
    "successfullyDeleted": "正常に削除されました",
    "successfullyUpdated": "正常に更新されました",
    "chartsAlreadyAdded": "グラフが既に追加されています",
    "createNewDashboardMsg": "カスタムダッシュボードがありません。新しいダッシュボードを作成してください。",
    "createNewDashboard": "新規ダッシュボードの作成",
    "allDashboardDataDeleted": "ダッシュボードのすべてのデータが削除されます。",
    "wantToDeleteDashboard": "本当にこのダッシュボードを削除しますか?",
    "save": "保存",
    "requestSent": "リクエストが送信されました",
    "requestCompleteTime": "リクエスト完了時刻",
    "jobStatus": "ジョブステータス",
    "type": "タイプ",
    "surveyType": "アンケートタイプ",
    "surveyName": "アンケートの名前",
    "stepName": "ステップ名",
    "selectFilter": "フィルタを選択してください",
    "publicURL": "公開URL",
    "filter": "フィルタ",
    "filter1Placeholder": "フィルタ1（ガイドの名前、ステップ名、ツールチップの名前）",
    "filter2Placeholder": "フィルタ2（ステップ名）",
    "tooltipList": "ツールチップリスト",
    "clearAll": "すべてクリア",
    "tooltipPlay": "ツールチップを再生",
    "played": "再生済み",
    "lastPlayedOn": "最終再生日",
    "activityDetails": "アクティビティの詳細",
    "exportExcel": "Excelのエクスポート",
    "downloading": "ダウンロード中...",
    "exportPDF": "PDFをエクスポート",
    "editFunnel": "目標到達プロセスの編集",
    "clickHereToCreateFunnel": "目標到達プロセスを作成するには、ここをクリックしてください",
    "step": "ステップ",
    "funnelStepsInOrder": "目標到達プロセスのステップを順に",
    "funnelStepsMsg": "目標到達プロセスを表示するには、[最小3ステップ]および[最大7ステップ]を選択してください。",
    "funnelStepsStatsMsg": "*リンクされたガイドの統計は、それぞれの詳細ページに表示できます。",
    "max7Steps": "最大7ステップを選択できます。",
    "clearList": "リストのクリア",
    "submit": "送信",
    "playedVsCompleted": "完了済み/総再生数",
    "deleteChartMsg": "本当にこのグラフを削除しますか?",
    "youAreHere": ">",
    "successfullyRenamed": "正常に名前が変更されました",
    "dashboardName20CharsErrorMsg": "*ダッシュボードの名前は10文字以内で入力してください。",
    "dashboardNameEmptyErrorMsg": "*ダッシュボードの名前は空欄にできません。",
    "maintenanceInProgress": "メンテナンス処理中。",
    "pleaseContactUs": "弊社にお問合わせください:",
    "backToList": "リストに戻る",
    "title": "タイトル",
    "filterTitle": "フィルタタイトル",
    "max100chars": "最大50文字が許可されています",
    "filterDescription": "フィルタの説明",
    "selectOrganizations": "組織の選択",
    "deleteFilter": "フィルタの削除",
    "deleteFilterMsg": "本当にこのフィルタを削除しますか?",
    "switchOrganization": "組織の切り替え",
    "ofPages": "ページ数",
    "ofPagesViews": "ページビューの",
    "untaggedURLS": "タグなしURL",
    "taggedURLS": "タグ付きURL",
    "singleSelect": "シングル選択",
    "multiSelect": "マルチ選択",
    "text": "テキスト",
    "range": "範囲",
    "rating": "レーティング",
    "yesNo": "はい - いいえ",
    "URLHostname": "URLホスト名",
    "URLPath": "URLパス",
    "URLParameters": "URLパラメータ",
    "URLHash": "URLハッシュ",
    "equals": "完全一致",
    "notEquals": "次と等しくない",
    "contains": "次を含む",
    "notContains": "次を含まない",
    "startsWith": "開始文字",
    "similarTo": "類似先",
    "endsWith": "末尾一致",
    "commonMsg": "エラー: 無効なパスです。英数字と特殊文字（~、=、<,>,/,*）のみ使用できます。パスの文字列にスペース\"は使用できません",
    "slashMsg": "エラー: パスは常にフォワードスラッシュで開始されます",
    "angularBracketMsg": "エラー: 開始と終了の角括弧（<>）をいずれも使用して、その中に文字列を入れてください",
    "tilEqAngularMsg": "エラー: フォワードスラッシュの後には<>と~=を一緒に使用することはできません。",
    "tilEqTogetMsg": "エラー: パスに~または=を単独で指定することはできません。~の後には常に=が続きます。",
    "starAloneMsg": "エラー: スター（*）が既にある場合、フォワードスラッシュの間には何も含めることができません。シングルスター（*）は常に2つのフォワードスラッシュの間に配置されます\"",
    "twoStarMsg": "エラー: パスの文字列の**（ダブルスター）の後には何も続けることができません",
    "noDoubleSlashMsg": "エラー: パスの文字列に二重スラッシュは使用できません",
    "onlyHyphenUnderMsg": "エラー: _と-は山括弧 (<>) の中でだけ使用できます",
    "noHyphenUnderMsg": "エラー: _と-は山括弧 (<>) の中以外では使用できません",
    "filedRequired": "エラー: このフィールドは必須です",
    "hashMsg": "エラー: 無効なURLハッシュです",
    "paramaterAlphAndSpecialChar": "エラー: 無効なパラメータです。英数字と特殊文字（-, _）は&に続けて使用できます",
    "parameterStartMsg": "エラー: 無効なパラメータです。パラメータは常に?で始まり、?の後に=を続けることはできません",
    "parameterAllowedMsg": "エラー: 無効なパラメータです。英数字と特殊文字（-, _, =, &）のみ使用できます",
    "urlTypeSelected": "既に選択されているタイプです。他のタイプを選択してください。",
    "errorHostnameAlreadyExists": "エラー: ホスト名が既に存在します",
    "errorValueAlreadyExsts": "エラー: 値が既に存在します",
    "URLHostnameMsg": "URLのホスト名はアプリケーション設定から継承されます。これは、アプリ内のすべてのタグやワークフローでデフォルトのルールです。",
    "and": "AND",
    "add": "追加",
    "addGroup": "グループの追加",
    "searchStep": "ステップの検索",
    "searchUser": "ユーザーの検索",
    "selectGuide": "ガイドの選択",
    "segmentFilter": "セグメントフィルタ",
    "selectSegments": "セグメントの選択",
    "summary": "サマリー",
    "edit": "編集",
    "delete": "削除",
    "bestTime": "最高の時間",
    "avgTime": "平均時間",
    "worstTime": "最悪の時間",
    "tagPage": "ページにタグを追加",
    "label": "ラベル",
    "cancel": "キャンセル",
    "deletePageTag": "ページタグの削除",
    "deleteConfirmation": "このページタグを削除しますか?",
    "confirm": "確認",
    "create": "作成",
    "tagged": "タグ付き",
    "untagged": "タグなし",
    "domainMessage": "アプリに現在ドメインが追加されていません。作成またはタグページするドメインを追加してください。",
    "workflowType": "ワークフロータイプ",
    "dynamicWorkflowPlayCount": "ダイナミックワークフロー再生回数",
    "guideWorkflowPlayCount": "ガイドワークフロー再生回数",
    "uniqueWorkflowPlayCount": "ワークフローユニーク再生回数",
    "workflowDetails": "ワークフローの詳細",
    "workflowPlayCounts": "ワークフローの再生回数",
    "positive": "ポジティブ",
    "neutral": "ニュートラル",
    "negative": "ネガティブ",
    "surveyDetails": "アンケートの詳細",
    "copied": "コピーしました",
    "allowView": "匿名表示を許可",
    "linkExpiry": "リンク失効",
    "change": "変更",
    "ok": "OK",
    "showList": "リストの表示",
    "surveyTitle": "アンケートタイトル",
    "markGlobal": "グローバルとしてマーク",
    "availableAllAppsInAcc": "アカウント内のすべてのアプリケーションで使用可能",
    "mandatoryForExtSurvey": "終了アンケートに必須",
    "enterDiffQuestType": "同じタイプの別の質問を入力してください",
    "linkDiffPage": "質問に同じページをリンクできません。別のページをリンクしてください。",
    "linkQuest": "質問をリンクする",
    "addOption": "オプションを追加",
    "analyzer": "アナライザ",
    "must3CharLong": "タイトルは3文字以上でなければなりません。",
    "threeStarRating": "3スターレーティング",
    "addPage": "ページを追加",
    "mandatory1Analyzer": "これは選択解除できません。1つのアナライザの質問は必須です。",
    "changeAnalyzerQuest": "アナライザの質問を変更しますか？",
    "set1QuestAsAnalyzer": "(※1問のみをアナライザ問題として設定できます。）",
    "conditionOfMoreThan3": "オプションが3を超える場合、分岐質問としてこれを選択することはできません。",
    "noteAboutoptionsReduction": "(※オプションの合計数を3に減らした後、再度選択し直します）",
    "QuestForChangingBranchQuest": "分岐質問を変更しますか?",
    "noteBranchQuestLimit": "(※分岐質問として設定できるのは、1ページにつき1問のみです)。",
    "removeBranching": "このアクションは、選択した質問から分岐を削除します。",
    "addBranching": "このアクションは、選択した質問に分岐を追加します。",
    "blankLinkMes": "空白リンクは、リンクが添付されていないことを意味します",
    "goalDeleteConfirmation": "この達成目標を削除しますか?",
    "viewDetails": "詳細の表示",
    "targetCompletion": "ターゲット完了",
    "click": "クリックする",
    "iconForNewGoal": "新規達成目標を作成するアイコン",
    "clickForEditMessage": "リストから達成目標をクリックして、特定の達成目標を編集します",
    "goalTitle": "達成目標タイトル",
    "goalExistsMsg": "ゴールは既に存在します。他のタイトルを選択してください。",
    "goalDescription": "達成目標の説明",
    "startTimeMsg": "開始日/時刻には、現在の日付/時刻から30分以上大きい値を指定してください",
    "endTimeMsg": "終了日/時刻には、開始日/時刻から24時間以上大きい値を指定してください",
    "goalStartDate": "達成目標開始日",
    "goalEndDate": "達成目標終了日",
    "canAddupto": "最大追加できます",
    "guideList": "ガイドリスト",
    "playCount": "再生回数",
    "eventSource": "イベントソース",
    "playMode": "再生モード",
    "msgDoitFormeNotselect": "（チュートリアルガイドのみが選択されている場合、[再生モード\"doitforme\"]をオフにしてください）",
    "msgCannotEditGoal": "公開した達成目標を編集できません。この達成目標を公開しますか?",
    "goalEmailNotification": "ゴールメール通知は現在、設定でオフになっています。重要なゴールのマイルストーン通知を逃す可能性があります。",
    "updatePreference": "設定を更新するために、いつでも",
    "goalNotificationPreference": "達成目標通知設定に移動できます",
    "goalDetails": "達成目標の詳細",
    "startDate": "開始日",
    "endDate": "終了日",
    "eventSources": "イベントソース",
    "playModes": "再生モード",
    "targetPlayCount": "ターゲットの再生回数",
    "oneEmailPublishedGuides": "メールを1日1回送信。ガイドを公開した日にステップで発生した失敗をすべて詳細に記します。",
    "oneEmailonSundays": "メール週1回送信。組織全体のMyGuide使用状況をまとめたもので、日曜日（GMT）に送信されます。",
    "receiveEmailOnMilestone": "このオプションを使用すると、マイルストーンが達成されるたびにメール通知を受け取ることができます（50%、80%、100%）。すでにゴールが設定されている前提です。",
    "receiveEmailOnAchievement": "このオプションを使用すると、目標が達成されるたびにメールを受け取ることができます。すでにゴールが設定されている前提です。",
    "emailNotificationPreference": "メール通知設定",
    "detail": "詳細",
    "moreDetails": "その他の詳細",
    "lessDetails": "簡略表示",
    "requestSentOn": "送信済みリクエストの内容",
    "requestCompletedOn": "リクエスト完了時刻",
    "requestCompletedvia": "リクエスト完了手段",
    "requestSourcedAt": "リクエストのソース",
    "requestGuideType": "リクエストガイドタイプ",
    "appsSelectedForRequest": "リクエスト用に選択されたアプリ",
    "dataNotAvailable": "データを使用できません。",
    "exportViaEmail": "メール経由でエクスポート",
    "selectedDateRange": "選択した日付範囲",
    "selectedApps": "選択したアプリ",
    "selectedDataSources": "選択したデータソース",
    "noteAboutExclusion": "※ツールチップのMobile CreatorとWeb Creatorを除外",
    "selecetdEventSource": "選択したイベントソース",
    "placeRequestLimitMsg": "過去24時間で保留中のリクエストがない場合のみ、リクエストを送信できます。",
    "textCommentsFormat": "このファイルには、ユーザーが入力したすべての回答 (コメント) がテキスト形式で表示されます。",
    "pieChartRepresentating": "すべてのユーザーが回答したオプションの割合と数を表す円グラフ。",
    "tabularRepresentationOfResponse": "各オプションの回答数と割合の表形式表現。",
    "vennDiagramCombinationOption": "ユーザーが回答したオプションの組み合わせを示すベン図。",
    "tableOfCombinationOption": "回答数と割合のオプションのすべての組み合わせを表形式で表示します。",
    "barforRating": "棒グラフは、ユーザーが指定したレーティングを表します。",
    "tableForCounts": "テーブルは、レーティングの数と割合を表します。",
    "barForRatings": "棒グラフは、ユーザーが指定したスターレーティングを表します。",
    "tableForRatings": "テーブルは、スターレーティングの数と割合を表します。",
    "barChartForYesNo": "棒グラフは\"はい\"と\"回答なし\"を示します。",
    "tableofCountsPercentage": "テーブルは、数と割合を表します。",
    "timeLine": "タイムライン",
    "detailsNotAvailable": "このインスタンスに詳細は使用できません。",
    "textType": "テキストタイプ",
    "selectSingleType": "シングルセレクトタイプ",
    "multiSelectType": "複数選択タイプ",
    "rangeType": "範囲タイプ",
    "starRatingType": "スターレーティングタイプ",
    "yesnoType": "はい/いいえタイプ",
    "responses": "回答数",
    "excelViewerQn": "Excel Viewerを利用可能ですか?",
    "viewPlayDetails": "再生の詳細の表示",
    "feedback": "フィードバック",
    "noResponseYet": "まだ応答がありません",
    "responseStats": "応答統計",
    "answerChoices": "回答選択肢",
    "responseCount": "回答数",
    "total": "合計",
    "max5AppsSelected": "アプリを5つまで選択できます。",
    "player": "プレイヤー",
    "webCreator": "ウェブ作成者",
    "webPlayer": "ウェブプレイヤー",
    "desktopCreator": "デスクトップ作成者",
    "mobileCreator": "モバイル作成者",
    "mobilePlayer": "モバイルプレイヤー",
    "dataTooltiptext7Days": "データは、実際のイベントの時点からポータルに反映されるまでに最長で7日かかる場合があります。",
    "password1SpecialChar": "[パスワード]フィールドには、少なくとも1つの特殊文字を含める必要があります。",
    "password1Numeric": "[パスワード]フィールドには1つの数値を指定する必要があります。",
    "passwordLowerCase": "[パスワード]フィールドには、少なくとも1つの小文字を含める必要があります。",
    "passwordUpperCase": "[パスワード]フィールドには、少なくとも1つの大文字を含める必要があります。",
    "passwordConfirmationField": "[パスワードの確認]フィールドは、[パスワード]フィールドと同じでなければなりません。",
    "password8Char": "[パスワードの確認]には8文字まで入力してください。",
    "newPassword8Char": "[新しいパスワード]には8文字まで入力してください。",
    "passwordChanged": "パスワードが正常に変更されました",
    "curPassword": "現在のパスワード",
    "newPassword": "新しいパスワード",
    "confirmPassword": "パスワードの確認",
    "admin": "管理者",
    "changePassword": "パスワードの変更",
    "logout": "ログアウト",
    "somethingWentWrong": "問題が発生しました",
    "showLabels": "ラベルの表示",
    "max9Allowed": "最大9個の選択が許可されています。",
    "showEntries": "エントリの表示",
    "activity": "アクティビティ",
    "guideMeActivity": "GuideMeアクティビティ",
    "showMeActivity": "ShowMeアクティビティ",
    "testMeActivity": "TestMeアクティビティ",
    "doItForMeActivity": "DoItForMeアクティビティ",
    "guideTutorialActivity": "ガイドチュートリアルアクティビティ",
    "activitiesOnGuidesonSelectApps": "選択したアプリのガイド上のアクティビティを表す折れ線グラフ",
    "guideCounts": "ガイド合計:",
    "guidesSelectedinTimeFrame": "指定した期間内の選択したモードのガイドの合計数",
    "guidesInGuideModeinTimeFrame": "指定した期間内のGuideMeモードのガイドの合計数",
    "guidesInShowMeModeinTimeFrame": "指定した期間内のShowMeモードのガイドの合計数",
    "guidesInTestMeModeinTimeFrame": "指定した期間内のTestMeモードのガイドの合計数。",
    "guidesInSDoItForMeModeinTimeFrame": "指定した期間内のDoItForMeモードのガイドの合計数",
    "tutorialGuidesinTimeFrame": "指定した期間内に再生されたチュートリアルガイド合計数",
    "guideSummary": "ガイドサマリー",
    "top10Guides": "ガイドトップ10",
    "titleOfGuide": "ガイドのタイトル。",
    "appTitle": "アプリタイトル",
    "appToWhichGuideBelong": "ガイドが属するアプリ。",
    "labels": "ラベル",
    "labelsToGuide": "ガイドに適用されたラベル。",
    "noOfResponsesForGuide": "ガイドに記録された回答の合計数。",
    "guideDistribution": "ガイド分布",
    "distributionAnalyzedNegNeuPos": "ネガティブ、ニュートラル、ポジティブとして分析されたガイドの回答の分布。",
    "lastUpdatedOn": "最終更新日:",
    "dateTimeofGuideModification": "ガイドが変更された日時。",
    "lineGraphonSelectedApps": "選択したアプリのワークフロー上のアクティビティを表す折れ線グラフ",
    "workflowSummary": "ワークフローサマリー",
    "top10Workflows": "ワークフロートップ10",
    "tableTop10WorkflowsAcrossApps": "アプリケーション全体の上位10ワークフローを表すテーブル。",
    "workflowTitle": "ワークフロータイトル",
    "titleWorkflow": "ワークフローのタイトル。",
    "typeWorkflow": "ワークフローのタイプ。",
    "walkthroughCounts": "ウォークスルー数",
    "totalCountinWorkflow": "ワークフローに含まれる合計数。",
    "avgTimeonWorkflow": "ワークフローに費やされた平均時間。",
    "bestimeAchievedWorkflow": "ワークフローで最高の期間を達成しました。",
    "lineGraphSurveysApps": "選択したアプリのアンケートに関するアクティビティを表す折れ線グラフ",
    "top10Survey": "アンケートトップ10",
    "tableTop10SurveysApps": "アプリケーション全体の上位10のアンケートを表すテーブル。",
    "nameSurvey": "アンケートの名前。",
    "appToWhichSurveyBelong": "アンケートが属するアプリ。",
    "labelsToSurvey": "アンケートに適用されたラベル。",
    "noOfResponsesForSurvey": "アンケートに記録された回答の合計数。",
    "surveyDistribution": "アンケート分布",
    "distributionAnalyzedSurevyNegNeuPos": "ネガティブ、ニュートラル、ポジティブとして分析されたアンケートの回答の分布。",
    "dateTimeofSurveyModification": "アンケートが変更された日時。",
    "tableFilteredBasedOnApps": "このテーブルは、選択したアプリにのみ基づいてフィルタリングされます。",
    "locationInsights": "場所インサイト",
    "whereUsersAccessFrom": "アプリにアクセスしているユーザーはどこにいますか?",
    "pageStats": "ページ統計",
    "pageAcivity": "ページアクティビティ",
    "blockDiagramTop50Pages": "各ページへのヒット数に基づいて上位50のタグ対応ページを表すブロック図。",
    "pageSummaryOverview": "ページサマリーの概要",
    "pageDetail": "ページ詳細",
    "collectionChartsTaggedPages": "タグ付けされたページでのアクティビティを表すグラフのコレクション。",
    "pageName": "ページの名前",
    "noOfPages": "ページの名前。",
    "labelsFilterTaggedPages": "ラベルを使用して、タグ付けされたページをフィルタリングします。",
    "pageView": "ページ表示数",
    "numberTimesPageViewed": "ページが表示された回数。",
    "uniqueVisits": "ユニークアクセス",
    "numberUniqueVisitorPage": "ページを表示したユニーク訪問者の数。",
    "topPages": "トップページ",
    "topUntaggedVistis": "ユーザーがアクセスした上位のタグなしページ。",
    "pageDistribution": "ページ寄与度",
    "chartRepresentingPage": "ページアクティビティ寄与度を表すグラフ。",
    "taggedPages": "タグ付きページ",
    "percentageContribution": "80%の表示に寄与するページの割合。",
    "urlContibution": "URL寄与度",
    "chartURLActivity": "URLアクティビティ寄与度を表すグラフ。",
    "untaggedURL": "タグなしURL",
    "comparisonTaggedUntagged": "タグが付けられていないURLの割合比較。",
    "pageUserActivity": "ページユーザーアクティビティ",
    "graphUserActiviyOnPage": "ページに関するユーザーアクティビティを表すグラフ。",
    "totalUserVisit": "合計ユーザーアクセス数",
    "totalUsersOnPageinTime": "指定した期間内にページを訪問したユーザーの合計数。",
    "minSessionDuration": "最小セッション時間",
    "maxSessionDuration": "最大セッション時間",
    "avgSessionDuration": "平均セッション時間",
    "minTimeonPageinTime": "指定した時間内にページに費やされた時間の最小値。",
    "maxTimeonPageinTime": "指定した時間内にページに費やされた時間の最大値。",
    "avgTimeonPageinTime": "指定した時間内にページに費やされた時間の平均値。",
    "whenUsersVisit": "ユーザーはいつアクセスしますか?",
    "heatMapforMaxTraffic": "ページの最大トラフィックがある日の時間を表すヒートマップ",
    "pageLoadTime": "ページロードにかかる時間",
    "averageTime": "平均時間",
    "workflowVisualSummary": "ワークフロー視覚的なサマリー",
    "workflowUsageStats": "ワークフロー使用状況の統計",
    "lineGraphWorkflowPlayed": "折れ線グラフは、再生されたワークフローの数を表します。",
    "workflowPlayCount": "ワークフロー再生回数",
    "workflowPlayedInTime": "指定した期間フレーム内に再生されたワークフローの数。",
    "uniqueWorkflowInTime": "指定した期間フレーム内のユニークに再生されたワークフロー再生回数。",
    "tableWorkflowAvgBestTime": "ワークフローリストを平均時間および最短時間と共に表すテーブル。",
    "titleOfWorkflow": "ワークフローのタイトル。",
    "associatedLabelWorkflow": "ワークフローが関連付けられているラベルの名前。",
    "totalUsersWalkthrough": "ウォークスルーをプレイしたユーザーの合計数。",
    "avgTimeWorkflow": "ワークフローに費やされた平均時間。",
    "bestTimeWorkflow": "ワークフローに費やされた最短時間。",
    "topWorkflows": "トップワークフロー",
    "top5WorkFLowByUsage": "多く使用されている上位5つのワークフロー",
    "barChartTop5Workflows": "上位5つのワークフローをその再生回数で表す棒グラフ。",
    "workflowUsageSummary": "ワークフロー使用状況のサマリー",
    "usageSummaryGraph": "使用状況のサマリーグラフ",
    "lineChartWorkflowinTime": "ユーザーエンゲージメントの概略の概要",
    "uniqueUserPlayCount": "ユーザーエンゲージメントの概略",
    "stepTitle": "ユーザーエンゲージメント詳細の概略の概要",
    "stepPlayedCount": "検索の概略",
    "numberTimesWorkflowPlayedbyAll": "ワークフローがすべてのユーザーによって再生された回数。",
    "numberTimesWorkflowPlayedbyUnique": "ワークフローがユニークユーザーによって再生された回数。",
    "usageHeatMap": "利用状況ヒートマップ",
    "heatmapOnWorkflow": "ワークフローに費やされた時間を表すヒートマップ。",
    "workflowUsageAnalysis": "ワークフロー利用状況分析",
    "scatterGraph": "散布図",
    "scatterPlotforBestAvgWorst": "散布図グラフは、指定した期間内の最短、平均、および最長時間を表します。",
    "bestTimeonWorkflow": "ワークフローに費やされた最短時間。",
    "averageTimeonWorkflow": "ワークフローに費やされた平均時間。",
    "worstTimeonWorkflow": "ワークフローに費やされた最長時間。",
    "stepsTimeUsageTable": "ステップ期間の使用状況テーブル",
    "tableUsageStepsinWorkflow": "テーブルには、選択したワークフローのステップの使用時間が表示されます。",
    "titleStepWorkflow": "ワークフロー内のステップのタイトル。",
    "numberStepsPlayed": "ステップが表示された回数。",
    "avgTimeSpentonStep": "ステップに費やされた平均時間。",
    "bestTimeSpentonStep": "ステップに費やされた最短時間。",
    "workflowUsageEngagement": "ワークフロー使用状況のエンゲージメント",
    "stepsFlowChart": "ステップフローチャート",
    "pathChartforWorkflow": "選択したワークフローのステップフローを表すパスグラフ。",
    "stepDropOff": "ステップドロップオフ",
    "funnelForworkflow": "現在のワークフローから選択したステップの目標を表示します。最小3ステップ、最大7ステップを選択できます。目標到達プロセスは、ワークフロー内のステップの順序に従って表示されるため、選択した順序は重要ではありません。",
    "specificUserActivity": "特定のユーザーアクティビティ",
    "lineProficiency": "折れ線グラフは、個人ユーザーの習熟度グラフを表します。ユーザー習熟度データを表示するには、ユーザー情報トラッキングを有効にする必要があります。",
    "overallWorkflowUsage": "全体的なワークフロー利用状況",
    "workflowFootfallAnalysis": "ワークフロー足音分析",
    "footfallGraph": "足音グラフ",
    "graphWalkThroughEntireWorkflow": "各ステップがワークフロー全体のウォークスルーの合計数に対して取得するウォークスルーの割合のグラフ。",
    "workflowPathAnalysis": "ワークフローパス分析",
    "pathChart": "パスグラフ",
    "graphStepsCombinationWorkflow": "特定のワークフローについてユーザーが従ったステップシーケンスのすべての組み合わせを表すグラフ。",
    "pathChartLegend": "パスグラフの凡例",
    "legendsStepsColorCode": "凡例には、そのカラーコードに対応するステップが紹介されています。",
    "barGraphPosNegNeu": "アンケート回答分布をポジティブ、ネガティブ、およびニュートラルとして表す棒グラフ。",
    "tableActitiesofSurvey": "アンケートのアクティビティを表すテーブル。",
    "titleSurveyCreated": "作成されたアンケートのタイトル。",
    "labelsofSurvey": "アンケートのラベル。",
    "numberResponsesEachSurvey": "各アンケートの回答数。",
    "responseDistribution": "回答分布",
    "graphPercentageSurevey": "アンケート回答の割合を表すグラフ。",
    "countPositiveResponses": "指定した期間内におけるポジティブの回答数。",
    "countNeutralResponses": "指定した期間内におけるニュートラルの回答数。",
    "countNegativeResponses": "指定した期間内におけるネガティブの回答数。",
    "pieChartPosNegNeu": "このアンケートに対するポジティブ、ネガティブ、およびニュートラルの回答の数と割合を示す円グラフ。",
    "surveyQuestions": "アンケート質問",
    "verifyAccount": "アカウントの確認",
    "otpSent": "メールにワンタイムパスワードを送信しました。ワンタイムパスワードを入力して、アカウントを確認してください。",
    "otp": "ワンタイムパスワード",
    "enterNumber": "数字のみを入力してください",
    "didntReceiveOTP": "ワンタイムパスワードを受信できませんでしたか?",
    "clicktoResendOTP": "クリックしてワンタイムパスワードを再送信",
    "backToLogin": "ログインに戻る",
    "manageGoals": "達成目標の管理",
    "goalDeletedSuccessfully": "達成目標が正常に削除されました。",
    "manageSurvey": "アンケートの管理",
    "surevyStats": "アンケート統計",
    "surveyStatsDetailed": "アンケートの詳細な統計",
    "graphActivitiesOnLiveGuide": "ライブガイド上のアクティビティを表すグラフ。",
    "totalNumberOfGuidesPlayed": "指定した期間内に再生されたガイドの合計数。",
    "totalNumberOfUniqueGuidesPlayed": "指定した期間内で (ユニークユーザーにつき) 再生されたユニークガイドの合計数。",
    "totalNumberOfCompleteGuidesPlayed": "これは、最初のステップから最後のステップまでに再生されたガイドの合計数です。",
    "completionRatio": "完了率",
    "slideshowPlayedInGivenTimeFrame": "指定した期間内に再生されたスライドショーの合計数。",
    "totalVideosPlayedInGivenTimeFrame": "指定した期間内に再生された動画の合計数。",
    "totalGIFdPlayedInGivenTimeFrame": "指定した期間内に再生されたGIFの合計数。",
    "pieActivityPercentageDistribution": "さまざまな形式でダウンロードされたガイドの分布率を表す円グラフ。",
    "barGraphTestMe": "各棒が2つの部分 (テストに合格したユーザー数、およびテストで不合格になったユーザー数) に分割されているTestMeアクティビティを実行したユーザーの合計数を表す棒グラフ。",
    "guidePlayedVieDoItForMe": "DoItForMeの自動化で再生されるガイドを表すグラフ。",
    "myGuideSurvey": "MyGuideアンケート",
    "totalNuberOfGuideAndExitResponses": "ガイド合計数とユーザーがそのガイドに関して提供した終了レスポンスを表すグラフ。",
    "displayCount": "表示数",
    "guideName": "ガイド名",
    "myGuideSurveysPlayed": "MyGuideアンケートが再生されました",
    "tableRepresentingGuideActivites": "ガイド調査のアクティビティを表すテーブル。",
    "nameOfGuidesPlayed": "再生されたガイドの名前。",
    "totalGuidesCompleteCount": "ガイドの合計数、ユニークガイド (ユニークユーザーにつき)、および指定した期間内に再生されたガイドの合計数。",
    "totalSlideshowVideoGIFCount": "指定した期間内に再生されたスライドショー、動画、GIFの合計数。",
    "totalTestPassCount": "指定した期間内でテストに合格したユーザーの合計数と、不合格になったユーザーの合計数。",
    "totalResponsesSubmitted": "指定した期間内に、ガイドについてユーザーが送信した回答の合計数。",
    "guideMePlay": "GuideMeを再生",
    "guidePlayedStats": "ガイドの再生に関する統計",
    "avgGuidePlayed": "特定の期間内に再生されたガイドのユニークユーザーごとの平均再生回数。",
    "guidePlayedOverview": "ガイドの再生に関する概要",
    "radialChartLiveGuides": "ライブガイド上のアクティビティを表す放射状グラフ。",
    "avgPlayed": "平均再生",
    "guidePlaySources": "ガイドソースの再生（プッシュ通知、ビーコン、パネル、URL）",
    "pieChartGuideSources": "プッシュ通知、ビーコン、パネル、URLにより再生されたガイドを表す円グラフ。",
    "audioGuidance": "オーディオガイダンス",
    "audioConsumptionGuideMeShowMe": "サポートされているGuideMe/ShowMeモードのオーディオ消費を表すグラフ。",
    "guideMeDetails": "GuideMeの詳細",
    "detailedSummary": "詳細なサマリ",
    "tableOfLiveGuideActivities": "ライブガイドステップ上のアクティビティを表すテーブル。",
    "startStep": "このステップから開始",
    "endStep": "このステップで終了",
    "failedOnStep": "このステップで不具合が発生しました",
    "stepsFlow": "ステップフロー",
    "titleOfStep": "ステップのタイトル。",
    "numberOfTimesStepPlayed": "ステップが何回再生されたか。",
    "stepBeganFromCurrentStep": "ガイド再生が現在のステップから何回開始されたか。",
    "stepEndedOnCurrentStep": "ガイド再生が現在のステップで何回終了したか",
    "stepErrors": "ガイド再生がエラーの発生により何回このステップで中断したか",
    "sankeyPaths": "ガイドの再生中にユーザーが選択したさまざまなパスを表すサンキー図。",
    "funnelWarningMsg": "現在のガイドから選択したステップの目標到達プロセスを表示します。最小3ステップ、最大7ステップを選択できます。目標到達プロセスは、ガイド内のステップの順序に従って表示されるため、選択した順序は重要ではありません。",
    "graphActivitiesOnShowMe": "ShowMeのアクティビティを表すグラフ。",
    "downloadDistribution": "さまざまな形式でダウンロードされたガイドの分布率を表す円グラフ。",
    "showMePlaySources": "ShowMeソースの再生 (プッシュ通知、ビーコン、パネル、URL)",
    "pieChartShowMeModes": "プッシュ通知、ビーコン、パネル、URLにより再生されたShowMeを表す円グラフ。",
    "guidesPlayedViaDoItForMe": "このガイドがDoItForMeの自動化で再生された回数を表すグラフ。",
    "doItForMeShowMeSources": "DoItforMe Plaソース (プッシュ通知、ビーコン、パネル、URL)",
    "doItForMeTableActivities": "DoItForMeモードでのガイドの再生アクティビティを表すテーブル。",
    "humanInterventionCount": "人的介入数",
    "humanInterventionCountTimes": "1つのステップで何回の人的介入が必要か。",
    "skippedStepCount": "スキップされたステップ数",
    "skippedStepCountTimes": "ステップが何回スキップされたか",
    "failedOnStepTimes": "ガイド再生がエラーの発生により何回このステップで失敗したか",
    "myGuideSurveys": "MyGuideアンケートが再生されました",
    "guideExitStepSurveys": "ガイド、終了- ガイド&ステップアンケート",
    "tutorialGuideStats": "チュートリアルガイドの統計",
    "lineChartTutorialGuide": "チュートリアルガイドの統計を表す折れ線グラフ。",
    "activitiesOnTutorialGraph": "チュートリアルガイド上のアクティビティを表すグラフ。",
    "tutorialGuideName": "再生されたチュートリアルガイドの名前。",
    "totalNoOfTutorialGuidesPlayed": "再生されたチュートリアルガイドの合計数",
    "guideCompleted": "完了済みガイド",
    "totalNumberOfGuidesCompleted": "完了したチュートリアルガイドの合計数。",
    "tutorialGuideDetailSummary": "チュートリアルガイド詳細サマリ",
    "tableOfGuidePlayActivities": "ガイドの再生アクティビティを表すテーブル。",
    "tooltipGuidanceAndValidationGraph": "指定した期間内におけるツールチップのガイダンスと検証の統計を表す折れ線グラフ。",
    "countOfTooltipHoverClicked": "ユーザーが、マウス ポインターを上に置くか、クリックするか、ページがロードされたかのいずれかの場合に、ページ上のツールチップとやりとりをした回数。",
    "countOfValidationPerformed": "ページに対して検証が実行された回数。",
    "countOfValidationAndInteractionPerformed": "両方のツールチップが操作され、検証が実行された回数。",
    "barChartPowerForm": "指定した期間内に電源フォームが呼び出されたときのステータス統計を表す積み上げ棒グラフ。",
    "countOfSubmittedPowerForm": "電源フォームを送信したすべてのユーザーの数。",
    "countOfCancelledPowerForm": "電源フォームをキャンセルしたすべてのユーザーの数。",
    "tableOfPowerFormStats": "指定した期間内の電源フォームのステータス統計を表すテーブル。",
    "titleOfPowerForm": "ステップオーダーとツールチップのガイド名を含む電源フォームのタイトル。",
    "countOfCancelledSpecificPowerForm": "特定の電源フォームをキャンセルしたすべてのユーザーの数。",
    "countOfSubmittedSpecificPowerForm": "特定の電源フォームを送信したすべてのユーザーの数。",
    "tableOfStatsOfTooltip": "指定した期間内のツールチップの統計を表すテーブル。",
    "nameOfTooltipContainsMoreTooltips": "1つ以上のツールチップを含むツールヒントガイドの名前。",
    "interactionHappenedWithGuidanceType": "特定のツールチップガイド内で、ユーザーとガイダンスタイプのツールチップとの間でやり取りが発生した回数。",
    "interactionHappenedWithValidationType": "特定のツールチップガイド内で、ユーザーと検証タイプのツールチップとの間でやり取りが発生した回数。",
    "interactionHappenedWithGuidanceValidationType": "特定のツールチップガイド内で、ユーザーがガイダンスや検証タイプのツールチップとやり取りした回数。",
    "digitalDuctTapeRenderCount": "ダクトテープが表示され、アクションが実行された回数。",
    "interactionHappenedWithPowerForm": "Power Formでユーザーとのやり取りが発生した回数。",
    "tooltipValidationActivity": "ツールチップ&Validationアクティビティ",
    "countOfTooltipClickedAndHovered": "ユーザーが、マウスポインターを上に置くか、クリックするか、ページがロードされたかのいずれかによって、ページ上にツールチップが表示された回数。",
    "countTooltipShownAndValidationPerformed": "両方のツールチップが表示され、検証が実行された回数。",
    "ductTapeActivity": "ダクトテープアクティビティ",
    "statsOfDigitalDuctTape": "指定した期間内のデジタルダクトテープに関する統計を表すグラフ。",
    "countDigitalDuctTapeDisplayed": "ページにデジタルダクトテープが表示された回数。",
    "powerFormActivity": "Power Formアクティビティ",
    "newUser": "新規ユーザー",
    "totalUsers": "合計ユーザー",
    "engagedUsers": "エンゲージメントを行ったユーザー",
    "newUserCount": "ガイドを含むページにアクセスしている新規ユーザー (過去30日間にセッションが存在しない) の合計数。",
    "numberOfUsersVisitingGuidePages": "ガイドが含まれているページにアクセスしているユーザーの合計数。",
    "usersPerformedSomeActivity": "MyGuideを使用してアクティビティを実行したユーザー。",
    "lineGraphUserEngagement": "ユーザーのエンゲージメントを表す折れ線グラフ。",
    "guideMeUniquePlayCount": "GuideMeユニーク再生回数",
    "guideMeTotalPlayCount": "GuideMe合計再生回数",
    "guideMeCompletePlayCount": "GuideMe完了再生回数",
    "numberOfUsersPlayedInGuideMode": "指定した期間内において、GuideMeモードでガイドを再生したユーザーの合計数。",
    "numberOfUniqueUsersPlayedInGuideMode": "指定した期間内において、GuideMeモードでガイドを再生したユニークユーザー数。",
    "numberOfTotalUsersPlayedInGuideMode": "指定した期間内において、GuideMeモードでガイドを完了したユーザーの合計数。",
    "doItForMeUniquePlayCount": "DoItForMeユニーク再生回数",
    "doItForMeTotalPlayCount": "DoItForMe合計再生回数",
    "doItForMeCompleteCount": "DoItForMe完了再生回数",
    "showMeTotalPlayCount": "指定した期間内において、ShowMeモードでガイドを再生したユーザーの合計数。",
    "showMeUniquePlayCount": "指定した期間内において、ShowMeモードでガイドを再生したユニークユーザー数。",
    "testMeUserTestCount": "指定した期間内において、TestMeモードでテストを完了したユーザーの合計数。",
    "testMeUniqueUserTestCount": "指定した期間内において、TestMeモードでテストを実行したユニークユーザーの数。",
    "doItForMeUserPlayCount": "指定した期間内において、DoItForMeモードでガイドを再生したユーザーの合計数。",
    "doItForMeUniqueUserPlayCount": "指定した期間内において、DoItForMeモードでガイドを再生したユニークユーザー数。",
    "doItForMeCompleteUserPlayCount": "指定した期間内において、DoItForMeモードでガイドを完了したユーザーの合計数。",
    "tutorialGuidePlayCount": "チュートリアルガイド合計再生回数",
    "tutorialGuideUniquePlayCount": "チュートリアルガイドユニーク再生回数",
    "tutorialGuideCompletePlayCount": "チュートリアルガイド完了再生回数",
    "tutorialGuideUserPlayCount": "指定した期間内において、チュートリアルガイドを再生したユーザーの合計数。",
    "tutorialGuideUniqueUserPlayCount": "指定した期間内において、チュートリアルガイドを再生したユニークユーザー数。",
    "tutorialGuideCompleteUserPlayCount": "指定した期間内において、チュートリアルガイドを完了したユーザーの合計数。",
    "userEngagementSummaryOverview": "ユーザーエンゲージメントのサマリーの概要",
    "userEngagementSummary": "ユーザーエンゲージメントのサマリー",
    "tableOfUserEngagements": "ユーザーエンゲージメントのリストを表すテーブル。",
    "email": "メール",
    "emailIdOfUser": "アクティビティを実行したユーザーのメールアドレス。",
    "nameOfUser": "アクティビティを実行したユーザーの名前。",
    "guidePlayedByUser": "指定した期間内にユーザーによって再生されたガイドの合計数。",
    "guidesPlayedByShowMeMod": "指定した期間内にユーザーによってShowMeモードで再生されたガイドの合計数。",
    "guidesPlayedByTestMeMod": "指定した期間内にユーザーによってTestMeモードで再生されたガイドの合計数。",
    "userEngagementDetailSummary": "ユーザーエンゲージメント詳細のサマリーの概要",
    "userEngagementDetailSummaryTable": "ユーザーに関するガイドのアクティビティを表すテーブル。",
    "userEngagementDetailGuideName": "再生されたガイドの名前。",
    "userEngagementDetailTotalGuidePlay": "ガイドの合計数と、ユーザーに関して指定した期間内に再生されたガイドの合計数。",
    "userEngagementDetailShowMePlay": "指定した期間内に再生された、そのユーザーに関するスライドショー、動画、GIFの合計数。",
    "userEngagementDetailTestMePlay": "指定した期間内でそのユーザーのためのテストに合格したユーザーの合計数と、不合格になったユーザーの合計数。",
    "userGuideGraph": "ユーザーガイドグラフ",
    "graphOfLiveGuideActivities": "そのユーザーに関するライブガイド上のアクティビティを表すグラフ。",
    "playsByMedia": "メディアタイプ別の再生",
    "donutChartShowMeDistribution": "ShowMeに関するアクティビティの分布率を表すドーナツグラフ。",
    "playsByTime": "時間別の再生",
    "lineChartOfTestMeActivities": "TestMeアクティビティでユーザーが合格または不合格になった回数の合計数を表す折れ線グラフ。",
    "graphOfDoItForMeMode": "DoItForMeモードで再生されたそのユーザーのためのライブガイドに関するアクティビティを表すグラフ。",
    "totalGuidesPlayedInDoItForMeMode": "指定した期間内にDoItForMeモードで再生されたガイドの合計数。",
    "totalGuidesPlayedFromFirstStep": "これは、最初のステップから最後のステップまでにDoItForMe再生されたガイドの合計数です。",
    "userEngagementTimeline": "ユーザーのエンゲージメントタイムライン",
    "userEngagementPlayDetails": "再生の詳細",
    "tutorialGuideActivity": "チュートリアルガイドアクティビティ",
    "userActivitiesTimelineCharts": "タイムライングラフは、指定した期間内に特定のユーザーによって実行された一連のアクティビティを表します。また、ガイドアクティビティが実行されたモードも表示されます。",
    "listOfSteps": "指定した期間内の特定のガイドについて、ユーザーが再生したステップの詳細リストを表示します。",
    "userActivitiesOnTutorialGuide": "ユーザーに関するチュートリアルガイドのアクティビティを表すグラフ。",
    "totalNumberOfGuideInTutorialGuide": "指定した期間内に再生されたチュートリアルガイドの合計数。",
    "totalNumberOfGuideInTutorialGuideFromFirstStep": "これは、最初のステップから最後のステップまでに再生されたチュートリアルガイドの合計数です。",
    "searchOverview": "検索の概要",
    "searchKeywords": "キーワードの検索",
    "keyword": "キーワード",
    "numberOfSearches": "検索数",
    "avgResultRetured": "平均結果が返されました",
    "clicks": "クリック数",
    "searchSummary": "検索のサマリー",
    "tableOfSearchSummary": "指定した期間内の上位50キーワード (各キーワードに対するヒット数に従って) を表すテーブル。 資格認定がないと思われる場合は、検索機能を使用して、希望するキーワードを自由に検索できます。",
    "searchKeystrokes": "さまざまなユーザーが検索したキーストロークをサーチします。",
    "keystrokesHitByUser": "各キーストロークがさまざまなユーザーによってヒットされた回数",
    "avgKeystrokes": "各キーストロークに対して実行された検索数に対する期間における平均結果数（最小）。",
    "totalClickSearchResult": "これは、検索結果の特定の項目に対して、クリックが何回行われたかを表します。",
    "wordClouseTooltipText": "指定した期間内の各キーワードに対するヒット数に従って、上位50キーワードに対する検索ボリュームを表すWord cloud図。",
    "ductTapeTable": "指定した期間内のデジタルダクトテープに関する統計を表すテーブル。",
    "guideAutomationPlay": "ガイド自動化再生数",
    "testCasesExecutedGraph": "指定した期間内にテストケースが実行された回数を示す折れ線グラフ。",
    "numberOfTimesTestCasesAutoExecuted": "指定した期間内にスケジュール済みの時間に従って、テストケースが自動的に実行された回数。",
    "numberOfTimesTestCasesManuallyExecuted": "指定した期間内にユーザーが手動でテストケースを実行した回数。",
    "guideAutomationSummary": "ガイド自動化のサマリー",
    "testCasesTable": "個人のテストケースステータスおよび統計を表すテーブル。",
    "playTime": "再生時間",
    "nameOfTestCase": "テストケースの名前。",
    "testCaseTriggeredTime": "テストケースがトリガーされた時刻。",
    "automaticallTriggered": "テストケースがスケジュールに従って自動的にトリガーされたか、または手動でユーザーによって実行されたかを示します。",
    "testCaseModes": "これは、テストケースがトリガーされたか、なんらかの理由により停止したか、または正常に完了したかを表します。",
    "guideAutomationDetails": "ガイド自動化詳細",
    "details": "詳細",
    "testCaseStatus": "ステータスと共に、すべてのテストケースの詳細なネストされたビュー。",
    "testCaseTitle": "テストケースが実行されたガイドのタイトル。",
    "testCaseResult": "特定のガイドに合格または不合格のテストケース。",
    "testCaseInfo": "特定のガイドで実行されたテストケースのステータスをサポートする情報。",
    "testCaseGuideInfo": "ガイドの実行ステータスに関する追加情報を説明するテキスト。",
    "goalDetailOverview": "達成目標詳細の概要",
    "goalPlayed": "達成目標再生済み",
    "doItForMePlayed": "DoItForMe再生済み",
    "goalActivitiesChart": "セット達成目標のアクティビティを表す折れ線グラフ。",
    "totalGuidesAndGuidesPlayedCount": "ガイドの合計数と、指定した期間内に再生されたガイドの合計数。",
    "totalTooltipsRenderCount": "指定した期間内に再生されたガイドの合計数。",
    "goalProgress": "達成目標進捗",
    "userGuidesSummary": "ユーザーガイドのサマリー",
    "goalCompletion": "達成目標完了",
    "goalCompletionOverhead": "達成目標完了のオーバーヘッド",
    "goalProgressBarChart": "達成目標に関する進捗を表す棒グラフ",
    "targetGoalPercentage": "ユーザーによって達成されたセットターゲット達成目標の割合。",
    "goalsExceedingTargets": "セットターゲット達成目標を超えるユーザーーの目標達成の割合。",
    "guideModification": "ガイド変更",
    "guideMeShowMeComparison": "特定期間内のGuideMeとShowMeの再生数を比較する折れ線グラフ。",
    "guidesPlayedInGuideMeMode": "特定期間内にGuideMeモードで再生されたガイドの合計数。",
    "guidesPlayedInShowMeMode": "特定期間内にShowMeモードで再生されたガイドの合計数。",
    "userInteractedWithPNBTMode": "ページアクセス時にユーザーがプッシュ通知やビーコン、ツールチップを使用した回数の合計を表すグラフ。",
    "totalModifiedGuides": "特定期間内に変更されたガイドの合計数を表す棒グラフ。",
    "serviceNotAvailable": "サービスがありません",
    "conflictingParameters": "リクエスト内の重複するパラメータ",
    "insightsLogin": "インサイトログイン",
    "emailId": "メールアドレス",
    "emailPlaceholder": "name@domain.com",
    "password": "パスワード",
    "remember": "保存",
    "pleaseContact": "お問い合わせ:",
    "accountSuspended": "アカウントが停止されました。",
    "forgotPassword": "パスワードを忘れた場合",
    "login": "ログイン",
    "copyright": "著作権©",
    "sessionExpiredMsg": "セッションが切断されました。",
    "reLogin": "再ログインしてください。",
    "sessionExpired": "セッションが期限切れです",
    "reaTimeDateCondition": "リアルタイムデータは、サポートされているすべてのデータセクションに含まれています。過去の日付のみ選択可能です。",
    "goToHome": "ホームに移動",
    "pageNotFound": "ページが見つかりません",
    "brandName": "MyGuide",
    "buildversion": "バージョン",
    "supportLinkText": "ヘルプが必要な場合は、メールでお問い合わせください。",
    "mailto": "宛先:",
    "addChartinCustomDash": "まだグラフが追加されていません。カスタムダッシュボードにグラフを追加してください。",
    "commentQnAnySuggestion": "提案はありますか?",
    "commentQnImprove": "改善できるのは何ですか?",
    "commentQnDescribe": "いくつかの単語で製品を記述します。",
    "multiselectFeaturesLiked": "最も気に入っている機能は何ですか?",
    "multiselectFeaturesUsed": "既に使用されている機能を選択してください。",
    "multiselectPlatform": "サイトを使用するプラットフォームを選択してください。",
    "rangeSelect": "本サービスを評価すると、10点満点で何点ですか?",
    "rangeProvide": "本サービスをどのように評価しますか?",
    "rateUs": "本サービスの評価をお願いします",
    "satisfiedRate": "どの程度満足していますか?",
    "singleselectLikeGuide": "ガイドを気に入っていますか?",
    "singleselectSuggestGuide": "友人にガイドを提案しますか?",
    "singleselectAccomplishGuide": "ガイドが目標を達成するために役立ちましたか?",
    "yesNoLikeGuide": "ガイドが目標を達成するために役立ちましたか?",
    "yesNoSuggest": "目標に達した各マイルストーンのメールを受信するには、オンにします。",
    "yesNoAccomplish": "目標に達した各マイルストーンのメールの受信を止めるには、オフにします。",
    "downloadingOption": "目標が達成されたときにメールを受信するにはオンにしてください。",
    "desktop": "目標が達成されたときにメールを受信するのを止めるにはオフにしてください。",
    "mobile": "モバイル",
    "tablet": "タブレット",
    "mayBe": "まあまあ",
    "minute": "分",
    "minutes": "分",
    "second": "秒",
    "responsesTitle": "回答数",
    "dashboard": "ダッシュボード",
    "manage": "管理",
    "selectType": "タイプの選択",
    "linkedPage": "リンク済みページ",
    "linkPage": "ページをリンク",
    "branching": "分岐",
    "addOptionLink": "オプションリンクの追加",
    "iconToCreateNewSurvey": "新規アンケートを作成するアイコン",
    "clickToEditSurvey": "リストからアンケートをクリックして、特定のアンケートを編集します",
    "lastUpdated": "最終更新日",
    "global": "グローバル",
    "questions": "質問",
    "turnOnGoalNotif": "目標に達した各マイルストーンのメールを受信するには、オンにします。",
    "turnOffGoalNotif": "目標に達した各マイルストーンのメールの受信を止めるには、オフにします。",
    "turnOnEmailNotif": "目標が達成されたときにメールを受信するにはオンにしてください。",
    "turnOffEmailNotif": "目標が達成されたときにメールを受信するのを止めるにはオフにしてください。",
    "error": "エラー",
    "goalAlreadyExists": "達成目標は既に存在します。他のタイトルを選択してください。",
    "successfullyCreated": "正常に作成されました",
    "enterText": "テキストを入力",
    "searchGoal": "達成目標を検索",
    "enterQuestion": "質問を入力してください",
    "addLink": "リンクを追加",
    "searchSurvey": "アンケートを検索",
    "nameTagOnPage": "ページのタグに名前を付ける",
    "describeTagOnPage": "ページに関するタグの説明",
    "selectUser": "ユーザーの選択",
    "pinToDashboard": "ダッシュボードにピン留めする",
    "deleteDashboard": "ダッシュボードの削除",
    "publishGoal": "達成目標の公開",
    "deleteChart": "グラフの削除",
    "analyzerQuestion": "アナライザの質問",
    "branchingQuestion": "分岐質問",
    "allDashboardDataDeletedMsg": "ダッシュボードのすべてのデータが削除されます。",
    "dashboardDeleteConfirmMsg": "本当にこのダッシュボードを削除しますか?",
    "exportDashboardData": "ダッシュボードデータのエクスポート",
    "deleteGoal": "達成目標を削除",
    "viewPendingJobs": "保留中のジョブの表示",
    "deleteSurvey": "アンケートを削除",
    "deleteSurveyConfirmMsg": "このアンケートを削除しますか?",
    "shareSurvey": "アンケートの共有",
    "best": "最高",
    "worst": "最悪",
    "average": "平均",
    "selectRange": "範囲を選択してください",
    "searchSegment": "セグメントの検索",
    "searches": "検索",
    "org": "組織",
    "downloads": "ダウンロード",
    "selectApps": "アプリの選択",
    "weeklyUsageStats": "週単位の使用状況の統計",
    "dailyFailureReport": "日時ステップエラーレポート",
    "allApps": "すべてのアプリ",
    "chooseDahsboard": "ダッシュボードを選択",
    "userEngagementDetail": "ユーザーのエンゲージメントの詳細",
    "organizationDetail": "組織の詳細",
    "organizationView": "組織ビュー",
    "guideSurveyQuestionsAndResponses": "ガイドアンケートの質問と回答",
    "exitGuideSurveyQuestionsAndResponses": "終了 - ガイド - アンケート問題 & 回答",
    "guideStepSurveyQuestionsAndResponses": "ガイドステップアンケートの質問と回答",
    "pageDetails": "ページの詳細",
    "controlCenter": "コントロールセンター",
    "slideshows": "スライドショー",
    "videos": "動画",
    "docx": "DOCX",
    "html": "HTML",
    "gifs": "GIF",
    "guideDetail": "ガイドアナリティクスの詳細",
    "tutorialGuideDetail": "チュートリアルガイドの詳細",
    "docTitleGuideDetail": "ガイドインサイト - ガイドの詳細",
    "docTitleTutorialGuideDetail": "ガイドインサイト - チュートリアルガイドの詳細",
    "docTitleTooltipDetails": "ガイドインサイト - ツールチップの詳細",
    "docTitleUserEngagementDetail": "ガイドインサイト - ユーザーのエンゲージメントの詳細",
    "docTitleGuideAutomationDetail": "自動化インサイト -ガイド自動化詳細",
    "tooltipDetails": "ツールチップの詳細",
    "userEngagement": "ユーザーのエンゲージメント",
    "search": "検索",
    "guidanceAndValidation": "ガイダンス&Validation",
    "guideMeAndShowMe": "GuideMe&ShowMe",
    "of": ":",
    "tooltipAndValidation": "Tooltip & Validation",
    "userActivity": "ユーザーアクティビティ",
    "eventType": "イベントタイプ",
    "guideDownloads": "ガイドダウンロード",
    "time": "時間",
    "combineWorkflowSteps": "複合ワークフローステップ",
    "share": "共有",
    "generating": "生成中...",
    "never": "なし",
    "custom": "カスタム",
    "surveyModalErrorMsg": "最大125文字です",
    "scheduled": "スケジュール済み",
    "manual": "手動",
    "interrupted": "中断されました",
    "editGoal": "達成目標を編集",
    "stopWatching": "視聴を停止",
    "startWatching": "視聴を開始",
    "invalidDate": "無効な日付です。",
    "invalidStartAndEndDate": "開始日と終了日が無効です。",
    "goalNotificationEmail": "達成目標通知メール",
    "goalCompletionPercent": "達成目標完了%",
    "goalCompletionOverheadPercent": "達成目標完了のオーバーヘッド%",
    "actualPlays": "実際の再生",
    "targetPlays": "ターゲット再生",
    "dataSource": "データソース",
    "selectIcon": "アイコンの選択",
    "guideSteps": "ガイドステップ",
    "createFilter": "フィルタの作成",
    "filterList": "フィルタリスト",
    "orgList": "組織リスト",
    "allOrganizations": "すべての組織",
    "views": "表示数",
    "docTitleUserEngagement": "ガイドインサイト - ユーザーのエンゲージメント",
    "docTitleSearches": "ガイドインサイト - 検索",
    "orLater": "以降",
    "pageNumber": "ページ",
    "live": "ライブ",
    "achieved": "達成済み",
    "ended": "終了",
    "achievedAndLive": "達成済みとライブ",
    "saved": "保存済み",
    "down": "下へ",
    "up": "上へ",
    "urlPath": "URLパス",
    "urlParameters": "URLパラメータ",
    "urlHash": "URLハッシュ",
    "errorInvalidURL": "エラー: URLが無効です。",
    "publish": "公開",
    "btnYes": "はい",
    "btnNo": "いいえ",
    "select": "選択",
    "export": "エクスポート",
    "apply": "適用",
    "all": "すべて",
    "slideShow": "スライドショー",
    "video": "動画",
    "seconds": "秒",
    "refresh": "更新",
    "hour": "時間",
    "hours": "時間",
    "inputPlaceHolderSearch": "ガイドを検索",
    "noRecordFound": "データはありません",
    "yes": "はい",
    "no": "いいえ",
    "avgRating": "平均レーティング",
    "ratings": "レーティング",
    "ranges": "範囲",
    "option": "オプション",
    "nameOfTheApp": "アプリの名称",
    "guideMePlays": "GuideMe再生",
    "guidePlayedInGuideMeMode": "ガイドがGuideMeモードで再生された回数",
    "showMePlays": "ShowMe再生",
    "guidePlayedInShowMeMode": "ガイドがShowMeモードで再生された回数",
    "testMePlays": "TestMe再生",
    "gupidePlayedInTestMeMode": "ガイドがTestMeモードで再生された回数",
    "doItForMePlayes": "DoItForMe再生",
    "guidePlayedInDoItForMeMode": "ガイドがDoItForMeモードで再生された回数",
    "tutorialPlays": "チュートリアル再生",
    "guidePlayedInTutorialMode": "ガイドがチュートリアルモードで再生された回数",
    "nameOfWorkflow": "ワークフローの名称",
    "workflowCounts": "ウォークスルー数",
    "counts": "カウント",
    "avgTimeSpentOnWorkflow": "ワークフローに費やされた平均時間",
    "minTimeSpentOnWorkflow": "ワークフローに費やされた最小時間",
    "numberOfGuidesPlayed": "再生されたガイドの数。",
    "totalGuidePlayedInDoItForMeMode": "[Do It For Me] モードで再生したガイドの数",
    "numberOfShowMePlayed": "ShowMeの再生数",
    "numberOfTestMePlayed": "TestMeの再生数",
    "numberOfSurveyResponses": "アンケート回答数",
    "nameOfSTep": "ステップの名前",
    "stepPlayeCount": "ステップ再生回数",
    "numberOfTimeStepWasPlayed": "ステップの再生回数",
    "stepStartedFrom": "このステップから開始",
    "numberOfTimesSTepStartedFrom": "このステップから再生が開始された回数",
    "stepEndedOn": "このステップで終了",
    "numberOfTimeStepEndedOn": "このステップで再生が終了した回数",
    "failedOnSTep": "このステップで不具合が発生しました",
    "numberIfTimesErroreOnStep": "このステップで再生にエラーが発生した回数",
    "humaneInterventionCount": "人的介入数",
    "numberOfTimesHUmanInterventionPerformed": "人的介入が実行された回数",
    "numberOfTimesStepWasSkiepped": "ステップがスキップされた回数",
    "tutorialGuideTitle": "チュートリアルガイドタイトル",
    "nameOfTutorialGuide": "チュートリアルガイド名",
    "tutorialGuidePlayed": "チュートリアルガイド再生済み",
    "numberOfTutorialGuidesPlayed": "再生されたチュートリアルガイドの数",
    "tutorialGuideCompleted": "チュートリアルガイド完了済み",
    "numberOfTutorialGuidesCompleted": "完了したチュートリアルガイドの数",
    "nameOfPowerForm": "Power Formの名前",
    "numberOfTimePowerFormCancelled": "Power Formがキャンセルされた回数",
    "numberOfTimesPowerFormSubmitted": "Power Formが送信された回数",
    "nameOfGuide": "ガイド名",
    "numberOfGuidanceCounts": "ガイダンスの回数",
    "numberOfValidationCounts": "検証の回数",
    "numberOfGuidanceAndValidationCounts": "ガイダンス&検証の回数",
    "numberOfDigitalDuctTapeCount": "デジタルダクトテープ数",
    "numberOfPowerFormsCount": "電源フォームの数",
    "guideMePlayCount": "GuideMe再生回数",
    "showMePlayCount": "ShowMe再生回数",
    "testMePlayCount": "TestMe再生回数",
    "doItForMePlayCount": "DoItForMe再生回数",
    "tutorialPlayCount": "チュートリアル再生回数",
    "uniqueKeywordSearched": "検索されたユニークキーワード",
    "searchCountTitle": "検索回数",
    "avgResultReaturned": "平均結果が返されました",
    "avgNumberOfResultsShown": "表示された結果の平均数",
    "numberOfTimeSearchResultedIntoAction": "検索結果がアクションにつながった数",
    "nameOfPageTag": "ページの名前タグ",
    "pageViews": "ページ表示数",
    "numberOfPageViews": "ページビュー数",
    "numberOfUniqueVisitors": "ユニーク訪問者数",
    "totalUniqueUsers": "合計ユニークユーザー数",
    "newUsers": "新規ユーザー",
    "numberOfSessions": "セッション数",
    "bouncedUsers": "バウンスされたユーザー",
    "nameOfStep": "ステップの名称",
    "stepPlayedCOunts": "ステップ再生回数",
    "abgTimeSpentOnStep": "ステップに費やされた平均時間",
    "minTimeSpentOnStep": "ステップに費やされた最小時間",
    "nameOfSurvey": "アンケートの名前",
    "numberOfResponses": "回答数",
    "userSurveyAnalysis": "ユーザーアンケート分析",
    "lastUpdateTime": "最終更新時刻",
    "receiveGoalEmailNotification": "達成目標メール通知を受信する",
    "receiveGoalMilestoneNotification": "達成目標マイルストーン通知を受信する",
    "other": "その他",
    "tooltipPlays": "ツールチップ再生",
    "watcherUpdatedSuccessfully": "ウォッチャーが正常に更新されました",
    "tag": "タグ",
    "chatbotConversation": "チャットボットディスカッション",
    "searchChatbot": "ディスカッションを検索",
    "iconToCreateNewChatbot": "新しいディスカッションを開始",
    "clickToEditChatbot": "リストからディスカッションをクリックして、そのディスカッションを編集してください",
    "chatBotTitle": "チャットボットのタイトル",
    "mandatoryForChatBot": "チャットボットに必須",
    "validateEmail": "メールの検証",
    "manageChatBot": "チャットボットの管理",
    "geoLocationChartCountryWise1": "世界中のアプリケーションの使用状況を国単位で表す地理的位置図。（ユーザのシステムのタイムゾーンに基づいて計算されます）'",
    "guidesVsCompletion1": "完了するまで全体を通して再生されたガイドの割合と再生されたが未完了のガイドの割合。",
    "totalNumbersUsersInteractedWithPNB1": "プッシュ通知またはビーコンを使用したGuideMe、ShowMe、DoItForMeの再生開始を示すグラフ。",
    "guideWithAudio1": "オーディオを使用するステップによってガイドが再生された合計時間 (オーディオを使用して再生されたステップの最大数)",
    "guideWithoutAudio1": "オーディオを使用しないステップによってガイドが再生された合計時間 (オーディオを使用せずに再生されたステップの最大数)",
    "done": "完了",
    "discard": "キャンセル",
    "enableExitSurvey": "終了アンケートの有効化",
    "selectExitSurvey": "終了アンケートのの選択",
    "numberUniqueUsersonPages": "所定の時間内にページにアクセスしたユニークユーザーの数。",
    "exitSurveyWarningMsg": "このアクションはすべてのアプリにわたって適用されます。",
    "orgAdmin": "組織管理者",
    "deleteChatBot": "チャットボットの削除",
    "deleteChatBotConfirmMsg": "このチャットボットを削除しますか？",
    "chatBotValidateEmailMsg": "(この質問に対するレスポンスに使用できるのは有効なメールアドレスのみです。それに応じて質問を考えてください。)",
    "partnerAdmin": "パートナー管理者",
    "totalCountSummary": "合計数の概略",
    "PNBandTooltips": "プッシュ通知、ビーコン、ツールチップ",
    "buttonText": "ログイン",
    "environmentFilterTitle": "環境",
    "segmentFilterTitle": "セグメント",
    "eventSourceFilterTitle": "イベントソース",
    "guideStatusFilterTitle": "ガイドのステータス",
    "tooltipFilterTitle": "ツールチップリスト",
    "applications": "アプリケーション",
    "none": "なし",
    "totalGuidesPlayedTitle": "合計:ガイド再生済み+ShowMe+DoItForMe+チュートリアル再生",
    "tooltipActivity": "ツールチップアクティビティ",
    "pushnotificationActivity": "プッシュ通知アクティビティ",
    "conversationStats": "ディスカッション統計",
    "chatBot": "ボット",
    "conversationsResponse": "ディスカッションレスポンス",
    "chatBotDetail": "ディスカッション詳細",
    "chatBotResponse": "ディスカッションレスポンス",
    "remarks": "備考",
    "noDataFound": "該当するデータは見つかりません",
    "noRemarkFound": "備考が見つかりません",
    "viewQuestions": "質問の表示",
    "viewRemarks": "備考の表示",
    "remarksPlaceholder": "備考を追加...",
    "remarkPostSuccessful": "備考が正常に投稿されました",
    "remarkPostFailed": "備考の投稿に失敗しました。再試行してください",
    "conversation": "ディスカッション",
    "conversationSummary": "ディスカッションまとめ",
    "tableActitiesofConversation": "ディスカッションのアクティビティを表すテーブル",
    "conversationName": "ディスカッション名",
    "nameConversationCreated": "作成されたディスカッションの名前。",
    "labelsofConversation": "ディスカッションのラベル。",
    "numberResponsesEachConversation": "各ディスカッションのレスポンス数。",
    "dateTimeofConversationModification": "ディスカッションが変更された日時。",
    "lineChartConversationStatTooltip": "ディスカッションレスポンスの分布を表す折れ線グラフ",
    "featureInteractions": "機能のユーザー操作",
    "featureDetail": "機能の詳細",
    "heatmapTooltipFeatureDetail": "一日のうち機能のユーザー操作が最大の時間を表すヒートマップ",
    "lineChartTooltipFeatureDetail": "時系列により機能のユーザー操作の数を表す折れ線グラフ",
    "featureSummary": "機能の概要",
    "tableActivitiesOfFeature": "機能のアクティビティを表すテーブル。",
    "featureSetName": "機能セット名",
    "nameOfFeatureSet": "機能セットの名前",
    "interactions": "ユーザー操作",
    "numOfInteractions": "各機能セットのユーザー操作の数",
    "dateTimeOfFeatureModification": "機能が変更された日時。",
    "rawURLActivities": "生のURLアクティビティ",
    "pageTagInfo": "※タグの作成/削除の結果として予想される変更は、反映されるまでに時間がかかります。",
    "createdBy": "作成者",
    "createdByText": "タグカードを作成したユーザーの名前。",
    "lastUpdatedTimespan": "タグの最終更新期間。",
    "blockDiagramTop50Pages1": "トップ50生ページイベントを表すブロック図（各ページに対するヒット数による）。",
    "webBrowserTooltip": "ブラウザに基づく使用状況の内訳を含むセクション（各ページに対するヒット数による）。",
    "osTooltip": "オペレーティングシステムに基づく使用状況の内訳を含むセクション（各ページに対するヒット数による）。",
    "screenResolutionTooltip": "画面の解像度に基づく使用状況の内訳を含むセクション（各ページに対するヒット数による）。",
    "completeURL": "完了URL",
    "completeURLText": "アクティビティが記録されているページの完了URL。",
    "URLVisits": "URLアクセス数",
    "URLVisitsText": "特定のURLへのアクセス数",
    "uniqueUserVisits": "ユニークURLアクセス",
    "uniqueUserVisitsText": "特定のURLでの一意の（ユニークユーザー）アクセスの数。",
    "associatedPageTags": "関連付けられたページタグ",
    "associatedPageTagsText": "このURLが関連付けられているページタグの数。",
    "totalUniqueUsersGraph": "特定の期間のユニークユーザー統計を表すグラフ",
    "noOfSessionsGraph": "特定の期間におけるセッション数（時間差が30分以下の連続アクティビティ）を表すグラフ",
    "avgSessionDurationGraph": "特定の期間の平均セッション（時間差が30分以下の連続アクティビティ）を表すグラフ",
    "pageViewsGraph": "特定の期間の合計ページビューを表すグラフ",
    "featureList": "機能リスト",
    "userName": "ユーザー名",
    "userNameTooltip": "ユーザーの名前",
    "userEmail": "ユーザーのメールアドレス",
    "userEmailTooltip": "ユーザーのメールアドレス",
    "numOfInteractionsforUser": "各ユーザーのユーザー操作数",
    "lineChartTooltipUserEngagement": "機能ユーザーの操作数と機能ユーザーの数を時系列で表す折れ線グラフ",
    "appEnvSelect": "アプリケーションと環境を選択してください",
    "totalCount": "合計数",
    "selectedAppsTitle": "選択したアプリ",
    "userActivityGraph": "選択したアプリに対してユーザーによって実行されたアクティビティを表す折れ線グラフ。",
    "selectDateRangeWithin180Days": "180日以内で日付範囲を選択してください!",
    "pageVisits": "ページアクセス数",
    "pageUsers": "ページユーザー",
    "lineChartTooltipUserEngagementPage": "ページの訪問数とページユーザーの数を時系列で表す折れ線グラフ",
    "lineChartTooltipUserEngagementPageModal": "ページ訪問数を時系列で表す折れ線グラフ",
    "numOfPageVisitsforUser": "各ユーザーのページ訪問数",
    "ytd": "今年",
    "tableTop10GuidesAcrossApps": "アプリケーション全体の上位10のガイドを表すテーブル。",
    "tooltipSummary": "ツールチップ概要",
    "top10Tooltips": "トップ10ツールチップ",
    "totalPlaysLabel": "合計再生数",
    "guidanceLabel": "ガイダンス",
    "validationLabel": "Validation",
    "powerFormLabel": "電源",
    "totalPlays": "操作されたツールチップの合計数",
    "guidanceTooltip": "ユーザーインタラクションがガイダンスモードで発生した回数",
    "validationTooltip": "ユーザーインタラクションがValidationモードで発生した回数",
    "guidanceAndValidationTooltip": "ユーザーインタラクションがガイダンス& 検証モードで発生した回数",
    "digitalDuctTapeTooltip": "ユーザーインタラクションがダクトテープモードで発生した回数",
    "powerFormTooltip": "ユーザーインタラクションがPower Formモードで発生した回数",
    "applicationAndPlayType": "アプリケーションとプレイタイプ",
    "selectApplication": "アプリケーションの選択:",
    "selectEventSource": "イベントソースの選択:",
    "selectPlayType": "プレイタイプの選択:",
    "appSelectionNote": "※細かく選択する場合は、1つのアプリケーションのみを選択してください",
    "chartFilteredBasedOnApps": "このグラフは、選択したアプリのみに基づいてフィルター処理されます。",
    "pushNotificationInteractions": "Push-Notificationの操作",
    "pushNotificationDisplayed": "Push-Notificationが表示されました",
    "beaconsInteractions": "ビーコンインタラクション",
    "beaconsDisplayed": "ビーコン表示",
    "guidesPlayedByPushAndBeacons": "ガイドがプッシュ&ビーコンによって再生されました",
    "emailCCPlaceholder": "メールアドレスと入力してEnterキーを押します。",
    "emailCC": "メールCC",
    "invalidEmailId": "無効なメールアドレス",
    "duplicateEmailId": "重複メールアドレス",
    "selectedPlayTypes": "あなたの選択したプレイタイプ",
    "selectedTooltip": "ツールチップを選択してください",
    "newTemplate": "新規テンプレート",
    "templateTitle": "テンプレートタイトル",
    "newSchedule": "新しいスケジュール",
    "scheduleTitle": "スケジュールタイトル",
    "useTemplate": "テンプレートを使用",
    "saveSchedule": "スケジュールの保存",
    "updateSchedule": "スケジュールの更新",
    "deleteTemplate": "テンプレートの削除",
    "deleteSchedule": "スケジュールの削除",
    "deleteTemplateConfirmationMsg": "このテンプレートを削除しますか?",
    "deleteScheduleConfirmationMsg": "このスケジュールを削除しますか?",
    "saveTemplate": "テンプレートの保存",
    "monday": "月曜日",
    "tuesday": "火曜日",
    "wednesday": "水曜日",
    "thursday": "木曜日",
    "friday": "金曜日",
    "saturday": "土曜日",
    "sunday": "日曜日",
    "triggerTime": "トリガー時間",
    "activate": "有効化",
    "deactivate": "無効化",
    "templateTitleErrorMsg": "テンプレートタイトルを追加してください",
    "scheduleTitleErrorMsg": "スケジュールタイトルを追加してください",
    "scheduleFutureDateErrorMsg": "スケジュールの将来の日付を選択してください",
    "scheduleDaySelectionErrorMsg": "スケジューラを実行する曜日を選択してください",
    "scheduleMaxDaySelectionErrorMsg": "最大7日間まで選択できます",
    "scheduleMaxActiveErrorMsg": "同時に有効にできるスケジュールは最大3つです。",
    "activeScheduleNonEditableInfoMsg": "有効なスケジュールは'編集できません",
    "selectTime": "時間の選択",
    "oneTime": "一度だけ",
    "repetitive": "繰り返し",
    "tutorial": "チュートリアル",
    "updatedSuccessfully": "正常に更新されました!!",
    "pushNotificationInteractedKey": "操作されたPush-Notification",
    "pushNotificationDisplayedKey": "表示されたPush-Notification",
    "beaconInteractedKey": "操作されたビーコン",
    "pushNotificationInteractedValue": "ユーザーがPush-Notificationを操作したインスタンスの数。",
    "pushNotificationDisplayedValue": "Push-Notificationがユーザーの画面に表示された回数（表示はユーザー & プッシュの組み合わせごとに1日に1回キャプチャされます）。",
    "beaconInteractedValue": "ユーザーがビーコンを操作したインスタンスの数。",
    "beaconDisplayedValue": "ビーコンがユーザーの画面に表示された回数（表示はユーザー&ビーコンの組み合わせごとに1日に1回キャプチャされます）。",
    "emailCCWarningMessage": "最大10個のメールアドレスを利用できます",
    "ytdCalendarYear": "YTD (Calendar Year)",
    "activateScheduleMsg": "このスケジュールを有効にしますか?",
    "deactivateScheduleMsg": "このスケジュールを無効化しますか?",
    "help": "ヘルプ",
    "updateTemplate": "テンプレートの更新",
    "beaconDisplayedKey1": "ビーコン表示",
    "taskList": "タスクリスト",
    "tasksTotalPlayed": "特定の時間フレーム内でエンゲージしたタスクリストの合計数。",
    "tasksCompleted": "特定の時間フレーム内で完了したタスクリストの合計数。",
    "taskListNameLabel": "タスクリスト名",
    "taskListEngagementsLabel": "エンゲージしたタスクリスト",
    "taskListCompletedLabel": "完了したタスクリスト",
    "taskListLastUpdatedLabel": "最終更新日",
    "taskListName": "タスクリストの名前",
    "taskListEngagements": "タスクリストエンゲージメントの合計数",
    "taskListCompleted": "完了したタスクリストの合計数",
    "taskListLastUpdated": "タスクリストが変更された日時",
    "taskListDetail": "タスクリストの詳細",
    "taskListGuidePlay": "ガイドプレイ",
    "completionStatus": "完了ステータス",
    "completionTime": "完了時間",
    "totalTaskListPlayedMsgTooltip": "タスクリストがプレイされた回数",
    "completedTaskListTooptip": "タスクリストが完了した回数",
    "guidePlays": "ガイドプレイ",
    "guidePlayTaskListTooltip": "タスクリストを介したガイドプレイの数",
    "taskListInteracted": "タスクリストの相互作用",
    "taskListInteractedTooltip": "ユーザーはタスクリストで少なくとも1つのガイドをプレイしました",
    "taskListCompletedTooltip": "ユーザーはタスクリスト内のすべてのガイドを完了しました",
    "viewGuides": "表示ガイド",
    "min7DaysSelection": "ユーザーエンゲージメントセクションの最短時間フレームの選択は7日間です。",
    "totalGuidesPlayCount": "GuideMe、DoItForMe、ShowMeモードで再生されたガイドの合計数。",
    "role": "役割",
    "performedSearches": "検索数",
    "searchesResultedIntoAction": "検索後にアクションした回数",
    "exportStatus": "エクスポートステータス",
    "myGuideGuidesPlayed": "MyGuideガイドが再生されました",
    "myGuideTutorialGuidesPlayed": "MyGuideチュートリアルガイドが再生されました",
    "totalNumberOfTimesGuidePlayedInDoItForMe": "DoItForMeモードでガイドが再生された合計回数。",
    "creatorExtensionRequired": "この機能を使用するには、最新バージョンのMyGuideCreator拡張機能を拡張機能としてブラウザにインストールする必要があります",
    "guidesInTooltipModeinTimeFrame": "指定された時間フレーム内に表示されたツールチップの合計数。",
    "guidesInPNModeinTimeFrame": "指定された時間フレーム内でPush-Notificationsを使用して再生されたガイドの合計数。",
    "guidesInBeaconModeinTimeFrame": "指定された時間フレーム内にビーコンを使用して再生されたガイドの合計数。",
    "guidesInSurveyModeinTimeFrame": "指定された時間フレーム内のアンケート回答の合計数。",
    "visits": "訪問",
    "desktopPlayer": "デスクトッププレーヤー",
    "guideErrors": "ガイドエラー",
    "guideErrorsDetails": "ガイドエラー詳細",
    "guideStepErrors": "ガイドステップエラー",
    "guideStepErrorDetails": "ガイドステップエラーの詳細",
    "guideStepErrorsActivity": "ガイドステップエラーアクティビティ",
    "guideErrorOccurrences": "ガイドエラー発生",
    "guideErrorOccurrencesValue": "選択した時間フレーム内のガイドエラーの合計数",
    "guideErrorSummary": "ガイドエラーの概要リスト",
    "guideErrorSummaryValue": "指定した時間フレーム内にステップエラーが発生したステップのあるすべてのガイsの概要",
    "stepInGuideWithErrors": "エラーが発生したガイドに含まれるステップ",
    "totalGuidePlayErrors": "合計ガイド再生エラー",
    "latestErrorRecorded": "最新のエラー記録日",
    "stepInGuideWithErrorsValue": "ガイドの合計ステップ数でエラーが発生しています。",
    "totalGuidePlayErrorsValue": "ガイド再生中にエラーが発生した合計回数",
    "latestErrorRecordedValue": "最後に記録されたエラーのタイムスタンプ",
    "latestStartTimestamp": "最新の開始タイムスタンプ",
    "stepErrorCounts": "ステップエラー数",
    "latestStartTimestampValue": "ステップでのエラー発生の最新のタイムスタンプ。",
    "stepErrorCountsValue": "ステップでエラーが発生した合計回数",
    "stepErrorSummary": "ステップエラーの概要",
    "guideError": "Guide エラー",
    "actions": "アクション",
    "createTemplate": "テンプレートの作成",
    "createSchedule": "スケジュールの作成",
    "createJob": "ジョブの作成",
    "playType": "操作タイプ",
    "doYouWantToDelete": "削除しますか?",
    "dateRange": "日付範囲",
    "triggerType": "トリガータイプ",
    "triggerFrequency": "トリガーの頻度",
    "rowsPerPage": "ページあたりの行数",
    "exportToExcel": "Excelにエクスポート",
    "triggerDay": "トリガー日",
    "triggerDate": "トリガー日",
    "dayOfWeek": "曜日",
    "dateOfMonth": "月の日付",
    "pauseSchedule": "スケジュールの一時停止",
    "playSchedule": "プレイスケジュール",
    "editSchedule": "スケジュールの編集",
    "editTemplate": "テンプレートの編集",
    "businessIntelligenceToolsIntegrations": "ビジネスインテリジェンスツールの統合",
    "bitiInfoHeading0": "「コーナーストーンガイド」用のPower BIデスクトップコネクタを取得するにはどうすればよいですか?",
    "bitiInfo0_1": "ここをクリック",
    "bitiInfo0_2": "コネクタ（.mez）ファイルをダウンロードするには",
    "bitiInfo0_3": "拡張ファイルをコピーします",
    "bitiInfo0_4": "オプションを確認してください",
    "bitiInfo0_5": "検証や警告なしで拡張機能を読み込むことを許可する",
    "bitiInfo0_6": "Power BI Desktopの以下の",
    "bitiInfo0_7": "Power BI Desktopを再起動する",
    "bitiInfoHeading1": "Cornerstone GuideインサイトからPowerBIデスクトップ インスタンスに直接データをプルするにはどうすればよいですか?",
    "bitiInfo1_1": "下の[生成]ボタンをクリックして、認証済みURLを生成します。",
    "bitiInfo1_2": "生成されたURLをコピーします",
    "bitiInfo1_3": "Windowsのパソコンで PowerBI Desktop に移動します",
    "bitiInfo1_4": "ナビゲーションバーの[ホーム]タブをクリックし、[データの取得]オプションをクリックします。ドロップダウンから[その他]をクリックします",
    "bitiInfo1_5": "開いたダイアログボックスから、[その他] をクリックし、[Cornerstone Guide（ベータ）（カスタム）] を検索してから、[接続] をクリックします。",
    "bitiInfo1_6": "新しいダイアログボックスで、認証済みURL URLを貼り付け、[OK]をクリックします",
    "bitiInfo1_7": "「ナビゲーション」ウィンドウが開きます。PowerBIデスクトップに取り込むデータに移動して選択し、[読み込み]をクリックします",
    "bitiInfoHeading2": "重要な説明",
    "bitiInfo2_1": "このポータルで生成される各認証済みURL URLは、個々のユーザーに固有であり、ユーザーアカウントに関連付けられています。",
    "bitiInfo2_2": "URL自体には有効期限はありませんが、60分間無効になると期限切れになるセッションに関連付けられています。",
    "bitiInfo2_3": "URLは、セクションの上にある[再承認]ボタンをクリックして再承認できます。",
    "bitiInfo2_4": "URLを変更する場合（自由に、またはURLが侵害された場合）、[再生成]ボタンをクリックして変更できます。",
    "bitiInfoNote": "※認証済みURLは生成時から60分間適用されます。",
    "generateUrl": "生成URL",
    "authenticateUrl": "URLを認証する",
    "goalLiveOn": "達成目標ライブオン",
    "surveySummary": "サマリー",
    "stats": "統計",
    "guidePlayed": "ガイド再生済み",
    "showMePlayed": "ShowMe再生済み",
    "testMePlayed": "TestMe再生済み",
    "digitalDuctTapeLabel": "デジタルダクトテープ",
    "taskListActivity": "タスクリストのアクティビティ",
    "taskListSummary": "タスクリストの概要",
    "copy": "コピー",
    "initiateDataSynchronization": "データ同期を開始する",
    "initiationInProgressInfo": "開始リクエストは処理中です。しばらくしてからもう一度アクセスしてください",
    "initiationFailedInfo": "前回の開始リクエストが失敗しました。再試行してください",
    "goalStatus": "ゴールステータス",
    "currentStatus": "現在のステータス",
    "goalActions": "Goalアクション",
    "goalTitleText": "追跡されている目標のタイトル",
    "goalStatusText": "目標の現在のステータス",
    "goalLiveOnText": "目標が公開される日付",
    "targetCompletionText": "予想または計画された目標の完了日",
    "currentStatusText": "目標の進捗率または現在の完了レベル。",
    "goalActionsText": "目標の管理に使用できるアクションまたはオプション",
    "dashboard24HrMsg": "ダッシュボード上のデータは、通常 24 時間に 1 回更新されます。",
    "totalShowMePlayedInGivenTimeFrame": "指定した期間に Show Me モードで再生されたガイドの合計数は。",
    "cioDashboardTitle": "ダッシュボード",
    "myGuideDashboard": "標準ダッシュボード",
    "guideInsights": "ガイド",
    "appInsights": "アプリケーション",
    "survey": "アンケート",
    "automationInsights": "自動化",
    "goal": "ゴール",
    "applicationAndEnvironment": "アプリケーションと環境",
    "appEnvironmentFilter": "アプリケーションフィルタ",
    "hi": "こんにちは",
    "yourInsightUpdates": "さんのインサイト",
    "tutorialGuide": "チュートリアルガイド",
    "tooltip": "ツールチップ",
    "pushNotification": "プッシュ通知とビーコン",
    "beacon": "ビーコン",
    "guidesTab": "ワークフローガイド",
    "jobList": "職務リスト",
    "templateList": "テンプレートのリスト",
    "schedularList": "スケジューラ リスト",
    "guideAutomation": "ガイドの自動化",
    "ductTape": "ダクトテープ",
    "powerForm": "高度なフォーム",
    "tutorialGuides": "アクティビティ",
    "guideMeTutorialGuide": "GuideMe",
    "pushNotifications": "プッシュ通知",
    "beacons": "ビーコン",
    "tooltipLabel": "ツールチップガイド",
    "validation": "検証",
    "guideSurvey": "Guide Survey",
    "exitGuideSurvey": "Guide Surveyを終了",
    "guideStepSurvey": "Guide ステップ Survey",
    "guide": "Guide",
    "guideNameSubheadingLabel": "Guideの名前",
    "guidesPlayedSubheadingLabel": "再生されたガイドの数。",
    "guidesPlayedInGuideMeModeSubheadingLabel": "GuideMeモードで再生されたガイドの数",
    "guidesPlayedInDoItForMeModeSubheadingLabel": "[Do It For Me] モードで再生したガイドの数",
    "showMePlayedSubheadingLabel": "ShowMeの再生数",
    "testMePlayedSubheadingLabel": "TestMeの再生数",
    "surveyResponsesSubheadingLabel": "Surveyレスポンス数",
    "stepsWithErrorsSubheadingLabel": "エラーが発生しているガイドの手順の数",
    "timesErrorEncounteredSubheadingLabel": "エラーが発生した回数",
    "lastErrorTimestampSubheadingLabel": "最後に記録されたエラーのタイムスタンプ",
    "stepNameSubheadingLabel": "ステップの名前",
    "stepLatestTimestampSubheadingLabel": "最新のタイムスタンプ",
    "stepErrorCountSubheadingLabel": "エラーが発生した回数",
    "stepPlayCountSubheadingLabel": "ステップの再生回数",
    "humanInterventionCountSubheadingLabel": "人的介入が実行された回数",
    "stepSkippedCountSubheadingLabel": "ステップがスキップされた回数",
    "stepPlayErrorCountSubheadingLabel": "このステップで再生にエラーが発生した回数",
    "tutorialGuideNameSubheadingLabel": "Tutorial Guide の名前",
    "tutorialGuidesPlayedSubheadingLabel": "再生されたチュートリアルガイドの数",
    "tutorialGuidesCompletedSubheadingLabel": "完了したチュートリアルガイドの数",
    "stepPlayStartedCountSubheadingLabel": "このステップから再生が開始された回数",
    "stepPlayEndedCountSubheadingLabel": "このステップで再生が終了した回数",
    "powerFormNameSubheadingLabel": "名前 Power Form",
    "powerFormCancelledCountSubheadingLabel": "Power Formがキャンセルされた回数",
    "powerFormSubmittedCountSubheadingLabel": "Power Form が送信された回数",
    "taskListNameSubheadingLabel": "タスク リストの名前",
    "taskListEngagedCountSubheadingLabel": "エンゲージしたタスク リストの数",
    "taskListCompletedCountSubheadingLabel": "完了したタスク リストの数",
    "taskListLastUpdatedSubheadingLabel": "タスクリスト 最終更新日",
    "userNameSubheadingLabel": "ユーザーの名前",
    "userEmailAddressSubheadingLabel": "ユーザーのメールアドレス",
    "userPlayedTaskListGuideSubheadingLabel": "少なくとも 1 つのタスク リスト ガイドをプレイしたユーザー",
    "userCompletedAllGuidesInTaskListSubheadingLabel": "タスク リスト内のすべてのガイドを完了したユーザー",
    "guideMePlayCountSubheadingLabel": "GuideMe 再生回数",
    "showMePlayCountSubheadingLabel": "ShowMe 再生回数",
    "testMePlayCountSubheadingLabel": "TestMe 再生回数",
    "doItForMePlayCountSubheadingLabel": "DoItForMeの再生回数",
    "tutorialPlayCountSubheadingLabel": "Tutorialの再生回数",
    "uniqueKeywordSearchedSubheadingLabel": "検索されたユニークキーワード",
    "keywordSearchCountSubheadingLabel": "検索数",
    "averageSearchResultsSubheadingLabel": "表示された結果の平均数",
    "searchActionCountSubheadingLabel": "検索結果がアクションにつながった数",
    "pageUrlSubheadingLabel": "ページの完了URL",
    "pageVisitCountSubheadingLabel": "訪問したURLの数",
    "uniqueUserVisitsSubheadingLabel": "ユニーク ユーザーの訪問数",
    "urlTagCountSubheadingLabel": "このURLに関連付けられているタグの数",
    "pageTagNameSubheadingLabel": "ページの名前タグ",
    "pageViewCountSubheadingLabel": "ページビュー数",
    "uniquePageVisitorsSubheadingLabel": "ユニーク訪問者数",
    "userWhoTaggedSubheadingLabel": "このタグをタグ付けたユーザーの名前",
    "pageLastUpdatedSubheadingLabel": "最終更新日:",
    "surveyNameSubheadingLabel": "名前 Survey",
    "surveyResponseCountSubheadingLabel": "レスポンス数",
    "userSurveyAnalysisSubheadingLabel": "ユーザー Survey}分析",
    "surveyLastUpdatedSubheadingLabel": "最終更新時刻",
    "conversationNameSubheadingLabel": "ディスカッション名",
    "conversationResponseCountSubheadingLabel": "レスポンス数",
    "conversationLastUpdatedSubheadingLabel": "最終更新時刻",
    "goalNameSubheadingLabel": "Goalの名前",
    "goalStatusSubheadingLabel": "Goal ステータス",
    "goalLiveDateSubheadingLabel": "Goal公開日",
    "goalCompletionDateSubheadingLabel": "Goal 完了日",
    "goalCompletionPercentageSubheadingLabel": "Goal完了率",
    "availableActionsSubheadingLabel": "使用可能なアクション",
    "pushNotificationAndBeacons": "プッシュ通知 & Beacons",
    "powerFormNDigitalDuctTape": "Power Form & デジタル Duct Tape",
    "chartLoadWarningMsg": "一部の最近のデータがまだグラフに反映されていない可能性があります",
    "userActivityGraphDashboard": "選択したアプリケーションセット全体のユーザーフットフォールの合計を表す線グラフ。",
    "totalURLClicks": "URL の合計クリック数",
    "uniqueTooltipValidationActivity": "ユニークTooltipとValidationアクティビティ",
    "uniqueDuctTapeActivity": "ユニークDuct Tapeアクティビティ",
    "uniquePowerFormActivity": "ユニーク Power Form アクティビティ",
    "totalUrlClicksGraph": "指定した期間内の合計URLクリック数の統計を表す折れ線グラフ。",
    "tooltipDetailSummaryInfo": "指定した期間内のユニークTooltipインタラクションの統計を表すテーブル。",
    "tooltipDetailSummaryTitleInfo": "Tooltip Guide の名前",
    "tooltipDetailSummaryInteractionInfo": "Tooltipごとに、ユーザーごとに1日あたり1つのイベントのみが記録されます。",
    "tooltipDetailSummaryEmbeddedUrlInfo": "Tooltipごとに、埋め込まれたURLがクリックされた回数。",
    "embeddedURLClicked": "埋め込み URL がクリックされました",
    "tooltipTitle": "Tooltip タイトル",
    "countPowerFormDisplayed": "パワーフォームがこのページに表示された回数。",
    "totalPlayCount": "合計再生回数",
    "uniquePlayCount": "ユニーク再生回数",
    "testMeTotalPlayCount": "合計再生回数",
    "testMeUniquePlayCount": "ユニーク再生回数",
    "passCount": "合計パス数",
    "failCount": "合計失敗回数",
    "makeDefault": "デフォルトにする",
    "guides": "ワークフローガイド",
    "pushNotificationsAndBeacons": "プッシュ通知 & Beacons"
}

module.exports = GmXt.playerLbls.ja_JP;