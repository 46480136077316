// Modified by: Mrunal Mane
// On: 2024-05-10
// Reason: Removed language dependancy from useEffect initial load

import React, { useState, useEffect, useRef, useCallback, ChangeEvent } from "react";
import withRouter from "hoc/withRouter";
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'


// Redux Imports
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { withReducer } from "store/withReducer";
import { AppsState, CommonComponentState, LanguageState } from "store/Reducers";
import * as ActionTypes from "../../../store/Actions/ActionTypes";
import { GuideAutomationDetailsState } from "store/Reducers/AutomationInsights/guideAutomationDetails";
import GuideAutomationDetailsReducer from "store/Reducers/AutomationInsights/guideAutomationDetails";
import * as guideAutomationDetailsActions from "store/Actions/index";


import { CommonUtils } from "utils/CommonUtils";

import qs from 'querystring';


import "./GuideAutomationDetails.scss";


import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";
import Loader from "components/Loader/Loader";
import SectionHeader from "components/Layouts/SectionHeader";
import Datepicker from "components/Calender/Datepicker";
import SectionButtons from "components/Layouts/SectionButtons";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import ExportExcel from "components/ExportExcel/ExportExcel";
import Maintenance from "components/Maintenance/Maintenance";


// type PathParamsType = {
//     // param1: string,
// }

// // Your component own properties
// type props = RouteComponentProps<PathParamsType> & {
//     // someString: string,
// }



import { getSelectedLabels } from "utils/stringConstants/trans_utils";
import { InsightLabels } from "labels";
import Icon from "components/Icon/Icon";
import SectionFilters from "components/Layouts/SectionFilters";
import UserInfoSection from "components/Layouts/UserInfoSection";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";


interface CurrentState {
    commonComponentData: CommonComponentState,
    guideAutomationDetails: GuideAutomationDetailsState,
    languageData: LanguageState,
    apps: AppsState
}

interface guideAutomationDescriptionTableState {
    guideAutomationDescriptionTableData: any,
    guideAutomationDescriptionTableDataFetched: boolean,
    detail_array: [],
    dataFetched: boolean,
    result_text: string,
    data_result: [],
    firstLoad: boolean,
    searchText: string,
    search_array: [],
    guideInfoData: any,
    errorMsg: string
}

const GuideAutomationDetails = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)


    let c = -1;
    let counter = -1;

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    let fetched_details = useSelector((state: CurrentState) => state);

    const location = useLocation();
    const { play_code } = useParams()
    const navigate = useNavigate();

    const playCode = play_code;

    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    const GuideAutomationDescriptionTableMount = useRef(false);

    const dispatch = useDispatch();

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        disabled: true,
    });
    const [errorCount, setErrorCount] = useState({
        errorCount: [0],
        showMaintenance_flag: false
    });

    const [exportModal, setExportModal] = useState({
        path: '',
        params: '',

    });



    let urlData: any = {};
    const query = new URLSearchParams(location.search);
    query.forEach((v, k) => {
        urlData[k] = v;
    });

    const [guideAutomationDescriptionTable, setGuideAutomationDescriptionTable] = useState<guideAutomationDescriptionTableState>({
        guideAutomationDescriptionTableData: [],
        guideAutomationDescriptionTableDataFetched: false,
        detail_array: [],
        dataFetched: false,
        result_text: 'all',
        data_result: [],
        firstLoad: true,
        searchText: '',
        search_array: [],
        guideInfoData: [],
        errorMsg: ''
    });

    const [filterVal, setFilterVal] = useState('All')


    const filterBy = [
        { key: "All", value: insightsLbls['all'] },
        { key: "Pass", value: insightsLbls['pass'] },
        { key: "Fail", value: insightsLbls['fail'] },
    ];

    const [guideTitle, setGuideTitle] = useState('');

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        if (insightsLbls !== undefined) {

            document.title = insightsLbls['docTitleGuideAutomationDetail'] ? insightsLbls.docTitleGuideAutomationDetail : "";
            CommonUtils.LeftPanelHighlight(5, 0, 0, true, false);
        }

        window.scrollTo(0, 0);
        CommonUtils.LeftPanelHighlight(5, 0, 0, true, false);
    }, [insightsLbls])

    useEffect(() => {
        // if (urlData['fdate']) {

        let queryParams: any = {
            app_id: fetched_details?.commonComponentData.datesData.appId,
            app_code: fetched_details?.commonComponentData.datesData.appCode,
            fdate: fetched_details?.commonComponentData.datesData.fdate,
            tdate: fetched_details?.commonComponentData.datesData.tdate,
        }

        let component_path: any[] = location.pathname.split('/');
        component_path = component_path.filter(function (el) {
            return el != '';
        });
        navigate({
            pathname: "/automation-insights/automation-detail/" + component_path[component_path.length - 1] + '/',
            search: qs.stringify(queryParams),
        });
        // }

        if (fetched_details?.commonComponentData.datesData.source === 'component') {
            if (fetched_details?.commonComponentData.datesData.url?.includes('automation-detail') && fetched_details?.commonComponentData.datesData.appCode) {
                getGuideAutomationDescriptionTableData();
            }
        }
    }, [fetched_details?.commonComponentData.datesData]);


    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getGuideAutomationDescriptionTableData = (apiErrorFlag?: string) => {

        let params = {
            "app_code": fetched_details?.commonComponentData.datesData.appCode,
            "time_zone": timeZone,
            "play_code": playCode,
            'exclude_weekends': false,
            'app_id': fetched_details?.commonComponentData.datesData.appId
        };

        guideAutomationDetailsActions.getGuideAutomationDetailsData(
            params,
            "GUIDE_AUTOMATION_DETAIL_DESCRIPTION_TABLE",
            ActionTypes.SET_GUIDE_AUTOMATION_DETAILS_DESCRIPTION_TABLE_DATA,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));

        setExportModal((prevState: any) => {
            return {
                ...prevState,
                path: 'GUIDE_AUTOMATION_DETAIL_DESCRIPTION_TABLE',
                params: params
            }
        })
    };


    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const callFun = (data: any) => {
        let d_array: any = [];
        if (guideAutomationDescriptionTable.firstLoad) {
            d_array = guideAutomationDescriptionTable.detail_array;
        } else {
            d_array = guideAutomationDescriptionTable.search_array;
        }

        if (data['childrenCategories'].length === 0) {
            c = c + 1;
            d_array[c] = [];
            d_array[c][0] = counter;
            d_array[c][1] = data.catgoryTitle;
            d_array[c][2] = true;

            c = c + 1;
            d_array[c] = [];
            d_array[c][0] = counter;
            d_array[c][1] = insightsLbls['srNo'];
            d_array[c][2] = insightsLbls['guideTitle'];
            d_array[c][3] = insightsLbls['result'];
            d_array[c][4] = insightsLbls['description'];
            d_array[c][5] = insightsLbls['moreInfo'];
            d_array[c][6] = true;

            for (let j = 0; j < data.guidesTested.length; j++) {
                c = c + 1;
                d_array[c] = [];
                d_array[c][0] = counter;
                d_array[c][1] = j + 1;
                d_array[c][2] = data.guidesTested[j].guideTitle;
                d_array[c][3] = data.guidesTested[j].status;
                d_array[c][4] = data.guidesTested[j].statusText;
                d_array[c][5] = data.guidesTested[j].messageText;
                d_array[c][6] = true;
            }
        }


        if (data['childrenCategories'].length !== 0) {
            c = c + 1;
            d_array[c] = [];
            d_array[c][0] = counter;
            d_array[c][1] = data.catgoryTitle;
            d_array[c][2] = true;

            if (data['childrenCategories'].length > 1) {
                for (let i = 0; i < data['childrenCategories'].length; i++) {
                    counter = counter + 1;
                    callFun(data['childrenCategories'][i]);
                    counter = counter - 1;
                }
            } else {
                for (let i = 0; i < data['childrenCategories'].length; i++) {
                    counter = counter + 1;
                    callFun(data['childrenCategories'][i]);
                }
            }
        }

        setGuideAutomationDescriptionTable((prevState: any) => {
            return {
                ...prevState,
                detail_array: d_array,
                search_array: d_array
            }
        })
    }

    const apiCatchError = (index: number, option: number) => {
        setErrorCount((prevState: any) => {
            return {
                ...prevState,
                errorCount: errorCount.errorCount.map((item: any, i: any) =>
                    i === index ? option : item
                ),
            };
        });
    };

    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        setLoaderBtn((prevState: any) => {
            return {
                ...prevState,
                ['loader' + loaderNumber]: val
            }
        })
    }

    const refreshSections = (optn: number) => {
        enableExportPdf(optn, false);
        if (optn === 0) {
            setGuideAutomationDescriptionTable((prevState: any) => {
                return {
                    ...prevState,
                    guideAutomationDescriptionTableData: [],
                    detail_array: [],
                    dataFetched: false,
                    result_text: 'all',
                    data_result: [],
                    firstLoad: true,
                    searchText: '',
                    search_array: [],
                    guideInfoData: []
                };
            });
            setFilterVal('All');
            getGuideAutomationDescriptionTableData();
        }
    };

    const getColor = (data: string) => {
        if (data === 'pass') {
            return 'greenFont';
        } else if (data === 'fail') {
            return 'redFont';
        }
    }

    const onSearchText = (e: ChangeEvent<HTMLInputElement>) => {
        counter = -1;
        c = -1;
        setGuideAutomationDescriptionTable((prevState: any) => {
            return {
                ...prevState,
                dataFetched: false,
                firstLoad: false,
                searchText: e.target.value,
                search_array: []
            };
        });
    }

    const onChangeEntries = (e: ChangeEvent<HTMLSelectElement>) => {
        counter = -1;
        c = -1;
        setGuideAutomationDescriptionTable((prevState: any) => {
            return {
                ...prevState,
                dataFetched: false,
                firstLoad: false,
                result_text: e.target.value.toLowerCase(),
                search_array: []
            };
        });
        setFilterVal(e.target.value);
    }

    const searchNestedCategoryJSON = (jsonInput: any, searchText: string, result_text: string) => {
        var result_object = [];
        function filterGuidesList(guidesArray: any, searchString: string, result_text_2: string) {
            var return_guides_array = [];
            try {
                for (var itr_fgl = 0; itr_fgl < guidesArray.length; itr_fgl++) {

                    if ((searchString === "" || guidesArray[itr_fgl]["guideTitle"].toLowerCase().indexOf(searchString.toLowerCase()) >= 0) && (result_text_2.toLowerCase() === "all" || guidesArray[itr_fgl]["status"].toLowerCase() === result_text_2.toLowerCase())) {
                        return_guides_array.push(JSON.parse(JSON.stringify(guidesArray[itr_fgl])));
                    }
                }
                return return_guides_array;
            }
            catch (err) {
                return return_guides_array;
            }
        }
        function filterNestedCategories(categoryArray: any, searchString2: string, result_text_1: string) {
            var return_category_array = [];
            try {
                for (var itr_fnc = 0; itr_fnc < categoryArray.length; itr_fnc++) {
                    var temp_category_object = JSON.parse(JSON.stringify(categoryArray[itr_fnc]));

                    if (temp_category_object["guidesTested"].length > 0) {
                        temp_category_object["guidesTested"] = filterGuidesList(JSON.parse(JSON.stringify(temp_category_object["guidesTested"])), searchString2, result_text_1);
                    }
                    if (temp_category_object["childrenCategories"].length > 0) {
                        temp_category_object["childrenCategories"] = filterNestedCategories(JSON.parse(JSON.stringify(temp_category_object["childrenCategories"])), searchString2, result_text_1);
                    }
                    if (temp_category_object["guidesTested"].length > 0 || temp_category_object["childrenCategories"].length > 0) {
                        return_category_array.push(temp_category_object);
                    }
                }
                return return_category_array;
            }
            catch (err) {
                return return_category_array;
            }
        }
        try {
            result_object = filterNestedCategories(jsonInput, searchText, result_text);
            return result_object;
        }
        catch (err) {
            return result_object;
        }
    }

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (!guideAutomationDescriptionTable.firstLoad) {
            var data = searchNestedCategoryJSON(guideAutomationDescriptionTable.data_result, guideAutomationDescriptionTable.searchText, guideAutomationDescriptionTable.result_text);

            for (let i = 0; i < data.length; i++) {
                counter = 0;
                callFun(data[i]);

            }

            setGuideAutomationDescriptionTable((prevState: any) => {
                return {
                    ...prevState,
                    dataFetched: true,
                    guideAutomationDescriptionTableData: data
                };
            });

        }
    }, [guideAutomationDescriptionTable.firstLoad,
    guideAutomationDescriptionTable.searchText,
    guideAutomationDescriptionTable.result_text])

    useEffect(() => {
        if (
            GuideAutomationDescriptionTableMount.current
        ) {
            enableExportPdf(0, false);
            setGuideAutomationDescriptionTable((prevState: any) => {
                return {
                    ...prevState,
                    guideAutomationDescriptionTableData: [],
                    dataFetched: false,
                };
            });
            getGuideAutomationDescriptionTableData();
        }
    }, [
        fetched_details?.commonComponentData.datesData.fdate,
        fetched_details?.commonComponentData.datesData.tdate,
        fetched_details?.commonComponentData.datesData.excludeFlag,
        fetched_details?.commonComponentData.datesData.dateSelection,
        fetched_details?.commonComponentData.datesData.realTimeCheck,
        fetched_details?.commonComponentData.datesData.realTime_tdate
    ])

    // Export PDF Btn
    useEffect(() => {
        if (loaderBtn.loader0) {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    disabled: false,
                };
            });
        }
        else {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    disabled: true,
                };
            });
        }
    }, [loaderBtn.loader0])

    useEffect(() => {
        if (
            fetched_details.guideAutomationDetails.guideAutomationDescriptionTableData.data !== undefined &&
            fetched_details.guideAutomationDetails.guideAutomationDescriptionTableData.result === "success"
        ) {
            GuideAutomationDescriptionTableMount.current = true;
            setGuideAutomationDescriptionTable((prevState: any) => {
                return {
                    ...prevState,
                    firstLoad: true
                }
            })

            setGuideAutomationDescriptionTable((prevState: any) => {
                return {
                    ...prevState,
                    guideAutomationDescriptionTableData: fetched_details.guideAutomationDetails.guideAutomationDescriptionTableData.data.resultSequence,
                    data_result: fetched_details.guideAutomationDetails.guideAutomationDescriptionTableData.data['resultSequence'],
                    guideInfoData: fetched_details.guideAutomationDetails.guideAutomationDescriptionTableData.data

                };
            });

            const data_resp: any[] = fetched_details.guideAutomationDetails.guideAutomationDescriptionTableData.data['resultSequence'];
            for (let i = 0; i < data_resp.length; i++) {
                counter = 0;
                callFun(data_resp[i]);
                setGuideAutomationDescriptionTable((prevState: any) => {
                    return {
                        ...prevState,
                        dataFetched: true,
                        errorMsg: ''
                    }
                })
            }

            apiCatchError(0, 0);
            enableExportPdf(0, true);

            setGuideTitle(fetched_details.guideAutomationDetails.guideAutomationDescriptionTableData.data.title)

        } else if (fetched_details.guideAutomationDetails.guideAutomationDescriptionTableData.result === 'retry') {
            getGuideAutomationDescriptionTableData('retry');
        } else if (
            fetched_details.guideAutomationDetails.guideAutomationDescriptionTableData.result === "error"
        ) {

            let errorMsg = insightsLbls.serviceNotAvailable;
            let errorFlag = 1;
            if (fetched_details.guideAutomationDetails.guideAutomationDescriptionTableData.errorCode === 409) {
                errorMsg = insightsLbls.conflictingParameters;
                errorFlag = 0;
            }

            setGuideAutomationDescriptionTable((prevState: any) => {
                return {
                    ...prevState,
                    dataFetched: true,
                    errorMsg: errorMsg
                };
            });
            apiCatchError(0, errorFlag);
            enableExportPdf(0, false)
        }

    }, [fetched_details.guideAutomationDetails.guideAutomationDescriptionTableData]);


    useEffect(() => {
        const result = errorCount.errorCount.every((val) => val === 1);

        setErrorCount((prevState: any) => {
            return {
                ...prevState,
                showMaintenance_flag: result
            }
        })

    }, [errorCount.errorCount])

    const navigateToMainPage = () => {
        let queryParams = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            fdate: fetched_details?.commonComponentData.datesData.fdate,
            tdate: fetched_details?.commonComponentData.datesData.tdate,
            event_source: fetched_details?.commonComponentData?.eventSource?.eventSourceGuideAutomation
        }

        navigate({
            pathname: `/automation-insights/guide-automation/`,
            search: qs.stringify(queryParams)
        })
    }

    const getData = (data: any) => {
        if(data === 'Pass' || data === 'pass') {
            return insightsLbls['pass'];
        } else if(data === 'Fail' || data === 'fail'){
            return insightsLbls['fail']
        }
    }


    return (
        <section className="demo guideAutomationDetail width100" ref={componentRef}>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li>
                            <span className="cursorPointer" onClick={() => navigateToMainPage()}>{insightsLbls['guideAutomation']}</span>
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </li>
                        <li className="active">{insightsLbls['guideAutomationDetails']}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <Datepicker source={'component'} />
                </SectionFilters>
            </div>

            {/* <SectionButtons sectionTitle={guideTitle} svgImage={"guideAutomation"}>
                <ExportToPdf
                    componentRef={componentRef}
                    source={"GuideAutomationDetails"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
            </SectionButtons> */}


            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={"GuideAutomationDetails"}
                                widthDividedBy={12}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                messageText={guideTitle}>

                <div className="displayInlineFlex">
                        <span className="dateChip marginLeft-5"> {guideAutomationDescriptionTable.guideInfoData.subTitle}</span>
                        <span className="dateChip marginLeft-5">{insightsLbls['playedBy']}: {guideAutomationDescriptionTable.guideInfoData.userName} </span>
                        <span className="dateChip marginLeft-5">{guideAutomationDescriptionTable.guideInfoData.playTime}</span>

                        <span className="dateChip marginLeft-5">{insightsLbls['runType']}: {guideAutomationDescriptionTable.guideInfoData.runType} </span>
                        <span className="dateChip marginLeft-5">{insightsLbls['status']}: {guideAutomationDescriptionTable.guideInfoData.result} </span>
                        <span className="dateChip marginLeft-5">{insightsLbls['result']}: {guideAutomationDescriptionTable.guideInfoData.resultStatus} </span>
                </div>

            </UserInfoSection>




            <div id="guideAutomationDetailSection">
                {
                    // <SectionRefresh sectionTitle={insightsLbls.guideAutomationDetails} refreshClass={""}>
                    //     <i
                    //         className="fa fa-refresh pull-right pointer row displayFlex"
                    //         aria-hidden="true" title={insightsLbls['refresh']}
                    //         onClick={() => refreshSections(0)}>
                    //     </i>
                    // </SectionRefresh>
                }

                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant marginBottom-30">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 minHeight450 height100-percent"}>
                        <div className="col-sm-12 paddingTop-20 overflowX chartdiv search_section minHeight450 row  paddingRight-15 marginLR-0">
                            <>
                                <ContainerTooltip div1Classes={"col-lg-12"} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                    {
                                        <SectionTooltip tooltipText={
                                            {
                                                "subTitle": insightsLbls.details,
                                                "tooltipText": insightsLbls.testCaseStatus,
                                                "childern": [
                                                    {
                                                        "key": insightsLbls.guideTitle + ":",
                                                        "value": insightsLbls.testCaseTitle
                                                    },
                                                    {
                                                        "key": insightsLbls.result + ":",
                                                        "value": insightsLbls.testCaseResult
                                                    },
                                                    {
                                                        "key": insightsLbls.description + ":",
                                                        "value": insightsLbls.testCaseInfo
                                                    },
                                                    {
                                                        "key": insightsLbls.moreInfo + ":",
                                                        "value": insightsLbls.testCaseGuideInfo
                                                    }
                                                ]
                                            }
                                        } placement="right"></SectionTooltip>
                                    }
                                        <button className="pointer refreshBtn" onClick={() => refreshSections(1)}> {insightsLbls.refresh}</button>

                                </ContainerTooltip>

                                <div className="col-lg-12 padding0">
                                    <p className="pull-right">
                                        <ExportExcel path={exportModal.path} params={exportModal.params}></ExportExcel>
                                    </p>
                                </div>
                            </>
                            <div className=" col-lg-12 padding0 marginTop-10 displayFlex alignCenter marginBottom-30">
                                <div className="selectCustomColor displayGrid marginLeft-auto marginTop--15">
                                    <span className="startAlign font14">{insightsLbls['result']}</span>
                                    <select
                                        className="entries-dropdown font14"
                                        onChange={(event) => onChangeEntries(event)}
                                        value={filterVal}
                                    // disabled={props.data.totalCount === 0}
                                    >
                                        {filterBy.map((option: any, index: number) => {
                                            return <option value={option.key} key={index}>{option.value}</option>;
                                        })}
                                    </select>
                                </div>
                                <p className="search-field-wrapper marginLeft-10">
                                    <input
                                        type="text"
                                        className="searchIcon form-control round-input"
                                        onChange={(event) => onSearchText(event)}
                                        placeholder={insightsLbls['search']}
                                    />
                                </p>
                            </div>

                            {guideAutomationDescriptionTable.dataFetched ? (
                                errorCount.errorCount[0] === 0 ? (
                                    guideAutomationDescriptionTable.guideAutomationDescriptionTableData.length === 0 ? (
                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <div className="noDataText-font textCenter-absolute">
                                                    {insightsLbls.noRecordFound}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div id="tableDiv" className="marginTop-10 minHeight100 paddingBottom-50">
                                                {guideAutomationDescriptionTable.detail_array.map((data: any, index: number) => {
                                                    return (

                                                        <>
                                                            {data.length === 3 && data[2]
                                                                && (<div className="categoryTitle" style={{
                                                                    marginLeft: data[0] * 20 + 20 + 'px'
                                                                }}>
                                                                    <Icon img={'img/category_gray.svg'} className="category-icon marginRight-10" />
                                                                    {data[1]}
                                                                </div>)}

                                                            {data.length > 3 && data[6]
                                                                && (<div className="guideDiv" style={{
                                                                    marginLeft: data[0] * 20 + 20 + 'px',
                                                                    fontWeight: data[1] === 'Sr. No' ? 700 : 200,
                                                                    backgroundColor: data[1] === 'Sr. No' ? '#b0deff' : ''
                                                                }}
                                                                >
                                                                    <div className="col-sm-4 col-md-4 col-lg-4 titleEllipsis paddingLeft-10">{data[2]}</div>
                                                                    <div className={"col-sm-1 col-md-1 col-lg-1 " + getColor(data[3])}>{getData(data[3])}</div>
                                                                    <div className="col-sm-3 col-md-3 col-lg-3 titleEllipsis">{data[4]}</div>
                                                                    <div className="col-sm-4 col-md-4 col-lg-4 titleEllipsis">{data[5]}</div>

                                                                </div>

                                                                )}
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </>
                                    )
                                ) : (
                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                {guideAutomationDescriptionTable.errorMsg}
                                            </h5>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}

                        </div>
                    </CardContainer>
                </div>
            </div>

            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}

        </section>
    )
}

export default withRouter(withReducer("guideAutomationDetails", GuideAutomationDetailsReducer)(GuideAutomationDetails));