import React from 'react';

const Loader=(props:any)=>{
    return(
        <div className="app-spinner">
            {/* .spinner-img */}
            <img
                className={`spinner-img img-fluid ${props.margin === 'none' ? 'margin0' : 'marginAuto'}`}
                src={require(`${process.env.REACT_APP_LOADER}`)}
                width={props.width || 150}
                height={props.height || 'auto'}
            />
        </div>
    )
};

export default Loader;
