import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation } from 'react-router-dom';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Button, ButtonGroup, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AppsState, DashboardsState, DomainState, StatusFilterState, CommonComponentState, LanguageState, VisibilityState, OrgState } from "../../store/Reducers";
import { DashboardState } from "../../store/Reducers/Dashboard/dashboard";
import * as ActionTypes from "../../store/Actions/ActionTypes";
import * as dashboardActions from "../../store/Actions/index";
import { withReducer } from "../../store/withReducer";
import dashboardReducer from "../../store/Reducers/Dashboard/dashboard";
import CommonExpandableTable from "components/Table/CommonExpandableTable";
import qs from "querystring";
import { CommonUtils } from "../../utils/CommonUtils";
import { getCallExportExcel } from "utils/ApiCallActions";
import { Tab, Tabs } from "react-bootstrap";

// JSON Imports
import SectionTooltip from "../../components/SectionTooltip/SectionTooltip";
import { getCall, postCall } from "utils/ApiCallActions";
import { ApiRelativePaths, RetryApi } from "../../utils/GlobalConstants";
import { setDashboards } from "store/Actions";
import { toast } from "react-toastify";
import "./Dashboard.scss";

// Components
import ContainerTooltip from "../../components/Layouts/ContainerTooltip";
import CardContainer from "../../components/Layouts/CardContainer";
import Loader from "../../components/Loader/Loader";
import CommonTable from "../../components/Table/CommonTable";
import LineChart from "components/Chart/nvd3Charts/LineChart";
import Datepicker from "../../components/Calender/Datepicker";
import ExportToPdf from "../../components/ExportToPdf/ExportToPdf";
import WorldMap from "../../components/Chart/WorldMap";
import Maintenance from "components/Maintenance/Maintenance";
import Piechart from "../../components/Chart/nvd3Charts/Piechart";
import AppListDropdown from "components/AppListDropdown/AppListDropdown";
import CommonModal from "components/CommonModal/CommonModal";
import EventSource from 'components/EventSource/EventSource';
import Monthpicker from "components/Calender/Monthpicker";
import SectionFilters from "components/Layouts/SectionFilters";
import AppEnvListDropdown from "components/AppEnvListDropdown/AppEnvListDropdown";
import UserInfoSection from "components/Layouts/UserInfoSection";

import Bubblechart from "components/Chart/Bubblechart";
import { InsightLabels } from "labels";
import { useErrorBoundary } from 'react-error-boundary';
import { v4 as uuidv4 } from 'uuid';

let lineChartMargin = {
    top: "150",
    right: "50",
    bottom: "50",
    left: "65",
};

let linechart_height = 450;

const Dashboard: React.FC = (props: any) => {

    const { showBoundary } = useErrorBoundary();
    const location = useLocation();
    const navigate = useNavigate();
    const appListTotal = useRef({})
    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    let fetched_dashboard_details = useSelector((state: CurrentState) => state.dashboards);
    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    const dispatch = useDispatch();
    interface CurrentState {
        apps: AppsState;
        commonComponentData: CommonComponentState;
        dashboard: DashboardState;
        domain: DomainState;
        dashboards: DashboardsState;
        statusFilter: StatusFilterState;
        languageData: LanguageState;
        sectionVisibility: VisibilityState;
        org: OrgState
    }

    let fetched_details = useSelector((state: CurrentState) => state);
    let orgDetails = useSelector((state: CurrentState) => state.org);

    const GuideMeMount = useRef(false);
    const ShowMeMount = useRef(false);
    const TestMeMount = useRef(false);
    const DoItForMeMount = useRef(false);
    const GuideTutorialsMount = useRef(false);
    const CountriesMapMount = useRef(false);
    const SurveysMount = useRef(false);
    const GuideSummarysMount = useRef(false);
    const SurveyChartMount = useRef(false);
    const AppListMount = useRef(false);
    const UserEngagementMount = useRef(false);

    const [parameter, setParameter] = useState({
        parameter: "",
        appCode: "",
        appId: "",
        source: "",
        isDeleted: "",
        option: '',
        type: '',
        appTitle: '',
        redirect: 0
    });

    // const [errorApiMsg, setApiErrorMsg] = useState([]);
    const [userEngagementLazyLoadChart, setUserEngagementLazyLoadChart] = useState({
        lazyLoad_flag: false,
        refreshFlag: false,
    })

    const [excelData, setExcelData] = useState({
        download: "",
        viewOnline: "",
        or: "",
        questionMsg: ""
    });


    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        showMaintenance_flag: false
    });

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        loader2: false,
        loader3: false,
        loader4: false,
        loader5: false,
        loader6: false,
        loader7: false,
        loader8: false,
        loader9: false,
        loader10: false,
        loader11: false,
        loader12: false,
        loader13: false,
        loader14: false,
        loader15: false,
        loader16: false,
        loader17: false,
        loader18: false,
        loader19: false,
        loader20: false,
        disabled: true,
    });

    const [tabKey, setTabKey] = useState({
        key: 1,
        chart: []
    });

    const [expanded, setExpanded] = useState(false);

    const [appsList, setAppsList] = useState({
        appsFetched: false,
        appList: [],
        appNames: [],
        appListString: [],
        lineChartEntities: ["app1", "app2", "app3", "app4", "app5", "app6", "app7", "app8", "app9", "appx"]
    });

    const [dashboardGuideMeChart, setDashboardGuideMeChart] = useState({
        loadGuideMeChartData: [],
        loadGuideMeChartOptions: [],
        dashboardGuideMeChartDataFetched: false,
        guideMeChart: {},
        guideMeChartFetched: false,
        bubbleChartData: [],
        selectedAppCount: 0,
        chartData_entites: [],
        optionData_entites: [],
        chartData_values: { counts: [] }
    });

    const [dashboardShowMeChart, setDashboardShowMeChart] = useState({
        loadShowMeChartData: [],
        loadShowMeChartOptions: [],
        dashboardShowMeChartDataFetched: false,
        showMeChart: {},
        showMeChartFetched: false,
        bubbleChartData: [],
        selectedAppCount: 0,
        chartData_entites: [],
        optionData_entites: [],
        chartData_values: { counts: [] }
    });

    const [dashboardTestMeChart, setDashboardTestMeChart] = useState({
        loadTestMeChartData: [],
        loadTestMeChartOptions: [],
        dashboardTestMeChartDataFetched: false,
        testMeChart: {},
        testMeChartFetched: false,
        bubbleChartData: [],
        selectedAppCount: 0,
        chartData_entites: [],
        optionData_entites: [],
        chartData_values: { counts: [] }

    });

    const [dashboardDoItForMeChart, setDashboardDoItForMeChart] = useState({
        loadDoItForMeChartData: [],
        loadDoItForMeChartOptions: [],
        dashboardDoItForMeChartDataFetched: false,
        doItForMeChart: {},
        doItForMeChartFetched: false,
        bubbleChartData: [],
        selectedAppCount: 0,
        chartData_entites: [],
        optionData_entites: [],
        chartData_values: { counts: [] }

    });

    const [dashboardGuideTutorialsChart, setDashboardGuideTutorialsChart] = useState({
        loadGuideTutorialsChartData: [],
        loadGuideTutorialsChartOptions: [],
        dashboardGuideTutorialsChartDataFetched: false,
        guideTutorialsChart: {},
        guideTutorialsChartFetched: false,
        bubbleChartData: [],
        selectedAppCount: 0,
        chartData_entites: [],
        optionData_entites: [],
        chartData_values: { counts: [] }
    });

    const [dashboardTooltipChart, setDashboardTooltipChart] = useState({
        loadTooltipChartData: [],
        loadTooltipChartOptions: [],
        dashboardTooltipChartDataFetched: false,
        tooltipChart: {},
        tooltipChartFetched: false,
        bubbleChartData: [],
        selectedAppCount: 0,
        chartData_entites: [],
        optionData_entites: [],
        chartData_values: { counts: [] }
    });

    const [dashboardPushnotificationChart, setDashboardPushnotificationChart] = useState({
        loadPushnotificationChartData: [],
        loadPushnotificationChartOptions: [],
        dashboardPushnotificationChartDataFetched: false,
        pushnotificationChart: {},
        pushnotificationChartFetched: false,
        bubbleChartData: [],
        selectedAppCount: 0,
        chartData_entites: [],
        optionData_entites: [],
        chartData_values: { counts: [] }
    });

    const [dashboardBeaconChart, setDashboardBeaconChart] = useState({
        loadBeaconChartData: [],
        loadBeaconChartOptions: [],
        dashboardBeaconChartDataFetched: false,
        beaconChart: {},
        beaconChartFetched: false,
        bubbleChartData: [],
        selectedAppCount: 0,
        chartData_entites: [],
        optionData_entites: [],
        chartData_values: { counts: [] }
    });

    const [dashboardSurveyGraph, setDashboardSurveyGraph] = useState({
        loadSurveyChartData: [],
        loadSurveyChartOptions: [],
        dashboardSurveyChartDataFetched: false,
        surveyChart: {},
        SurveyChartFetched: false,
        bubbleChartData: [],
        selectedAppCount: 0,
        chartData_entites: [],
        optionData_entites: [],
        chartData_values: { counts: [] }
    });

    const [dashboardValidationChart, setDashboardValidationChart] = useState({
        dashboardValidationChartDataFetched: false,
    });

    const [dashboardPowerhtmlChart, setDashboardPowerhtmlChart] = useState({
        dashboardPowerhtmlChartDataFetched: false,
    });

    const [dashboardDucttapeChart, setDashboardDucttapeChart] = useState({
        dashboardDucttapeChartDataFetched: false,
    });

    const [dashboardGuidancevalidationChart, setDashboardGuidancevalidationChart] = useState({
        dashboardGuidancevalidationChartDataFetched: false,
    });

    const [dashboardGuidanceChart, setDashboardGuidanceChart] = useState({
        dashboardGuidanceChartDataFetched: false,
    });

    const [dashboardCountriesChartData, setDashboardCountriesChartData] = useState({
        dashboardCountriesChartDataFetched: false,
        legendData: [],
        boundaryValues: [],
        colors: [],
        countryData: []
    });

    const [dashboardGuideSummary, setDashboardGuideSummary] = useState({
        dashboardGuideSummaryFetched: false,
        defaultSort: {
            sortColumn_index: 2,
            sortValue: "desc",
        },
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 10,
            currentPage: 0,
        },
        limit: 10,
        firstLoad: true
    });

    const [dashboardTooltipSummary, setDashboardTooltipSummary] = useState({
        dashboardTooltipSummaryFetched: false,
        defaultSort: {
            sortColumn_index: 2,
            sortValue: "desc",
        },
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 10,
            currentPage: 0,
        },
        limit: 10,
        firstLoad: true
    });

    const [dashboardSurveyData, setDashboardSurveyData] = useState({
        dashboardSurveyDataFetched: false,
        defaultSort: {
            sortColumn_index: 3,
            sortValue: "desc",
        },
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 10,
            currentPage: 0,
        },
        limit: 10,
        firstLoad: true
    });

    const [exportModal, setExportModal] = useState({
        open: false,
        exportDisabled: false,
        start_date: '',
        end_date: '',
        app_id: '',
        event_source: '',
        defaultValue: 'player'
    })

    const [dashboardTotalGuideCount, setDashboardTotalGuideCount] = useState({
        totalGuideCountFetched: false,
        totalGuideCountData: {},
        isLoading: false,
        firstLoad: true
    })

    let maxAppLength = 10;

    const [request_uuid, setUuid] = useState({
        value: uuidv4()
    })

    const [bubbleChartData, setBubbleChartData] = useState({
        count: 0
    })

    const [dashboardUserEngagementChart, setDashboardUserEngagementChart] = useState({
        loadUserEngagementChartData: [],
        loadUserEngagementChartOptions: [],
        dashboardUserEngagementChartDataFetched: false,
        userEngagementChart: {},
        userEngagementFetched: false,
    });

    const [dashboard_state, setDashboard_state] = useState({
        isDefault: true
    })
    // First Load (on date change) @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    }, [fetched_details.languageData.languageData])


    useEffect(() => {
        try {
            if (fetched_details?.commonComponentData?.datesData?.fdate &&
                fetched_details?.commonComponentData?.datesData?.tdate &&
                fetched_details?.commonComponentData?.eventSource?.eventSourceDashboard &&
                appsList.appList.length <= maxAppLength) {

                let activeItems = 0;
                for (let i = 0; i < fetched_details?.dashboards?.data.length; i++) {
                    if (fetched_details?.dashboards?.data[i]['dashboard' + (i + 1)]['isActive'] && fetched_details?.dashboards?.data[i]['dashboard' + (i + 1)]['profileDetails'].length) {
                        activeItems = i;
                        break;
                    } else {
                        activeItems = 3;
                    }
                }

                window.scrollTo(0, 0);
                setTimeout(() => {
                    CommonUtils.LeftPanelHighlight(0, activeItems, 0, false, false);
                }, 10);

                const queryParams = {
                    fdate: fetched_details?.commonComponentData?.datesData.fdate,
                    tdate: fetched_details?.commonComponentData?.datesData.tdate,
                    event_source: fetched_details?.commonComponentData?.eventSource.eventSourceDashboard,
                    state: fetched_details?.commonComponentData?.stateFilterData.stateFilter,
                };

                navigate({
                    pathname: `/dashboard/`,
                    search: decodeURIComponent(qs.stringify(queryParams)),
                });

                if ((fetched_details?.commonComponentData?.datesData.url.includes('dashboard')) && appsList.appsFetched) {
                    const reqUUID = uuidv4();
                    loadOnFilterChangeData(reqUUID);
                    getDashboardStatus();
                    setUuid((prevState: any) => {
                        return {
                            ...prevState,
                            value: reqUUID
                        }
                    })
                }
            }

        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details?.commonComponentData?.datesData,
    appsList.appsFetched,
    appsList.appList,
    appsList.appListString,
    fetched_details?.statusFilter?.stateFilter,
    fetched_details?.commonComponentData?.eventSource?.eventSourceDashboard]);
    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    const getDashboardData = (reqUUID, actionType, actionSetType, options: { apiErrorFlag?: string, isEnableRealTime?: boolean; firstLoad?: boolean }) => {
        let params = {
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.tdate + "_23:59:59",
            "event_source": '',
            "app_id": appsList.appListString,
            "filter": 'all',
            'request_uuid': reqUUID,
            ...(options.isEnableRealTime && { "enable_real_time": fetched_details?.commonComponentData?.datesData.realTimeCheck })
        };

        dashboardActions.getDashboardData(params, actionType, actionSetType, options.apiErrorFlag ?? '', options.firstLoad ?? undefined)
            .then((res: any) => {
                dispatch(res);
            });
    };

    const getDashboardGuideMeChartData = (reqUUID, apiErrorFlag?: string) =>
        getDashboardData(reqUUID, "DASHBOARD_GUIDE_ME_CHART", ActionTypes.SET_DASHBOARD_GUIDEME_CHART_DATA, { apiErrorFlag: apiErrorFlag });

    const getDashboardShowMeChartData = (reqUUID, apiErrorFlag?: string) =>
        getDashboardData(reqUUID, "DASHBOARD_SHOW_ME_CHART", ActionTypes.SET_DASHBOARD_SHOWME_CHART_DATA, { apiErrorFlag: apiErrorFlag });

    const getDashboardTestMeChartData = (reqUUID, apiErrorFlag?: string) =>
        getDashboardData(reqUUID, "DASHBOARD_TEST_ME_CHART", ActionTypes.SET_DASHBOARD_TESTME_CHART_DATA, { apiErrorFlag: apiErrorFlag });

    const getDashboardDoItForMeChartData = (reqUUID, apiErrorFlag?: string) =>
        getDashboardData(reqUUID, "DASHBOARD_DO_IT_FOR_ME_CHART", ActionTypes.SET_DASHBOARD_DO_IT_FOR_ME_CHART_DATA, { apiErrorFlag: apiErrorFlag });

    const getDashboardGuideTutorialsChartData = (reqUUID, apiErrorFlag?: string) =>
        getDashboardData(reqUUID, "DASHBOARD_GUIDE_TUTORIALS_CHART_DATA", ActionTypes.SET_DASHBOARD_GUIDE_TUTORIALS_CHART_DATA, { apiErrorFlag: apiErrorFlag });

    const getDashboardTooltipChartData = (reqUUID, apiErrorFlag?: string) =>
        getDashboardData(reqUUID, "DASHBOARD_TOOLTIP_CHART_DATA", ActionTypes.SET_DASHBOARD_TOOLTIP_CHART_DATA, { apiErrorFlag: apiErrorFlag });

    const getDashboardPushnotificationChartData = (reqUUID, apiErrorFlag?: string) =>
        getDashboardData(reqUUID, "DASHBOARD_PUSHNOTIFICATION_CHART_DATA", ActionTypes.SET_DASHBOARD_PUSHNOTIFICATION_CHART_DATA, { apiErrorFlag: apiErrorFlag });

    const getDashboardBeaconChartData = (reqUUID, apiErrorFlag?: string) =>
        getDashboardData(reqUUID, "DASHBOARD_BEACON_CHART_DATA", ActionTypes.SET_DASHBOARD_BEACON_CHART_DATA, { apiErrorFlag: apiErrorFlag });

    const getDashboardSurveyGraphData = (reqUUID, apiErrorFlag?: string) =>
        getDashboardData(reqUUID, "DASHBOARD_SURVEY_CHART_DATA", ActionTypes.SET_DASHBOARD_SURVEY_CHART_DATA, { apiErrorFlag: apiErrorFlag });

    const getDashboardValidationChartData = (reqUUID, apiErrorFlag?: string) =>
        getDashboardData(reqUUID, "DASHBOARD_VALIDATION_CHART_DATA", ActionTypes.SET_DASHBOARD_VALIDATION_CHART_DATA, { apiErrorFlag: apiErrorFlag });

    const getDashboardPowerhtmlChartData = (reqUUID, apiErrorFlag?: string) =>
        getDashboardData(reqUUID, "DASHBOARD_POWERHTML_CHART_DATA", ActionTypes.SET_DASHBOARD_POWERHTML_CHART_DATA, { apiErrorFlag: apiErrorFlag });

    const getDashboardDucttapeChartData = (reqUUID, apiErrorFlag?: string) =>
        getDashboardData(reqUUID, "DASHBOARD_DUCTTAPE_CHART_DATA", ActionTypes.SET_DASHBOARD_DUCTTAPE_CHART_DATA, { apiErrorFlag: apiErrorFlag });

    const getDashboardGuidancevalidationChartData = (reqUUID, apiErrorFlag?: string) =>
        getDashboardData(reqUUID, "DASHBOARD_GUIDANCEVALIDATION_CHART_DATA", ActionTypes.SET_DASHBOARD_GUIDANCEVALIDATION_CHART_DATA, { apiErrorFlag: apiErrorFlag });

    const getDashboardGuidanceChartData = (reqUUID, apiErrorFlag?: string) =>
        getDashboardData(reqUUID, "DASHBOARD_GUIDANCE_CHART_DATA", ActionTypes.SET_DASHBOARD_GUIDANCE_CHART_DATA, { apiErrorFlag: apiErrorFlag });

    const getDashboardSurveyChartData = (reqUUID, apiErrorFlag?: string) =>
        getDashboardData(reqUUID, "GET_SURVEY_MULTIAPP_GRAPH", ActionTypes.SET_DASHBOARD_SURVEY_DATA, { apiErrorFlag: apiErrorFlag });

    const getDashboardUserEngagementChartData = (reqUUID, apiErrorFlag?: string) =>
        getDashboardData(reqUUID, "DASHBOARD_USER_ENGAGEMENT", ActionTypes.SET_DASHBOARD_USER_ENGAGEMENT, { apiErrorFlag: apiErrorFlag });

    const getDashboardCountriesChartData = (reqUUID, apiErrorFlag?: string) => {
        if (fetched_details?.commonComponentData?.datesData.fdate && fetched_details?.commonComponentData?.datesData.tdate) {
            getDashboardData(reqUUID, "GET_COUNTRY_DATA", ActionTypes.SET_DASHBOARD_CONTRIES_CHART_DATA, { apiErrorFlag: apiErrorFlag, isEnableRealTime: true });
        }
    };

    const getDashboardSurveysData = (reqUUID, apiErrorFlag?: string) => {
        if (fetched_details?.commonComponentData?.datesData.fdate && fetched_details?.commonComponentData?.datesData.tdate) {
            getDashboardData(reqUUID, "GET_SENTIMENT_SUMMARY", ActionTypes.SET_DASHBOARD_SURVEYS_TABLE_DATA, { apiErrorFlag: apiErrorFlag, isEnableRealTime: true, firstLoad: dashboardSurveyData.firstLoad });
        }
    };

    const getDashboardGuideSummary = (reqUUID, apiErrorFlag?: string) => {
        if (fetched_details?.commonComponentData?.datesData.fdate && fetched_details?.commonComponentData?.datesData.tdate) {
            getDashboardData(reqUUID, "GET_DASHBOARD_GUIDE_SUMMARY", ActionTypes.SET_DASHBOARD_GUIDE_SUMMARY_TABLE_DATA, { apiErrorFlag: apiErrorFlag, isEnableRealTime: true, firstLoad: dashboardGuideSummary.firstLoad });
        }
    };

    const getDashboardTooltipSummary = (reqUUID, apiErrorFlag?: string) => {
        if (fetched_details?.commonComponentData?.datesData.fdate && fetched_details?.commonComponentData?.datesData.tdate) {
            getDashboardData(reqUUID, "GET_DASHBOARD_TOOLTIP_SUMMARY", ActionTypes.SET_DASHBOARD_TOOLTIP_SUMMARY_TABLE_DATA, { apiErrorFlag: apiErrorFlag, isEnableRealTime: true, firstLoad: dashboardTooltipSummary.firstLoad });
        }
    };

    const exportDashboardData = (optn: boolean) => {
        let params = {
            "app_id": exportModal.app_id,
            "time_zone": timeZone,
            "start_date": exportModal.start_date,
            "end_date": exportModal.end_date,
            "event_source": exportModal.event_source,
        };

        getCallExportExcel(params, 'DASHBOARD_EXPORT_DATA', optn);
        closeExportModal();
    }

    const getDashboardGuidesCount = (reqUUID, apiErrorFlag?: string) => {
        const params = {
            "app_id": appsList.appListString,
            'request_uuid': reqUUID
        };

        if (fetched_details?.commonComponentData?.datesData.fdate && fetched_details?.commonComponentData?.datesData.tdate) {
            dashboardActions.getDashboardData(
                params,
                "GET_DASHBOARD_GUIDE_COUNT",
                ActionTypes.SET_DASHBOARD_GUIDE_COUNT,
                apiErrorFlag ?? '',
                dashboardTotalGuideCount.firstLoad
            ).then((res: any) => {
                dispatch(res);
            })
        }
    }
    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const loadOnFilterChangeData = (reqUUID) => {
        try {
            setDashboardGuideMeChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardGuideMeChartDataFetched: false,
                    guideMeChart: {},
                    guideMeChartFetched: false,
                    loadGuideMeChartData: [],
                    loadGuideMeChartOptions: [],
                };
            });
            enableExportPdf(0, false);
            getDashboardGuideMeChartData(reqUUID);

            setDashboardShowMeChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardShowMeChartDataFetched: false,
                    showMeChart: {},
                    showMeChartFetched: false,
                };
            });
            enableExportPdf(1, false);
            if (process.env.REACT_APP_WESTPAC === 'false') {
                getDashboardShowMeChartData(reqUUID);
            }

            setDashboardTestMeChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardTestMeChartDataFetched: false,
                    testMeChart: {},
                    testMeChartFetched: false,
                };
            });
            enableExportPdf(2, false);
            if (process.env.REACT_APP_WESTPAC === 'false') {
                getDashboardTestMeChartData(reqUUID);
            }

            setDashboardDoItForMeChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardDoItForMeChartDataFetched: false,
                    doItForMeChart: {},
                    doItForMeChartFetched: false,
                };
            });
            enableExportPdf(3, false);
            if (process.env.REACT_APP_WESTPAC === 'false') {
                getDashboardDoItForMeChartData(reqUUID);
            }

            setDashboardGuideTutorialsChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardGuideTutorialsChartDataFetched: false,
                    guideTutorialsChart: {},
                    guideTutorialsChartFetched: false,
                };
            });
            enableExportPdf(4, false);
            getDashboardGuideTutorialsChartData(reqUUID);


            setDashboardTooltipChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardTooltipChartDataFetched: false,
                    tooltipChart: {},
                    tooltipChartFetched: false,
                };
            });
            enableExportPdf(10, false);
            getDashboardTooltipChartData(reqUUID);


            setDashboardPushnotificationChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardPushnotificationChartDataFetched: false,
                    pushnotificationChart: {},
                    pushnotificationChartFetched: false,
                };
            });
            enableExportPdf(11, false);
            getDashboardPushnotificationChartData(reqUUID);


            setDashboardBeaconChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardBeaconChartDataFetched: false,
                    beaconChart: {},
                    beaconChartFetched: false,
                };
            });
            enableExportPdf(12, false);
            getDashboardBeaconChartData(reqUUID);

            setDashboardSurveyGraph((prevState: any) => {
                return {
                    ...prevState,
                    dashboardSurveyChartDataFetched: false,
                    surveyChart: {},
                    surveyChartFetched: false,
                };
            });
            enableExportPdf(13, false);
            getDashboardSurveyGraphData(reqUUID);

            setDashboardValidationChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardValidationChartDataFetched: false,
                }
            })

            setDashboardPowerhtmlChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardPowerhtmlChartDataFetched: false,
                }
            })

            setDashboardDucttapeChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardDucttapeChartDataFetched: false,
                }
            })

            setDashboardGuidancevalidationChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardGuidancevalidationChartDataFetched: false,
                }
            })

            setDashboardGuidancevalidationChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardGuidanceChartDataFetched: false,
                }
            })

            setDashboardGuidanceChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardGuidanceChartDataFetched: false,
                }
            })



            if (fetched_details.sectionVisibility.guideInsights_flag) {
                getDashboardValidationChartData(reqUUID);
                getDashboardPowerhtmlChartData(reqUUID);
                getDashboardDucttapeChartData(reqUUID);
                getDashboardGuidancevalidationChartData(reqUUID);
                getDashboardGuidanceChartData(reqUUID);
            }


            setDashboardGuideSummary((prevState: any) => {
                return {
                    ...prevState,
                    dashboardGuideSummaryFetched: false,
                    defaultSort: {
                        sortColumn_index: 3,
                        sortValue: "desc",
                    },
                    tablePagination: {
                        offset: 0,
                        data: [],
                        perPage: 10,
                        currentPage: 0,
                    },
                    limit: 10,
                    firstLoad: true
                };
            });
            enableExportPdf(5, false);


            setDashboardTooltipSummary((prevState: any) => {
                return {
                    ...prevState,
                    dashboardTooltipSummaryFetched: false,
                    defaultSort: {
                        sortColumn_index: 3,
                        sortValue: "desc",
                    },
                    tablePagination: {
                        offset: 0,
                        data: [],
                        perPage: 10,
                        currentPage: 0,
                    },
                    limit: 10,
                    firstLoad: true
                };
            });
            enableExportPdf(19, false);

            if (fetched_details.sectionVisibility.guideInsights_flag) {
                getDashboardGuideSummary(reqUUID);
                getDashboardTooltipSummary(reqUUID);
            }

            enableExportPdf(6, true);

            enableExportPdf(7, true);

            setDashboardSurveyData((prevState: any) => {
                return {
                    ...prevState,
                    dashboardSurveyDataFetched: false,
                    defaultSort: {
                        sortColumn_index: 3,
                        sortValue: "desc",
                    },
                    tablePagination: {
                        offset: 0,
                        data: [],
                        perPage: 10,
                        currentPage: 0,
                    },
                    limit: 10,
                    firstLoad: true
                };
            });
            enableExportPdf(8, false);
            getDashboardSurveysData(reqUUID);

            setDashboardCountriesChartData((prevState: any) => {
                return {
                    ...prevState,
                    dashboardCountriesChartDataFetched: false,
                    legendData: [],
                    boundaryValues: [],
                    colors: [],
                    countryData: [],
                };
            });
            enableExportPdf(9, false);
            getDashboardCountriesChartData(reqUUID);

            setDashboardTotalGuideCount((prevState) => {
                return {
                    ...prevState,
                    isLoading: false,
                    totalGuideCountFetched: false,
                    totalGuideCountData: []
                }
            });
            getDashboardGuidesCount(reqUUID)

            // setDashboardSurveyChart((prevState: any) => {
            //     return {
            //         ...prevState,
            //         dashboardSurveyChartDataFetched: false,
            //         surveyChart: {},
            //         surveyChartFetched: false,
            //     };
            // });
            // enableExportPdf(1, false);
            // getDashboardSurveyChartData();

            setDashboardUserEngagementChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardUserEngagementChartDataFetched: false,
                    userEngagementChartChart: {},
                    userEngagementChartFetched: false,
                    loadUserEngagementChartData: [],
                    loadUserEngagementChartOptions: [],
                };
            });
            getDashboardUserEngagementChartData(reqUUID);

        } catch (error) {
            showBoundary(error)
        }
    }

    const handleTableEvents = useCallback(
        (option: string, data: any) => {
            try {
                switch (option) {
                    case "routeToDetail":
                        if (data.guideID) {
                            setParameter((prevState: any) => {
                                return {
                                    ...prevState,
                                    parameter: data.guideID,
                                    option: data.tooltipGuideFlag ? ("/guide-insights/tooltips-detail/") : (!data.tutorialGuideFlag ? "/guide-insights/guide-analytics-detail/" : "/guide-insights/tutorial-guides-detail/"),
                                    source: data.guideTitle,
                                    appCode: data.appCode,
                                    appId: data.appId,
                                    appTitle: data.appTitle,
                                };
                            });

                        } else if (data.sentimentCode) {
                            if (data.appCode) {
                                setParameter((prevState: any) => {
                                    return {
                                        ...prevState,
                                        parameter: data.sentimentCode,
                                        appCode: data.appCode,
                                        appId: data.appId,
                                        appTitle: data.appTitle,
                                        isDeleted: data.isDeleted,
                                        option: "/survey/survey-details/",
                                        source: data.sentimentTitle,
                                    };
                                });
                            }
                        }
                        break;
                }
            } catch (error) {
                showBoundary(error)
            }

        },
        [dashboardSurveyData]
    );

    const handleSwitch = (e: any) => {
        try {
            setDashboardGuideMeChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardGuideMeChartDataFetched: false
                };
            });

            setDashboardShowMeChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardShowMeChartDataFetched: false
                };
            });

            setDashboardTestMeChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardTestMeChartDataFetched: false
                };
            });

            setDashboardDoItForMeChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardDoItForMeChartDataFetched: false
                };
            });

            setDashboardGuideTutorialsChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardGuideTutorialsChartDataFetched: false
                };
            });

            setDashboardTooltipChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardTooltipChartDataFetched: false
                };
            });

            setDashboardPushnotificationChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardPushnotificationChartDataFetched: false
                };
            });

            setDashboardBeaconChart((prevState: any) => {
                return {
                    ...prevState,
                    dashboardBeaconChartDataFetched: false
                };
            });

            setDashboardSurveyGraph((prevState: any) => {
                return {
                    ...prevState,
                    dashboardSurveyChartDataFetched: false
                };
            });

            setTabKey((prevState: any) => {
                return {
                    ...prevState,
                    key: e
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    useEffect(() => {
        try {
            switch (+tabKey.key) {
                case 1:

                    if (fetched_details?.dashboard?.dashboardGuideMeChartData.result == 'success') {
                        setDashboardGuideMeChart((prevState: any) => {
                            return {
                                ...prevState,
                                guideMeChartFetched: true,
                                dashboardGuideMeChartDataFetched: true
                            };
                        });
                    }
                    break;

                case 2:
                    setDashboardShowMeChart((prevState: any) => {
                        return {
                            ...prevState,
                            showMeChartFetched: true,
                            dashboardShowMeChartDataFetched: true
                        };
                    });
                    break;

                case 3:
                    setDashboardTestMeChart((prevState: any) => {
                        return {
                            ...prevState,
                            testMeChartFetched: true,
                            dashboardTestMeChartDataFetched: true
                        };
                    });


                    break;

                case 4:
                    setDashboardDoItForMeChart((prevState: any) => {
                        return {
                            ...prevState,
                            doItForMeChartFetched: true,
                            dashboardDoItForMeChartDataFetched: true
                        };
                    });


                    break;

                case 5:
                    setDashboardGuideTutorialsChart((prevState: any) => {
                        return {
                            ...prevState,
                            guideTutorialsChartFetched: true,
                            dashboardGuideTutorialsChartDataFetched: true
                        };
                    });


                    break;

                case 6:
                    setDashboardTooltipChart((prevState: any) => {
                        return {
                            ...prevState,
                            tooltipChartFetched: true,
                            dashboardTooltipChartDataFetched: true
                        };
                    });


                    break;

                case 7:
                    setDashboardPushnotificationChart((prevState: any) => {
                        return {
                            ...prevState,
                            pushnotificationChartFetched: true,
                            dashboardPushnotificationChartDataFetched: true
                        };
                    });


                    break;

                case 8:
                    setDashboardBeaconChart((prevState: any) => {
                        return {
                            ...prevState,
                            beaconChartFetched: true,
                            dashboardBeaconChartDataFetched: true
                        };
                    });


                    break;

                case 9:
                    setDashboardSurveyGraph((prevState: any) => {
                        return {
                            ...prevState,
                            surveyChartFetched: true,
                            dashboardSurveyChartDataFetched: true
                        };
                    });
                    break;

                default:
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [tabKey, fetched_details.dashboard.dashboardGuideMeChartData])

    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(optn, false);
            switch (optn) {
                case 0:
                    setDashboardGuideMeChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardGuideMeChartDataFetched: false,
                            guideMeChartFetched: false,
                            guideMeChart: {}
                        };
                    });

                    setDashboardShowMeChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardShowMeChartDataFetched: false,
                            showMeChartFetched: false,
                            showMeChart: {}
                        };
                    });

                    setDashboardTestMeChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardTestMeChartDataFetched: false,
                            testMeChartFetched: false,
                            testMeChart: {}
                        };
                    });

                    setDashboardDoItForMeChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardDoItForMeChartDataFetched: false,
                            doItForMeChartFetched: false,
                            doItForMeChart: {}
                        };
                    });


                    setDashboardGuideTutorialsChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardGuideTutorialsChartDataFetched: false,
                            guideTutorialsChartFetched: false,
                            guideTutorialsChart: {}
                        };
                    });

                    setDashboardTooltipChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardTooltipChartDataFetched: false,
                            tooltipChartFetched: false,
                            tooltipChart: {}
                        };
                    });

                    setDashboardPushnotificationChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardPushnotificationChartDataFetched: false,
                            pushnotificationChartFetched: false,
                            pushnotificationChart: {}
                        };
                    });

                    setDashboardBeaconChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardBeaconChartDataFetched: false,
                            beaconChartFetched: false,
                            beaconChart: {}
                        };
                    });

                    setDashboardSurveyGraph((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardSurveyChartDataFetched: false,
                            surveyChartFetched: false,
                            surveyChart: {}
                        };
                    });

                    setDashboardValidationChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardValidationChartDataFetched: false,
                        }
                    })

                    setDashboardPowerhtmlChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardPowerhtmlChartDataFetched: false,
                        }
                    })

                    setDashboardDucttapeChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardDucttapeChartDataFetched: false,
                        }
                    })

                    setDashboardGuidancevalidationChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardGuidancevalidationChartDataFetched: false,
                        }
                    })

                    setDashboardGuidancevalidationChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardGuidanceChartDataFetched: false,
                        }
                    })

                    setDashboardGuidanceChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardGuidanceChartDataFetched: false,
                        }
                    })

                    setDashboardTotalGuideCount((prevState) => {
                        return {
                            ...prevState,
                            isLoading: false,
                            totalGuideCountFetched: false,
                            totalGuideCountData: []
                        }
                    });

                    getDashboardGuidesCount(request_uuid.value)
                    getDashboardGuideMeChartData(request_uuid.value);
                    if (process.env.REACT_APP_WESTPAC === 'false') {
                        getDashboardShowMeChartData(request_uuid.value);
                        getDashboardTestMeChartData(request_uuid.value);
                        getDashboardDoItForMeChartData(request_uuid.value);
                    }
                    getDashboardGuideTutorialsChartData(request_uuid.value);
                    getDashboardTooltipChartData(request_uuid.value);
                    getDashboardPushnotificationChartData(request_uuid.value);
                    getDashboardBeaconChartData(request_uuid.value);
                    getDashboardSurveyGraphData(request_uuid.value);
                    getDashboardValidationChartData(request_uuid.value);
                    getDashboardPowerhtmlChartData(request_uuid.value);
                    getDashboardDucttapeChartData(request_uuid.value);
                    getDashboardGuidancevalidationChartData(request_uuid.value);
                    getDashboardGuidanceChartData(request_uuid.value);
                    getDashboardGuidesCount(request_uuid.value);
                    break;

                case 1:
                    break;

                case 2:
                    setDashboardSurveyData((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardSurveyDataFetched: false,
                        }
                    })
                    getDashboardSurveysData(request_uuid.value);
                    break;
                case 3:
                    setDashboardCountriesChartData((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardCountriesChartDataFetched: false
                        }
                    })

                    getDashboardCountriesChartData(request_uuid.value);
                    break;
                case 4:
                    setDashboardGuideSummary((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardGuideSummaryFetched: false,
                        }
                    })
                    getDashboardGuideSummary(request_uuid.value);
                    break;

                case 5:
                    setDashboardUserEngagementChart((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardUserEngagementChartDataFetched: false,
                            userEngagementChartFetched: false,
                            userEngagementChart: {}
                        };
                    });
                    getDashboardUserEngagementChartData(request_uuid.value);

                    break;

                case 6:
                    setDashboardTooltipSummary((prevState: any) => {
                        return {
                            ...prevState,
                            dashboardTooltipSummaryFetched: false,
                        }
                    })
                    getDashboardTooltipSummary(request_uuid.value);
                    break;
                default:
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }
    }

    const closeExportModal = () => {
        try {
            setExportModal((prevState: any) => {
                return {
                    ...prevState,
                    open: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    const openExportModal = (event: any) => {
        try {
            event.stopPropagation();
            setExportModal((prevState: any) => {
                return {
                    ...prevState,
                    open: true,
                    defaultValue: 'player'
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const getErrorFlag = (flag: boolean) => {
        try {
            setExportModal((prevState: any) => {
                return {
                    ...prevState,
                    exportDisabled: flag,
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    const getDates = (data: any) => {
        try {
            setExportModal((prevState: any) => {
                return {
                    ...prevState,
                    start_date: data.start_date,
                    end_date: data.end_date,
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const handleExpansion = (event: any) => {
        try {
            setDashboardGuideMeChart((prevState: any) => {
                return {
                    ...prevState,
                    guideMeChartFetched: true,
                    dashboardGuideMeChartDataFetched: true
                };
            });

            setDashboardShowMeChart((prevState: any) => {
                return {
                    ...prevState,
                    showMeChartFetched: true,
                    dashboardShowMeChartDataFetched: true
                };
            });

            setDashboardTestMeChart((prevState: any) => {
                return {
                    ...prevState,
                    testMeChartFetched: true,
                    dashboardTestMeChartDataFetched: true
                };
            });

            setDashboardDoItForMeChart((prevState: any) => {
                return {
                    ...prevState,
                    doItForMeChartFetched: true,
                    dashboardDoItForMeChartDataFetched: true
                };
            });

            setDashboardGuideTutorialsChart((prevState: any) => {
                return {
                    ...prevState,
                    guideTutorialsChartFetched: true,
                    dashboardGuideTutorialsChartDataFetched: true
                };
            });

            setDashboardTooltipChart((prevState: any) => {
                return {
                    ...prevState,
                    tooltipChartFetched: true,
                    dashboardTooltipChartDataFetched: true
                };
            });

            setDashboardPushnotificationChart((prevState: any) => {
                return {
                    ...prevState,
                    pushnotificationChartFetched: true,
                    dashboardPushnotificationChartDataFetched: true
                };
            });

            setDashboardBeaconChart((prevState: any) => {
                return {
                    ...prevState,
                    beaconChartFetched: true,
                    dashboardBeaconChartDataFetched: true
                };
            });

            setDashboardSurveyGraph((prevState: any) => {
                return {
                    ...prevState,
                    surveyChartFetched: true,
                    dashboardSurveyChartDataFetched: true
                };
            });

            setExpanded((prevState) => {
                return !prevState;
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    const tooltipText = (selected: boolean) => {
        return (<Tooltip id="tooltipText">
            <br></br>
            <div><span className="fontGreyCard">{!selected ? insightsLbls.expandViews : insightsLbls.collapseViews}</span></div>
            <br></br>
        </Tooltip>)
    }

    const getEventSourceValues = (type: string, eventSources: any) => {
        try {
            setExportModal((prevState: any) => {
                return {
                    ...prevState,
                    event_source: eventSources,
                    defaultValue: eventSources
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    const getSelectedAppsForExport = (data: any) => {
        try {
            let paramsString = '';

            let itr = 0;
            for (let item of data) {
                if (itr !== 0) {
                    paramsString += "&app_id=" + item['app_id'];
                } else {
                    paramsString += item['app_id']
                }
                itr++;
            }

            setExportModal((prevState: any) => {
                return {
                    ...prevState,
                    app_id: paramsString,
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    //GuideMe
    useEffect(() => {
        try {
            const dashboardData = fetched_details?.dashboard?.dashboardGuideMeChartData;
            if (
                dashboardData?.data !== undefined &&
                dashboardData?.result === "success" &&
                dashboardData?.requestUUID === request_uuid.value
            ) {
                GuideMeMount.current = true;

                const graphData: any[] = dashboardData.data.graphData || [];
                const legends: any[] = dashboardData.data.legends || [];

                // Prepare guideMeChartOptions
                const guideMeChartOptions = legends.map(legend => ({
                    key: legend.legendTitle?.trim(),
                    color: legend.color,
                    area: legend.area,
                    associatedDataPoint: legend.associatedDataPoint,
                }));

                // Calculate counts for legends
                legends.forEach(legend => {
                    legend.count = graphData.reduce((sum, data) =>
                        sum + (data.counts[legend.associatedDataPoint] || 0),
                        0);
                });

                // Prepare data for dashboard state
                const chartDataEntities = legends.map(legend => legend.legendTitle);
                const optionDataEntities = legends.map(legend => ({
                    key: legend.legendTitle,
                    color: legend.color,
                }));
                const chartDataValues = {
                    counts: legends.reduce((acc, legend) => {
                        acc[legend.legendTitle] = legend.count;
                        return acc;
                    }, {} as Record<string, number>),
                };

                setDashboardGuideMeChart(prevState => ({
                    ...prevState,
                    loadGuideMeChartData: [...graphData],
                    loadGuideMeChartOptions: guideMeChartOptions,
                    guideMeChartFetched: true,
                    dashboardGuideMeChartDataFetched: true,
                    bubbleChartData: legends,
                    selectedAppCount: dashboardData.data.metadata?.length || 0,
                    chartData_entites: chartDataEntities,
                    optionData_entites: optionDataEntities,
                    chartData_values: chartDataValues,
                }));

                apiCatchError(0, 0);
                enableExportPdf(0, true);
            } else if (dashboardData?.result === "retry") {
                getDashboardGuideMeChartData(request_uuid.value, "retry");
            } else if (dashboardData?.result === "error") {
                setDashboardGuideMeChart(prevState => ({
                    ...prevState,
                    dashboardGuideMeChartDataFetched: true,
                }));

                const errorCode = dashboardData.errorCode || 1;
                apiCatchError(0, errorCode === 503 ? 503 : 1);
                enableExportPdf(0, false);
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details?.dashboard?.dashboardGuideMeChartData]);

    //ShowMe
    useEffect(() => {
        try {
            const showMeData = fetched_details?.dashboard?.dashboardShowMeChartData;
            if (
                showMeData?.data !== undefined &&
                showMeData?.result === "success" &&
                showMeData?.requestUUID === request_uuid.value
            ) {
                ShowMeMount.current = true;

                const graphData: any[] = showMeData.data.graphData || [];
                const legends: any[] = showMeData.data.legends || [];

                // Prepare showMeChartOptions
                const showMeChartOptions = legends.map(legend => ({
                    key: legend.legendTitle?.trim(),
                    color: legend.color,
                    area: legend.area,
                    associatedDataPoint: legend.associatedDataPoint,
                }));

                // Calculate counts for legends
                legends.forEach(legend => {
                    legend.count = graphData.reduce(
                        (sum, data) => sum + (data.counts[legend.associatedDataPoint] || 0),
                        0
                    );
                });

                // Prepare data for dashboard state
                const chartDataEntities = legends.map(legend => legend.legendTitle);
                const optionDataEntities = legends.map(legend => ({
                    key: legend.legendTitle,
                    color: legend.color,
                }));
                const chartDataValues = {
                    counts: legends.reduce((acc, legend) => {
                        acc[legend.legendTitle] = legend.count;
                        return acc;
                    }, {} as Record<string, number>),
                };

                setDashboardShowMeChart(prevState => ({
                    ...prevState,
                    loadShowMeChartData: [...graphData],
                    loadShowMeChartOptions: showMeChartOptions,
                    showMeChartFetched: true,
                    bubbleChartData: legends,
                    dashboardShowMeChartDataFetched: true,
                    selectedAppCount: showMeData.data.metadata?.length || 0,
                    chartData_entites: chartDataEntities,
                    optionData_entites: optionDataEntities,
                    chartData_values: chartDataValues,
                }));

                apiCatchError(1, 0);
                enableExportPdf(1, true);
            } else if (showMeData?.result === "retry") {
                getDashboardShowMeChartData(request_uuid.value, "retry");
            } else if (showMeData?.result === "error") {
                setDashboardShowMeChart(prevState => ({
                    ...prevState,
                    dashboardShowMeChartDataFetched: true,
                }));

                const errorCode = showMeData.errorCode || 1;
                apiCatchError(1, errorCode === 503 ? 503 : 1);
                enableExportPdf(1, false);
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details?.dashboard?.dashboardShowMeChartData]);

    //TestMe
    useEffect(() => {
        try {
            const testMeData = fetched_details?.dashboard?.dashboardTestMeChartData;

            if (
                testMeData?.data !== undefined &&
                testMeData?.result === "success" &&
                testMeData?.requestUUID === request_uuid.value
            ) {
                TestMeMount.current = true;

                const graphData: any[] = testMeData.data.graphData || [];
                const legends: any[] = testMeData.data.legends || [];

                // Prepare chart options
                const testMeChartOptions = legends.map(legend => ({
                    key: legend.legendTitle?.trim(),
                    color: legend.color,
                    area: legend.area,
                    associatedDataPoint: legend.associatedDataPoint,
                }));

                // Calculate counts for legends
                legends.forEach(legend => {
                    legend.count = graphData.reduce(
                        (sum, data) => sum + (data.counts[legend.associatedDataPoint] || 0),
                        0
                    );
                });

                // Prepare chart data for state
                const chartDataEntities = legends.map(legend => legend.legendTitle);
                const optionDataEntities = legends.map(legend => ({
                    key: legend.legendTitle,
                    color: legend.color,
                }));
                const chartDataValues = {
                    counts: legends.reduce((acc, legend) => {
                        acc[legend.legendTitle] = legend.count;
                        return acc;
                    }, {} as Record<string, number>),
                };

                // Update state with the processed data
                setDashboardTestMeChart(prevState => ({
                    ...prevState,
                    loadTestMeChartData: [...graphData],
                    loadTestMeChartOptions: testMeChartOptions,
                    dashboardTestMeChartDataFetched: true,
                    testMeChartFetched: true,
                    bubbleChartData: legends,
                    selectedAppCount: testMeData.data.metadata?.length || 0,
                    chartData_entites: chartDataEntities,
                    optionData_entites: optionDataEntities,
                    chartData_values: chartDataValues,
                }));

                apiCatchError(2, 0);
                enableExportPdf(2, true);

            } else if (testMeData?.result === "retry") {
                getDashboardTestMeChartData(request_uuid.value, "retry");
            } else if (testMeData?.result === "error") {
                setDashboardTestMeChart(prevState => ({
                    ...prevState,
                    dashboardTestMeChartDataFetched: true,
                }));
                const errorCode = testMeData.errorCode || 1;
                apiCatchError(2, errorCode === 503 ? 503 : 1);
                enableExportPdf(2, false);
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details?.dashboard?.dashboardTestMeChartData]);

    //DoItForMe
    useEffect(() => {
        try {
            const doItForMeData = fetched_details?.dashboard?.dashboardDoItForMeChartData;

            if (
                doItForMeData?.data !== undefined &&
                doItForMeData?.result === "success" &&
                doItForMeData?.requestUUID === request_uuid.value
            ) {
                DoItForMeMount.current = true;

                const graphData: any[] = doItForMeData.data.graphData || [];
                const legends: any[] = doItForMeData.data.legends || [];

                // Prepare chart options
                const doItForMeChartOptions = legends.map(legend => ({
                    key: legend.legendTitle?.trim(),
                    color: legend.color,
                    area: legend.area,
                    associatedDataPoint: legend.associatedDataPoint,
                }));

                // Calculate counts for legends
                legends.forEach(legend => {
                    legend.count = graphData.reduce(
                        (sum, data) => sum + (data.counts[legend.associatedDataPoint] || 0),
                        0
                    );
                });

                // Prepare chart data for state
                const chartDataEntities = legends.map(legend => legend.legendTitle);
                const optionDataEntities = legends.map(legend => ({
                    key: legend.legendTitle,
                    color: legend.color,
                }));
                const chartDataValues = {
                    counts: legends.reduce((acc, legend) => {
                        acc[legend.legendTitle] = legend.count;
                        return acc;
                    }, {} as Record<string, number>),
                };

                // Update state with the processed data
                setDashboardDoItForMeChart(prevState => ({
                    ...prevState,
                    loadDoItForMeChartData: [...graphData],
                    loadDoItForMeChartOptions: doItForMeChartOptions,
                    doItForMeChartFetched: true,
                    dashboardDoItForMeChartDataFetched: true,
                    bubbleChartData: legends,
                    selectedAppCount: doItForMeData.data.metadata?.length || 0,
                    chartData_entites: chartDataEntities,
                    optionData_entites: optionDataEntities,
                    chartData_values: chartDataValues,
                }));

                apiCatchError(3, 0);
                enableExportPdf(3, true);
            } else if (doItForMeData?.result === "retry") {
                getDashboardDoItForMeChartData(request_uuid.value, "retry");
            } else if (doItForMeData?.result === "error") {
                setDashboardDoItForMeChart(prevState => ({
                    ...prevState,
                    dashboardDoItForMeChartDataFetched: true,
                }));

                const errorCode = doItForMeData.errorCode || 1;
                apiCatchError(3, errorCode === 503 ? 503 : 1);
                enableExportPdf(3, false);
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details?.dashboard?.dashboardDoItForMeChartData]);

    //GuideTutorials
    useEffect(() => {
        try {
            const guideTutorialsData = fetched_details?.dashboard?.dashboardGuideTutorialsChartData;

            if (
                guideTutorialsData?.data !== undefined &&
                guideTutorialsData?.result === "success" &&
                guideTutorialsData?.requestUUID === request_uuid.value
            ) {
                GuideTutorialsMount.current = true;

                const graphData: any[] = guideTutorialsData.data.graphData || [];
                const legends: any[] = guideTutorialsData.data.legends || [];

                // Prepare chart options
                const guideTutorialsChartOptions = legends.map(legend => ({
                    key: legend.legendTitle?.trim(),
                    color: legend.color,
                    area: legend.area,
                    associatedDataPoint: legend.associatedDataPoint,
                }));

                // Calculate counts for legends
                legends.forEach(legend => {
                    legend.count = graphData.reduce(
                        (sum, data) => sum + (data.counts[legend.associatedDataPoint] || 0),
                        0
                    );
                });

                // Prepare chart data for state
                const chartDataEntities = legends.map(legend => legend.legendTitle);
                const optionDataEntities = legends.map(legend => ({
                    key: legend.legendTitle,
                    color: legend.color,
                }));
                const chartDataValues = {
                    counts: legends.reduce((acc, legend) => {
                        acc[legend.legendTitle] = legend.count;
                        return acc;
                    }, {} as Record<string, number>),
                };

                // Update state with processed data
                setDashboardGuideTutorialsChart(prevState => ({
                    ...prevState,
                    loadGuideTutorialsChartData: [...graphData],
                    loadGuideTutorialsChartOptions: guideTutorialsChartOptions,
                    guideTutorialsChartFetched: true,
                    dashboardGuideTutorialsChartDataFetched: true,
                    bubbleChartData: legends,
                    selectedAppCount: guideTutorialsData.data.metadata?.length || 0,
                    chartData_entites: chartDataEntities,
                    optionData_entites: optionDataEntities,
                    chartData_values: chartDataValues,
                }));

                apiCatchError(4, 0);
                enableExportPdf(4, true);
            } else if (guideTutorialsData?.result === "retry") {
                getDashboardGuideTutorialsChartData(request_uuid.value, "retry");
            } else if (guideTutorialsData?.result === "error") {
                setDashboardGuideTutorialsChart(prevState => ({
                    ...prevState,
                    dashboardGuideTutorialsChartDataFetched: true,
                }));

                const errorCode = guideTutorialsData.errorCode || 1;
                apiCatchError(4, errorCode === 503 ? 503 : 1);
                enableExportPdf(4, false);
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details?.dashboard?.dashboardGuideTutorialsChartData]);

    // TooltipData
    useEffect(() => {
        try {
            const tooltipData = fetched_details?.dashboard?.dashboardTooltipChartData;

            if (
                tooltipData?.data !== undefined &&
                tooltipData?.result === "success" &&
                tooltipData?.requestUUID === request_uuid.value
            ) {
                const graphData: any[] = tooltipData.data.graphData || [];
                const legends: any[] = tooltipData.data.legends || [];

                // Prepare chart options
                const tooltipChartOptions = legends.map(legend => ({
                    key: legend.legendTitle?.trim(),
                    color: legend.color,
                    area: legend.area,
                    associatedDataPoint: legend.associatedDataPoint,
                }));

                // Calculate counts for legends
                legends.forEach(legend => {
                    legend.count = graphData.reduce(
                        (sum, data) => sum + (data.counts[legend.associatedDataPoint] || 0),
                        0
                    );
                });

                // Prepare chart data for state
                const chartDataEntities = legends.map(legend => legend.legendTitle);
                const optionDataEntities = legends.map(legend => ({
                    key: legend.legendTitle,
                    color: legend.color,
                }));
                const chartDataValues = {
                    counts: legends.reduce((acc, legend) => {
                        acc[legend.legendTitle] = legend.count;
                        return acc;
                    }, {} as Record<string, number>),
                };

                // Update state with processed data
                setDashboardTooltipChart(prevState => ({
                    ...prevState,
                    loadTooltipChartData: [...graphData],
                    loadTooltipChartOptions: tooltipChartOptions,
                    tooltipChartFetched: true,
                    dashboardTooltipChartDataFetched: true,
                    bubbleChartData: legends,
                    selectedAppCount: tooltipData.data.metadata?.length || 0,
                    chartData_entites: chartDataEntities,
                    optionData_entites: optionDataEntities,
                    chartData_values: chartDataValues,
                }));

                apiCatchError(10, 0);
                enableExportPdf(10, true);
            } else if (tooltipData?.result === "retry") {
                getDashboardTooltipChartData(request_uuid.value, "retry");
            } else if (tooltipData?.result === "error") {
                setDashboardTooltipChart(prevState => ({
                    ...prevState,
                    dashboardTooltipChartDataFetched: true,
                }));

                const errorCode = tooltipData.errorCode || 1;
                apiCatchError(10, errorCode === 503 ? 503 : 1);
                enableExportPdf(10, false);
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details?.dashboard?.dashboardTooltipChartData]);

    // PushnotificationData
    useEffect(() => {
        const processChartData = (chartData, legends) => {
            const chartOptions = legends.map(({ legendTitle, color, area, associatedDataPoint }) => ({
                key: legendTitle?.trim(),
                color,
                area,
                associatedDataPoint,
            }));

            const chartDataValues = legends.reduce((acc, { legendTitle, associatedDataPoint }) => {
                acc.counts[legendTitle] = chartData.reduce(
                    (sum, data) => sum + data.counts[associatedDataPoint],
                    0
                );
                return acc;
            }, { counts: {} });

            const chartDataEntities = legends.map(({ legendTitle }) => legendTitle);
            const optionDataEntities = legends.map(({ legendTitle, color }) => ({
                key: legendTitle,
                color,
            }));

            return {
                chartOptions,
                chartDataEntities,
                optionDataEntities,
                chartDataValues,
            };
        };

        try {
            const { dashboardPushnotificationChartData } = fetched_details.dashboard;
            const { data, result, requestUUID } = dashboardPushnotificationChartData;

            if (data && result === "success" && requestUUID === request_uuid.value) {
                const { chartOptions, chartDataEntities, optionDataEntities, chartDataValues } = processChartData(
                    data.graphData,
                    data.legends
                );

                setDashboardPushnotificationChart((prevState) => ({
                    ...prevState,
                    loadPushnotificationChartData: data.graphData,
                    loadPushnotificationChartOptions: chartOptions,
                    pushnotificationChartFetched: true,
                    dashboardPushnotificationChartDataFetched: true,
                    bubbleChartData: data.legends,
                    selectedAppCount: data.metadata?.length,
                    chartData_entites: chartDataEntities,
                    optionData_entites: optionDataEntities,
                    chartData_values: chartDataValues,
                }));

                apiCatchError(11, 0);
                enableExportPdf(11, true);
            } else if (result === "retry") {
                getDashboardPushnotificationChartData(request_uuid.value, 'retry');
            } else if (result === "error") {
                setDashboardPushnotificationChart((prevState) => ({
                    ...prevState,
                    dashboardPushnotificationChartDataFetched: true,
                }));
                const errorCode = dashboardPushnotificationChartData.errorCode;
                apiCatchError(11, errorCode === 503 ? 503 : 1);
                enableExportPdf(11, false);
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details.dashboard.dashboardPushnotificationChartData]);

    // Beacon
    useEffect(() => {
        const processChartData = (chartData, legends) => {
            const chartOptions = legends.map(({ legendTitle, color, area, associatedDataPoint }) => ({
                key: legendTitle?.trim(),
                color,
                area,
                associatedDataPoint,
            }));

            const chartDataValues = legends.reduce((acc, { legendTitle, associatedDataPoint }) => {
                acc.counts[legendTitle] = chartData.reduce(
                    (sum, data) => sum + data.counts[associatedDataPoint],
                    0
                );
                return acc;
            }, { counts: {} });

            const chartDataEntities = legends.map(({ legendTitle }) => legendTitle);
            const optionDataEntities = legends.map(({ legendTitle, color }) => ({
                key: legendTitle,
                color,
            }));

            return {
                chartOptions,
                chartDataEntities,
                optionDataEntities,
                chartDataValues,
            };
        };

        try {
            const { dashboardBeaconChartData } = fetched_details.dashboard;
            const { data, result, requestUUID } = dashboardBeaconChartData;

            if (data && result === "success" && requestUUID === request_uuid.value) {
                const { chartOptions, chartDataEntities, optionDataEntities, chartDataValues } = processChartData(
                    data.graphData,
                    data.legends
                );

                setDashboardBeaconChart((prevState) => ({
                    ...prevState,
                    loadBeaconChartData: data.graphData,
                    loadBeaconChartOptions: chartOptions,
                    beaconChartFetched: true,
                    dashboardBeaconChartDataFetched: true,
                    bubbleChartData: data.legends,
                    selectedAppCount: data.metadata?.length,
                    chartData_entites: chartDataEntities,
                    optionData_entites: optionDataEntities,
                    chartData_values: chartDataValues,
                }));

                apiCatchError(12, 0);
                enableExportPdf(12, true);
            } else if (result === "retry") {
                getDashboardBeaconChartData(request_uuid.value, 'retry');
            } else if (result === "error") {
                setDashboardBeaconChart((prevState) => ({
                    ...prevState,
                    dashboardBeaconChartDataFetched: true,
                }));
                const errorCode = dashboardBeaconChartData.errorCode;
                apiCatchError(12, errorCode === 503 ? 503 : 1);
                enableExportPdf(12, false);
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details.dashboard.dashboardBeaconChartData]);


    useEffect(() => {
        const processSurveyChartData = (chartData, legends) => {
            const chartOptions = legends.map(({ legendTitle, color, area, associatedDataPoint }) => ({
                key: legendTitle?.trim(),
                color,
                area,
                associatedDataPoint,
            }));

            const chartDataValues = legends.reduce((acc, { legendTitle, associatedDataPoint }) => {
                acc.counts[legendTitle] = chartData.reduce(
                    (sum, data) => sum + data.counts[associatedDataPoint],
                    0
                );
                return acc;
            }, { counts: {} });

            const chartDataEntities = legends.map(({ legendTitle }) => legendTitle);
            const optionDataEntities = legends.map(({ legendTitle, color }) => ({
                key: legendTitle,
                color,
            }));

            return {
                chartOptions,
                chartDataEntities,
                optionDataEntities,
                chartDataValues,
            };
        };

        try {
            const { dashboardSurveyChartData } = fetched_details.dashboard;
            const { data, result, requestUUID } = dashboardSurveyChartData;

            if (data && result === "success" && requestUUID === request_uuid.value) {
                const { chartOptions, chartDataEntities, optionDataEntities, chartDataValues } = processSurveyChartData(
                    data.graphData,
                    data.legends
                );

                setDashboardSurveyGraph((prevState) => ({
                    ...prevState,
                    loadSurveyChartData: data.graphData,
                    loadSurveyChartOptions: chartOptions,
                    dashboardSurveyChartDataFetched: true,
                    SurveyChartFetched: true,
                    bubbleChartData: data.legends,
                    selectedAppCount: data.metadata?.length,
                    chartData_entites: chartDataEntities,
                    optionData_entites: optionDataEntities,
                    chartData_values: chartDataValues,
                }));

                apiCatchError(13, 0);
                enableExportPdf(13, true);
            } else if (result === "retry") {
                getDashboardSurveyGraphData(request_uuid.value, 'retry');
            } else if (result === "error") {
                setDashboardSurveyGraph((prevState) => ({
                    ...prevState,
                    dashboardSurveyChartDataFetched: true,
                }));
                apiCatchError(13, 1);
                enableExportPdf(13, false);
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details.dashboard.dashboardSurveyChartData]);

    useEffect(() => {
        const dashboardValidationData = fetched_details?.dashboard?.dashboardValidationChartData;

        const handleSetDashboardValidation = (fetched: boolean, errorCode: number, enablePdf: boolean) => {
            setDashboardValidationChart(prevState => ({
                ...prevState,
                dashboardValidationChartDataFetched: fetched,
            }));
            apiCatchError(14, errorCode);
            enableExportPdf(14, enablePdf);
        };

        try {
            if (
                dashboardValidationData?.data !== undefined &&
                dashboardValidationData?.result === "success" &&
                dashboardValidationData?.requestUUID === request_uuid.value
            ) {
                handleSetDashboardValidation(true, 0, true);
            } else if (dashboardValidationData?.result === 'retry') {
                getDashboardValidationChartData(request_uuid.value, 'retry');
            } else if (dashboardValidationData?.result === "error") {
                handleSetDashboardValidation(true, 1, false);
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details?.dashboard?.dashboardValidationChartData]);

    useEffect(() => {
        const dashboardPowerhtmlData = fetched_details?.dashboard?.dashboardPowerhtmlChartData;

        const handleSetDashboardPowerhtml = (fetched: boolean, errorCode: number, enablePdf: boolean) => {
            setDashboardPowerhtmlChart(prevState => ({
                ...prevState,
                dashboardPowerhtmlChartDataFetched: fetched,
            }));
            apiCatchError(15, errorCode);
            enableExportPdf(15, enablePdf);
        };

        try {
            if (
                dashboardPowerhtmlData?.data !== undefined &&
                dashboardPowerhtmlData?.result === "success" &&
                dashboardPowerhtmlData?.requestUUID === request_uuid.value
            ) {
                handleSetDashboardPowerhtml(true, 0, true);
            } else if (dashboardPowerhtmlData?.result === 'retry') {
                getDashboardPowerhtmlChartData(request_uuid.value, 'retry');
            } else if (dashboardPowerhtmlData?.result === "error") {
                handleSetDashboardPowerhtml(true, 1, false);
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details?.dashboard?.dashboardPowerhtmlChartData]);

    //DuctTape
    useEffect(() => {
        const dashboardDucttapeData = fetched_details?.dashboard?.dashboardDucttapeChartData;

        const handleSetDashboardDucttape = (fetched: boolean, errorCode: number, enablePdf: boolean) => {
            setDashboardDucttapeChart(prevState => ({
                ...prevState,
                dashboardDucttapeChartDataFetched: fetched,
            }));
            apiCatchError(16, errorCode);
            enableExportPdf(16, enablePdf);
        };

        try {
            if (
                dashboardDucttapeData?.data !== undefined &&
                dashboardDucttapeData?.result === "success" &&
                dashboardDucttapeData?.requestUUID === request_uuid.value
            ) {
                handleSetDashboardDucttape(true, 0, true);
            } else if (dashboardDucttapeData?.result === 'retry') {
                getDashboardDucttapeChartData(request_uuid.value, 'retry');
            } else if (dashboardDucttapeData?.result === "error") {
                handleSetDashboardDucttape(true, 1, false);
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details?.dashboard?.dashboardDucttapeChartData]);


    //GuidanceValidation
    useEffect(() => {
        const dashboardGuidanceValidationData = fetched_details?.dashboard?.dashboardGuidancevalidationChartData;

        const handleSetDashboardGuidanceValidation = (fetched: boolean, errorCode: number, enablePdf: boolean) => {
            setDashboardGuidancevalidationChart(prevState => ({
                ...prevState,
                dashboardGuidancevalidationChartDataFetched: fetched,
            }));
            apiCatchError(17, errorCode);
            enableExportPdf(17, enablePdf);
        };

        try {
            if (
                dashboardGuidanceValidationData?.data !== undefined &&
                dashboardGuidanceValidationData?.result === "success" &&
                dashboardGuidanceValidationData?.requestUUID === request_uuid.value
            ) {
                handleSetDashboardGuidanceValidation(true, 0, true);
            } else if (dashboardGuidanceValidationData?.result === 'retry') {
                getDashboardGuidancevalidationChartData(request_uuid.value, 'retry');
            } else if (dashboardGuidanceValidationData?.result === "error") {
                handleSetDashboardGuidanceValidation(true, 1, false);
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details?.dashboard?.dashboardGuidancevalidationChartData]);


    //Guidance
    useEffect(() => {
        const dashboardGuidanceData = fetched_details?.dashboard?.dashboardGuidanceChartData;

        const handleSetDashboardGuidance = (fetched: boolean, errorCode: number, enablePdf: boolean) => {
            setDashboardGuidanceChart(prevState => ({
                ...prevState,
                dashboardGuidanceChartDataFetched: fetched,
            }));
            apiCatchError(18, errorCode);
            enableExportPdf(18, enablePdf);
        };

        try {
            if (
                dashboardGuidanceData?.data !== undefined &&
                dashboardGuidanceData?.result === "success" &&
                dashboardGuidanceData?.requestUUID === request_uuid.value
            ) {
                handleSetDashboardGuidance(true, 0, true);
            } else if (dashboardGuidanceData?.result === 'retry') {
                getDashboardGuidanceChartData(request_uuid.value, 'retry');
            } else if (dashboardGuidanceData?.result === "error") {
                handleSetDashboardGuidance(true, 1, false);
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details?.dashboard?.dashboardGuidanceChartData]);

    //Guide Summary Table Data
    useEffect(() => {
        const guideSummaryData = fetched_details?.dashboard?.dashboardGuideSummaryTableData;

        const handleSetDashboardGuideSummary = (fetched: boolean, errorCode: number, enablePdf: boolean) => {
            setDashboardGuideSummary(prevState => ({
                ...prevState,
                dashboardGuideSummaryFetched: fetched,
            }));
            apiCatchError(5, errorCode);
            enableExportPdf(5, enablePdf);
        };

        try {
            if (
                guideSummaryData?.data !== undefined &&
                guideSummaryData?.result === "success" &&
                guideSummaryData?.requestUUID === request_uuid.value
            ) {
                GuideSummarysMount.current = true;
                handleSetDashboardGuideSummary(true, 0, true);
            } else if (guideSummaryData?.result === 'retry') {
                getDashboardGuideSummary(request_uuid.value, 'retry');
            } else if (guideSummaryData?.result === "error") {
                handleSetDashboardGuideSummary(true, 1, false);
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details?.dashboard?.dashboardGuideSummaryTableData]);



    //Tooltip Summary Table Data
    useEffect(() => {
        const tooltipSummaryData = fetched_details?.dashboard?.dashboardTooltipSummaryTableData;

        const handleSetDashboardTooltipSummary = (fetched: boolean, errorCode: number, enablePdf: boolean) => {
            setDashboardTooltipSummary(prevState => ({
                ...prevState,
                dashboardTooltipSummaryFetched: fetched,
            }));
            apiCatchError(19, errorCode);
            enableExportPdf(19, enablePdf);
        };

        try {
            if (
                tooltipSummaryData?.data !== undefined &&
                tooltipSummaryData?.result === "success" &&
                tooltipSummaryData?.requestUUID === request_uuid.value
            ) {
                handleSetDashboardTooltipSummary(true, 0, true);
            } else if (tooltipSummaryData?.result === 'retry') {
                getDashboardTooltipSummary(request_uuid.value, 'retry');
            } else if (tooltipSummaryData?.result === "error") {
                handleSetDashboardTooltipSummary(true, 1, false);
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details?.dashboard?.dashboardTooltipSummaryTableData]);

    // Survey table data
    useEffect(() => {
        const surveysData = fetched_details?.dashboard?.dashboardSurveysTableData;

        const handleSetDashboardSurveyData = (fetched: boolean, errorCode: number, enablePdf: boolean) => {
            setDashboardSurveyData(prevState => ({
                ...prevState,
                dashboardSurveyDataFetched: fetched,
            }));
            apiCatchError(8, errorCode);
            enableExportPdf(8, enablePdf);
        };

        try {
            if (
                surveysData?.data !== undefined &&
                surveysData?.result === "success" &&
                surveysData?.requestUUID === request_uuid.value
            ) {
                SurveysMount.current = true;
                handleSetDashboardSurveyData(true, 0, true);
            } else if (surveysData?.result === 'retry') {
                getDashboardSurveysData(request_uuid.value, 'retry');
            } else if (surveysData?.result === "error") {
                handleSetDashboardSurveyData(true, 1, false);
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details?.dashboard?.dashboardSurveysTableData]);

    // Countries chart data
    useEffect(() => {
        const dashboardData = fetched_details?.dashboard?.dashboardCountriesChartData;
        const dashboardState = dashboardData?.data;
        const bucketData = dashboardState ? dashboardState.locationData : [];

        const handleSetDashboardCountriesChartData = (
            legendData: any[],
            boundaryValues: any[],
            colors: any[],
            countryData: any[],
            fetched: boolean
        ) => {
            setDashboardCountriesChartData(prevState => ({
                ...prevState,
                dashboardCountriesChartDataFetched: fetched,
                legendData,
                boundaryValues,
                colors,
                countryData,
            }));
            apiCatchError(9, fetched ? 0 : 1);
            enableExportPdf(9, fetched);
        };

        const generateLegendsAndBoundaries = (bucketData: any[]) => {
            let max = Math.max(...bucketData.map(item => item.count), 0);
            let number = max;
            while (number % 5 !== 0) number++;

            const boundarySet = number / 5;
            const boundaryValues = Array.from({ length: 6 }, (_, idx) =>
                Math.round(boundarySet * idx) !== 1 ? Math.round(boundarySet * idx) : null
            ).filter(Boolean);

            const colors = ['#ddebff', '#bdd6ff', '#3895ff', '#005bf0', '#0046b8'];

            const legends = boundaryValues.map((value, index) => ({
                value,
                color: colors[index]
            }));

            return { legends, boundaryValues, colors };
        };

        const categorizeCountries = (bucketData: any[], legends: any[]) => {
            return bucketData.map(item => {
                const matchingLegend = legends.find(legend => item.count < legend.value);
                return { ...item, color: matchingLegend?.color || legends[legends.length - 1].color };
            });
        };

        try {
            if (
                dashboardData?.data !== undefined &&
                dashboardData?.result === "success" &&
                dashboardData?.requestUUID === request_uuid.value
            ) {
                CountriesMapMount.current = true;
                const { legends, boundaryValues, colors } = generateLegendsAndBoundaries(bucketData);
                const countryData = categorizeCountries(bucketData, legends);

                handleSetDashboardCountriesChartData(legends, boundaryValues, colors, countryData, true);
            } else if (dashboardData?.result === 'retry') {
                getDashboardCountriesChartData(request_uuid.value, 'retry');
            } else if (dashboardData?.result === "error") {
                handleSetDashboardCountriesChartData([], [], [], [], false);
            }
        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details?.dashboard?.dashboardCountriesChartData]);

    // Export pdf btn
    useEffect(() => {
        try {
            const loaderKeys = [
                'loader0', 'loader1', 'loader2', 'loader3', 'loader4', 'loader5',
                'loader8', 'loader9', 'loader10', 'loader11', 'loader12', 'loader13',
                'loader14', 'loader15', 'loader16', 'loader17', 'loader18', 'loader19'
            ];

            const allLoadersVisible = loaderKeys.every(key => loaderBtn[key]);
            const specificLoadersVisible = loaderBtn.loader8 && loaderBtn.loader9 && loaderBtn.loader13;

            const exportVisibility = fetched_details?.sectionVisibility?.guideInsights_flag
                ? allLoadersVisible
                : specificLoadersVisible;

            setLoaderBtn(prevState => ({
                ...prevState,
                disabled: !exportVisibility,
            }));

        } catch (error) {
            showBoundary(error);
        }
    }, [
        loaderBtn.loader0, loaderBtn.loader1, loaderBtn.loader2, loaderBtn.loader3, loaderBtn.loader4,
        loaderBtn.loader5, loaderBtn.loader6, loaderBtn.loader7, loaderBtn.loader8, loaderBtn.loader9,
        loaderBtn.loader10, loaderBtn.loader11, loaderBtn.loader12, loaderBtn.loader13, loaderBtn.loader14,
        loaderBtn.loader15, loaderBtn.loader16, loaderBtn.loader17, loaderBtn.loader18, loaderBtn.loader19
    ]);

    useEffect(() => {
        try {
            const result = errorCount.errorCount.every((val) => val === 1);
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    showMaintenance_flag: result
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }, [errorCount.errorCount])

    //Total Summary Count
    useEffect(() => {
        try {
            if (process.env.REACT_APP_WESTPAC === 'false') {
                if (
                    dashboardGuideMeChart.guideMeChartFetched && dashboardShowMeChart.showMeChartFetched &&
                    dashboardTestMeChart.testMeChartFetched && dashboardDoItForMeChart.doItForMeChartFetched &&
                    dashboardGuideTutorialsChart.guideTutorialsChartFetched &&
                    dashboardTooltipChart.tooltipChartFetched &&
                    dashboardPushnotificationChart.pushnotificationChartFetched &&
                    dashboardBeaconChart.beaconChartFetched &&
                    dashboardSurveyGraph.SurveyChartFetched &&
                    dashboardValidationChart.dashboardValidationChartDataFetched &&
                    dashboardPowerhtmlChart.dashboardPowerhtmlChartDataFetched &&
                    dashboardDucttapeChart.dashboardDucttapeChartDataFetched &&
                    dashboardGuidancevalidationChart.dashboardGuidancevalidationChartDataFetched &&
                    dashboardGuidanceChart.dashboardGuidanceChartDataFetched &&
                    dashboardTotalGuideCount.totalGuideCountFetched
                ) {
                    // let countData = calculateGuideCount(fetched_details?.dashboard?.dashboardTotalGuideCount?.data);

                    // let tableData = createTableData(calculateCount(fetched_details?.dashboard?.dashboardGuideMeChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardShowMeChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardTestMeChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardDoItForMeChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardGuideTutorialsChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardTooltipChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardPushnotificationChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardBeaconChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardSurveyChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardValidationChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardPowerhtmlChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardDucttapeChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardGuidancevalidationChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardGuidanceChartData),
                    //     countData, fetched_details?.dashboard?.dashboardGuideMeChartData);

                    // let totalSummaryCountTable =
                    //     <div className="table-fixed-column-outter">
                    //         <div className="table-fixed-column-inner">
                    //             <Table id="summary" tableData={tableData} superHeaders={superHeaders} colKeys={colKeys} colHeaders={colHeaders} addCustomClass={true}></Table>
                    //         </div>
                    //     </div>


                    // setTotalSummaryCount((prevState: any) => {
                    //     return {
                    //         ...prevState,
                    //         totalSummaryCountFetched: true,
                    //         tableData: tableData,
                    //         totalSummaryCountTable: totalSummaryCountTable,
                    //     }
                    // });

                }
            } else {
                if (
                    dashboardGuideMeChart.guideMeChartFetched &&
                    dashboardGuideTutorialsChart.guideTutorialsChartFetched &&
                    dashboardTooltipChart.tooltipChartFetched &&
                    dashboardPushnotificationChart.pushnotificationChartFetched &&
                    dashboardBeaconChart.beaconChartFetched &&
                    dashboardSurveyGraph.SurveyChartFetched &&
                    dashboardValidationChart.dashboardValidationChartDataFetched &&
                    dashboardPowerhtmlChart.dashboardPowerhtmlChartDataFetched &&
                    dashboardDucttapeChart.dashboardDucttapeChartDataFetched &&
                    dashboardGuidancevalidationChart.dashboardGuidancevalidationChartDataFetched &&
                    dashboardGuidanceChart.dashboardGuidanceChartDataFetched &&
                    dashboardTotalGuideCount.totalGuideCountFetched
                ) {
                    // let countData = calculateGuideCount(fetched_details?.dashboard?.dashboardTotalGuideCount?.data);

                    // let tableData = createTableDataWestpac(calculateCount(fetched_details?.dashboard?.dashboardGuideMeChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardGuideTutorialsChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardTooltipChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardPushnotificationChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardBeaconChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardSurveyChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardValidationChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardPowerhtmlChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardDucttapeChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardGuidancevalidationChartData),
                    //     calculateCount(fetched_details?.dashboard?.dashboardGuidanceChartData),
                    //     countData, fetched_details?.dashboard?.dashboardGuideMeChartData);

                    // let totalSummaryCountTable =
                    //     <div className="table-fixed-column-outter">
                    //         <div className="table-fixed-column-inner">
                    //             <Table id="summary" tableData={tableData} superHeaders={superHeaders} colKeys={colKeys} colHeaders={colHeaders} addCustomClass={true}></Table>
                    //         </div>
                    //     </div>


                    // setTotalSummaryCount((prevState: any) => {
                    //     return {
                    //         ...prevState,
                    //         totalSummaryCountFetched: true,
                    //         tableData: tableData,
                    //         totalSummaryCountTable: totalSummaryCountTable,
                    //     }
                    // });

                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [dashboardGuideMeChart.guideMeChartFetched,
    dashboardShowMeChart.showMeChartFetched,
    dashboardTestMeChart.testMeChartFetched,
    dashboardDoItForMeChart.doItForMeChartFetched,
    dashboardGuideTutorialsChart.guideTutorialsChartFetched,
    dashboardTooltipChart.dashboardTooltipChartDataFetched,
    dashboardPushnotificationChart.dashboardPushnotificationChartDataFetched,
    dashboardBeaconChart.dashboardBeaconChartDataFetched,
    dashboardSurveyGraph.dashboardSurveyChartDataFetched,
    dashboardValidationChart.dashboardValidationChartDataFetched,
    dashboardPowerhtmlChart.dashboardPowerhtmlChartDataFetched,
    dashboardDucttapeChart.dashboardDucttapeChartDataFetched,
    dashboardGuidancevalidationChart.dashboardGuidancevalidationChartDataFetched,
    dashboardGuidanceChart.dashboardGuidanceChartDataFetched,
    dashboardTotalGuideCount.totalGuideCountFetched
    ]);

    useEffect(() => {
        try {
            setDashboardTotalGuideCount((prevState) => {
                return {
                    ...prevState,
                    isLoading: false,
                    totalGuideCountFetched: true
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.dashboard?.dashboardTotalGuideCount]);


    //UserEngagment
    useEffect(() => {
        try {
            const { dashboardUserEngagementData } = fetched_details?.dashboard || {};

            // Early return if data is not available or has error
            if (!dashboardUserEngagementData) return;

            const { data, result, requestUUID, errorCode } = dashboardUserEngagementData;

            if (result === "success" && data?.graphData && data?.legends && requestUUID === request_uuid.value) {
                UserEngagementMount.current = true;

                // Process chart data and options
                const userEngagementChartData = data.graphData;
                const optionEntities = data.legends.map(option => ({
                    key: option.legendTitle?.trim(),
                    color: option.color,
                    area: option.area,
                    associatedDataPoint: option.associatedDataPoint,
                    count: 0  // Initialize count to 0
                }));

                // Calculate counts for each option
                optionEntities.forEach((option, index) => {
                    option.count = userEngagementChartData.reduce((cnt, chartData) => {
                        return cnt + (chartData.counts[option.associatedDataPoint] || 0);
                    }, 0);
                });

                const userEngagementChartOptions = optionEntities.map(option => ({
                    key: option.key,
                    color: option.color,
                    area: option.area,
                    associatedDataPoint: option.associatedDataPoint
                }));

                // Set data state
                setDashboardUserEngagementChart((prevState) => ({
                    ...prevState,
                    loadUserEngagementChartData: [...userEngagementChartData],
                    loadUserEngagementChartOptions: userEngagementChartOptions,
                    userEngagementChartFetched: true,
                    dashboardUserEngagementChartDataFetched: true,
                }));

                apiCatchError(20, 0);
                enableExportPdf(20, true);
            } else if (result === "retry") {
                getDashboardUserEngagementChartData(request_uuid.value, 'retry');
            } else if (result === "error") {
                setDashboardUserEngagementChart((prevState) => ({
                    ...prevState,
                    dashboardUserEngagementChartDataFetched: true,
                }));

                const errorCodeToHandle = errorCode === 503 ? 503 : 1;
                apiCatchError(20, errorCodeToHandle);
                enableExportPdf(20, false);
            }

        } catch (error) {
            showBoundary(error);
        }
    }, [fetched_details?.dashboard?.dashboardUserEngagementData]);


    // ***************************************************************************************************************************************

    useEffect(() => {
        try {
            document.title = insightsLbls.cioDashboardTitle ? insightsLbls.cioDashboardTitle : "";
        } catch (error) {
            showBoundary(error)
        }

    }, [insightsLbls])

    const getFinalEnvAppsList = useCallback((appData: any) => {
        try {
            let appIdList: any[] = [];
            let appNameList: any[] = [];
            let appListString: any = "";
            let lineChartEntities: any[] = [];
            let envCodeString: any = "";
            appData.map((data: any, key: number) => {
                lineChartEntities.push(`app${key + 1}`)
                appIdList.push(data.app_id);
                appNameList.push(data.app_name);
                if (key === 0) {
                    appListString += data.app_id;
                }
                else if (key > 0) {
                    appListString += "&app_id=" + data.app_id;
                }

                if (data.env_code && data.app_code) {
                    envCodeString += "&env_code=" + data.app_code + ":" + data.env_code;
                }
            });
            appListString += envCodeString;

            if (appData.length > 5) {
                lineChartEntities.push(`appx`)
            }

            setAppsList((prevState: any) => {
                return {
                    ...prevState,
                    appList: appIdList,
                    appNames: appNameList,
                    appListString: appListString,
                    appsFetched: true,
                    lineChartEntities: lineChartEntities,
                };
            });
        } catch (error) {
            showBoundary(error)
        }
    }, []);


    const getTotalAppList = useCallback((appData: any) => {
        try {
            appListTotal.current = [...appData]
        } catch (error) {
            showBoundary(error)
        }

    }, [])

    useEffect(() => {
        try {
            if (parameter.parameter !== '') {
                let queryParams = {
                    app_id: parameter.appId,
                    app_code: parameter.appCode,
                    app_title: parameter.appTitle,
                    fdate: fetched_details?.commonComponentData?.datesData.fdate,
                    tdate: fetched_details?.commonComponentData?.datesData.tdate,
                    redirect: 1,
                }

                navigate({
                    pathname: parameter.option + parameter.parameter + '/',
                    search: qs.stringify(queryParams),
                });
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [parameter])

    const setSectionRefresh = useCallback((flag: boolean) => {
        try {
            setUserEngagementLazyLoadChart((prevState: any) => {
                return {
                    ...prevState,
                    lazyLoad_flag: flag
                }
            })

            if (flag) {
                enableExportPdf(0, true);
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [setUserEngagementLazyLoadChart, enableExportPdf])


    const getTotalCount = (cnt) => {
        setBubbleChartData((prevState: any) => {
            return {
                ...prevState,
                count: cnt
            }
        })
    }




    const getAppsList = useMemo(() => {
        return appsList.appsFetched ? appsList.appNames.map((optn) => (
            <span key={optn} className='infoChip marginLeft-5'>{optn}</span>
        )) : null;
    }, [appsList.appsFetched, appsList.appNames]);


    const setDefaultDashboard = (from?: string, apiErrorFlag?: string) => {
        let d_dummy = [];
        for (let itr0 = 0; itr0 < fetched_dashboard_details.data.length; itr0++) {
            const dashboardKey = 'dashboard' + (itr0 + 1);
            const currentDashboard = fetched_dashboard_details.data[itr0][dashboardKey];

            d_dummy.push({
                profileID: currentDashboard['profileID'],
                profileName: currentDashboard['name'],
                isDefault: false,
                dashboardNumber: itr0 + 1,
                dashboardIcon: currentDashboard['dashboardIcon'],
                isDashboard: false,
                profileDetails: currentDashboard['profileDetails']
            });
        }

        let path = ApiRelativePaths.CREATE_DASHBOARD;
        let params = {};
        let paramObj = {};
        let a = CommonUtils.URLGenerator(params, path, paramObj);

        postCall(d_dummy, "CREATE_DASHBOARD").then((data: any) => {
            if (data.result === "success") {
                let dData = []
                for (let itr0 = 0; itr0 < data.data.length; itr0++) {
                    if (data.data.length < 3) {
                        dData.push({
                            ['dashboard' + (itr0 + 1)]: {
                                active: true,
                                name: data.data[itr0].profileName,
                                profileID: data.data[itr0].profileID,
                                isDefault: false,
                                dashboardIcon: data.data[itr0].dashboardIcon,
                                profileDetails: data.data[itr0].profileDetails
                            }
                        })
                    }
                }
                dispatch(setDashboards(dData))

                setDashboard_state((prevState: any) => {
                    return {
                        ...prevState,
                        isDefault: true
                    }
                })

                toast.success(insightsLbls.successfullyUpdated, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    const getServiceNotAvailableTemplate = (isWithPosition = false) => {
        if (isWithPosition) {
            return <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                <div className="displayFlex alignCenter margin-0-auto">
                    <h5 className="errorCss card-heading font-weight-400 text-center">
                        {insightsLbls.serviceNotAvailable}
                    </h5>
                </div>
            </div>
        }

        return <h5 className="errorCss card-heading font-weight-400 text-center">
            {insightsLbls.serviceNotAvailable}
        </h5>
    }

    const getLoaderTemplate = () => {
        return <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
            <div className="displayFlex alignCenter margin-0-auto">
                <Loader></Loader>
            </div>
        </div>
    }

    const getDashboardStatus = () => {
        let cnt = 0
        for (let itr0 = 0; itr0 < fetched_dashboard_details.data.length; itr0++) {
            const dashboardKey = 'dashboard' + (itr0 + 1);
            const currentDashboard = fetched_dashboard_details.data[itr0][dashboardKey];

            if (currentDashboard['isDefault']) {
                cnt = cnt + 1
            }
        }

        setDashboard_state((prevState: any) => {
            return {
                ...prevState,
                isDefault: cnt === 0
            }
        })

    }
    // ***************************************************************************************************************************************
    return (
        <section className=" demo width100 dashboardSection" ref={componentRef}>
            <div className="row">
                <SectionFilters>
                    <AppEnvListDropdown finalApps={getFinalEnvAppsList}></AppEnvListDropdown>
                    <Datepicker source={'component'} />
                </SectionFilters>
            </div>

            <div className="marginTop-50 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={"Dashboard"}
                                widthDividedBy={12}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="2"
                            title={insightsLbls.cioDashboardModalTitle}
                            data-toggle="modal"
                            onClick={(event) => openExportModal(event)}
                            disabled={loaderBtn.disabled}>
                            {insightsLbls.cioDashboardModalTitle}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <div className="page-title-new col-4 col-sm-4 col-4 section-subheading-div">
                <h5>
                    {(insightsLbls.cioDashboardSubTitle !== undefined) ? insightsLbls.cioDashboardSubTitle : ('')}
                </h5>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                messageText={insightsLbls.yourInsightUpdates}
                showUserInfo={true}>
                {getAppsList}
            </UserInfoSection>

            <button className="btn btn-primary dashDefault-btn" disabled={dashboard_state.isDefault} onClick={() => setDefaultDashboard()}>{insightsLbls.makeDefault}</button>

            <div className="infoMessage">{insightsLbls.dashboard24HrMsg}</div>
            <div className="dashboard">
                {fetched_details.sectionVisibility.guideInsights_flag && (
                    <>
                        {
                            !expanded ?
                                <>
                                    <CardContainer
                                        div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                        div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                        div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                        <div className="ActivityExpand">
                                            <ContainerTooltip div1Classes={"marginBottom-20"} div3Classes={""} hTagClasses={"section-heading headingText"}>
                                                <SectionTooltip tooltipText={{
                                                    "subTitle": insightsLbls.activity,
                                                    "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                    "childern": [{
                                                        "key": insightsLbls.guideCounts,
                                                        "value": insightsLbls.guidesSelectedinTimeFrame
                                                    }
                                                    ]
                                                }} placement="right"></SectionTooltip>

                                                <button className="pull-right pointer refreshBtn" onClick={() => refreshSections(0)}> {insightsLbls.refresh}</button>

                                            </ContainerTooltip>
                                        </div>

                                        <div className="minHeight400">
                                            <Tabs
                                                activeKey={tabKey.key}
                                                onSelect={handleSwitch}
                                                id="controlled-tab-guides"
                                            >
                                                <Tab eventKey={1} title={insightsLbls.guideMe} tabClassName="tab">

                                                    {dashboardGuideMeChart.dashboardGuideMeChartDataFetched ? (
                                                        errorCount.errorCount[0] === 0 ? (
                                                            dashboardGuideMeChart?.loadGuideMeChartData?.length === 0 ? (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="col-sm-12 col-lg-12 col-md-12 chartInfo-div displayFlex">
                                                                        <div>
                                                                            <p className="infoKey">{insightsLbls.selectedApps}</p>
                                                                            <p className="infoValue">{dashboardGuideMeChart.selectedAppCount}</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="displayFlex justifyCenter marginLR-10">
                                                                        <div className="col-6 marginTop-20 outer-border marginRight-10 height530">
                                                                            <LineChart
                                                                                chartEntities={appsList.lineChartEntities}
                                                                                optionEntities={dashboardGuideMeChart.loadGuideMeChartOptions}
                                                                                chartData={fetched_details?.dashboard?.dashboardGuideMeChartData?.data?.graphData}
                                                                                value_type={"count"}
                                                                                height={linechart_height}
                                                                                margin={lineChartMargin}
                                                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                refId="dashboard_guideme"
                                                                                component='dashboard'>
                                                                            </LineChart>
                                                                        </div>
                                                                        <div className="col-6 paddingLeft-20 marginTop-20 donutDiv outer-border marginLeft-10 height530">

                                                                            <Piechart
                                                                                chartData={[
                                                                                    dashboardGuideMeChart.chartData_values
                                                                                ]}
                                                                                chartEntities={dashboardGuideMeChart.chartData_entites}
                                                                                optionEntities={dashboardGuideMeChart.optionData_entites}
                                                                                valueFormat={'value'}
                                                                                height={450}
                                                                                width={450}
                                                                                showLegends={true}
                                                                                chartType={'donut'}
                                                                                labelType={'percent'}
                                                                            />
                                                                            {/* <Bubblechart dispatchTotalCount={getTotalCount} data={dashboardGuideMeChart.bubbleChartData} selectedAppCount={dashboardGuideMeChart.selectedAppCount}></Bubblechart> */}
                                                                        </div>
                                                                    </div>
                                                                </>

                                                            )
                                                        ) : getServiceNotAvailableTemplate()
                                                    ) : getLoaderTemplate()}
                                                </Tab>

                                                {process.env.REACT_APP_WESTPAC === 'false' && (
                                                    <Tab eventKey={2} title={insightsLbls.showMe} tabClassName="tab">
                                                        {dashboardShowMeChart.dashboardShowMeChartDataFetched ? (
                                                            errorCount.errorCount[1] === 0 ? (
                                                                dashboardShowMeChart?.loadShowMeChartData?.length === 0 ? (
                                                                    <div className="noDataText-font textCenter-absolute">
                                                                        {insightsLbls.noRecordFound}
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div className="col-sm-12 col-lg-12 col-md-12 chartInfo-div displayFlex">
                                                                            {/* <div>
                                                                                <p className="infoKey">Total Count</p>
                                                                                <p className="infoValue">{bubbleChartData.count}</p>
                                                                            </div>
                                                                            <hr className="infoDivider"></hr> */}
                                                                            <div>
                                                                                <p className="infoKey">{insightsLbls.selectedApps}</p>
                                                                                <p className="infoValue">{dashboardGuideMeChart.selectedAppCount}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="displayFlex justifyCenter marginLR-10">
                                                                            <div className="col-6 marginTop-20 outer-border marginRight-10 height530">
                                                                                <LineChart
                                                                                    chartEntities={appsList.lineChartEntities}
                                                                                    optionEntities={dashboardShowMeChart.loadShowMeChartOptions}
                                                                                    chartData={dashboardShowMeChart.loadShowMeChartData}
                                                                                    value_type={"count"}
                                                                                    height={linechart_height}
                                                                                    margin={lineChartMargin}
                                                                                    yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                    refId="dashboard_showme"
                                                                                    component='dashboard'>
                                                                                </LineChart>
                                                                            </div>
                                                                            <div className="col-6 paddingLeft-20 marginTop-20 donutDiv outer-border marginLeft-10 height53">
                                                                                <Piechart
                                                                                    chartData={[
                                                                                        dashboardShowMeChart.chartData_values
                                                                                    ]}
                                                                                    chartEntities={dashboardShowMeChart.chartData_entites}
                                                                                    optionEntities={dashboardShowMeChart.optionData_entites}
                                                                                    valueFormat={'value'}
                                                                                    height={450}
                                                                                    width={450}
                                                                                    showLegends={true}
                                                                                    chartType={'donut'}
                                                                                    labelType={'percent'}
                                                                                />
                                                                                {/* <Bubblechart dispatchTotalCount={getTotalCount} data={dashboardShowMeChart.bubbleChartData} selectedAppCount={dashboardShowMeChart.selectedAppCount}></Bubblechart> */}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            ) : getServiceNotAvailableTemplate()
                                                        ) : getLoaderTemplate()}

                                                    </Tab>
                                                )}


                                                {process.env.REACT_APP_WESTPAC === 'false' && (
                                                    <Tab eventKey={3} title={insightsLbls.testMe} tabClassName="tab">
                                                        {dashboardTestMeChart.dashboardTestMeChartDataFetched ? (
                                                            errorCount.errorCount[2] === 0 ? (
                                                                dashboardTestMeChart?.loadTestMeChartData?.length === 0 ? (
                                                                    <div className="noDataText-font textCenter-absolute">
                                                                        {insightsLbls.noRecordFound}
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div className="col-sm-12 col-lg-12 col-md-12 chartInfo-div displayFlex">
                                                                            <div>
                                                                                <p className="infoKey">{insightsLbls.selectedApps}</p>
                                                                                <p className="infoValue">{dashboardGuideMeChart.selectedAppCount}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="displayFlex justifyCenter marginLR-10">
                                                                            <div className="col-6 marginTop-20 outer-border marginRight-10 height530">
                                                                                <LineChart
                                                                                    chartEntities={appsList.lineChartEntities}
                                                                                    optionEntities={dashboardTestMeChart.loadTestMeChartOptions}
                                                                                    chartData={dashboardTestMeChart.loadTestMeChartData}
                                                                                    value_type={"count"}
                                                                                    height={linechart_height}
                                                                                    margin={lineChartMargin}
                                                                                    yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                    refId="dashboard_testme"
                                                                                    component='dashboard'>
                                                                                </LineChart>
                                                                            </div>
                                                                            <div className="col-6 paddingLeft-20 marginTop-20 donutDiv outer-border marginLeft-10 height530">
                                                                                <Piechart
                                                                                    chartData={[
                                                                                        dashboardTestMeChart.chartData_values
                                                                                    ]}
                                                                                    chartEntities={dashboardTestMeChart.chartData_entites}
                                                                                    optionEntities={dashboardTestMeChart.optionData_entites}
                                                                                    valueFormat={'value'}
                                                                                    height={450}
                                                                                    width={450}
                                                                                    showLegends={true}
                                                                                    chartType={'donut'}
                                                                                    labelType={'percent'}
                                                                                />
                                                                                {/* <Bubblechart dispatchTotalCount={getTotalCount} data={dashboardTestMeChart.bubbleChartData} selectedAppCount={dashboardTestMeChart.selectedAppCount}></Bubblechart> */}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            ) : getServiceNotAvailableTemplate()
                                                        ) : getLoaderTemplate()}
                                                    </Tab>
                                                )}


                                                {process.env.REACT_APP_WESTPAC === 'false' && (
                                                    <Tab eventKey={4} title={insightsLbls.doItForMe} tabClassName="tab">
                                                        {dashboardDoItForMeChart.dashboardDoItForMeChartDataFetched ? (
                                                            errorCount.errorCount[3] === 0 ? (
                                                                dashboardDoItForMeChart?.loadDoItForMeChartData?.length === 0 ? (
                                                                    <div className="noDataText-font textCenter-absolute">
                                                                        {insightsLbls.noRecordFound}
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div className="col-sm-12 col-lg-12 col-md-12 chartInfo-div displayFlex">
                                                                            {/* <div>
                                                                                <p className="infoKey">Total Count</p>
                                                                                <p className="infoValue">{bubbleChartData.count}</p>
                                                                            </div>
                                                                            <hr className="infoDivider"></hr> */}
                                                                            <div>
                                                                                <p className="infoKey">{insightsLbls.selectedApps}</p>
                                                                                <p className="infoValue">{dashboardGuideMeChart.selectedAppCount}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="displayFlex justifyCenter marginLR-10">
                                                                            <div className="col-6 marginTop-20 outer-border marginRight-10 height530">
                                                                                <LineChart
                                                                                    chartEntities={appsList.lineChartEntities}
                                                                                    optionEntities={dashboardDoItForMeChart.loadDoItForMeChartOptions}
                                                                                    chartData={dashboardDoItForMeChart.loadDoItForMeChartData}
                                                                                    value_type={"count"}
                                                                                    height={linechart_height}
                                                                                    margin={lineChartMargin}
                                                                                    yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                    refId="dashboard_doitforme"
                                                                                    component='dashboard'>
                                                                                </LineChart>
                                                                            </div>
                                                                            <div className="col-6 paddingLeft-20 marginTop-20 donutDiv outer-border marginLeft-10 height530">
                                                                                <Piechart
                                                                                    chartData={[
                                                                                        dashboardDoItForMeChart.chartData_values
                                                                                    ]}
                                                                                    chartEntities={dashboardDoItForMeChart.chartData_entites}
                                                                                    optionEntities={dashboardDoItForMeChart.optionData_entites}
                                                                                    valueFormat={'value'}
                                                                                    height={450}
                                                                                    width={450}
                                                                                    showLegends={true}
                                                                                    chartType={'donut'}
                                                                                    labelType={'percent'}
                                                                                />
                                                                                {/* <Bubblechart dispatchTotalCount={getTotalCount} data={dashboardDoItForMeChart.bubbleChartData} selectedAppCount={dashboardDoItForMeChart.selectedAppCount}></Bubblechart> */}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            ) : getServiceNotAvailableTemplate()
                                                        ) : getLoaderTemplate()}
                                                    </Tab>
                                                )}


                                                <Tab eventKey={5} title={insightsLbls.tutorialGuide} tabClassName="tab">
                                                    {dashboardGuideTutorialsChart.dashboardGuideTutorialsChartDataFetched ? (
                                                        errorCount.errorCount[4] === 0 ? (
                                                            dashboardGuideTutorialsChart?.loadGuideTutorialsChartData?.length === 0 ? (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="col-sm-12 col-lg-12 col-md-12 chartInfo-div displayFlex">
                                                                        {/* <div>
                                                                            <p className="infoKey">Total Count</p>
                                                                            <p className="infoValue">{bubbleChartData.count}</p>
                                                                        </div>
                                                                        <hr className="infoDivider"></hr> */}
                                                                        <div>
                                                                            <p className="infoKey">{insightsLbls.selectedApps}</p>
                                                                            <p className="infoValue">{dashboardGuideMeChart.selectedAppCount}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="displayFlex justifyCenter marginLR-10">
                                                                        <div className="col-6 marginTop-20 outer-border marginRight-10 height530">
                                                                            <LineChart
                                                                                chartEntities={appsList.lineChartEntities}
                                                                                optionEntities={dashboardGuideTutorialsChart.loadGuideTutorialsChartOptions}
                                                                                chartData={dashboardGuideTutorialsChart.loadGuideTutorialsChartData}
                                                                                value_type={"count"}
                                                                                height={linechart_height}
                                                                                margin={lineChartMargin}
                                                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                refId="dashboard_guideTutorials"
                                                                                component='dashboard'>
                                                                            </LineChart>
                                                                        </div>
                                                                        <div className="col-6 paddingLeft-20 marginTop-20 donutDiv outer-border marginLeft-10 height530">
                                                                            <Piechart
                                                                                chartData={[
                                                                                    dashboardGuideTutorialsChart.chartData_values
                                                                                ]}
                                                                                chartEntities={dashboardGuideTutorialsChart.chartData_entites}
                                                                                optionEntities={dashboardGuideTutorialsChart.optionData_entites}
                                                                                valueFormat={'value'}
                                                                                height={450}
                                                                                width={450}
                                                                                showLegends={true}
                                                                                chartType={'donut'}
                                                                                labelType={'percent'}
                                                                            />
                                                                            {/* <Bubblechart dispatchTotalCount={getTotalCount} data={dashboardGuideTutorialsChart.bubbleChartData} selectedAppCount={dashboardGuideTutorialsChart.selectedAppCount}></Bubblechart> */}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        ) : getServiceNotAvailableTemplate()
                                                    ) : getLoaderTemplate()}
                                                </Tab>

                                                <Tab eventKey={6} title={insightsLbls.tooltip} tabClassName="tab">
                                                    {dashboardTooltipChart.dashboardTooltipChartDataFetched ? (
                                                        errorCount.errorCount[10] === 0 ? (
                                                            dashboardTooltipChart?.loadTooltipChartData?.length === 0 ? (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            ) : (

                                                                <>
                                                                    <div className="col-sm-12 col-lg-12 col-md-12 chartInfo-div displayFlex">
                                                                        {/* <div>
                                                                            <p className="infoKey">Total Count</p>
                                                                            <p className="infoValue">{bubbleChartData.count}</p>
                                                                        </div>
                                                                        <hr className="infoDivider"></hr> */}
                                                                        <div>
                                                                            <p className="infoKey">{insightsLbls.selectedApps}</p>
                                                                            <p className="infoValue">{dashboardGuideMeChart.selectedAppCount}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="displayFlex justifyCenter marginLR-10">
                                                                        <div className="col-6 marginTop-20 outer-border marginRight-10 height530">
                                                                            <LineChart
                                                                                chartEntities={appsList.lineChartEntities}
                                                                                optionEntities={dashboardTooltipChart.loadTooltipChartOptions}
                                                                                chartData={dashboardTooltipChart.loadTooltipChartData}
                                                                                value_type={"count"}
                                                                                height={linechart_height}
                                                                                margin={lineChartMargin}
                                                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                refId="dashboard_tooltip"
                                                                                component='dashboard'>
                                                                            </LineChart>
                                                                        </div>
                                                                        <div className="col-6 paddingLeft-20 marginTop-20 donutDiv outer-border marginLeft-10 height530">
                                                                            <Piechart
                                                                                chartData={[
                                                                                    dashboardTooltipChart.chartData_values
                                                                                ]}
                                                                                chartEntities={dashboardTooltipChart.chartData_entites}
                                                                                optionEntities={dashboardTooltipChart.optionData_entites}
                                                                                valueFormat={'value'}
                                                                                height={450}
                                                                                width={450}
                                                                                showLegends={true}
                                                                                chartType={'donut'}
                                                                                labelType={'percent'}
                                                                            />
                                                                            {/* <Bubblechart dispatchTotalCount={getTotalCount} data={dashboardTooltipChart.bubbleChartData} selectedAppCount={dashboardTooltipChart.selectedAppCount}></Bubblechart> */}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        ) : getServiceNotAvailableTemplate()
                                                    ) : getLoaderTemplate()}
                                                </Tab>

                                                <Tab eventKey={7} title={insightsLbls.pushNotification} tabClassName="tab">
                                                    {dashboardPushnotificationChart.dashboardPushnotificationChartDataFetched ? (
                                                        errorCount.errorCount[11] === 0 ? (
                                                            dashboardPushnotificationChart?.loadPushnotificationChartData?.length === 0 ? (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            ) : (


                                                                <>
                                                                    <div className="col-sm-12 col-lg-12 col-md-12 chartInfo-div displayFlex">
                                                                        {/* <div>
                                                                            <p className="infoKey">Total Count</p>
                                                                            <p className="infoValue">{bubbleChartData.count}</p>
                                                                        </div>
                                                                        <hr className="infoDivider"></hr> */}
                                                                        <div>
                                                                            <p className="infoKey">{insightsLbls.selectedApps}</p>
                                                                            <p className="infoValue">{dashboardGuideMeChart.selectedAppCount}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="displayFlex justifyCenter marginLR-10">
                                                                        <div className="col-6 marginTop-20 outer-border marginRight-10 height530">
                                                                            <LineChart
                                                                                chartEntities={appsList.lineChartEntities}
                                                                                optionEntities={dashboardPushnotificationChart.loadPushnotificationChartOptions}
                                                                                chartData={dashboardPushnotificationChart.loadPushnotificationChartData}
                                                                                value_type={"count"}
                                                                                height={linechart_height}
                                                                                margin={lineChartMargin}
                                                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                refId="dashboard_pushnotification"
                                                                                component='dashboard'>
                                                                            </LineChart>
                                                                        </div>
                                                                        <div className="col-6 paddingLeft-20 marginTop-20 donutDiv outer-border marginLeft-10 height530">
                                                                            <Piechart
                                                                                chartData={[
                                                                                    dashboardPushnotificationChart.chartData_values
                                                                                ]}
                                                                                chartEntities={dashboardPushnotificationChart.chartData_entites}
                                                                                optionEntities={dashboardPushnotificationChart.optionData_entites}
                                                                                valueFormat={'value'}
                                                                                height={450}
                                                                                width={450}
                                                                                showLegends={true}
                                                                                chartType={'donut'}
                                                                                labelType={'percent'}
                                                                            />
                                                                            {/* <Bubblechart dispatchTotalCount={getTotalCount} data={dashboardPushnotificationChart.bubbleChartData} selectedAppCount={dashboardPushnotificationChart.selectedAppCount}></Bubblechart> */}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        ) : getServiceNotAvailableTemplate()
                                                    ) : getLoaderTemplate()}
                                                </Tab>

                                                <Tab eventKey={8} title={insightsLbls.beacon} tabClassName="tab">
                                                    {dashboardBeaconChart.dashboardBeaconChartDataFetched ? (
                                                        errorCount.errorCount[12] === 0 ? (
                                                            dashboardBeaconChart?.loadBeaconChartData?.length === 0 ? (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            ) : (


                                                                <>
                                                                    <div className="col-sm-12 col-lg-12 col-md-12 chartInfo-div displayFlex">
                                                                        {/* <div>
                                                                            <p className="infoKey">Total Count</p>
                                                                            <p className="infoValue">{bubbleChartData.count}</p>
                                                                        </div>
                                                                        <hr className="infoDivider"></hr> */}
                                                                        <div>
                                                                            <p className="infoKey">{insightsLbls.selectedApps}</p>
                                                                            <p className="infoValue">{dashboardGuideMeChart.selectedAppCount}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="displayFlex justifyCenter marginLR-10">
                                                                        <div className="col-6 marginTop-20 outer-border marginRight-10 height530">
                                                                            <LineChart
                                                                                chartEntities={appsList.lineChartEntities}
                                                                                optionEntities={dashboardBeaconChart.loadBeaconChartOptions}
                                                                                chartData={dashboardBeaconChart.loadBeaconChartData}
                                                                                value_type={"count"}
                                                                                height={linechart_height}
                                                                                margin={lineChartMargin}
                                                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                refId="dashboard_beacon"
                                                                                component='dashboard'>
                                                                            </LineChart>
                                                                        </div>
                                                                        <div className="col-6 paddingLeft-20 marginTop-20 donutDiv outer-border marginLeft-10 height530">
                                                                            <Piechart
                                                                                chartData={[
                                                                                    dashboardBeaconChart.chartData_values
                                                                                ]}
                                                                                chartEntities={dashboardBeaconChart.chartData_entites}
                                                                                optionEntities={dashboardBeaconChart.optionData_entites}
                                                                                valueFormat={'value'}
                                                                                height={450}
                                                                                width={450}
                                                                                showLegends={true}
                                                                                chartType={'donut'}
                                                                                labelType={'percent'}
                                                                            />
                                                                            {/* <Bubblechart dispatchTotalCount={getTotalCount} data={dashboardBeaconChart.bubbleChartData} selectedAppCount={dashboardBeaconChart.selectedAppCount}></Bubblechart> */}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        ) : getServiceNotAvailableTemplate()
                                                    ) : getLoaderTemplate()}
                                                </Tab>

                                                <Tab eventKey={9} title={insightsLbls.survey} tabClassName="tab">
                                                    {dashboardSurveyGraph.dashboardSurveyChartDataFetched ? (
                                                        errorCount.errorCount[13] === 0 ? (
                                                            dashboardSurveyGraph?.loadSurveyChartData?.length === 0 ? (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            ) : (


                                                                <>
                                                                    <div className="col-sm-12 col-lg-12 col-md-12 chartInfo-div displayFlex">
                                                                        {/* <div>
                                                                            <p className="infoKey">Total Count</p>
                                                                            <p className="infoValue">{bubbleChartData.count}</p>
                                                                        </div>
                                                                        <hr className="infoDivider"></hr> */}
                                                                        <div>
                                                                            <p className="infoKey">{insightsLbls.selectedApps}</p>
                                                                            <p className="infoValue">{dashboardGuideMeChart.selectedAppCount}</p>
                                                                        </div>
                                                                    </div>
                                                                    {/* <p className="marginBottom-0 marginTop-10"><span className="bg-yellow">
                                                                        {"*" + insightsLbls.chartFilteredBasedOnApps}</span></p> */}
                                                                    <div className="displayFlex justifyCenter marginLR-10">

                                                                        <div className="col-6 marginTop-20 outer-border marginRight-10 height530">
                                                                            <LineChart
                                                                                chartEntities={appsList.lineChartEntities}
                                                                                optionEntities={dashboardSurveyGraph.loadSurveyChartOptions}
                                                                                chartData={dashboardSurveyGraph.loadSurveyChartData}
                                                                                value_type={"count"}
                                                                                height={linechart_height}
                                                                                margin={lineChartMargin}
                                                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                refId="dashboard_beacon"
                                                                                component='dashboard'>
                                                                            </LineChart>
                                                                        </div>
                                                                        <div className="col-6 paddingLeft-20 marginTop-20 donutDiv outer-border marginLeft-10 height530">
                                                                            <Piechart
                                                                                chartData={[
                                                                                    dashboardSurveyGraph.chartData_values
                                                                                ]}
                                                                                chartEntities={dashboardSurveyGraph.chartData_entites}
                                                                                optionEntities={dashboardSurveyGraph.optionData_entites}
                                                                                valueFormat={'value'}
                                                                                height={450}
                                                                                width={450}
                                                                                showLegends={true}
                                                                                chartType={'donut'}
                                                                                labelType={'percent'}
                                                                            />
                                                                            {/* <Bubblechart dispatchTotalCount={getTotalCount} data={dashboardSurveyGraph.bubbleChartData} selectedAppCount={dashboardSurveyGraph.selectedAppCount}></Bubblechart> */}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        ) : getServiceNotAvailableTemplate()
                                                    ) : getLoaderTemplate()}
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </CardContainer>
                                </>
                                :
                                <>
                                    <CardContainer
                                        div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                        div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                        div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                        <div className="ActivityExpand">
                                            <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                <SectionTooltip tooltipText={{
                                                    "subTitle": insightsLbls.guideMeActivity,
                                                    "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                    "childern": [{
                                                        "key": insightsLbls.guideCounts,
                                                        "value": insightsLbls.guidesInGuideModeinTimeFrame
                                                    }]
                                                }} placement="right">
                                                </SectionTooltip>
                                            </ContainerTooltip>
                                            <div className="downArrowDashboard">
                                                <OverlayTrigger placement={"bottom"} overlay={tooltipText(expanded)}>
                                                    <span className="expand" onClick={handleExpansion}>{insightsLbls.collapse}<i id={`detail-arrow`} className={'fa fa-angle' + (!expanded ? '-down ' : '-up ') + 'pointer ' + 'arrowWeightDashBoard'} aria-hidden="true"></i>
                                                    </span>
                                                </OverlayTrigger>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">

                                            {(dashboardGuideMeChart.dashboardGuideMeChartDataFetched) ? (
                                                errorCount.errorCount[0] === 0 ? (
                                                    dashboardGuideMeChart?.loadGuideMeChartData?.length === 0 ? (
                                                        <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (

                                                        <>
                                                            <div className="displayFlex">
                                                                <div className="col-8">
                                                                    <LineChart
                                                                        chartEntities={appsList.lineChartEntities}
                                                                        optionEntities={dashboardGuideMeChart.loadGuideMeChartOptions}
                                                                        chartData={fetched_details?.dashboard?.dashboardGuideMeChartData?.data?.graphData}
                                                                        value_type={"count"}
                                                                        height={linechart_height}
                                                                        margin={lineChartMargin}
                                                                        yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                        refId="dashboard_guideme"
                                                                        component='dashboard'>
                                                                    </LineChart>
                                                                </div>
                                                                <div className="col-4 paddingLeft-20 buubleDiv">
                                                                    <Bubblechart data={dashboardGuideMeChart.bubbleChartData} selectedAppCount={dashboardGuideMeChart.selectedAppCount}></Bubblechart>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                ) : getServiceNotAvailableTemplate(true)
                                            ) : getLoaderTemplate()}
                                        </div>

                                    </CardContainer>


                                    {process.env.REACT_APP_WESTPAC === 'false' && (
                                        <>
                                            <CardContainer
                                                div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                                div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                                div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                                <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                    <SectionTooltip tooltipText={{
                                                        "subTitle": insightsLbls.showMeActivity,
                                                        "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                        "childern": [
                                                            {
                                                                "key": insightsLbls.guideCounts,
                                                                "value": insightsLbls.guidesInShowMeModeinTimeFrame
                                                            }
                                                        ]
                                                    }} placement="right">
                                                    </SectionTooltip>
                                                </ContainerTooltip>

                                                <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">

                                                    {dashboardShowMeChart.dashboardShowMeChartDataFetched ? (
                                                        errorCount.errorCount[1] === 0 ? (
                                                            dashboardShowMeChart?.loadShowMeChartData?.length === 0 ? (
                                                                <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                                        <div className="noDataText-font textCenter-absolute">
                                                                            {insightsLbls.noRecordFound}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="displayFlex">
                                                                        <div className="col-8">
                                                                            <LineChart
                                                                                chartEntities={appsList.lineChartEntities}
                                                                                optionEntities={dashboardShowMeChart.loadShowMeChartOptions}
                                                                                chartData={dashboardShowMeChart.loadShowMeChartData}
                                                                                value_type={"count"}
                                                                                height={linechart_height}
                                                                                margin={lineChartMargin}
                                                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                refId="dashboard_showme"
                                                                                component='dashboard'>
                                                                            </LineChart>
                                                                        </div>
                                                                        <div className="col-4 paddingLeft-20 buubleDiv">
                                                                            <Bubblechart data={dashboardShowMeChart.bubbleChartData} selectedAppCount={dashboardShowMeChart.selectedAppCount}></Bubblechart>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        ) : getServiceNotAvailableTemplate(true)
                                                    ) : getLoaderTemplate()}
                                                </div>

                                            </CardContainer>

                                            <CardContainer
                                                div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                                div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                                div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                                <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                    <SectionTooltip tooltipText={{
                                                        "subTitle": insightsLbls.testMeActivity,
                                                        "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                        "childern": [
                                                            {
                                                                "key": insightsLbls.guideCounts,
                                                                "value": insightsLbls.guidesInTestMeModeinTimeFrame
                                                            }
                                                        ]
                                                    }} placement="right">
                                                    </SectionTooltip>
                                                </ContainerTooltip>
                                                <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                                    {dashboardTestMeChart.dashboardTestMeChartDataFetched ? (
                                                        errorCount.errorCount[2] === 0 ? (
                                                            dashboardTestMeChart?.loadTestMeChartData?.length === 0 ? (
                                                                <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                                        <div className="noDataText-font textCenter-absolute">
                                                                            {insightsLbls.noRecordFound}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (

                                                                <>
                                                                    <div className="displayFlex">
                                                                        <div className="col-8">
                                                                            <LineChart
                                                                                chartEntities={appsList.lineChartEntities}
                                                                                optionEntities={dashboardTestMeChart.loadTestMeChartOptions}
                                                                                chartData={dashboardTestMeChart.loadTestMeChartData}
                                                                                value_type={"count"}
                                                                                height={linechart_height}
                                                                                margin={lineChartMargin}
                                                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                refId="dashboard_testme"
                                                                                component='dashboard'>
                                                                            </LineChart>
                                                                        </div>
                                                                        <div className="col-4 paddingLeft-20 buubleDiv">
                                                                            <Bubblechart data={dashboardTestMeChart.bubbleChartData} selectedAppCount={dashboardTestMeChart.selectedAppCount}></Bubblechart>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        ) : getServiceNotAvailableTemplate(true)
                                                    ) : getLoaderTemplate()}
                                                </div>
                                            </CardContainer>


                                            <CardContainer
                                                div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                                div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                                div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                                <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                    <SectionTooltip tooltipText={
                                                        {
                                                            "subTitle": insightsLbls.doItForMe,
                                                            "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                            "childern": [
                                                                {
                                                                    "key": insightsLbls.guideCounts,
                                                                    "value": insightsLbls.guidesInSDoItForMeModeinTimeFrame
                                                                }
                                                            ]
                                                        }
                                                    } placement="right"></SectionTooltip>
                                                </ContainerTooltip>

                                                <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">

                                                    {dashboardDoItForMeChart.dashboardDoItForMeChartDataFetched ? (
                                                        errorCount.errorCount[3] === 0 ? (
                                                            dashboardDoItForMeChart?.loadDoItForMeChartData?.length === 0 ? (
                                                                <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                                        <div className="noDataText-font textCenter-absolute">
                                                                            {insightsLbls.noRecordFound}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (

                                                                <>
                                                                    <div className="displayFlex">
                                                                        <div className="col-8">
                                                                            <LineChart
                                                                                chartEntities={appsList.lineChartEntities}
                                                                                optionEntities={dashboardDoItForMeChart.loadDoItForMeChartOptions}
                                                                                chartData={dashboardDoItForMeChart.loadDoItForMeChartData}
                                                                                value_type={"count"}
                                                                                height={linechart_height}
                                                                                margin={lineChartMargin}
                                                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                                refId="dashboard_doitforme"
                                                                                component='dashboard'>
                                                                            </LineChart>
                                                                        </div>
                                                                        <div className="col-4 paddingLeft-20 buubleDiv">
                                                                            <Bubblechart data={dashboardDoItForMeChart.bubbleChartData} selectedAppCount={dashboardDoItForMeChart.selectedAppCount}></Bubblechart>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        ) : getServiceNotAvailableTemplate(true)
                                                    ) : getLoaderTemplate()}
                                                </div>

                                            </CardContainer>
                                        </>
                                    )}

                                    <CardContainer
                                        div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                        div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                        div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                            <SectionTooltip tooltipText={
                                                {
                                                    "subTitle": insightsLbls.guideTutorialActivity,
                                                    "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                    "childern": [
                                                        {
                                                            "key": insightsLbls.guideCounts,
                                                            "value": insightsLbls.tutorialGuidesinTimeFrame
                                                        }
                                                    ]
                                                }
                                            } placement="right"></SectionTooltip>
                                        </ContainerTooltip>

                                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">

                                            {dashboardGuideTutorialsChart.dashboardGuideTutorialsChartDataFetched ? (
                                                errorCount.errorCount[4] === 0 ? (
                                                    dashboardGuideTutorialsChart?.loadGuideTutorialsChartData?.length === 0 ? (
                                                        <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div className="displayFlex">
                                                                <div className="col-8">
                                                                    <LineChart
                                                                        chartEntities={appsList.lineChartEntities}
                                                                        optionEntities={dashboardGuideTutorialsChart.loadGuideTutorialsChartOptions}
                                                                        chartData={dashboardGuideTutorialsChart.loadGuideTutorialsChartData}
                                                                        value_type={"count"}
                                                                        height={linechart_height}
                                                                        margin={lineChartMargin}
                                                                        yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                        refId="dashboard_guideTutorials"
                                                                        component='dashboard'>
                                                                    </LineChart>
                                                                </div>
                                                                <div className="col-4 paddingLeft-20 buubleDiv">
                                                                    <Bubblechart data={dashboardGuideTutorialsChart.bubbleChartData} selectedAppCount={dashboardGuideTutorialsChart.selectedAppCount}></Bubblechart>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                ) : getServiceNotAvailableTemplate(true)
                                            ) : getLoaderTemplate()}
                                        </div>

                                    </CardContainer>


                                    <CardContainer
                                        div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                        div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                        div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                            <SectionTooltip tooltipText={
                                                {
                                                    "subTitle": insightsLbls.tooltipActivity,
                                                    "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                    "childern": [
                                                        {
                                                            "key": insightsLbls.guideCounts,
                                                            "value": insightsLbls.guidesInTooltipModeinTimeFrame
                                                        }
                                                    ]
                                                }
                                            } placement="right"></SectionTooltip>
                                        </ContainerTooltip>

                                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                            {dashboardTooltipChart.dashboardTooltipChartDataFetched ? (
                                                errorCount.errorCount[10] === 0 ? (
                                                    dashboardTooltipChart?.loadTooltipChartData?.length === 0 ? (
                                                        <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div className="displayFlex">
                                                                <div className="col-8">
                                                                    <LineChart
                                                                        chartEntities={appsList.lineChartEntities}
                                                                        optionEntities={dashboardTooltipChart.loadTooltipChartOptions}
                                                                        chartData={dashboardTooltipChart.loadTooltipChartData}
                                                                        value_type={"count"}
                                                                        height={linechart_height}
                                                                        margin={lineChartMargin}
                                                                        yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                        refId="dashboard_tooltip"
                                                                        component='dashboard'>
                                                                    </LineChart>
                                                                </div>
                                                                <div className="col-4 paddingLeft-20 buubleDiv">
                                                                    <Bubblechart data={dashboardTooltipChart.bubbleChartData} selectedAppCount={dashboardTooltipChart.selectedAppCount}></Bubblechart>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                ) : getServiceNotAvailableTemplate(true)
                                            ) : getLoaderTemplate()}
                                        </div>
                                    </CardContainer>


                                    <CardContainer
                                        div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                        div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                        div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                            <SectionTooltip tooltipText={
                                                {
                                                    "subTitle": insightsLbls.pushnotificationActivity,
                                                    "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                    "childern": [
                                                        {
                                                            "key": insightsLbls.guideCounts,
                                                            "value": insightsLbls.guidesInPNModeinTimeFrame
                                                        }
                                                    ]
                                                }
                                            } placement="right"></SectionTooltip>
                                        </ContainerTooltip>

                                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                            {dashboardPushnotificationChart.dashboardPushnotificationChartDataFetched ? (
                                                errorCount.errorCount[11] === 0 ? (
                                                    dashboardPushnotificationChart?.loadPushnotificationChartData?.length === 0 ? (
                                                        <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div className="displayFlex">
                                                                <div className="col-8">
                                                                    <LineChart
                                                                        chartEntities={appsList.lineChartEntities}
                                                                        optionEntities={dashboardPushnotificationChart.loadPushnotificationChartOptions}
                                                                        chartData={dashboardPushnotificationChart.loadPushnotificationChartData}
                                                                        value_type={"count"}
                                                                        height={linechart_height}
                                                                        margin={lineChartMargin}
                                                                        yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                        refId="dashboard_pushnotification"
                                                                        component='dashboard'>
                                                                    </LineChart>
                                                                </div>
                                                                <div className="col-4 paddingLeft-20 buubleDiv">
                                                                    <Bubblechart data={dashboardPushnotificationChart.bubbleChartData} selectedAppCount={dashboardPushnotificationChart.selectedAppCount}></Bubblechart>
                                                                </div>
                                                            </div>
                                                        </>

                                                    )
                                                ) : getServiceNotAvailableTemplate(true)
                                            ) : getLoaderTemplate()}
                                        </div>
                                    </CardContainer>

                                    <CardContainer
                                        div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                        div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                        div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                            <SectionTooltip tooltipText={
                                                {
                                                    "subTitle": insightsLbls.beacon,
                                                    "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                    "childern": [
                                                        {
                                                            "key": insightsLbls.guideCounts,
                                                            "value": insightsLbls.guidesInBeaconModeinTimeFrame
                                                        }
                                                    ]
                                                }
                                            } placement="right"></SectionTooltip>
                                        </ContainerTooltip>

                                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                            {dashboardBeaconChart.dashboardBeaconChartDataFetched ? (
                                                errorCount.errorCount[12] === 0 ? (
                                                    dashboardBeaconChart?.loadBeaconChartData?.length === 0 ? (
                                                        <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div className="displayFlex">
                                                                <div className="col-8">
                                                                    <LineChart
                                                                        chartEntities={appsList.lineChartEntities}
                                                                        optionEntities={dashboardBeaconChart.loadBeaconChartOptions}
                                                                        chartData={dashboardBeaconChart.loadBeaconChartData}
                                                                        value_type={"count"}
                                                                        height={linechart_height}
                                                                        margin={lineChartMargin}
                                                                        yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                        refId="dashboard_beacon"
                                                                        component='dashboard'>
                                                                    </LineChart>
                                                                </div>
                                                                <div className="col-4 paddingLeft-20 buubleDiv">
                                                                    <Bubblechart data={dashboardBeaconChart.bubbleChartData} selectedAppCount={dashboardBeaconChart.selectedAppCount}></Bubblechart>
                                                                </div>
                                                            </div>
                                                        </>

                                                    )
                                                ) : getServiceNotAvailableTemplate(true)
                                            ) : getLoaderTemplate()}
                                        </div>
                                    </CardContainer>

                                    <CardContainer
                                        div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 "}
                                        div2Classes={"card icon-text marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0 dashboardCardBorder0"}
                                        div3Classes={"card-body minHeight490  overflowY-hidden"}>
                                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                            <SectionTooltip tooltipText={
                                                {
                                                    "subTitle": insightsLbls.survey,
                                                    "tooltipText": insightsLbls.activitiesOnGuidesonSelectApps,
                                                    "childern": [
                                                        {
                                                            "key": insightsLbls.guideCounts,
                                                            "value": insightsLbls.guidesInSurveyModeinTimeFrame
                                                        }
                                                    ]
                                                }
                                            } placement="right"></SectionTooltip>
                                        </ContainerTooltip>

                                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                            {dashboardSurveyGraph.dashboardSurveyChartDataFetched ? (
                                                errorCount.errorCount[13] === 0 ? (
                                                    dashboardSurveyGraph?.loadSurveyChartData?.length === 0 ? (
                                                        <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (

                                                        <>
                                                            <p className="marginBottom-0 marginTop-10">{"*" + insightsLbls.chartFilteredBasedOnApps}</p>
                                                            <div className="displayFlex">
                                                                <div className="col-8 marginTop-30">
                                                                    <LineChart
                                                                        chartEntities={appsList.lineChartEntities}
                                                                        optionEntities={dashboardSurveyGraph.loadSurveyChartOptions}
                                                                        chartData={dashboardSurveyGraph.loadSurveyChartData}
                                                                        value_type={"count"}
                                                                        height={linechart_height}
                                                                        margin={lineChartMargin}
                                                                        yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                        refId="dashboard_beacon"
                                                                        component='dashboard'>
                                                                    </LineChart>
                                                                </div>
                                                                <div className="col-4 paddingLeft-20 buubleDiv">
                                                                    <Bubblechart data={dashboardSurveyGraph.bubbleChartData} selectedAppCount={dashboardSurveyGraph.selectedAppCount}></Bubblechart>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                ) : getServiceNotAvailableTemplate(true)
                                            ) : getLoaderTemplate()}
                                        </div>
                                    </CardContainer>

                                </>
                        }
                    </>
                )}

                {/* GUIDE TABLE SUMMARY */}

                {fetched_details.sectionVisibility.guideInsights_flag && (<>
                    <CardContainer
                        div1Classes={"col-sm-12 col-lg-12 col-md-12 marginTop-20 padding0 "}
                        div2Classes={"card icon-text section-wrapper marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0"}
                        div3Classes={"card-body minHeight490  overflowY-hidden"}>
                        <ContainerTooltip
                            div1Classes={"graph-title displayFlex"}
                            div2Classes={"col-sm-12 col-lg-12 col-md-12 row"}
                            hTagClasses={"section-heading headingText tooltipBG"}
                        >
                            {process.env.REACT_APP_WESTPAC === 'false' ? (
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.top10Guides,
                                        "tooltipText": insightsLbls.tableTop10GuidesAcrossApps,
                                        "childern": [
                                            {
                                                "key": insightsLbls.guideTitle + ":",
                                                "value": insightsLbls.titleOfGuide
                                            },
                                            {
                                                "key": insightsLbls.appTitle + ":",
                                                "value": insightsLbls.appToWhichGuideBelong
                                            },
                                            {
                                                "key": insightsLbls.totalPlayed + ":",
                                                "value": insightsLbls.totalGuidesPlayedTitle
                                            },
                                            {
                                                "key": insightsLbls.guidePlayed + ":",
                                                "value": insightsLbls.totalGuidesCompleteCount
                                            },
                                            {
                                                "key": insightsLbls.showMePlayed + ":",
                                                "value": insightsLbls.totalSlideshowVideoGIFCount
                                            },
                                            {
                                                "key": insightsLbls.testMePlayed + ":",
                                                "value": insightsLbls.totalTestPassCount
                                            },
                                            {
                                                "key": insightsLbls.doItForMePlayed + ":",
                                                "value": insightsLbls.totalNumberOfTimesGuidePlayedInDoItForMe
                                            },
                                            {
                                                "key": insightsLbls.tutorialGuidePlayed + ":",
                                                "value": insightsLbls.guidePlayedInTutorialMode
                                            }
                                        ]
                                    }

                                } placement="right"></SectionTooltip>
                            ) : (
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.top10Guides,
                                        "tooltipText": insightsLbls.tableTop10GuidesAcrossApps,
                                        "childern": [
                                            {
                                                "key": insightsLbls.guideTitle + ":",
                                                "value": insightsLbls.titleOfGuide
                                            },
                                            {
                                                "key": insightsLbls.appTitle + ":",
                                                "value": insightsLbls.appToWhichGuideBelong
                                            },
                                            {
                                                "key": insightsLbls.totalPlayed + ":",
                                                "value": insightsLbls.totalGuidesPlayedTitle
                                            },
                                            {
                                                "key": insightsLbls.guidePlayed + ":",
                                                "value": insightsLbls.totalGuidesCompleteCount
                                            },
                                            {
                                                "key": insightsLbls.tutorialGuidePlayed + ":",
                                                "value": insightsLbls.guidePlayedInTutorialMode
                                            }
                                        ]
                                    }
                                } placement="right"></SectionTooltip>
                            )}

                            <button className="pull-right pointer refreshBtn" onClick={() => refreshSections(4)}> {insightsLbls.refresh}</button>

                        </ContainerTooltip>
                        {dashboardGuideSummary.dashboardGuideSummaryFetched ? (
                            errorCount.errorCount[5] === 0 ? (
                                <div className="guideSummaryTable tabelEditing commonSummaryTable">
                                    <CommonExpandableTable tableId="3"
                                        data={fetched_details.dashboard.dashboardGuideSummaryTableData.data}
                                        currentPage={0}
                                        barColors=""
                                        linkPresent={true}
                                        showExport={false}
                                        showSearch={false}
                                        showEntries={false}
                                        defaultSort={dashboardGuideSummary.defaultSort}
                                        tablePagination={dashboardGuideSummary.tablePagination}
                                        tableEvent={handleTableEvents}
                                        firstTimeloadFlag={fetched_details.dashboard.dashboardGuideSummaryTableData.firstTimeload}
                                        hideDeleted={false}
                                        modalTable={false}
                                        source={"dashboard"}
                                        tableComponent={"guide"}
                                    />
                                </div>
                            ) : getServiceNotAvailableTemplate()
                        ) : getLoaderTemplate()}

                    </CardContainer></>)}



                {/* TOOLTIP TABLE SUMMARY */}
                {fetched_details.sectionVisibility.guideInsights_flag && (<>
                    <CardContainer
                        div1Classes={"col-sm-12 col-lg-12 col-md-12 padding0 marginTop-20"}
                        div2Classes={"card icon-text section-wrapper marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0"}
                        div3Classes={"card-body minHeight490  overflowY-hidden"}>
                        <ContainerTooltip
                            div1Classes={"graph-title displayFlex"}
                            div2Classes={"col-sm-12 col-lg-12 col-md-12 row"}
                            hTagClasses={"section-heading headingText tooltipBG"}
                        >
                            <SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.top10Tooltips,
                                    "tooltipText": insightsLbls.tableTop10GuidesAcrossApps,
                                    "childern": [
                                        {
                                            "key": insightsLbls.guideTitle + ":",
                                            "value": insightsLbls.titleOfGuide
                                        },
                                        {
                                            "key": insightsLbls.appTitle + ":",
                                            "value": insightsLbls.appToWhichGuideBelong
                                        },
                                        {
                                            "key": insightsLbls.totalPlaysLabel + ":",
                                            "value": insightsLbls.totalPlays
                                        },
                                        {
                                            "key": insightsLbls.guidanceLabel + ":",
                                            "value": insightsLbls.guidanceTooltip
                                        },
                                        {
                                            "key": insightsLbls.validationLabel + ":",
                                            "value": insightsLbls.validationTooltip
                                        },
                                        {
                                            "key": insightsLbls.guidanceAndValidation + ":",
                                            "value": insightsLbls.guidanceAndValidationTooltip
                                        },
                                        {
                                            "key": insightsLbls.digitalDuctTapeLabel + ":",
                                            "value": insightsLbls.digitalDuctTapeTooltip
                                        },
                                        {
                                            "key": insightsLbls.powerFormLabel + ":",
                                            "value": insightsLbls.powerFormTooltip
                                        }
                                    ]
                                }
                            } placement="right"></SectionTooltip>

                            <button className="pull-right pointer refreshBtn" onClick={() => refreshSections(6)}> {insightsLbls.refresh}</button>

                        </ContainerTooltip>

                        {dashboardTooltipSummary.dashboardTooltipSummaryFetched ? (
                            errorCount.errorCount[5] === 0 ? (
                                <div className="guideSummaryTable tabelEditing commonSummaryTable">
                                    <CommonExpandableTable tableId="3"
                                        data={fetched_details.dashboard.dashboardTooltipSummaryTableData.data}
                                        currentPage={0}
                                        barColors=""
                                        linkPresent={true}
                                        showExport={false}
                                        showSearch={false}
                                        showEntries={false}
                                        defaultSort={dashboardTooltipSummary.defaultSort}
                                        tablePagination={dashboardTooltipSummary.tablePagination}
                                        tableEvent={handleTableEvents}
                                        firstTimeloadFlag={fetched_details.dashboard.dashboardTooltipSummaryTableData.firstTimeload}
                                        hideDeleted={false}
                                        modalTable={false}
                                        source={"dashboard"}
                                        tableComponent={"tooltip"}
                                    />
                                </div>
                            ) : getServiceNotAvailableTemplate()
                        ) : getLoaderTemplate()}
                    </CardContainer>
                </>
                )}

                <div id="userEngagementSection marginTop-30">
                    <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height600 blockImportant marginTop-20">
                        <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                            div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent paddingBottom-20"}
                            div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
                            <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.userActivity,
                                        "tooltipText": insightsLbls.userActivityGraphDashboard,
                                        "childern": []
                                    }
                                } placement="right"></SectionTooltip>
                                <button className="pull-right pointer refreshBtn" onClick={() => refreshSections(5)} style={{ cursor: userEngagementLazyLoadChart.lazyLoad_flag ? 'pointer' : 'default' }}> {insightsLbls.refresh}</button>

                            </ContainerTooltip>

                            <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                {dashboardUserEngagementChart.dashboardUserEngagementChartDataFetched ? (
                                    errorCount.errorCount[20] === 0 ? (
                                        dashboardUserEngagementChart?.loadUserEngagementChartData?.length === 0 ? (
                                            <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <div className="noDataText-font textCenter-absolute">
                                                        {insightsLbls.noRecordFound}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (


                                            <div className="displayFlex">
                                                <div className="col-12 marginTop-30">
                                                    <LineChart
                                                        chartEntities={appsList.lineChartEntities}
                                                        optionEntities={dashboardUserEngagementChart.loadUserEngagementChartOptions}
                                                        chartData={dashboardUserEngagementChart.loadUserEngagementChartData}
                                                        value_type={"count"}
                                                        height={linechart_height}
                                                        margin={lineChartMargin}
                                                        yAxisLabel={insightsLbls.yAxisLabelCount}
                                                        refId="dashboard_userEngagement"
                                                        component='dashboard'>
                                                    </LineChart>
                                                </div>

                                            </div>

                                        )
                                    ) : getServiceNotAvailableTemplate(true)
                                ) : getLoaderTemplate()}
                            </div>

                        </CardContainer>
                    </div>
                </div>

                {/* SURVEY TABLE */}
                <CardContainer
                    div1Classes={"col-sm-12 col-lg-12 col-md-12 marginTop-20 padding0 "}
                    div2Classes={"card icon-text section-wrapper marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0"}
                    div3Classes={"card-body minHeight400  overflowY-hidden"}>
                    <ContainerTooltip
                        div1Classes={"graph-title displayFlex"}
                        div2Classes={"col-sm-12 col-lg-12 col-md-12 row"}
                        hTagClasses={"section-heading headingText tooltipBG"}
                    >
                        <SectionTooltip tooltipText={
                            {
                                "subTitle": insightsLbls.top10Survey,
                                "tooltipText": insightsLbls.tableTop10SurveysApps,
                                "childern": [
                                    {
                                        "key": insightsLbls.surveyName + ":",
                                        "value": insightsLbls.nameSurvey
                                    },
                                    {
                                        "key": insightsLbls.appTitle + ":",
                                        "value": insightsLbls.appToWhichSurveyBelong
                                    },
                                    {
                                        "key": insightsLbls.labels + ":",
                                        "value": insightsLbls.labelsToSurvey
                                    },
                                    {
                                        "key": insightsLbls.responses + ":",
                                        "value": insightsLbls.noOfResponsesForSurvey
                                    },
                                    {
                                        "key": insightsLbls.surveyDistribution + ":",
                                        "value": insightsLbls.distributionAnalyzedSurevyNegNeuPos
                                    },
                                    {
                                        "key": insightsLbls.lastUpdatedOn + ":",
                                        "value": insightsLbls.dateTimeofSurveyModification
                                    }
                                ]
                            }
                        } placement="right"></SectionTooltip>
                        <button className="pull-right pointer refreshBtn" onClick={() => refreshSections(2)}> {insightsLbls.refresh}</button>
                    </ContainerTooltip>

                    <div className="card-body cardPadding-bt100 tabelEditing search_section tableInherit commonSummaryTable marginTop-20">
                        <span>{"*" + insightsLbls.tableFilteredBasedOnApps}</span>
                        {dashboardSurveyData.dashboardSurveyDataFetched ? (
                            errorCount.errorCount[8] === 0 ? (
                                <CommonTable
                                    tableId="Surveys"
                                    data={fetched_details.dashboard.dashboardSurveysTableData.data}
                                    currentPage={0}
                                    barColors="positiveColors"
                                    linkPresent={true}
                                    showExport={false}
                                    showSearch={false}
                                    showEntries={false}
                                    showPagination={false}
                                    defaultSort={dashboardSurveyData.defaultSort}
                                    tablePagination={dashboardSurveyData.tablePagination}
                                    tableEvent={handleTableEvents}
                                    firstTimeloadFlag={
                                        fetched_details.dashboard.dashboardSurveysTableData.firstTimeload
                                    }
                                    modalTable={false}

                                />
                            ) : getServiceNotAvailableTemplate()
                        ) : getLoaderTemplate()}
                    </div>
                </CardContainer>
            </div>

            <CardContainer
                div1Classes={
                    "col-sm-12 col-lg-12 col-md-12 marginTop-20 padding0 "
                }
                div2Classes={
                    "card icon-text section-wrapper marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0"
                }
                div3Classes={"card-body minHeight490  overflowY-hidden"}
            >
                <ContainerTooltip
                    div1Classes={"graph-title displayFlex"}
                    div2Classes={"col-sm-12 col-lg-12 col-md-12 row"}
                    hTagClasses={"section-heading headingText tooltipBG"}
                >
                    <SectionTooltip tooltipText={
                        {
                            "subTitle": insightsLbls.whereUsersAccessFrom,
                            "tooltipText": insightsLbls.geoLocationChartCountryWise1,
                            "childern": []
                        }
                    } placement="right"></SectionTooltip>

                    <button className="pull-right pointer refreshBtn" onClick={() => refreshSections(3)}> {insightsLbls.refresh}</button>

                </ContainerTooltip>
                {dashboardCountriesChartData.dashboardCountriesChartDataFetched ? (
                    errorCount.errorCount[9] === 0 ? (
                        <div>
                            <WorldMap
                                // WorldMapData={fetched_details.dashboard.dashboardCountriesChartData.data}
                                WorldMapData={dashboardCountriesChartData.countryData}
                                boudaryValues={dashboardCountriesChartData.boundaryValues}
                                colors={dashboardCountriesChartData.colors}
                            ></WorldMap>
                            <div className="legendKey">
                                <div>
                                    {dashboardCountriesChartData.legendData.map((data: any, key: number) => {
                                        return (
                                            <span className="worldLegend"
                                                style={{ backgroundColor: data.color }} key={key}></span>
                                        );
                                    })}
                                    {/* "{backgroundColor: data.color}" */}
                                </div>
                                <div>
                                    {dashboardCountriesChartData.legendData.map((data: any, key: number) => {
                                        return (
                                            <span className="worldLegend font14"
                                            >≥{data.value}</span>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="countryListDiv">
                                <div className="countryNameDiv">
                                    <p className="font14 chipParagraph">
                                        {
                                            dashboardCountriesChartData.countryData.map((country: any) => {
                                                return (
                                                    <>
                                                        <span className="infoChip maxWidth250 displayFlex countryNameChip" style={{ backgroundColor: `${country.color}` }} data-tip data-for={country.countryName}>
                                                            <span className="maxWidth200 countryLabel"
                                                            >{country.countryName}</span>
                                                            <span>:&nbsp;{country.count}</span>
                                                            <ReactTooltip id={country.countryName} place="bottom">{country.countryName}: {country.count}</ReactTooltip>
                                                        </span>
                                                    </>
                                                );
                                            })
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : getServiceNotAvailableTemplate()
                ) : getLoaderTemplate()}
            </CardContainer>



            <CommonModal
                modalState={exportModal.open}
                dispatchModalState={closeExportModal}
                modalTitle={insightsLbls.exportDashboardData}
                size={'lg'}
                footerDisabled={true}
                exportModal={false}
                padding={true}
                modalDialogClass={''}

                dispatchModalCancel={closeExportModal}

            // okBtnState={exportModal.exportDisabled}
            >
                <div className="exportDiv minHeight200">

                    <div className='exportFilter'>
                        <div className="displayFlex">
                            <Monthpicker errorDispacthed={getErrorFlag} datesDispatch={getDates}></Monthpicker>
                        </div>

                        <div className="displayFlex marginTop-10 paddingTop-20 paddingBottom-50 paddingLeft-76 positionRelative">
                            <AppListDropdown finalApps={getSelectedAppsForExport} getTotalAppList={getTotalAppList} fromComponent="exportModal" component="exportModal"></AppListDropdown>
                            <EventSource
                                component={"dashboardExportModal"}
                                outerDivClass={'marginLeft-30 positionRelative'}
                                eventSourceValue={getEventSourceValues}
                                defaultValue={exportModal.defaultValue}></EventSource>
                        </div>
                    </div>

                    <div className="displayFlex height100-percent exportExcel-div positionRelative">
                        <div className="col-lg-12 paddingTB-20 displayFlex btns-div">
                            <div className="downloadCsv col-lg-6 padding0 displayFlex">
                                <button className="btn btn-primary font13 exportModal-btn" disabled={exportModal.exportDisabled} onClick={() => exportDashboardData(false)}>
                                    <i className="fa fa-download marginRight-5" aria-hidden="true"></i>
                                    {insightsLbls['download']}
                                </button>
                            </div>
                            <div className="marginTop-25">
                                <div className="vl"></div>
                                <div className="or-div">
                                    {insightsLbls['or']}
                                </div>
                            </div>
                            <span className="text font14 marginLeft-10 font500">
                                {insightsLbls['excelViewerQn']}
                            </span>
                            <div className="downloadCsv col-lg-6 padding0 displayFlex">
                                <button className="btn btn-primary font13 exportModal-btn" disabled={exportModal.exportDisabled} onClick={() => exportDashboardData(true)}>
                                    <i className="fa fa-cloud marginRight-5" aria-hidden="true"></i>
                                    {insightsLbls['viewOnline']}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonModal>

            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}
        </section>
    );
};

export default withRouter(withReducer("dashboard", dashboardReducer)(Dashboard));
