
import React from 'react';
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

import { Dropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import { getCall } from "utils/ApiCallActions";
import { RetryApi } from "utils/GlobalConstants";
import { LanguageState } from "../../store/Reducers";

import axios from "axios";
import { useErrorBoundary } from 'react-error-boundary';

import './AppListDropDown.scss';





import { InsightLabels } from 'labels';
import Icon from 'components/Icon/Icon';

interface CurrentState {
    languageData: LanguageState
}

interface CheckedInterFace {
    checked: any[],
    selectAllChecked: boolean,
    checkedTrue: any[],
    checkedFalse: any[]
    fromComponent: string
}

const AppListDropDown = (props: any) => {

    let fetched_details = useSelector((state: CurrentState) => state);

    const location = useLocation();
    const { showBoundary } = useErrorBoundary();
    const AppListMount = useRef(false);

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    const [appListDropDown, setAppListDropDown] = useState({
        isOpen: false,
        appList: [],
        appLength: 0,
        appliedChecked: [],
        appsFetched: false,
        selectedTitle: ''
    });

    const [checked, setChecked] = useState<CheckedInterFace>({
        checked: [],
        selectAllChecked: true,
        checkedTrue: [],
        checkedFalse: [],
        fromComponent: ''
    });

    const total_appCount = 10;

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if(fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
            getAppList();
        }
     
    },[fetched_details.languageData.languageData])

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@



    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getAppList = (apiErrorFlag?: string) => {
        try {
            let cancelToken: any;
            if (typeof cancelToken !== typeof undefined) {
                cancelToken.cancel(insightsLbls.tokenCancelledMsg);
            }

            cancelToken = axios.CancelToken.source();

            let params = {
                "type": 'org',
                "sort_by": "app_name",
                "order": "asc",
                "search_text": '',
                "page_index": 1,
                "page_size": 9999,
                "hide_deleted": 0
            };

            let checkedTrue: any = [];

            let checkedFalse: any = [];

            let dashboardAppList: any = localStorage.getItem('DASHBOARD_APP_LIST');
            dashboardAppList = JSON.parse(dashboardAppList);
            let cnt = 0;

            getCall(params, 'APP_LIST', apiErrorFlag ?? '').then((data: any) => {
                if (data?.result === 'success') {
                    let totalSelectionCount = data.data.total_count

                    let checkedIndexes = [];
                    for (let i = 0; i < totalSelectionCount; i++) {
                        if (props.fromComponent === 'dashboard') {

                            let flag = 0;
                            if (dashboardAppList == null) {
                                checkedFalse[i] = false;
                                checkedTrue[i] = i < total_appCount ? true : false;
                            } else {
                                checkedFalse[i] = false;

                                for (let itr0 = 0; itr0 < dashboardAppList.length; itr0++) {
                                    if (data.data.apps[i].app_id == dashboardAppList[itr0].app_id) {
                                        flag = 1;
                                        cnt = cnt + 1;
                                        break;
                                    }
                                }
                            }

                            if (dashboardAppList) {
                                if (flag === 1) {
                                    checkedTrue[i] = true;
                                } else {
                                    checkedTrue[i] = false;
                                }

                            }
                        } else {
                            checkedTrue[i] = true;
                            checkedFalse[i] = false;
                        }
                    }

                    setChecked((prevState: any) => {
                        return {
                            ...prevState,
                            checked: checkedTrue,
                            checkedTrue: checkedTrue,
                            checkedFalse: checkedFalse,
                            fromComponent: props.fromComponent
                        };
                    });

                    setAppListDropDown((prevState: any) => {
                        return {
                            ...prevState,
                            appList: data.data.apps,
                            appLength: props.fromComponent === 'dashboard' ? (dashboardAppList == null ? (data.data.total_count < total_appCount ? data.data.total_count : total_appCount) : cnt) : data.data.total_count,
                            appliedChecked: checkedTrue,
                            appsFetched: true,
                        };
                    });

                    let finalChecked = [...checkedTrue];
                    let finalAppList = [...data.data.apps];

                    let j = 0
                    for (let i = 0; i < finalChecked.length; i++) {
                        if (!finalChecked[i]) {
                            finalAppList.splice(j, 1);
                        }
                        else {
                            j++;
                        }
                    }
                    if (AppListMount.current === false) {

                        setAppListDropDown((prevState: any) => {
                            return {
                                ...prevState,
                                selectedTitle: props.fromComponent === 'dashboard' ? (finalAppList.length ? finalAppList[0]['app_name'] : data.data.apps[0]['app_name']) : data.data.apps[0]['app_name']
                            }
                        })


                        if (props.fromComponent === 'dashboard') {
                            localStorage.setItem("DASHBOARD_APP_LIST", JSON.stringify(finalAppList));

                        }

                        props.finalApps(finalAppList)
                        if (props.fromComponent === "exportList") {
                            props.getTotalAppList(data.data.apps)
                        }
                    }
                    AppListMount.current = true

                } else if (data.result === 'retry') {
                    setTimeout(() => {
                        getAppList('retry')
                    }, RetryApi.TIMEOUT)
                }
                else if (
                    data?.result === "error"
                ) {
                    setAppListDropDown((prevState: any) => {
                        return {
                            ...prevState,
                            appsFetched: true,
                        };
                    });
                }
            });
        } catch (error) {
            showBoundary(error)
        }
    }

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const toggleDropDown = (event: boolean, toggledFrom: string) => {
        try {
            setAppListDropDown((prevState: any) => {
                return {
                    ...prevState,
                    isOpen: event,
                };
            });

            if (AppListMount.current === true && event === false && toggledFrom !== "apply") {
                setChecked((prevState: any) => {
                    let appliedChecked = appListDropDown.appliedChecked

                    if (appliedChecked.every((value) => value === true)) {
                        return {
                            ...prevState,
                            checked: appliedChecked,
                            selectAllChecked: true,
                        };
                    }
                    else if (appliedChecked.every((value) => value === false)) {
                        return {
                            ...prevState,
                            checked: appliedChecked,
                            selectAllChecked: false,
                        };
                    }
                    else {
                        return {
                            ...prevState,
                            checked: appliedChecked,
                            selectAllChecked: false,
                        };
                    }
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    };

    const handleSelectAll = (e: any) => {
        try {
            if (props.fromComponent !== 'dashboard') {
                setChecked((prevState: any) => {
                    if (prevState.selectAllChecked === false) {
                        return {
                            ...prevState,
                            selectAllChecked: true,
                            checked: checked.checkedTrue
                        };
                    }
                    else {
                        return {
                            ...prevState,
                            selectAllChecked: false,
                            checked: checked.checkedFalse
                        };
                    }
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    };

    const handleSelect = (e: any) => {
        try {
            selectChecked(e.target.value, e.target.checked)
        } catch (error) {
            showBoundary(error)
        }

    };

    const selectChecked = (index: number, option: boolean) => {
        try {
            setChecked((prevState: any) => {
                if (option === true) {

                    let newChecked = [...checked.checked];


                    if (props.fromComponent === 'dashboard') {
                        let cnt = 0;
                        for (let itr1 = 0; itr1 < newChecked.length; itr1++) {
                            if (newChecked[itr1]) {
                                cnt++;
                            }
                        }

                        if (cnt === total_appCount) {
                            for (let itr1 = 0; itr1 < newChecked.length; itr1++) {
                                if (newChecked[itr1]) {
                                    newChecked[itr1] = false;
                                    break;
                                }
                            }
                        }
                    }

                    newChecked[index] = option;

                    if (props.fromComponent === 'dashboard') {
                        return {
                            ...prevState,
                            checked: newChecked,
                            selectAllChecked: false,
                        };

                    } else {
                        if (newChecked.every((value) => value === true)) {
                            return {
                                ...prevState,
                                checked: newChecked,
                                selectAllChecked: true,
                            };
                        }
                    }

                    return {
                        ...prevState,
                        checked: newChecked,
                        selectAllChecked: false,
                    };
                }
                else if (option === false) {
                    let newChecked = [...checked.checked];
                    newChecked[index] = option;
                    return {
                        ...prevState,
                        checked: newChecked,
                        selectAllChecked: false,
                    };
                }
            });
        } catch (error) {
            showBoundary(error)
        }
    };

    const handleApply = () => {
        try {
            let finalChecked = [...checked.checked];
            let finalAppList = [...appListDropDown.appList];

            let j = 0
            for (let i = 0; i < finalChecked.length; i++) {
                if (!finalChecked[i]) {
                    finalAppList.splice(j, 1);
                }
                else {
                    j++;
                }
            }

            setAppListDropDown((prevState: any) => {
                return {
                    ...prevState,
                    appLength: finalAppList.length,
                    appliedChecked: finalChecked,
                    selectedTitle: finalAppList[0]['app_name']

                };
            });

            if (props.fromComponent === 'dashboard') {
                localStorage.setItem("DASHBOARD_APP_LIST", JSON.stringify(finalAppList));
            }

            props.finalApps(finalAppList)
            toggleDropDown(false, "apply");
        } catch (error) {
            showBoundary(error)
        }
    };


    return (
        <div className={props.fromComponent === "dashboard" ? ("appListDropdownDashboard") : (props.fromComponent === "exportModal" ? ("appListDropdownExportModal") : ("appListDropdown"))}>
            {props.component !== 'exportEmailGuide' && (
                <p className={props.fromComponent === "exportModal" ? "applicationModalFilterTitle" : "applicationFilterTitle"}>{insightsLbls.applications}</p>
            )}
            <Dropdown id="modalDropdownButton" show={appListDropDown.isOpen} onToggle={(event) => toggleDropDown(event, "dropdown")}>
                <Dropdown.Toggle className={props.fromComponent === "dashboard" ?
                    ("source-but-app-list-dashboard text-alignLeft")
                    : (props.fromComponent === "exportModal" ?
                        ("source-but-app-list-exportModal text-alignLeft")
                        : ("source-but-app-list text-alignLeft")
                    )
                    + " btn btn-lg positionAbsolute bootstrapDCWorkaround2 "
                }
                >
                    {appListDropDown.appLength - 1 > 0 && (
                        <span className="app-count">+{appListDropDown.appLength - 1}</span>
                    )}
                    
                    <Icon img={"img/apps_ic_old.svg"} className="app_icon switchApp-btn-icons marginRight-25 dropDownIcon" />
                    <span className="dropDownText">{appListDropDown.selectedTitle}</span>
                    <i aria-hidden="true" className={"switchApp-btn-icon " + (appListDropDown.isOpen ? "fa fa-chevron-up" : "fa fa-chevron-down")}></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className={props.fromComponent === "dashboard" ? ("source-app-list-dashboard") : (props.fromComponent === "exportModal" ? ("source-app-list-exportModal") : ("source-app-list")) + " dropdown-menu"} id="app_list_dropdown_menu">

                    {props.fromComponent !== 'dashboard' && (
                        <>
                            <div className="custom-control custom-checkbox mr-sm-2 displayFlex alignCenter marginLeft-20 marginTop-10 check-container
                                padding-chk-0 marginBottom-10">
                                <div className="custom-control custom-checkbox mr-sm-2 check-container padding-chk-0 pointer">
                                    <label className="check-container displayFlex alignCenter exportCheck marginBottom-0 marginLeft--5">
                                        <input type="checkbox" className="custom-control-input chk checkbox" id="customControlAutosizingEvntSrc"
                                            name="chk-box" value="all" onChange={handleSelectAll} checked={checked.selectAllChecked}></input>
                                        <span className="checkmark checkmarkBox-color displayInlineBlock top0"></span>
                                        <span className="marginLeft-10 minWidth120 maxWidth120 ellipsisText120 text-alignLeft">{insightsLbls.selectAll}</span>
                                    </label>
                                </div>
                            </div>
                        </>
                    )}


                    <hr className="marginBottom-0 marginTop-0"></hr>
                    <div className="dropdown-menu-Applist">

                        {
                            appListDropDown.appList.map((source: any, index: number) => {
                                return (
                                    <div key={index} className="custom-control custom-checkbox mr-sm-2 displayFlex alignCenter  marginLeft-20 marginTop-10 check-container padding-chk-0">
                                        <div className="custom-control custom-checkbox mr-sm-2 check-container padding-chk-0 pointer">
                                            <label className="check-container displayFlex alignCenter exportCheck marginBottom-0 marginLeft--5">

                                                <input type="checkbox" className={"custom-control-input chk checkbox eventFiltermenu filter_" + index}
                                                    id="customControlAutosizing" name={source.app_name}
                                                    value={index} onChange={(ev) => handleSelect(ev)} checked={checked.checked[index]}></input>
                                                <span className="checkmark checkmarkBox-color displayInlineBlock top0"></span>
                                                <span className="marginLeft-10 minWidth120 maxWidth120 ellipsisText120 text-alignLeft">{source.app_name}</span>
                                                <span className="paddingDeleted font12 deletedText">  {source.is_archived ? `(${insightsLbls.deleted})` : null}</span>


                                            </label>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <hr className="marginBottom-8 marginTop-0"></hr>
                    <div className="applyButton">
                        <button className="modal-save-btn events-apply-btn" onClick={handleApply} disabled={checked.checked.every((value) => value === false)}>{insightsLbls.apply}</button>
                    </div>


                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default React.memo(AppListDropDown);
