import React from "react";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";


import { useDispatch } from "react-redux";
import { toast } from "react-toastify";


import "./LanguageFilter.scss";
import { setLanguage } from "../../store/Actions";
import { patchCall } from "utils/ApiCallActions";

import { CommonUtils } from "utils/CommonUtils";
import { useErrorBoundary } from 'react-error-boundary';
import Icon from "components/Icon/Icon";

const LanguageFilter = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    const dispatch = useDispatch();

    const getSelectedState = () => {
        try {
            const selectedLang = localStorage.getItem('WEB_APP_LANGUAGE');
            if (selectedLang === null || selectedLang === '' || selectedLang === undefined) {
                switch (process.env.REACT_APP_LANGUAGE) {
                    case 'english':
                        return 'English (US)';

                    default:
                        return 'English (US)';
                }
            } else {
                switch (selectedLang) {
                    case 'en_US':
                        return 'English (US)';

                    case 'fr_CA':
                        return 'French (Canada)';

                    case 'fr_FR':
                        return 'French (France)';

                    case 'ja_JP':
                        return 'Japanese (Japan)';

                    case 'ko_KR':
                        return 'Korean (Korea)';

                    default:
                        return 'English (US)';
                }
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    const [stateFilter, setStatusFilter] = useState({
        isOpen: false,
        selectedState: getSelectedState()
    });

    let filterState = [
        { key: 'en_US', value: 'English (US)' },
        { key: 'fr_CA', value: 'French (Canada)' },
        { key: 'fr_FR', value: 'French (France)' },
        { key: 'ja_JP', value: 'Japanese (Japan)' },
        { key: 'ko_KR', value: 'Korean (Korea)' }
    ];

    const toggleDropDown = (event: boolean) => {
        try {
            setStatusFilter((prevState: any) => {
                return {
                    ...prevState,
                    isOpen: event,
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const changeStatus = (key: string, value: string) => {
        try {
            setStatusFilter((prevState: any) => {
                return {
                    ...prevState,
                    isOpen: false,
                    selectedState: value
                };
            });

            const data = {
                languageData: key
            };

            localStorage.setItem('WEB_APP_LANGUAGE', key);


            const params = {};
            const urlPath = 'GET_LANGUAGE';

            let payload = {
                "preferredLanguage": {
                    "UILanguagePreference": key
                }
            }

            // window.location.reload();
            patchCall(params, payload, urlPath, '').then((data: any) => {
                if (data.result === 'success') {
                    toast.success(data.data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    dispatch(setLanguage(CommonUtils.getLanguageChangeData()));
                    // window.location.reload();
                    setTimeout(() => {
                        toast.dismiss();
                    }, 5000);

                } else if (data.result === 'error') {
                    if (data.error) {
                        toast.error(data.error, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setTimeout(() => {
                            toast.dismiss();
                        }, 5000);
                    }
                }
            })
        } catch (error) {
            showBoundary(error)
        }


    };

    return (
        <div>
            <Dropdown id="language-filter-dropdown" show={stateFilter.isOpen} onToggle={(event) => toggleDropDown(event)}>
                <Dropdown.Toggle className={"btn btn-lg positionAbsolute bootstrapDCWorkaround2 " + (props.component === "customDashboard" ? "source-but-state-filter-dashboard buttonPositionStateFilter" : "source-but-state-filter buttonPositionStateFilter")}>
                    {/* <img src={require('../../assets/')} className="languageIcon" /> */}
                    {/* <Icon className="languageIcon" img="img/language.svg"/> */}
                    <span className="langFilterFont">{stateFilter.selectedState}</span>
                    <i aria-hidden="true" className={"switchApp-btn-icon " + (stateFilter.isOpen ? "fa fa-chevron-up" : "fa fa-chevron-down")}></i>
                </Dropdown.Toggle>

                {/* dropdown-menu-state-filter userList-dropdown  */}
                <Dropdown.Menu className={"dropdown-menu " + (props.component === "customDashboard" ? "source-menu-state-filter-dashboard" : (props.component === 'guideAnalytics' ? "source-menu-state-filter guideAnalytics-source-menu-state-filter" : "source-menu-state-filter"))}>
                    {
                        filterState.map((state: any, index: number) => {
                            return (
                                <div className="dropdown-status-menu" key={index}>
                                    <button onClick={() => changeStatus(state.key, state.value)} id={"status_" + state.key}
                                        className={"pointer dropdown-menu-item statusBtn " + (stateFilter.selectedState === state.value ? "active-button" : "")}>
                                        {state.value}
                                    </button>
                                </div>
                            );
                        })
                    }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default LanguageFilter;